import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';
import { fbLogPurchase } from 'facebook/Facebook';
import { gaLogBeginCheckout, gaLogPageView, gaLogPaymentInfo } from 'ecommerce_ga_events/EcommerceGAEvents';
import { Constants } from 'app/utilities';
import { Svg } from 'app/components/common';
import { CART_AVAILABLE_OFFERS_REQUEST, ORDERS_CREATE_REQUEST } from 'app/store/ActionTypes';
import * as Actions from 'app/store/Actions';
import ConfirmOrder from 'app/layout/checkout/ConfirmOrder';
import { cartPaymentUpdateRequest } from 'app/store/cart/actions';
import orderApis from 'api/order';
import { simplChargeAPI } from 'api/api';
// eslint-disable-next-line import/no-cycle
import Cart from '../../Cart';
import * as buttonActions from '../../../../events/buttonActions';
import * as screens from '../../../../events/screens';
import { logButtonClick } from '../../../../events/Events';
import '../../../../styles/cart/cart.scss';
import BillDetails from '../bill/BillDetails';

const { CancelToken } = Axios;

class MakePayment extends React.Component {
  constructor(props) {
    super(props);
    const {
      cartDetails,
    } = props;
    let paymentPreference = '';
    let deliveryOption = -1;
    if (cartDetails) {
      paymentPreference = cartDetails.paymentPreference === 'NOT DEFINED' ? '' : cartDetails.paymentPreference;
      deliveryOption = cartDetails.deliveryPreference === 'STORE PICKUP' ? 1 : 0;
    }
    this.state = {
      warnPaymentPreference: false,
      paymentPreference,
      processingPayment: false,
      failureOrder: [],
      deliveryOption,
      isPayOnlineOnDelivery: false,
      isPayWithSimpl: false,
      eligibleData: null,
      simplChargeData: null,
    };
    this.source = CancelToken.source();
  }

  componentDidMount() {
    const script = document.createElement('script');
    if (!document.getElementById('razorpay-checkout')) {
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.id = 'razorpay-checkout';
      document.body.appendChild(script);
    }
    gaLogPageView();
    const { cartLoadRequest, cartAvailableOffersLoadRequest } = this.props;
    cartLoadRequest(
      {
        isCheckout: false,
      },
      {
        type: 'sync',
        callback: () => { },
      },
    );
    cartAvailableOffersLoadRequest(0, null, null, null);
  }

  componentDidUpdate() {
    const { updateStateRequest } = this.props;
    const { simplChargeData } = this.state;
    updateStateRequest({ simplChargeData });
  }

  updateCartDetails = () => {
    const { cartLoadRequest } = this.props;
    cartLoadRequest();
  }

  logGAonCheckout = (cartDetails) => {
    const cartProducts = cartDetails.cartProducts.map(
      (item) => ({
        item_id: item.libraryProductId,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      }),
    );
    gaLogBeginCheckout(
      cartDetails.totalBill,
      cartProducts,
    );
  }

  afterSimplCheckoutQueries = () => {
    const {
      history, cartNullRequest, toggleSideOverlay, cartDetails,
      eligibleData,
    } = this.props;
    simplChargeAPI('post', {
      amount: cartDetails.totalBill * 100,
      token: eligibleData.data.token,
      cartId: cartDetails.cartId,
    })
      .then((response) => {
        const order = response.data.data;
        this.setState(
          {
            simplChargeData: response.data.data,
          },
        );
        if (response.data.data.status === 'CLAIMED') {
          toggleSideOverlay(null);
          this.updateCartDetails();
          history.replace(`/success/${order.orderId}`, {
            isFirstLoad: true,
          });
          cartNullRequest();
        }
      })
      .catch((error) => {
        this.setState({
          eligibleData: null,
          // eslint-disable-next-line react/no-unused-state
          error,
        });
      });
  }

  componentWillUnmount = () => {
    const { cartResetRequestStatus } = this.props;
    this.source.cancel();
    cartResetRequestStatus([
      CART_AVAILABLE_OFFERS_REQUEST,
    ]);
  }

  updateCartOffers = () => {
    const { cartAvailableOffersLoadRequest } = this.props;
    cartAvailableOffersLoadRequest(0, null, null, null);
  }

  afterCheckoutQueries = (order) => {
    const { history, cartNullRequest, toggleSideOverlay } = this.props;
    toggleSideOverlay(null);
    this.updateCartDetails();
    history.replace(`/success/${order.orderId}`, {
      isFirstLoad: true,
    });
    cartNullRequest();
  }

  processPayment = (paymentId = '', order = {}) => {
    orderApis.orderPaymentVerification(paymentId).then((res) => {
      this.afterCheckoutQueries(res.data.data || order);
    }).catch((err) => {
      let failureOrder = [];
      if (
        err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        failureOrder = err.response.data.errors;
        return;
      }
      failureOrder = [{
        message: 'Payment Failed',
      }];
      this.setState({
        failureOrder,
        processingPayment: false,
      });
    });
  }

  makePayment = (order = {}) => {
    const { language } = this.props;
    this.setState({
      processingPayment: true,
    });
    const paymentInfo = order;
    const options = {
      key: process.env.REACT_APP_RZP_KEY,
      amount: paymentInfo.payment_details.amountInPaise,
      currency: 'INR',
      name: paymentInfo.payment_details.storeName,
      description: paymentInfo.payment_details.orderDescriptio || '',
      image: paymentInfo.payment_details.storeLogo,
      order_id: paymentInfo.payment_details.razorpayOrderId,
      handler: (response) => {
        if (response && response.razorpay_order_id) {
          this.setState({
            processingPayment: true,
          });
          this.processPayment(response.razorpay_order_id);
          return;
        }
        this.setState({
          processingPayment: false,
        });
      },
      prefill: {
        email: paymentInfo.payment_details.customer_details.email,
        contact: paymentInfo.payment_details.customer_details.phoneNumber,
        name: paymentInfo.payment_details.customer_details.name,
      },
      theme: {
        color: Constants.Color.primary,
      },
      modal: {
        escape: false,
        ondismiss: () => {},
      },
    };
    try {
      this.setState({
        processingPayment: false,
      });
      const checkoutRazorpay = new window.Razorpay(options);
      checkoutRazorpay.open();
    } catch (error) {
      this.setState({
        failureOrder: error.res
          ? error.res.data
          : [{
            message: Constants.String.OOPS[language],
          }],
        processingPayment: false,
      });
    }
  }

  createOrders = () => {
    const {
      ordersCreateRequest, language, cartDetails,
    } = this.props;
    const { paymentPreference, isPayOnlineOnDelivery, isPayWithSimpl } = this.state;
    fbLogPurchase(cartDetails.totalBill);
    ordersCreateRequest(
      {
        cartId: cartDetails.cartId,
        isPayOnlineOnDelivery,
        // isPayWithSimpl,
      },
      {
        type: 'sync',
        callback: (data) => {
          const order = data.res;
          if (paymentPreference === 'DIGITAL') {
            if (isPayOnlineOnDelivery) {
              this.afterSimplCheckoutQueries(order);
            } else if (isPayWithSimpl) {
              this.afterSimplCheckoutQueries(order);
            } else {
              this.makePayment(order);
            }
          } else {
            this.afterCheckoutQueries(order);
          }
        },
      },
      {
        type: 'sync',
        callback: (error) => {
          this.setState({
            failureOrder: (error.res && error.res.data)
              ? error.res.data.errors
              : [{
                message: Constants.String.OOPS[language],
              }],
          });
        },
      },
    );
  }

  updateCheckoutStore = (paymentPreference = '') => {
    const { paymentPreferenceRequest, cartDetails } = this.props;
    const { couponCode } = this.state;
    const updatedPaymentPreference = paymentPreference === 'SIMPL' ? 'DIGITAL' : paymentPreference;

    this.setState({
      paymentPreference: updatedPaymentPreference,
      isPayOnlineOnDelivery: paymentPreference === 'SIMPL',
      // isPayWithSimpl: paymentPreference === 'SIMPL',
    });
    paymentPreferenceRequest(
      {
        paymentPreference: updatedPaymentPreference,
      },
      null,
      null,
    );
    gaLogPaymentInfo(
      cartDetails.totalBill,
      couponCode,
      paymentPreference,
      cartDetails.cartProducts.map((item) => ({
        item_id: item.libraryProductId,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    );
  }

  render() {
    const {
      cartDetails, toggleSideOverlay,
      isMobile, language, processing,
    } = this.props;

    const {
      [ORDERS_CREATE_REQUEST]: processingOrder,
    } = processing;

    const {
      warnPaymentPreference, processingPayment, failureOrder,
      deliveryOption, paymentPreference,
      isPayOnlineOnDelivery, isPayWithSimpl, eligibleData, simplChargeData,
    } = this.state;
    const uncheckedPaymentPreferences = paymentPreference === '';

    if (!cartDetails) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    return (
      <Container
        fluid
        className="px-0 d-flex flex-column h-100"
      >
        <Row
          className="mx-0 py-4 align-items-center"
        >
          <Button
            variant="link"
            className={isMobile ? 'd-none' : 'py-0'}
            onClick={() => {
              toggleSideOverlay(Cart);
            }}
          >
            <Svg
              svg="leftArrow"
              width="16px"
              fill={Constants.Color.black}
            />
          </Button>
          <Col
            xs="auto"
            className="font-weight-black flex-grow-1 pl-md-0"
          >
            Your Payment Options
          </Col>
          <Col
            xs="auto"
            className="cursor-pointer"
            onClick={() => {
              toggleSideOverlay(null);
            }}
          >
            <Svg
              svg="close"
              width="24px"
              fill={Constants.Color.mediumLight}
            />
          </Col>
        </Row>
        <Row
          className="py-1 bg-light mx-0"
        />
        <Row className="mx-0 d-flex h-100 bg-light flex-grow overflow-y-scroll">
          <Col xs={24}>
            <Row
              className="mx-0 mb-3"
            >
              <Col
                xs={24}
                className={`bg-light overflow-hidden
                  ${warnPaymentPreference ? 'warning' : ''}
                `}
              >
                <Row>
                  <Col
                    xs={24}
                    className=""
                  >
                    <ConfirmOrder
                      cartDetails={cartDetails}
                      updateCheckoutStore={this.updateCheckoutStore}
                      isPayOnlineOnDelivery={isPayOnlineOnDelivery}
                      isPayWithSimpl={isPayWithSimpl}
                      language={language}
                      totalPayAmount={cartDetails.totalBill}
                      createOrders={this.createOrders}
                      makePayment={this.makePayment}
                      processingPayment={processingPayment}
                      processingOrder={processingOrder}
                      failureOrder={failureOrder}
                      deliveryOption={deliveryOption}
                      paymentPreference={paymentPreference}
                      checkEligibilityApproval={this.checkEligibilityApproval}
                      eligibleData={eligibleData}
                      simplChargeData={simplChargeData}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className="mt-auto align-self-end">
            <BillDetails language={language} cartDetails={cartDetails} />
          </Col>
        </Row>
        <Row
          className="mx-0 mt-3 align-items-center"
        >
          <Col
            xs={24}
            className="bg-white border-radius-8 shadow-sm"
          >
            {
              failureOrder && failureOrder.length > 0
                ? (
                  <Row
                    className="pb-3 fs-6 justify-content-center text-danger"
                  >
                    <Col
                      xs={24}
                      className="text-justify"
                    >
                      {failureOrder[0].message}
                    </Col>
                  </Row>
                ) : ''
            }
            <Row
              className="pb-3"
            >
              <Col
                xs={24}
              >
                <Button
                  block
                  variant="primary"
                  className="py-2 font-weight-black fs-5 border-radius-8"
                  disabled={processingOrder || processingPayment
                    || uncheckedPaymentPreferences
                    || deliveryOption === -1}
                  onClick={() => {
                    if (uncheckedPaymentPreferences) {
                      this.setState({
                        warnPaymentPreference: true,
                      });
                      setTimeout(() => {
                        this.setState({
                          warnPaymentPreference: false,
                        });
                      }, 2000);
                      return;
                    }
                    logButtonClick({
                      BUTTON: buttonActions.PLACE_ORDER,
                      SCREEN: screens.CHECKOUT,
                      CART_ID: cartDetails.cartId,
                      PAYABLE_AMOUNT: cartDetails.totalBill,
                    });
                    this.createOrders();
                  }}
                >
                  {Constants.String.PLACE_ORDER[language].toUpperCase()}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartDetails: state.main.cartDetails,
  cartAvailableOffers: state.main.cartAvailableOffers,
  cartItems: state.main.cartItems,
  highlightCartOfferDetails: state.main.highlightCartOfferDetails,
  processing: state.main.requestsProcessing,
  eligibleData: state.main.eligibleData,
  simplChargeData: state.main.simplChargeData,
});

const mapDispatchToProps = (dispatch) => ({
  cartAvailableOffersLoadRequest: (
    offset, onSuccess, cancelToken, storeId,
  ) => {
    dispatch(
      Actions.cartAvailableOffersRequest(
        offset, 20, onSuccess, cancelToken, storeId,
      ),
    );
  },
  cartResetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  paymentPreferenceRequest: (
    body,
    onSuccess,
    onFailure,
  ) => {
    dispatch(
      cartPaymentUpdateRequest.updateRequest(
        onSuccess,
        onFailure,
        body,
        {},
      ),
    );
  },
  ordersCreateRequest: (cartData, onSuccess, onFailure) => {
    dispatch(Actions.ordersCreateRequest(cartData, onSuccess, onFailure));
  },
  cartNullRequest: () => {
    dispatch(Actions.cartNullRequest());
  },
  toggleScratchCard: (scratchCard) => {
    dispatch(Actions.toggleScratchCard(scratchCard));
  },
  updateStateRequest: (data) => {
    dispatch(Actions.updateState(data));
  },
});

MakePayment.propTypes = {
  cartDetails: PropTypes.shape({
    cartProductsCount: PropTypes.number,
    cartCustomProductsCount: PropTypes.number,
    cartId: PropTypes.number,
    totalBill: PropTypes.number,
    totalSavings: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      locality: PropTypes.string,
      type: PropTypes.string,
    }),
    cartProducts: PropTypes.arrayOf({}),
    cartCustomProducts: PropTypes.arrayOf({}),
    homeDeliveryRemainingMOV: PropTypes.number,
    paymentPreference: PropTypes.string,
    deliveryPreference: PropTypes.string,
  }),
  cartAvailableOffersLoadRequest: PropTypes.func.isRequired,
  cartResetRequestStatus: PropTypes.func.isRequired,
  cartAvailableOffers: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf({}),
  }),
  cartItems: PropTypes.shape({
    productInfo: PropTypes.shape({}),
  }),
  cartLoadRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  cartAddRequest: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string,
  paymentPreferenceRequest: PropTypes.func.isRequired,
  ordersCreateRequest: PropTypes.func.isRequired,
  cartNullRequest: PropTypes.func.isRequired,
  toggleScratchCard: PropTypes.func.isRequired,
  processing: PropTypes.shape({}).isRequired,
  updateStateRequest: PropTypes.func.isRequired,
  eligibleData: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      availableCredit: PropTypes.string.isRequired,
      errorCode: PropTypes.string,
      redirectionUrl: PropTypes.string,
      status: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
      errorString: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

MakePayment.defaultProps = {
  cartDetails: null,
  cartAvailableOffers: null,
  cartItems: null,
  language: 'en',
};

export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);
