import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import Axios from 'axios';
import { specialOffers } from 'api/api';
import { Placeholder, Svg } from 'app/components/common';
import BogoOfffer from 'app/components/derived/offer/BOGOOffer';
import { SHOP_FRONT } from 'app/events/screens';
import { Constants } from 'app/utilities';

function ComboOffersSeeAll({
  storeId: storeIdFromProp, match, isMobile,
  changeStoreState, language, history,
}) {
  const { CancelToken } = Axios;
  const source = CancelToken.source();
  const cartItems = useSelector((state) => state.main.cartItems);
  const cartId = useSelector((state) => state.main.cartId);
  const selectedAddress = useSelector((state) => state.main.selectedAddress);
  const [offerList, setOfferList] = useState(null);
  const [loadStatus, setLoadStatus] = useState('');
  const location = selectedAddress ? `${selectedAddress.location.lat},${selectedAddress.location.lng}` : '19.1207983,72.8782323';
  const limit = 50;
  const offset = 0;

  let storeId = storeIdFromProp;
  if (!storeId) {
    const { storeCode } = match.params;
    storeId = storeCode;
  }

  const loadOffers = () => {
    if (storeId) {
      setLoadStatus('loading');
      specialOffers(
        'get',
        source.token,
        storeId,
        'SPECIAL_BOGO_OFFER',
        offset,
        limit,
        location,
        cartId,
      ).then((res) => {
        const { data } = res;
        if (data.count === 0) {
          setLoadStatus('zero_results');
        } else {
          setOfferList(data);
          setLoadStatus('');
        }
      }).catch(() => {
        setLoadStatus('error');
      });
    }
  };

  useEffect(() => (() => {
    source.cancel();
  }), []);

  useEffect(() => {
    loadOffers();
  }, []);

  const handleBack = () => {
    changeStoreState('ALL');
  };

  return (
    <div className="bg-white px-4 px-md-0 py-4 py-md-0">
      {
        (() => {
          if (loadStatus === 'loading') {
            return (
              <div
                className="r__loader"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          }
          if (loadStatus === 'error') {
            return (
              <div className="r__loader">
                <Placeholder
                  language={language}
                  handleRetry={loadOffers}
                  imageSrc="/images/error-placeholder.png"
                  heading={Constants.String.OH_NO[language]}
                />
              </div>
            );
          }
          if (loadStatus === 'zero_results') {
            return (
              <Placeholder
                language={language}
                imageSrc="/images/no-results.png"
                heading={Constants.String.NO_RESULTS_FOUND[language]}
              />
            );
          }
          return (
            offerList && offerList.count > 0 ? (
              <>
                <div
                  className="d-flex justify-content-space-between pb-5"
                >
                  <div className="d-flex fs-3 font-weight-black">
                    {
                      !isMobile && (
                        <>
                          <Button
                            variant="link"
                            onClick={handleBack}
                            className="p-0 d-flex align-items-center"
                          >
                            <Svg
                              svg="leftArrow"
                              fill={Constants.Color.black}
                              width="1rem"
                            />
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      )
                    }
                    {Constants.String.EXCITING_COMBO_OFFERS[language]}
                  </div>
                  <div className="">
                    {`${offerList.count} Items`}
                  </div>
                </div>
                <div className="r__flex r__flex-20 pb-2 flex-wrap">
                  {offerList.results.map((item) => (
                    <BogoOfffer
                      key={item.id}
                      item={item}
                      language={language}
                      cartItems={cartItems}
                      history={history}
                      isMobile={isMobile}
                      page={SHOP_FRONT}
                      horizontalView={isMobile}
                      section="Exciting Combo Offer See All"
                    />
                  ))}
                </div>
              </>
            ) : null
          );
        })()
      }
    </div>
  );
}

ComboOffersSeeAll.propTypes = {
  storeId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeCode: PropTypes.string,
    }),
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default ComboOffersSeeAll;
