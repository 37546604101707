const crypt = (salt, text) => {
  const textToChars = (t) => t.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => (`0${Number(n).toString(16)}`).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export function encrypt(token) {
  return crypt('salt', token);
}

export function decryptToken(encryptedToken) {
  if (!encryptedToken) {
    return encryptedToken;
  }
  return decrypt('salt', encryptedToken);
}
