import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CustomCarousel from '../../../components/common/carousel/CustomCarousel';
import { Svg } from '../../../components/common';
import { Constants } from '../../../utilities';
import CustomImage from '../../../components/common/CustomImage';

const StoreCover = (props) => {
  const {
    storeCovers, isMobile, storeName, preview, activeIndex, togglePreviewCover,
  } = props;
  return (
    <Col
      xs={{
        span: 24,
        order: 0,
      }}
      className={`px-0 ${preview ? 'bg-none preview' : ''}`}
    >
      <Container
        className="px-0"
      >
        <CustomCarousel
          itemsPerCarousel={isMobile || preview ? 1 : 4}
          list={storeCovers}
          idField="id"
          interval={!preview && storeCovers.length > (isMobile ? 1 : 4) ? 3000 : null}
          CarouselItem={({ item, itemsPerCarousel, idField }) => (
            <Col
              key={item[idField]}
              style={{
                width: `${100 / itemsPerCarousel}%`,
                height: isMobile && !preview ? '150px' : '100%',
              }}
              className="d-inline-block margin-0 p-0 p-lg-2 align-top border-radius-8"
              {
                ...togglePreviewCover ? ({
                  onClick: () => {
                    togglePreviewCover(storeCovers.findIndex(
                      (cover) => item[idField] === cover[idField],
                    ));
                  },
                }) : null
              }
            >
              {
                item.type === 'IMAGE' ? (
                  <CustomImage
                    imageStyle="w-100 store-cover"
                    imageUrl={item.url}
                    imageType="STORE_COVER"
                  />
                ) : (
                  <div
                    className={(preview || item.type === 'VIDEO') ? 'video-wrapper' : 'h-100'}
                  >
                    {
                      (preview || item.type === 'VIDEO') ? (
                        <video
                          title={storeName}
                          autoPlay
                          loop
                          controls
                          muted
                          width="100%"
                          height="100%"
                        >
                          <source src={item.url} type="video/mp4" />
                          <track kind="captions" label="English" srcLang="en" src="path-to-captions.vtt" default />
                        </video>
                      ) : (
                        <>
                          <div
                            className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                          >
                            <Svg
                              svg="play"
                              fill={Constants.Color.white}
                              width="3.5rem"
                            />
                          </div>
                          <img
                            className="w-100"
                            src={`https://img.youtube.com/vi/${item.url}/hqdefault.jpg`}
                            alt=""
                          />
                        </>
                      )
                    }
                  </div>
                )
              }
            </Col>
          )}
          noControl={storeCovers.length <= (isMobile || preview ? 1 : 4)}
          activeIndex={activeIndex}
        />
      </Container>
    </Col>
  );
};

StoreCover.propTypes = {
  storeCovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired,
  storeName: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  activeIndex: PropTypes.number,
  togglePreviewCover: PropTypes.func,
};

StoreCover.defaultProps = {
  preview: false,
  activeIndex: -1,
  togglePreviewCover: null,
};

export default React.memo(StoreCover);
