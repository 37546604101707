import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  gaLogShippingInfo,
} from 'ecommerce_ga_events/EcommerceGAEvents';
import { Constants } from 'app/utilities';
import {
  CustomModal, EditAddress, LocationSearch, Svg,
} from 'app/components/common';
import * as Actions from 'app/store/Actions';
import '../../../../styles/cart/cart.scss';
import { getAddressString, isGuestUser } from 'app/utilities/Utils';
import { orderLocation } from 'api/api';
import { cartDeliveryUpdateRequest, cartPaymentUpdateRequest } from 'app/store/cart/actions';
import CustomerDetails from 'app/components/derived/checkout/CustomerDetails';
import ScooterChecked from 'app/components/common/svg/Cart/ScooterChecked';
import ScooterUnchecked from 'app/components/common/svg/Cart/ScooterUnchecked';
import StorePickUpUnchecked from 'app/components/common/svg/Cart/StorePickUpUnchecked';
import StorePickUpChecked from 'app/components/common/svg/Cart/StorePickUpChecked';
import Toast from 'app/HOC/Toast';
import * as LogClevertapEvent from '../../../../../clevertap/LogEvent';
import * as buttonActions from '../../../../events/buttonActions';
import * as screens from '../../../../events/screens';
import * as Clevertap from '../../../../../clevertap/Clevertap';
import { logButtonClick } from '../../../../events/Events';
import Cart from '../../Cart';
import MakePayment from '../payment/MakePayment';

const { CancelToken } = Axios;

class SelectDeliveryOption extends React.Component {
  constructor(props) {
    super(props);
    const { selectedAddress, cartDetails } = props;
    let storePickupOnly = false;
    let isHDEnabled = false;
    if (cartDetails) {
      const {
        homeDelivery, storePickup,
      } = cartDetails.store.productTransferModes;
      isHDEnabled = homeDelivery;
      storePickupOnly = (!homeDelivery && storePickup);
    }
    this.state = {
      addressStep: 'address_list',
      applicableLocations: null,
      applicableAddressLoadStatus: '',
      selectedAddress,
      editAddressType: '',
      checkoutAddressAddStatus: '',
      checkoutAddAddressError: '',
      showFillCustomerDetails: false,
      isHDEnabled,
      storePickupOnly,
    };
    this.source = CancelToken.source();
  }

  componentDidMount() {
    const { selectedAddress } = this.props;
    this.setState({
      selectedAddress,
      selectedEditAddress: selectedAddress,
    });
    this.getCartApplicableLocation();
    Actions.cartLoadRequest(
      {
        isCheckout: true,
      },
      {
        type: 'async',
        callback: (data, cartLoadSuccess) => {
          const { res } = data;
          const {
            homeDelivery, storePickup,
          } = res.store.productTransferModes;
          const isHDEnabled = homeDelivery;
          const storePickupOnly = (!homeDelivery && storePickup);
          this.setState(
            {
              storePickupOnly,
              isHDEnabled,
            },
            () => {
              cartLoadSuccess(data);
            },
          );
        },
      },
    );
  }

  static getDerivedStateFromProps(props) {
    const { cartDetails, selectedAddress } = props;
    let storePickupOnly = false;
    let isHDEnabled = false;
    if (cartDetails) {
      const {
        homeDelivery, storePickup,
      } = (
        cartDetails.store
        && cartDetails.store.productTransferModes
      ) ? cartDetails.store.productTransferModes : {};
      isHDEnabled = homeDelivery;
      storePickupOnly = (!homeDelivery && storePickup);
    }
    return ({
      cartDetails,
      storePickupOnly,
      isHDEnabled,
      selectedAddress,
    });
  }

  getCartApplicableLocation = () => {
    const { cartId, showToast, language } = this.props;
    this.setState({
      applicableAddressLoadStatus: 'loading',
    });
    orderLocation(
      'GET',
      null,
      cartId,
    ).then((res) => {
      this.setState({
        applicableLocations: res.data,
        applicableAddressLoadStatus: '',
      });
    }).catch(() => {
      showToast(Constants.String.OOPS[language]);
      this.setState({
        applicableAddressLoadStatus: 'error',
      });
    });
  }

  checkAddAddressEligibility = (addressPayload = {}) => {
    const { cartId, showToast, language } = this.props;
    this.setState({
      checkoutAddressAddStatus: 'loading',
    });
    orderLocation(
      'PUT',
      addressPayload.location,
      cartId,
    ).then((res) => {
      if (res.data.allowed) {
        this.setState({
          checkoutAddressAddStatus: '',
          selectedEditAddress: addressPayload,
          addressStep: 'address_edit',
          editAddressType: 'add',
        });
      } else {
        this.setState({
          checkoutAddressAddStatus: 'error',
          checkoutAddAddressError: res.data.serverMessage,
        });
      }
    }).catch((err) => {
      if (err.response
        && err.response.data
        && err.response.data[0].client_message
      ) {
        showToast(err.response.data[0].client_message);
        this.setState({
          checkoutAddressAddStatus: 'error',
          checkoutAddAddressError: err.response.data[0].client_message,
        });
        return;
      }
      showToast(Constants.String.OOPS[language]);
      this.setState({
        checkoutAddressAddStatus: 'error',
      });
    });
  }

  handleDeliveryOptionChange = (deliveryOption, selAddress) => {
    const {
      deliveryPreferenceRequest,
      customer,
      cartDetails,
      toggleSideOverlay,
      showToast,
    } = this.props;
    const { couponCode } = this.state;
    const customerLocationId = selAddress ? selAddress.locationId : '';
    deliveryPreferenceRequest(
      deliveryOption === 1 ? {
        deliveryPreference: 'STORE PICKUP',
        customerLocationId,
      } : {
        deliveryPreference: 'HOME DELIVERY',
        customerLocationId,
      },
      {
        type: 'async',
        callback: (res, callback) => {
          if (callback) {
            callback(res);
          }
          toggleSideOverlay(MakePayment, false);
        },
      },
      {
        type: 'async',
        callback: (err, callback) => {
          if (err) {
            showToast(err.errorMsg);
            if (callback) { callback(err); }
          }
        },
      },
    );
    gaLogShippingInfo(
      cartDetails.totalBill,
      couponCode,
      deliveryOption === 1 ? 'STORE PICKUP' : 'HOME DELIVERY',
      cartDetails.cartProducts.map((item) => ({
        item_id: item.libraryProductId,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    );
    if (customer && !customer.first_name && !isGuestUser()) {
      this.setState({
        showFillCustomerDetails: true,
      });
    }
  }

  render() {
    const {
      cartDetails, toggleSideOverlay,
      isMobile,
      language,
    } = this.props;

    const {
      addressStep, applicableAddressLoadStatus, applicableLocations,
      selectedEditAddress,
      editAddressType,
      checkoutAddressAddStatus, checkoutAddAddressError,
      showFillCustomerDetails, selectedAddress,
    } = this.state;

    if (!cartDetails) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    let addressesJSX = null;
    if (
      !applicableLocations
      && applicableAddressLoadStatus === 'loading'
      && addressStep === 'address_list'
    ) {
      addressesJSX = (
        <Row
          className="mx-0 py-4 justify-content-center"
        >
          <Col
            xs="auto"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </Col>
        </Row>
      );
    } else if (
      !applicableLocations
      && applicableAddressLoadStatus === 'error'
      && addressStep === 'address_list'
    ) {
      addressesJSX = (
        <Row
          className="mx-0 py-4"
        >
          <Col
            xs={24}
            className="fs-5 text-danger text-center"
          >
            {Constants.String.OOPS[language]}
          </Col>
          <Col
            xs={24}
            className="text-center pt-2"
          >
            <Button
              variant="primary"
              className="fs-5 border-radius-4"
              onClick={() => {
                this.getCartApplicableLocation();
              }}
            >
              {Constants.String.RETRY[language].toUpperCase()}
            </Button>
          </Col>
        </Row>
      );
    } else if (applicableLocations) {
      if (addressStep === 'address_list') {
        addressesJSX = (
          <Row className="gap-10">
            <Col
              xs={24}
              key="store-pickup"
              className={cartDetails.store.productTransferModes.storePickup ? 'cursor-pointer' : ''}
              onClick={() => {
                this.handleDeliveryOptionChange(1, selectedAddress);
                logButtonClick(
                  {
                    BUTTON: buttonActions.SELECT_SHOP_PICK_UP,
                    CART_ID: cartDetails.cartId,
                    SCREEN: screens.CHECKOUT,
                  },
                );
                LogClevertapEvent.deliveryType('Store Pickup');
              }}
            >
              <Container
                className="bg-white shadow-sm border-radius-16"
              >
                <Row
                  className="py-4"
                >
                  <Col
                    xs="auto"
                  >
                    {cartDetails.store.productTransferModes.storePickup ? (
                      <StorePickUpChecked />
                    ) : (
                      <StorePickUpUnchecked />
                    )}
                  </Col>
                  <Col
                    className="pl-0"
                  >
                    <div
                      className="fs-5 text-dark font-weight-black"
                    >
                      Pickup from Shop
                    </div>
                    <div
                      className="fs-5 text-dark text-medium"
                    >
                      Pick the parcel from the shop at your convenience
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            {
              applicableLocations.results.map((item) => (
                <Col
                  xs={24}
                  key={item.locationId}
                  className={item.isApplicable ? 'cursor-pointer' : ''}
                  onClick={() => {
                    if (item.isApplicable) {
                      if (item.isCompleted) {
                        this.setState({
                          selectedAddress: item,
                          selectedEditAddress: item,
                        }, () => this.handleDeliveryOptionChange(0, item));
                      } else {
                        this.setState({
                          selectedEditAddress: item,
                          addressStep: 'address_edit',
                        });
                      }
                    }
                  }}
                >
                  <Container
                    className="bg-white shadow-sm border-radius-16"
                  >
                    <Row
                      className="py-4"
                    >
                      <Col
                        xs="auto"
                      >
                        {item.isApplicable ? (
                          <ScooterChecked />
                        ) : (
                          <ScooterUnchecked />
                        )}
                      </Col>
                      <Col
                        className="pl-0"
                      >
                        <div
                          className=""
                        >
                          <span
                            className="fs-5 text-dark font-weight-black word-break"
                          >
                            {`Deliver to ${item.addressType}`}
                          </span>
                          {
                            !item.isCompleted && (
                              <span
                                className="fs-7 text-medium"
                              >
                                &nbsp;&nbsp;
                                {`(${Constants.String.COMPLETE_ADDRESS_REQUIRED[language].toUpperCase()})`}
                              </span>
                            )
                          }
                        </div>
                        <div
                          className="fs-5 text-dark text-medium word-break"
                        >
                          {getAddressString(item)}
                        </div>
                      </Col>
                    </Row>
                    {
                      item.isApplicable && (
                        <Row
                          className="bg-green-light py-4 border-bottom-radius-16"
                        >
                          <Col xs={24} className="fs-5 font-weight-black text-green-dark">
                            { cartDetails.homeDeliveryRemainingMOV !== 0
                              ? `FREE DELIVERY in ${Math.ceil(item.deliveryTime / 60)} hours`
                              : `Delivers in ${Math.ceil(item.deliveryTime / 60)} hours` }
                          </Col>
                          {
                            cartDetails.homeDeliveryRemainingMOV !== 0 && (
                              <Col xs={24} className="fs-6 text-green-dark">
                                {`Add items worth ₹${cartDetails.homeDeliveryRemainingMOV} for FREE DELIVERY`}
                              </Col>
                            )
                          }
                        </Row>
                      )
                    }
                    {
                      !item.isApplicable && (
                        <Row
                          xs={24}
                          className="bg-gray-lighter py-4 border-bottom-radius-16"
                        >
                          <Col
                            xs={24}
                            className="fs-5 font-weight-black text-medium"
                          >
                            {Constants.String.CANNOT_DELIVER_TO_LOCATION[language]}
                          </Col>
                        </Row>
                      )
                    }
                  </Container>
                </Col>
              ))
            }
          </Row>
        );
      } else if (addressStep === 'address_edit') {
        addressesJSX = (
          <Row
            className="py-2 bg-white"
          >
            <Col
              xs={24}
            >
              <EditAddress
                isMobile={isMobile}
                language={language}
                address={selectedEditAddress}
                screen={screens.CHECKOUT}
                onSuccess={{
                  type: 'sync',
                  callback: (data) => {
                    this.setState({
                      selectedAddress: data.res,
                      selectedEditAddress: data.res,
                      addressStep: 'address_list',
                    });
                    this.handleDeliveryOptionChange(0, data.res);
                    this.getCartApplicableLocation();
                  },
                }}
                editAddressType={editAddressType}
              />
            </Col>
          </Row>
        );
      } else if (addressStep === 'address_add') {
        addressesJSX = (
          <Row>
            <Col
              xs={24}
            >
              <LocationSearch
                isMobile={isMobile}
                language={language}
                modifyAddressType
                screen={screens.CHECKOUT}
                onSuccess={() => {}}
                checkoutAddressAddStatus={checkoutAddressAddStatus}
                checkoutAddAddressError={checkoutAddAddressError}
                onSubmitAddAddress={(addressPayload) => {
                  this.checkAddAddressEligibility(addressPayload);
                }}
              />
            </Col>
          </Row>
        );
      }
    }

    const updateAddressModalHeaderJSX = (
      <Row
        className="py-4 bg-white justify-content-between align-items-center"
      >
        {(() => {
          if (addressStep === 'address_edit') {
            return (
              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() => {
                    this.setState({
                      addressStep: 'address_list',
                    });
                  }}
                >
                  <Svg
                    svg="leftArrow"
                    width="16px"
                    fill={Constants.Color.black}
                  />
                </Button>
                <div
                  className="pl-2 font-weight-black"
                >
                  {Constants.String.COMPLETE_ADDRESS[language]}
                </div>
              </Col>
            );
          } if (addressStep === 'address_add') {
            return (
              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() => {
                    this.setState({
                      addressStep: 'address_list',
                    });
                  }}
                >
                  <Svg
                    svg="leftArrow"
                    width="16px"
                    fill={Constants.Color.black}
                  />
                </Button>
                <div
                  className="pl-2 font-weight-black"
                >
                  {Constants.String.ADD_NEW_ADDRESS[language]}
                </div>
              </Col>
            );
          }
          return (
            <Col
              xs="auto"
              className={`d-flex align-items-center ${isMobile ? 'd-none' : ''}`}
            >
              <Button
                variant="link"
                className="py-0 pl-0"
                onClick={() => {
                  toggleSideOverlay(Cart);
                }}
              >
                <Svg
                  svg="leftArrow"
                  width="16px"
                  fill={Constants.Color.black}
                />
              </Button>
              <div
                className="font-weight-black"
              >
                Select Delivery Option
              </div>
            </Col>
          );
        })()}
        <Col
          xs="auto"
          className="cursor-pointer"
          onClick={() => {
            toggleSideOverlay(null);
          }}
        >
          <Svg
            svg="close"
            width="24px"
            fill={Constants.Color.mediumLight}
          />
        </Col>
      </Row>
    );

    const manageAndEditAddressModalHeaderJSX = (
      <>
        {
          (addressStep === 'address_list') && (
            <Row className="bg-light p-4">
              <Col
                xs={24}
                className="d-flex py-2 bg-white border border-radius-16 align-items-center"
              >
                <Col
                  xs="auto"
                  className="p-1 border border-radius-rounded border-primary flex-center"
                >
                  <Svg
                    svg="add"
                    width="20px"
                    fill={Constants.Color.primary}
                  />
                </Col>
                <Col
                  className="flex-grow"
                >
                  <Button
                    variant="link"
                    className="px-0 font-weight-black w-100 text-left"
                    onClick={() => {
                      this.setState({
                        addressStep: 'address_add',
                      });
                    }}
                  >
                    Add New Delivery Address
                  </Button>
                </Col>
              </Col>
            </Row>
          )
          }
      </>
    );

    return (
      <Container
        fluid
        className={`h-100 px-0 ${isMobile ? 'my-3' : ''}`}
      >
        {/* if first name of customer is absent then show this */}
        <CustomModal
          show={showFillCustomerDetails}
          title={Constants.String.CUSTOMER_DETAILS[language]}
          closeButton
          onHide={() => {
            this.setState({
              showFillCustomerDetails: false,
            });
          }}
          body={(
            <Container
              fluid
              className="px-0 py-4 bg-white"
            >
              <Row>
                <Col
                  xs={24}
                >
                  <CustomerDetails
                    {...this.props}
                    onSuccess={
                      {
                        type: 'sync',
                        callback: (resData) => {
                          Clevertap.pushProfile(resData.res);
                          this.setState({
                            showFillCustomerDetails: false,
                          });
                        },
                      }
                    }
                  />
                </Col>
              </Row>
            </Container>
          )}
        />
        <Container
          fluid
          className="d-flex flex-column h-100 flex-nowrap border-radius-16"
        >
          {updateAddressModalHeaderJSX}
          <Row
            className="py-3 bg-light flex-grow-1 overflow-y-scroll"
          >
            <Col
              xs={24}
              style={isMobile ? {
                maxHeight: '500px',
              } : {}}
            >
              {addressesJSX}
            </Col>
          </Row>
          {manageAndEditAddressModalHeaderJSX}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartAvailableOffers: state.main.cartAvailableOffers,
  cartItems: state.main.cartItems,
  highlightCartOfferDetails: state.main.highlightCartOfferDetails,
  processing: state.main.requestsProcessing,
  cartDetails: state.main.cartDetails,
  selectedAddress: state.main.selectedAddress,
  cartId: state.main.cartId,
  customer: state.main.customer,
});

const mapDispatchToProps = (dispatch) => ({
  cartAvailableOffersLoadRequest: (
    offset, onSuccess, cancelToken, storeId,
  ) => {
    dispatch(
      Actions.cartAvailableOffersRequest(
        offset, 20, onSuccess, cancelToken, storeId,
      ),
    );
  },
  cartResetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  paymentPreferenceRequest: (
    body,
    onSuccess,
    onFailure,
  ) => {
    dispatch(
      cartPaymentUpdateRequest.updateRequest(
        onSuccess,
        onFailure,
        body,
        {},
      ),
    );
  },
  ordersCreateRequest: (onSuccess, onFailure) => {
    dispatch(Actions.ordersCreateRequest(onSuccess, onFailure));
  },
  cartNullRequest: () => {
    dispatch(Actions.cartNullRequest());
  },
  toggleScratchCard: (scratchCard) => {
    dispatch(Actions.toggleScratchCard(scratchCard));
  },
  deliveryPreferenceRequest: (
    body,
    onSuccess,
    onFailure,
  ) => {
    dispatch(
      cartDeliveryUpdateRequest.updateRequest(
        onSuccess,
        onFailure,
        {},
        body,
      ),
    );
  },
});

SelectDeliveryOption.propTypes = {
  cartDetails: PropTypes.shape({
    cartProductsCount: PropTypes.number,
    cartCustomProductsCount: PropTypes.number,
    cartId: PropTypes.number,
    totalBill: PropTypes.number,
    totalSavings: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      locality: PropTypes.string,
      type: PropTypes.string,
      productTransferModes: PropTypes.shape({
        homeDelivery: PropTypes.bool,
        storePickup: PropTypes.bool,
      }),
      paymentModes: PropTypes.shape({}),
    }),
    cartProducts: PropTypes.arrayOf({}),
    cartCustomProducts: PropTypes.arrayOf({}),
    homeDeliveryRemainingMOV: PropTypes.number,
  }),
  cartAvailableOffersLoadRequest: PropTypes.func.isRequired,
  cartResetRequestStatus: PropTypes.func.isRequired,
  cartAvailableOffers: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf({}),
  }),
  cartItems: PropTypes.shape({
    productInfo: PropTypes.shape({}),
  }),
  cartLoadRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  cartAddRequest: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string,
  paymentPreferenceRequest: PropTypes.func.isRequired,
  ordersCreateRequest: PropTypes.func.isRequired,
  cartNullRequest: PropTypes.func.isRequired,
  toggleScratchCard: PropTypes.func.isRequired,
  processing: PropTypes.shape({}).isRequired,
  selectedAddress: PropTypes.shape({
    locationId: PropTypes.number,
    isCompleted: PropTypes.bool,
    location: PropTypes.shape({}),
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    localityName: PropTypes.string,
    landmark: PropTypes.string,
    addressType: PropTypes.string,
    addressText: PropTypes.string,
  }),
  showToast: PropTypes.func.isRequired,
  deliveryPreferenceRequest: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    first_name: PropTypes.string,
  }),
  cartId: PropTypes.number.isRequired,
};

SelectDeliveryOption.defaultProps = {
  cartDetails: null,
  cartAvailableOffers: null,
  cartItems: null,
  language: 'en',
  selectedAddress: null,
  customer: null,
};

export default Toast(connect(mapStateToProps, mapDispatchToProps)(SelectDeliveryOption));
