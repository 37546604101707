import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form,
} from 'react-bootstrap';
import OnlinePaymentIcon from 'app/components/common/svg/Cart/OnlinePaymentIcon';
import CashOnDeliveryIcon from 'app/components/common/svg/Cart/CashOnDeliveryIcon';
import CheckedIcon from 'app/components/common/svg/Cart/CheckedIcon';
import { Constants } from 'app/utilities';
import { logCTCustomEvents } from 'app/events/Events';
import { connect, useSelector } from 'react-redux';
import * as Actions from 'app/store/Actions';
import { useHistory } from 'react-router-dom';
import { simplChargeAPI, simplGetEligibilityTokenAPI } from 'api/api';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';

const getApplicablePaymentModes = (paymentModes = {}) => {
  const { digital, cash } = paymentModes;
  if (digital.isEnabled && cash.isEnabled) {
    return 'BOTH';
  } if (digital.isEnabled) {
    return 'DIGITAL';
  } if (digital.isEnabled || cash.isEnabled) {
    return 'SIMPL';
  }
  return 'CASH';
};
const ConfirmOrder = (props) => {
  const {
    language, cartDetails,
  } = props;
  const {
    paymentPreference,
    updateCheckoutStore,
    isPayOnlineOnDelivery,
  } = props;
  const checkoutStore = cartDetails.store;
  const storePaymentMode = getApplicablePaymentModes(
    checkoutStore.paymentModes,
  );
  const {
    eligibleData,
  } = useSelector((state) => state.main);
  const isStorePickup = cartDetails.deliveryPreference === 'STORE PICKUP';
  const deliveryOption = isStorePickup ? 1 : 0;
  useEffect(() => {
    if (
      storePaymentMode !== 'BOTH'
    ) {
      updateCheckoutStore(storePaymentMode);
    }
  }, [storePaymentMode, updateCheckoutStore]);

  const isDigitalPayment = paymentPreference === 'DIGITAL' && !isPayOnlineOnDelivery;
  const isSimplPayment = paymentPreference === 'DIGITAL' && isPayOnlineOnDelivery;

  const isCashPayment = paymentPreference === 'CASH';
  const isSimplPaymentEnable = eligibleData
     && eligibleData.success === true
     && eligibleData.data.status === 'eligible';

  const isLinkingRequired = eligibleData
  && eligibleData.data.errorCode === 'linking_required';

  const isPaymentDigitalBothModeValid = storePaymentMode === 'DIGITAL' || storePaymentMode === 'BOTH';

  const isPaymentCashBothModeValid = storePaymentMode === 'CASH' || storePaymentMode === 'BOTH';
  const deliveryOptionLabel = deliveryOption === 1
    ? Constants.String.PAY_AT_STORE[language]
    : Constants.String.COD[language];

  const shouldDisplayExtraDeliveryCharge = deliveryOption !== 1
    && checkoutStore
    && checkoutStore.productTransferModes.homeDelivery
    && storePaymentMode === 'CASH'
    && cartDetails.codDeliveryCharge > 0;

  const extraDeliveryCharge = shouldDisplayExtraDeliveryCharge && (
    <Row className="mx-0">
      <Col xs={24} className="my-1 py-1 px-2 fs-7 bg-green-light border-radius-4">
        {`\u20B9${Constants.String.COD_EXTRA_DELIVERY_CHARGE[language]
          .replace(/{extraDeliveryCharge}/g, cartDetails.codDeliveryCharge)}`}
      </Col>
    </Row>
  );

  let orderPlaced = false;
  const openPopupWithIframe = (url) => {
    const width = 800;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
    const popupWindow = window.open(url, '_blank', features);
    if (popupWindow) {
      popupWindow.focus();
      setTimeout(() => {
        popupWindow.postMessage('closeIframeAndRedirect', 'https://homedeliverybackend.mpaani.com/payment/simpl/token-webhook');
      }, 1000);
    } else {
      // eslint-disable-next-line no-console
      console.error('Popup blocked by the browser. Please allow popups for this site.');
    }

    window.addEventListener('message', (event) => {
      if (event.data === 'closeIframeAndRedirect' && event.origin !== 'https://homedeliverybackend.mpaani.com/payment/simpl/token-webhook') {
        popupWindow.close();
      }
    });
  };
  const updateCartDetails = () => {
    const { cartLoadRequest } = props;
    cartLoadRequest();
  };
  const history = useHistory();
  const placeOrderWithToken = (token) => {
    const { cartNullRequest, toggleSideOverlay } = props;
    if (orderPlaced) {
      return;
    }
    const amountInPaise = cartDetails.totalBill * 100;
    simplChargeAPI('post', {
      amount: amountInPaise,
      token,
      cartId: cartDetails.cartId,
    })
      .then((response) => {
        const order = response.data.data;
        orderPlaced = true;
        window.parent.postMessage('closeIframeAndRedirect', '/');
        history.replace(`/success/${order.orderId}`, {
          isFirstLoad: true,
        });
        toggleSideOverlay(null);
        updateCartDetails();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error placing the order:', error);
      });
    cartNullRequest();
  };

  const fetchTokenAndPlaceOrder = () => {
    if (orderPlaced) {
      return;
    }
    simplGetEligibilityTokenAPI('get', {})
      .then((response) => {
        if (response.data.data.token) {
          placeOrderWithToken(response.data.data.token);
        } else {
          throw new Error('Token not found in the response');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching token:', error);
      });
  };

  const digitalPaymentSelection = () => {
    updateCheckoutStore('DIGITAL');
    logCTCustomEvents(buttonActions.SELECT_PAYMENT_METHOD, {
      EVENT_NAME: buttonActions.PAY_ONLINE,
      SCREEN: screens.CHECKOUT,
      CART_ID: cartDetails.cartId,
      PAYABLE_AMOUNT: cartDetails.totalBill,
    });
  };
  const cashPaymentSelection = () => {
    updateCheckoutStore('CASH');
    logCTCustomEvents(buttonActions.SELECT_PAYMENT_METHOD, {
      EVENT_NAME: buttonActions.PAY_CASH_ON_DELIVERY,
      SCREEN: screens.CHECKOUT,
      CART_ID: cartDetails.cartId,
      PAYABLE_AMOUNT: cartDetails.totalBill,
    });
  };
  const linkingRequiredCase = () => {
    if (eligibleData.data.redirectionUrl) {
      const { redirectionUrl } = eligibleData.data;
      openPopupWithIframe(redirectionUrl);
      setInterval(() => {
        fetchTokenAndPlaceOrder();
      }, 5000);
    }
  };
  const notEligibleCase = () => {
    // Handle the not eligible case
  };
  const handlePaymentSelection = () => {
    updateCheckoutStore('SIMPL');
    logCTCustomEvents(buttonActions.SELECT_PAYMENT_METHOD, {
      EVENT_NAME: buttonActions.PAY_WITH_SIMPL,
      SCREEN: screens.CHECKOUT,
      CART_ID: cartDetails.cartId,
      PAYABLE_AMOUNT: cartDetails.totalBill,
    });

    if (eligibleData && eligibleData.data.errorCode === 'linking_required') {
      linkingRequiredCase();
    } else if (eligibleData && eligibleData.data.status === 'not_eligible') {
      notEligibleCase();
    }
  };
  const convertPaiseToRupees = (paise) => (paise / 100).toFixed(2);
  const blanaceAMount = Math.floor(Number(convertPaiseToRupees(eligibleData.data.availableCredit)));
  function generateCommonClassName(isPayment, isLastStoreItem = true, includePy3 = true) {
    const baseClassName = 'bg-white cursor-pointer mb-2 border-radius-16 shadow-sm';
    const paymentClassName = isPayment ? 'bg-primary-light border border-gray' : '';
    const dottedBorderClassName = isLastStoreItem ? '' : 'dotted-border-bottom';
    const py3ClassName = includePy3 ? 'py-3' : '';
    const Py3linkClassName = isLinkingRequired ? 'py-3' : '';
    return `${baseClassName} ${py3ClassName} ${Py3linkClassName} ${paymentClassName} ${dottedBorderClassName}`;
  }
  const isBalanceAmountLess = cartDetails.totalBill > blanaceAMount;
  const paymentOptions = [
    { id: 1, src: '/Assets/payments/visa.png' },
    { id: 2, src: '/Assets/payments/master-card.png' },
    { id: 3, src: '/Assets/payments/paytm.png' },
    { id: 4, src: '/Assets/payments/phone-pe.png' },
    { id: 5, src: '/Assets/payments/gpay.png' },
  ];
  let contentToDisplay;

  if (!isLinkingRequired) {
    if (isSimplPaymentEnable) {
      if (isBalanceAmountLess) {
        contentToDisplay = (
          <Col className="font-weight-normal">
            {Constants.String.INSUFFICIENT_FUNDS_IN_YOUR_SIMPL_ACCOUNT[language]}
          </Col>
        );
      } else {
        contentToDisplay = (
          isLinkingRequired ? '' : (
            <Col className="font-weight-bold">
              {Constants.String.BALANCE_AMOUNT[language]}
              :
              &nbsp;
              &#8377;
              {blanaceAMount}
            </Col>
          )

        );
      }
    } else {
      contentToDisplay = (
        <Col className="font-weight-normal">
          {Constants.String.YOU_ARE_NOT_ELIGIBLE[language]}
        </Col>
      );
    }
  }
  let paymentImageSrc;

  if (isSimplPayment && isSimplPaymentEnable) {
    paymentImageSrc = '/Assets/payments/simpl2.png';
  } else if (isBalanceAmountLess && !isLinkingRequired) {
    paymentImageSrc = '/Assets/payments/simpl-light.png';
  } else {
    paymentImageSrc = '/Assets/payments/simpl2.png';
  }

  return (
    <div
      className=""
    >
      <div
        key={checkoutStore.id}
      >
        <Row
          className=""
        >
          {
            isPaymentDigitalBothModeValid && (
              <Col
                xs={24}
                className={generateCommonClassName(isDigitalPayment)}
                onClick={digitalPaymentSelection}
              >
                <Row className="d-flex flex-row-reverse">
                  <Col
                    xs="auto"
                    className="pl-0"
                  >
                    {isDigitalPayment
                      ? <CheckedIcon />
                      : (
                        <Form.Check
                          custom
                          label=""
                          type="radio"
                          id={`${checkoutStore.id}-DIGITAL`}
                          checked={isDigitalPayment}
                        />
                      )}
                  </Col>
                  <Col
                    className="pr-0 fs-5 font-weight-bold text-black-72"
                  >
                    <Row>
                      <Col
                        xs={24}
                        className="d-flex align-items-center"
                      >
                        <OnlinePaymentIcon />
                        &nbsp;
                        {Constants.String.ONLINE_PAYMENT[language]}
                      </Col>
                    </Row>
                    {
                        cartDetails?.maxDiscountForOnlinePaymentShort && (
                          <Row
                            className="mx-0"
                          >
                            <Col
                              xs={24}
                              className="my-1 py-1 px-2 fs-7 bg-green-light border-radius-4"
                            >
                              {cartDetails.maxDiscountForOnlinePaymentShort}
                            </Col>
                          </Row>
                        )
                      }
                    <Row
                      className="pt-2 pb-2"
                    >
                      <Col
                        xs={24}
                        className="payment-box"
                      >
                        {paymentOptions.map((item) => (
                          <div key={item.id}>
                            <img src={item.src} alt="" width="42px" height="24px" />
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row
                      className="d-flex align-items-center"
                    >
                      <Col
                        xs={24}
                        className=""
                      >
                        <span
                          className="fs-7"
                        >
                          powered by
                        </span>
                          &nbsp;
                        <span>
                          <img
                            src="/Assets/payments/rozorpay.png"
                            alt=""
                            width="52px"
                            height="12px"
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )
          }
          {
           isPaymentCashBothModeValid && (
           <Col
             xs={24}
             className={generateCommonClassName(isCashPayment)}
             onClick={cashPaymentSelection}
           >
             <Row
               className="d-flex flex-row-reverse"
             >
               <Col
                 xs="auto"
                 className="pl-0"
               >
                 {
                      isCashPayment
                        ? <CheckedIcon />
                        : (
                          <Form.Check
                            custom
                            label=""
                            readOnly
                            type="radio"
                            id={`${checkoutStore.id}-CASH`}
                            checked={isCashPayment}
                          />
                        )

                    }
               </Col>
               <Col
                 className="pr-0 fs-5 font-weight-bold text-black-72"
               >
                 <Row>
                   <Col
                     xs={24}
                     className="d-flex align-items-center"
                   >
                     <CashOnDeliveryIcon />
                        &nbsp;
                     {deliveryOptionLabel}
                   </Col>
                 </Row>
                 {extraDeliveryCharge}
               </Col>
             </Row>
           </Col>
           )
          }

          {
              isPaymentDigitalBothModeValid && (
                <Col
                  xs={24}
                  className={generateCommonClassName(isSimplPayment, true, false)}
                  // eslint-disable-next-line consistent-return
                  onClick={() => {
                    if (isLinkingRequired) {
                      handlePaymentSelection();
                    } if (isBalanceAmountLess) {
                      return null;
                    }
                    handlePaymentSelection();
                  }}
                >
                  <Row className={`d-flex align-items-center ${isLinkingRequired ? '' : 'pt-3'}`}>
                    <Col>
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-1">
                          <img
                            alt=""
                            src={paymentImageSrc}
                            style={{
                              width: paymentImageSrc === '/Assets/payments/simpl-light.png' ? '24px' : '25px',
                              height: paymentImageSrc === '/Assets/payments/simpl-light.png' ? '24px' : '25px',
                              borderRadius: paymentImageSrc === '/Assets/payments/simpl-light.png' ? '4px' : '0px',
                              border: paymentImageSrc === '/Assets/payments/simpl-light.png' ? '1px solid #F0F0F0' : 'none',
                            }}
                          />
                        </Col>
                        <Col className="px-0 fs-5 font-weight-bold text-black-72">
                          {Constants.String.PAY_WITH_SIMPL[language]}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto" className="pl-0">
                      {isSimplPayment ? (
                        isSimplPaymentEnable && <CheckedIcon />
                      ) : (
                        <Form.Check
                          custom
                          label=""
                          readOnly
                          disabled={!isSimplPaymentEnable}
                          type="radio"
                          id={`${checkoutStore.id}-SIMPL`}
                          checked={isSimplPayment}
                        />
                      )}
                    </Col>
                  </Row>
                  {
                    isLinkingRequired ? (
                      <p className="mb-0 fs-6 font-weight-normal text-primary">
                        {Constants.String.LINK_ACCOUNT_TO_ENABLE_SIMPL[language]}
                        <br />
                      </p>
                    ) : (
                      <Row className="mt-4 fs-5 r__simpl_row p-2">
                        {contentToDisplay}
                      </Row>
                    )
                  }

                </Col>
              )
            }

        </Row>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  cartAvailableOffersLoadRequest: (
    offset, onSuccess, cancelToken, storeId,
  ) => {
    dispatch(
      Actions.cartAvailableOffersRequest(
        offset, 20, onSuccess, cancelToken, storeId,
      ),
    );
  },
  cartResetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },

  ordersCreateRequest: (cartData, onSuccess, onFailure) => {
    dispatch(Actions.ordersCreateRequest(cartData, onSuccess, onFailure));
  },
  cartNullRequest: () => {
    dispatch(Actions.cartNullRequest());
  },
  toggleScratchCard: (scratchCard) => {
    dispatch(Actions.toggleScratchCard(scratchCard));
  },
  updateStateRequest: (data) => {
    dispatch(Actions.updateState(data));
  },
});

ConfirmOrder.propTypes = {
  paymentPreference: PropTypes.string.isRequired,
  cartDetails: PropTypes.shape({
    cartId: PropTypes.number,
    totalBill: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      productTransferModes: PropTypes.shape({
        homeDelivery: PropTypes.bool,
        storePickup: PropTypes.bool,
      }),
      paymentModes: PropTypes.shape({}),
    }),
    codDeliveryCharge: PropTypes.number,
    maxDiscountForOnlinePaymentShort: PropTypes.string,
    deliveryPreference: PropTypes.string,
  }),
  updateCheckoutStore: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  isPayOnlineOnDelivery: PropTypes.number.isRequired,
  cartLoadRequest: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  cartNullRequest: PropTypes.func.isRequired,
};

ConfirmOrder.defaultProps = {
  cartDetails: null,
};
export default connect(mapDispatchToProps)(ConfirmOrder);
