import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import CustomImage from 'app/components/common/CustomImage';
import { getCDNImageUrl, getSeoProductUrl } from 'app/utilities/Utils';
import RoundedCartCounter from 'app/components/derived/cart/RoundedCartCounter';
import CustomOfferBadge from 'app/components/common/CustomOfferBadge';
import { Link } from 'react-router-dom';
import Events from 'clevertap/Event';
import { uaLogProductClick } from 'ecommerce_ga_events/EcommerceUAEvents';
import { logCTEvent } from '../../events/Events';
import { Constants } from '../../utilities';

const ProductThumb = ({
  cartItems,
  language,
  libraryProductId,
  retailProductId,
  name,
  image,
  mrp,
  sellingPrice,
  measurementDisplayText,
  displayTag,
  storeName,
  storeId,
  offerPercent,
  isBrandProduct,
  horizontalView,
  variants,
  page,
  section,
}) => {
  const [selectedVar, setSelectedVar] = useState({
    libraryProductId,
    retailProductId,
    name,
    image,
    mrp,
    sellingPrice,
    measurementDisplayText,
    offerPercent,
    displayTag,
  });

  const {
    libraryProductId: libraryProductIdActive,
    retailProductId: retailProductIdActive,
    name: nameActive,
    image: imageActive,
    mrp: mrpActive,
    sellingPrice: sellingPriceActive,
    measurementDisplayText: measurementDisplayTextActive,
    offerPercent: offerPercentActive,
    displayTag: displayTagActive,
  } = selectedVar;

  const discount = mrpActive !== -1 && mrpActive > sellingPriceActive
    ? mrpActive - sellingPriceActive : 0;

  const quantity = !!cartItems && !!cartItems[retailProductIdActive]
    ? cartItems[retailProductIdActive].quantity : 0;

  const {
    pathName, state, searchParams,
  } = getSeoProductUrl(libraryProductIdActive, nameActive, storeId);
  const searchQuery = `/search?q=${nameActive}`;

  // dont consider horizontalView similar to mobile view
  return (
    <Col
      id={horizontalView ? '' : 'store-top-saving-offer'}
      xs={horizontalView ? 24 : 'auto'}
      className={`border border-radius-16 p-4 px-3 ${horizontalView ? '' : 'text-wrap position-relative shadow-sm'}`}
    >
      <Row
        className={`${horizontalView ? '' : 'mx-0 align-items-center top-saving-card'}`}
      >
        <Col
          className={horizontalView ? '' : 'px-0'}
          xs={horizontalView ? 8 : 24}
        >
          <Row
            className="mx-0"
          >
            <Link
              to={{ pathname: pathName, state, search: searchParams }}
              onClick={() => {
                logCTEvent(
                  Events.LL_Product_Viewed,
                  {
                    Page: page,
                    'Product Id': libraryProductIdActive,
                    'Product name': nameActive,
                    'Retail Price': sellingPriceActive,
                    Price: mrpActive,
                    Currency: 'INR',
                    Quantity: quantity,
                    Image: imageActive,
                    section: section || 'NA',
                  },
                );
                uaLogProductClick({
                  name: nameActive,
                  libraryProductId: libraryProductIdActive,
                  price: sellingPriceActive,
                  brand: '',
                  category: '',
                  variant: measurementDisplayTextActive,
                  position: 1,
                });
              }}
            >
              <Col
                className="px-0 top-saving-image"
              >
                {
                  (offerPercentActive > 0 || displayTagActive) && (
                    <div
                      className="past-product-save-tag"
                    >
                      <CustomOfferBadge
                        badgeText={displayTagActive || `${Math.round(offerPercentActive)}% Off`}
                        badgeType="STAR_OFFER"
                      />
                    </div>
                  )
                }
                <CustomImage
                  imageStyle="mw-100 mh-100"
                  imageUrl={getCDNImageUrl(imageActive)}
                  imageType="PRODUCT"
                  imageAlt={nameActive}
                  imageTitle={nameActive}
                />
              </Col>
            </Link>
          </Row>
        </Col>
        <Col
          xs={horizontalView ? 16 : 24}
          className={horizontalView ? '' : 'px-0'}
        >
          <Row
            className={`pb-2 ${horizontalView ? 'mr-0' : 'mx-0 pt-3'}`}
          >
            <Col
              className="px-0 text-truncate-2 fs-4 font-weight-bold word-break"
            >
              {nameActive}
            </Col>
          </Row>
          <Row className={`${horizontalView ? 'mr-0' : 'mx-0'}`}>
            <span
              className="fs-4 font-weight-bold text-primary"
            >
              {`\u20B9 ${sellingPriceActive}`}
            </span>
            {
              (
                discount > 0
              ) && (
                <span
                  className="pl-2 fs-5 text-black-50 text-strike-through"
                >
                  <del>
                    {`\u20B9 ${mrpActive}`}
                  </del>
                </span>
              )
            }
          </Row>
          <Row className={`mt-2 ${horizontalView ? 'mr-0 align-items-center gap-10' : 'mx-0'}`}>
            <Col
              {...horizontalView ? { } : { xs: 24 }}
              className="px-0"
            >
              {
                variants && (variants || []).length > 1 ? (
                  <div className={`r__store_rating word-break cart-counter text-center border border-radius-8 py-1 bg-white font-weight-bold ${horizontalView ? '' : 'mb-2 px-2'}`}>
                    <select
                      name="shop-product-variant form-control"
                      onChange={(event) => {
                        setSelectedVar(variants[event.target.value]);
                      }}
                      className="variant-product"
                    >
                      {
                        variants.map((variant, index) => (
                          <option
                            selected={variant.retailProductId === retailProductIdActive}
                            key={variant.retailProductId}
                            value={index}
                            className="font-weight-black text-center"
                          >
                            {variant.measurementDisplayText || '-- --'}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                ) : (
                  (measurementDisplayText) && (
                    <div
                      className={`r__store_rating word-break cart-counter text-center border border-radius-8 py-1 bg-white font-weight-bold ${horizontalView ? '' : 'mb-2 px-2'}`}
                    >
                      {measurementDisplayText}
                    </div>
                  )
                )
              }
            </Col>
            <Col
              {...horizontalView ? { } : { xs: 24 }}
              className="px-0"
            >
              {
                isBrandProduct
                && (
                  <a href={searchQuery} className="d-block w-100 cart-counter text-center border border-primary fs-5 border-radius-8 px-2 py-1 bg-white mb-2 font-weight-bold text-primary">
                    {Constants.String.SHOP[language].toUpperCase()}
                  </a>
                )
              }
              {
                !isBrandProduct
                && (
                  <RoundedCartCounter
                    language={language}
                    section={section}
                    productInfo={{
                      storeName,
                      productName: nameActive,
                      sellingPrice: sellingPriceActive,
                      mrp: mrpActive,
                      imageUrl: imageActive,
                      measurementDisplayText: measurementDisplayTextActive,
                    }}
                    storeCode={storeId}
                    productId={retailProductIdActive}
                    quantity={quantity}
                    page={page}
                  />
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

ProductThumb.propTypes = {
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  isBrandProduct: PropTypes.bool,
  storeName: PropTypes.string,
  storeId: PropTypes.number,
  libraryProductId: PropTypes.number,
  retailProductId: PropTypes.number,
  name: PropTypes.string,
  mrp: PropTypes.number,
  sellingPrice: PropTypes.number,
  image: PropTypes.string,
  offerPercent: PropTypes.number,
  displayTag: PropTypes.string,
  measurementDisplayText: PropTypes.string,
  horizontalView: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.string,
  section: PropTypes.string,
};

ProductThumb.defaultProps = {
  cartItems: null,
  isBrandProduct: false,
  storeName: '',
  storeId: null,
  libraryProductId: null,
  retailProductId: null,
  name: '',
  mrp: 0,
  sellingPrice: 0,
  image: '',
  offerPercent: 0,
  displayTag: '',
  measurementDisplayText: '',
  horizontalView: false,
  variants: [],
  page: '',
  section: '',
};

export default ProductThumb;
