/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';
import { fbLogInitiateCheckout, fbLogPageView } from 'facebook/Facebook';
import {
  gaLogBeginCheckout, gaLogPageView, gaLogShippingInfo,
} from 'ecommerce_ga_events/EcommerceGAEvents';
import { uaLogCustomEvents } from 'ecommerce_ga_events/EcommerceUAEvents';
import { storeOfferLoadActions } from 'app/store/offer/actions';
import {
  cartAddCouponRequest, cartAddOfferRequest, cartCouponDeleteActions,
  cartDeleteActions, cartDeleteOfferRequest, cartDeliveryUpdateRequest,
  cartPaymentUpdateRequest,
} from 'app/store/cart/actions';
import Toast from 'app/HOC/Toast';
import ShopOffline from 'app/components/common/ShopOffline';
import CartProduct from './CartProduct';
import { Constants } from '../../utilities';
import { ActionAlert, CustomModal, Svg } from '../../components/common';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import { logButtonClick } from '../../events/Events';
import { getSeoShopUrl } from '../../utilities/Utils';
import { CART_AVAILABLE_OFFERS_REQUEST, ORDERS_CREATE_REQUEST } from '../../store/ActionTypes';
import * as Actions from '../../store/Actions';
import CustomScroller from '../../components/common/CustomScroller';
import CartAvailableOffer from './CartAvailableOffer';
import '../../styles/cart/cart.scss';
import CartCustomProduct from './CartCustomProduct';
import ScratchCardCoupons from './layout/reward-coupons/ScratchCardCoupons';
import CartFreeSample from './CartFreeSample';
import CreateCustomProduct from './layout/custom-product/CreateCustomProduct';
import ShopOffers from './layout/shop-offers/ShopOffers';
import FreeDeliveryStrip from './layout/delivery-preferences/FreeDeliveryStrip';
import BillDetails from './layout/bill/BillDetails';
import MakePayment from './layout/payment/MakePayment';
import SelectDeliveryOption from './layout/delivery-preferences/SelectDeliveryOption';
import DeliveryPreferenceBar from './layout/delivery-preferences/DeliveryPreferenceBar';
import PaymentPreferenceBar from './layout/delivery-preferences/PaymentPreferenceBar';
import {
  simplCheckEligibility,
} from '../../../api/api';
import ValueUnlockingStrip from './cart-applicable-offers';

const { CancelToken } = Axios;

class Cart extends React.Component {
  constructor(props) {
    super();
    this.source = CancelToken.source();
    const { cartDetails, selectedAddress, customer } = props;
    let rewardApplied = null;
    let couponCode = '';
    if (cartDetails && cartDetails.cartProductsCount !== 0) {
      rewardApplied = cartDetails.appliedCoupon ? cartDetails.appliedCoupon.isScratchCard : null;
      couponCode = cartDetails.appliedCoupon ? cartDetails.appliedCoupon.code : '';
    }
    this.state = {
      couponCode: couponCode || '',
      couponApplied: couponCode !== '',
      spendOffer: null,
      spendOfferError: null,
      alertReason: null,
      rewardApplied,
      selectedAddress,
      // eslint-disable-next-line react/no-unused-state
      customer,
      eligibleData: null,
      // eslint-disable-next-line react/no-unused-state
      error: null,
    };
    this.refCheckout = React.createRef();
    this.refToBillDetail = React.createRef(null);
  }

  componentDidMount() {
    const script = document.createElement('script');
    if (!document.getElementById('razorpay-checkout')) {
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.id = 'razorpay-checkout';
      document.body.appendChild(script);
    }
    if (this.refCheckout.current) {
      this.refCheckout.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
    gaLogPageView();
    fbLogPageView();
    const {
      cartLoadRequest, isMobile,
      cartDetails, selectedAddress,
      storeOffersLoadReq, cartAvailableOffersLoadRequest,
    } = this.props;
    // if (
    //   cartDetails
    //   && cartDetails.deliveryPreference !== 'NOT DEFINED'
    // ) {
    //   this.handleDeliveryOptionChange(
    //     cartDetails.deliveryPreference === 'STORE PICKUP' ? 1 : 0,
    //   );
    // }
    if (isMobile && cartDetails) {
      this.handleApplyOffersShowcase(cartDetails);
    }
    this.setState({
      selectedAddress,
    });
    cartLoadRequest(
      {
        isCheckout: true,
      },
      {
        type: 'async',
        callback: (data, cartLoadSuccess) => {
          const { res } = data;
          // LogClevertapEvent.shoppingCartViewed(
          //   data.res.totalBill,
          //   data.res.cartProductsCount,
          //   1,
          // );
          cartAvailableOffersLoadRequest(0, null, null, data.res.store.id);
          storeOffersLoadReq(
            res.store.id,
            null,
            null,
            {
              cartId: res.cartId,
            },
          );
          if (isMobile && !cartDetails) {
            this.handleApplyOffersShowcase(res);
          }
          const rewardApplied = res.appliedCoupon ? res.appliedCoupon.isScratchCard : null;
          const couponCode = res.appliedCoupon ? res.appliedCoupon.code : '';
          this.setState(
            {
              rewardApplied,
              couponCode,
            },
            () => {
              cartLoadSuccess(data);
            },
          );
        },
      },
    );
  }

// eslint-disable-next-line react/sort-comp
checkEligibilityApproval = () => {
  const { customer } = this.props;
  if (window.SimplFingerprint && window.SimplFingerprint.generateFingerprint) {
    window.SimplFingerprint.generateFingerprint(
      'test@google.com',
      customer.phone_number,
      (payload) => {
        simplCheckEligibility('post', {
          amount: 10000,
          payload,
        })
          .then((response) => {
            this.setState(
              {
                eligibleData: response.data,
              },

            );
          })
          .catch((error) => {
            this.setState({
              eligibleData: null,
              // eslint-disable-next-line react/no-unused-state
              error,
            });
          });
      },
    );
  } else {
    // eslint-disable-next-line no-console
    console.error('Payloadprint or generatePayloadprint is not available');
  }
};

// eslint-disable-next-line no-dupe-class-members
componentDidMount() {
  const script = document.createElement('script');
  script.src = 'https://cdn.getsimpl.com/simpl-fingerprint-v1.min.js';
  script.async = true;
  script.onload = () => {
    if (window.SimplFingerprint && window.SimplFingerprint.generateFingerprint) {
      this.checkEligibilityApproval();
    } else {
      // eslint-disable-next-line no-console
      console.error('SimplFingerprint functions not available');
    }
  };
  script.onerror = () => {
    // eslint-disable-next-line no-console
    console.error('Error');
  };
  document.head.appendChild(script);
}

componentDidUpdate() {
  const { updateStateRequest } = this.props;
  // eslint-disable-next-line react/destructuring-assignment
  updateStateRequest({ eligibleData: this.state.eligibleData });
}

  componentWillUnmount = () => {
    const { cartResetRequestStatus } = this.props;
    this.source.cancel();
    cartResetRequestStatus([
      CART_AVAILABLE_OFFERS_REQUEST,
    ]);
  }

  static getDerivedStateFromProps(props) {
    const { cartDetails, selectedAddress } = props;
    let rewardApplied = null;
    const appExclusiveOffer = null;
    let storePickupOnly = false;
    let couponCode = '';
    let isHDEnabled = false;
    let paymentPreference = '';
    if (cartDetails) {
      const {
        homeDelivery, storePickup,
      } = (
        cartDetails.store
        && cartDetails.store.productTransferModes
      ) ? cartDetails.store.productTransferModes : {};
      isHDEnabled = homeDelivery;
      storePickupOnly = (!homeDelivery && storePickup);
      rewardApplied = cartDetails.appliedCoupon ? cartDetails.appliedCoupon.isScratchCard : '';
      couponCode = cartDetails.appliedCoupon ? cartDetails.appliedCoupon.code : '';
      paymentPreference = cartDetails.paymentPreference === 'NOT DEFINED' ? '' : cartDetails.paymentPreference;
    }
    return ({
      cartDetails,
      couponCode,
      couponApplied: couponCode !== '',
      storePickupOnly,
      rewardApplied,
      appExclusiveOffer,
      isHDEnabled,
      paymentPreference,
      selectedAddress,
    });
  }

  componentWillUnmount() {
    const { resetRequestStatus } = this.props;
    resetRequestStatus([
      ORDERS_CREATE_REQUEST,
    ]);
  }

  handleSpendOfferRequest = (offer, apply) => {
    const {
      language, applyOffer,
    } = this.props;
    const offerType = offer.offerType === 'SPECIAL_SPEND_OFFER' ? 'SPEND_OFFER' : 'VALUE_OFFER';
    const onSuccess = {
      key: 'async',
      callback: () => {
        this.setState({
          alertReason: apply
            ? 'SPEND_OFFER_APPLIED'
            : 'SPEND_OFFER_REMOVED',
        });
      },
    };
    const onFailure = {
      key: 'async',
      callback: (data) => {
        this.setState({
          alertReason: 'SPEND_OFFER_FAILURE',
          spendOfferError: (data && data.errorMsg)
          || Constants.String.OOPS[language],
        });
      },
    };
    applyOffer[apply ? 'addRequest' : 'deleteRequest'](
      offer.id,
      offerType,
      onSuccess,
      onFailure,
    );
  }

  logGAonCheckout = (cartDetails) => {
    const cartProducts = cartDetails.cartProducts.map(
      (item) => ({
        item_id: item.libraryProductId,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      }),
    );
    gaLogBeginCheckout(
      cartDetails.totalBill,
      cartProducts,
    );
  }

  updateCartOffers = () => {
    const { cartAvailableOffersLoadRequest, storeOffersLoadReq, cartDetails } = this.props;
    cartAvailableOffersLoadRequest(0, null, null, null);
    if (cartDetails) {
      storeOffersLoadReq(
        cartDetails.store.id,
        null,
        null,
        {
          cartId: cartDetails.cartId,
        },
      );
    }
  }

  handleDeliveryOptionChange = (deliveryOption) => {
    const {
      deliveryPreferenceRequest,
      cartDetails,
    } = this.props;
    const { selectedAddress, couponCode } = this.state;
    const customerLocationId = selectedAddress ? selectedAddress.locationId : '';
    deliveryPreferenceRequest(
      deliveryOption === 1 ? {
        deliveryPreference: 'STORE PICKUP',
        customerLocationId,
      } : {
        deliveryPreference: 'HOME DELIVERY',
        customerLocationId,
      },
      null,
      null,
    );
    gaLogShippingInfo(
      cartDetails.totalBill,
      couponCode,
      deliveryOption === 1 ? 'STORE PICKUP' : 'HOME DELIVERY',
      cartDetails.cartProducts.map((item) => ({
        item_id: item.libraryProductId,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    );
    // if (customer && !customer.first_name && !isGuestUser()) {
    //   this.setState({
    //     showFillCustomerDetails: true,
    //   });
    // }
  }

  handleCouponCode = (apply = true) => {
    const {
      couponCodeRequest,
    } = this.props;
    const {
      couponCode, couponApplied,
    } = this.state;
    if (!couponApplied && !apply) {
      this.setState({
        couponCode: '',
      });
      return;
    }
    couponCodeRequest[apply ? 'addRequest' : 'deleteRequest'](
      apply ? couponCode : null,
      {
        type: 'sync',
        callback: () => {
          if (!apply) {
            this.handleCouponCode(false);
            return;
          }
          this.setState({
            alertReason: null,
          });
        },
      },
      {
        type: 'sync',
        callback: () => { },
      },
    );
  }

  useReward = (reward, apply = true) => {
    const {
      couponCodeRequest,
    } = this.props;
    couponCodeRequest[apply ? 'addRequest' : 'deleteRequest'](
      reward.id,
      apply,
      null,
      {
        type: 'sync',
        callback: () => { },
      },
    );
  }

  handleSpendOfferRequest = (offer, apply) => {
    const {
      language, applyOffer,
    } = this.props;
    const offerType = offer.offerType === 'SPEND_OFFER' ? 'SPEND_OFFER' : 'VALUE_OFFER';
    const onSuccess = {
      key: 'async',
      callback: () => {
        this.setState({
          alertReason: apply
            ? 'SPEND_OFFER_APPLIED'
            : 'SPEND_OFFER_REMOVED',
        });
      },
    };
    const onFailure = {
      key: 'async',
      callback: (data) => {
        this.setState({
          alertReason: 'SPEND_OFFER_FAILURE',
          spendOfferError: (data && data.errorMsg)
          || Constants.String.OOPS[language],
        });
      },
    };
    applyOffer[apply ? 'addRequest' : 'deleteRequest'](
      offer.id,
      offerType,
      onSuccess,
      onFailure,
    );
  }

  handleApplyOffersShowcase = (cartDetails) => {
    const {
      coachmark, updateCoachmark, unusedOfferCountRequest,
    } = this.props;
    unusedOfferCountRequest(
      {
        type: 'sync',
        callback: (data) => {
          const { applyOffers } = coachmark;
          const currentTime = new Date().getTime();
          if (
            currentTime - applyOffers.lastActive > applyOffers.interval
            && !cartDetails.scratchCardApplied
            && cartDetails.totalCouponAmount === 0
            && data.res.activeScratchCardCount !== 0) {
            updateCoachmark({
              ...coachmark,
              applyOffers: {
                ...applyOffers,
                lastActive: currentTime,
              },
            });
          }
        },
      },
    );
  }

  scrollToViewDetails = () => {
    LogClevertapEvent.logViewBillDetailsClick(
      {
        Page: 'Cart',
      },
    );
    this.refToBillDetail.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const {
      cartDetails, history, toggleSideOverlay,
      cartAddRequest, requestsProcessing,
      isMobile, cartAvailableOffers, cartItems,
      language, cartLoadRequest, storeOffers,
    } = this.props;

    const {
      alertReason, spendOfferError,
      appExclusiveOffer, couponApplied, couponCode,
      rewardApplied,
    } = this.state;

    const spendAndValueOffers = storeOffers ? storeOffers.filter(
      (item) => (item.offerType === 'VALUE_OFFER' || item.offerType === 'SPEND_OFFER'),
    ) : null;

    const appliedValueOffer = (
      cartDetails && cartDetails.cartOffers.length > 0
    ) ? cartDetails.cartOffers[0] : null;

    if (!cartDetails) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    let title = null;
    let message = '';
    let actionOnConfirm = null;
    let actionOnCancel = null;
    let confirmText = '';
    let cancelText = '';
    if (alertReason) {
      switch (alertReason) {
        case 'REMOVE_SPEND_OFFER':
          title = Constants.String.ARE_YOU_SURE[language];
          message = Constants.String.REMOVE_SPEND_OFFER_ALERT[language];
          cancelText = Constants.String.GO_BACK[language];
          confirmText = Constants.String.YES_REMOVE[language];
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          actionOnConfirm = () => {
            const { spendOffer } = this.state;
            this.handleSpendOfferRequest(spendOffer, false);
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          break;
        case 'REPLACE_SPEND_OFFER':
          title = Constants.String.REPLACE_OFFER[language];
          message = Constants.String.REPLACE_OFFER_DESC[language];
          cancelText = Constants.String.DISMISS[language];
          confirmText = Constants.String.OKAY[language];
          actionOnConfirm = () => {
            const { spendOffer } = this.state;
            this.handleSpendOfferRequest(spendOffer, true);
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          break;
        case 'SPEND_OFFER_APPLIED':
          title = Constants.String.OFFER_APPLIED[language];
          message = Constants.String.OFFER_APPLIED_DESC[language];
          confirmText = Constants.String.YAY[language];
          actionOnConfirm = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        case 'SPEND_OFFER_REMOVED':
          title = Constants.String.OFFER_REMOVED[language];
          message = Constants.String.OFFER_REMOVED_DESC[language];
          confirmText = Constants.String.OKAY[language];
          actionOnConfirm = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        case 'SPEND_OFFER_FAILURE':
          message = spendOfferError;
          cancelText = Constants.String.OKAY[language];
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        case 'REMOVE_APP_EXCLUSIVE':
          title = Constants.String.REMOVE_OFFER[language];
          message = Constants.String.REMOVE_APP_EXCLUSIVE_DESC[language];
          cancelText = Constants.String.DISMISS[language];
          confirmText = Constants.String.OKAY[language];
          actionOnConfirm = () => {
            this.setState({
              alertReason: '',
            });
            cartAddRequest(
              appExclusiveOffer.store.code,
              appExclusiveOffer.productId,
              0,
            );
          };
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        default:
          break;
      }
    }

    const cartProducts = cartDetails.cartProducts.filter((p) => !p.freeSample);
    const cartFreeSamples = cartDetails.cartProducts.filter((p) => p.freeSample);
    const cartSpendOffers = (cartDetails.cartOffers || []).filter((p) => p.offerType === 'SPEND_OFFER');
    const isEmptyCart = !!cartDetails && cartDetails.cartProductsCount === 0;
    const isShopOffline = cartDetails && cartDetails.store && !cartDetails.store.isOnline;
    let totalSavingsAmt = cartDetails.totalSavings;
    if (
      cartDetails
      && cartDetails.cartProductsCount > 0
      && cartDetails.homeDeliveryRemainingMOV === 0
      && (
        (cartDetails.deliveryPreference === 'HOME DELIVERY'
        && cartDetails.deliveryCharges
        && !cartDetails.deliveryCharges.deliveryAmount)
        || cartDetails.deliveryPreference === 'NOT DEFINED'
      )
    ) {
      totalSavingsAmt = cartDetails.totalSavings + cartDetails.deliveryFee;
    }
    if (
      cartDetails
      && cartDetails.platformFee
    ) {
      totalSavingsAmt += (
        Math.ceil(Number(
          cartDetails?.platformFee?.intialValue - cartDetails?.platformFee?.finalValueAndGst,
        ))
      );
    }

    return (
      <Container
        fluid
        id="cart"
        className={`px-0 d-flex flex-column h-100 fl${isMobile ? 'my-3' : ''}`}
      >
        <ActionAlert
          show={!!alertReason}
          title={title}
          message={message}
          actionOnCancel={actionOnCancel}
          actionOnConfirm={actionOnConfirm}
          cancelText={cancelText}
          confirmText={confirmText}
          isMobile={isMobile}
        />
        {
          isShopOffline && (
            <CustomModal
              show={isShopOffline}
              body={(
                <ShopOffline
                  language={language}
                  history={history}
                  isMobile={isMobile}
                  toggleSideOverlay={() => {
                    toggleSideOverlay(null);
                  }}
                  page="checkout"
                />
              )}
            />
          )
        }
        {!isMobile && (
          <>
            <Row
              className="w-100 mx-0 py-4 align-items-center"
            >
              <Col
                xs="auto"
                className="font-weight-black"
              >
                {Constants.String.YOUR_CART[language]}
              </Col>
            </Row>
          </>
        )}
        <div
          className=""
          // className="mov-section-top"
        >
          <ValueUnlockingStrip
            cartView
            history={history}
            isMobile={isMobile}
            toggleSideOverlay={toggleSideOverlay}
          />
        </div>
        {
          cartDetails && cartDetails.cartProductsCount > 0 && (
            !!totalSavingsAmt || !!cartFreeSamples.length || !!cartSpendOffers.length
          ) && (
            <div className="text-center bg-green-dark font-weight-black p-1 text-white">
              {`${totalSavingsAmt ? `₹${totalSavingsAmt} Saved` : ''}
              ${cartFreeSamples.length + cartSpendOffers.length
                ? ` ${totalSavingsAmt ? '+' : ''} ${cartFreeSamples.length + cartSpendOffers.length} FREE Products` : ''}`}
            </div>
          )
        }
        <Row
          className="mx-0 overlay-list h-100 bg-light"
        >
          {
            cartDetails.cartProductsCount > 0 && (
              <>
                {/* cart products */}
                {
                  cartDetails.store && (
                    <Col
                      xs={24}
                      key={cartDetails.store.id}
                      className="mb-2 bg-white"
                    >
                      <Row
                        className="bg-white"
                      >
                        <Col
                          xs={24}
                          className="px-2"
                        >
                          <Row
                            className="mx-0 py-3 px-2 font-weight-bold border-bottom"
                          >
                            <Col
                              xs={24}
                              // className="px-0 fs-4"
                              className="px-0 ml-auto d-flex align-items-center font-weight-bold cursor-pointer"
                              onClick={() => {
                                if (!isMobile && toggleSideOverlay) {
                                  toggleSideOverlay(null);
                                }
                                const { shopUrl } = getSeoShopUrl(
                                  cartDetails.store.country,
                                  cartDetails.store.city,
                                  cartDetails.store.locality,
                                  cartDetails.store.type,
                                  cartDetails.store.name,
                                  cartDetails.store.id,
                                );
                                history.push(shopUrl);
                              }}
                            >
                              {cartDetails.store.name}
                              <Svg
                                svg="arrowRight"
                                width="16px"
                                stroke={Constants.Color.black}
                              />
                            </Col>
                            <Col
                              xs={24}
                              className="px-0 fs-5"
                            >
                              {`${cartDetails.cartProductsCount + cartDetails.cartCustomProductsCount} Items`}
                            </Col>
                          </Row>
                          <Row
                            className="mx-0 px-2"
                          >
                            {/* cart products */}
                            {
                              cartProducts.map((product, index) => (
                                <CartProduct
                                  key={product.retailerProductId}
                                  product={product}
                                  store={cartDetails.store}
                                  isLastProduct={cartDetails.cartProducts.length === index + 1}
                                  isCart
                                  language={language}
                                  cartAddRequest={cartAddRequest}
                                  requestsProcessing={requestsProcessing}
                                  isMobile={isMobile}
                                  updateCartOffers={this.updateCartOffers}
                                  history={history}
                                  toggleSideOverlay={toggleSideOverlay}
                                />
                              ))
                            }
                            {/* custom products */}
                            {
                              cartDetails.cartCustomProducts.map((product) => (
                                <CartCustomProduct
                                  key={product.id}
                                  product={product}
                                  store={cartDetails.store}
                                  isCart
                                  language={language}
                                  cartAddRequest={cartAddRequest}
                                  requestsProcessing={requestsProcessing}
                                  isMobile={isMobile}
                                  updateCartOffers={this.updateCartOffers}
                                />
                              ))
                            }
                            {/* free spend offer products */}
                            {
                              cartSpendOffers.filter((p) => !!p.productInfo).map((product) => (
                                <CartFreeSample
                                  key={product.offerRetailerProductId}
                                  product={product}
                                  language={language}
                                  isMobile={isMobile}
                                  freeProductType="SPEND_OFFER"
                                />
                              ))
                            }
                            {/* free samples */}
                            {
                              cartFreeSamples.map((product) => (
                                <CartFreeSample
                                  key={product.retailerProductId}
                                  product={product}
                                  language={language}
                                  isMobile={isMobile}
                                  freeProductType="FREE_SAMPLE"
                                />
                              ))
                            }
                            {/* create custom product */}
                            {
                              <Col
                                xs={24}
                              >
                                <CreateCustomProduct
                                  cartDetails={cartDetails}
                                  onCloseOverlay={() => {
                                    // toggleSideOverlay(null);
                                    cartLoadRequest(
                                      {
                                        isCheckout: false,
                                      },
                                    );
                                  }}
                                />
                              </Col>
                            }
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )
                }
                {/* last minute add ons */}
                {
                  !!(cartAvailableOffers && cartAvailableOffers.count > 0)
                  && (
                    <Col
                      xs={24}
                      className="py-4 mb-2 bg-white"
                    >
                      <Row>
                        <Col
                          xs={24}
                          className="font-weight-black"
                        >
                          Your last minute add-ons
                        </Col>
                        <Col
                          xs={24}
                          className="pt-4 px-0"
                        >
                          <CustomScroller
                            contentPrefix="cart-available-offers"
                            noMargin
                            content={(
                              <div
                                className="d-flex mx-4"
                              >
                                {cartAvailableOffers.results.map((item) => (
                                  <CartAvailableOffer
                                    key={item.id}
                                    item={item}
                                    language={language}
                                    cartItems={cartItems}
                                    history={history}
                                    isMobile={isMobile}
                                    toggleSideOverlay={toggleSideOverlay}
                                    updateCartOffers={this.updateCartOffers}
                                  />
                                ))}
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
                }
                {/* spend value offers  - it will disaply both applied and non applied
                but cuurently filter is for only applied ones */}
                {
                  !!appliedValueOffer
                  && spendAndValueOffers
                  && spendAndValueOffers.length > 0
                  && (
                    <Col xs={24} className="mb-2 pt-3 bg-white">
                      <ShopOffers
                        {...this.props}
                        spendAndValueOffers={spendAndValueOffers}
                        cartDetails={cartDetails}
                        handleSpendOffer={
                          (apply, item, isOfferAlreadyApplied) => {
                            if (apply) {
                              if (isOfferAlreadyApplied) {
                                this.setState({
                                  alertReason: 'REPLACE_SPEND_OFFER',
                                  spendOffer: item,
                                });
                              } else {
                                this.handleSpendOfferRequest(item, apply);
                              }
                            } else {
                              this.setState({
                                alertReason: 'REMOVE_SPEND_OFFER',
                                spendOffer: item,
                              });
                            }
                          }
                        }
                      />
                    </Col>
                  )
                }
                {
                  cartDetails.homeDeliveryRemainingMOV === 0 && (
                    <Col xs={24} className="mb-2 bg-white">
                      <FreeDeliveryStrip language={language} />
                    </Col>
                  )
                }
                {/* Apply Scratch Card & Coupons */}
                {
                  // If no shop offer and coupon
                  !cartDetails.appliedCoupon
                  && cartDetails.cartOffers.length === 0 ? (
                    <Col xs={24} className="mb-2 bg-white">
                      <ScratchCardCoupons
                        {...this.props}
                        cartDetails={cartDetails}
                        history={history}
                        language={language}
                        isMobile={isMobile}
                        toggleSideOverlay={toggleSideOverlay}
                      />
                    </Col>
                    ) : null
                }
                {/* Show Applied coupons */}
                {
                  cartDetails.appliedCoupon ? (
                    <Col xs={24} className="bg-white mb-2">
                      <Row
                        className="py-4"
                      >
                        <Col xs="auto">
                          <Svg
                            svg="graphicOffer"
                            pathFill={Constants.Color.green}
                            circleFill={Constants.Color.white}
                            width="32px"
                          />
                        </Col>
                        <Col
                          className="pl-0"
                        >
                          <div
                            className="text-green font-weight-black"
                          >
                            {(couponApplied && couponCode)}
                          </div>
                          <div
                            className="fs-5 text-dark text-medium"
                          >
                            { cartDetails?.appliedCoupon?.campaign.name}
                          </div>
                        </Col>
                        <Col xs="auto" className="px-0">
                          <Button
                            className="fs-7 py-0"
                            variant="link"
                            // remove coupon here
                            onClick={() => {
                              if (rewardApplied) {
                                this.useReward(
                                  rewardApplied,
                                  false,
                                );
                              }
                              if (couponApplied) {
                                this.handleCouponCode(false);
                              }
                            }}
                          >
                            <Svg
                              svg="circleClose"
                              pathFill={Constants.Color.white}
                              circleFill={Constants.Color.secondary}
                              width="24px"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ) : null
                }
                {
                  <Col xs={24} className="mb-2 bg-white" ref={this.refToBillDetail}>
                    <BillDetails language={language} cartDetails={cartDetails} />
                  </Col>
                }
              </>
            )
        }
        </Row>
        {
          <PaymentPreferenceBar cartDetails={cartDetails} />
        }
        {
          cartDetails && cartDetails.cartProductsCount > 0 && cartDetails.deliveryPreference !== 'NOT DEFINED' && (
            <div className="">
              <DeliveryPreferenceBar
                cartDetails={cartDetails}
                language={language}
                toggleSideOverlay={toggleSideOverlay}
              />
            </div>
          )
        }
        <Row className="w-100 mx-0 py-3 d-flex checkout-section align-items-center border-top">
          <Col xs="auto">
            <div className="font-weight-black">
              {`₹${cartDetails.totalBill}`}
            </div>
            <Button
              variant="link"
              onClick={this.scrollToViewDetails}
              className="font-weight-black fs-7 text-primary p-0"
              disabled={isEmptyCart}
            >
              {'View Bill'.toUpperCase()}
            </Button>
          </Col>
          <Col className="flex-grow-1">
            <Button
              variant="primary"
              className={`fs-5 font-weight-black border-radius-8 w-100
              text-white ${isEmptyCart ? 'bg-disabled' : 'bg-primary'}`}
              disabled={isEmptyCart}
              onClick={() => {
                logButtonClick(
                  {
                    BUTTON: buttonActions.BUY_NOW,
                    SCREEN: screens.CART,
                    CART_ID: cartDetails.cartId,
                    CART_AMOUNT: cartDetails.totalBill,
                    PRODUCT_COUNT: cartDetails.cartProductsCount,
                  },
                );
                LogClevertapEvent.shoppingCartBuyNow(
                  cartDetails.cartProductsCount,
                  1,
                );
                uaLogCustomEvents('Initiate Checkout');
                fbLogInitiateCheckout();
                this.logGAonCheckout(cartDetails);
                this.checkEligibilityApproval();
                if (cartDetails && cartDetails.deliveryPreference === 'NOT DEFINED') {
                  toggleSideOverlay(SelectDeliveryOption, false);
                } else {
                  toggleSideOverlay(MakePayment, false);
                }
              }}
            >
              {
                cartDetails
                && cartDetails.deliveryPreference === 'NOT DEFINED'
                  ? 'SELECT DELIVERY OPTION' : 'CONTINUE TO PAYMENT'
              }
              &nbsp;
              <Svg
                svg="circleArrow"
                width="24px"
                circleFill={isEmptyCart ? Constants.Color.disabled : Constants.Color.primary}
                pathFill={Constants.Color.white}
              />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartAvailableOffers: state.main.cartAvailableOffers,
  cartItems: state.main.cartItems,
  highlightCartOfferDetails: state.main.highlightCartOfferDetails,
  coupons: state.main.coupons,
  customer: state.main.customer,
  cartDetails: state.main.cartDetails,
  selectedAddress: state.main.selectedAddress,
  processing: state.main.requestsProcessing,
  failure: state.main.requestsFailure,
  rewards: state.main.rewards,
  coachmark: state.main.coachmark,
  cartId: state.main.cartId,
  storeOffers: state.main.storeOffers,
  data: state.main.data,
});

const mapDispatchToProps = (dispatch) => ({
  couponCodeRequest: {
    addRequest: (
      couponCode,
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartAddCouponRequest.addRequest(
          onSuccess,
          onFailure,
          {},
          {
            couponCode,
          },
        ),
      );
    },
    deleteRequest: (
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartCouponDeleteActions.deleteRequest(
          onSuccess,
          onFailure,
          {},
        ),
      );
    },
  },
  deliveryPreferenceRequest: (
    body,
    onSuccess,
    onFailure,
  ) => {
    dispatch(
      cartDeliveryUpdateRequest.updateRequest(
        onSuccess,
        onFailure,
        {},
        body,
      ),
    );
  },
  paymentPreferenceRequest: (
    body,
    onSuccess,
    onFailure,
  ) => {
    dispatch(
      cartPaymentUpdateRequest.updateRequest(
        onSuccess,
        onFailure,
        body,
        {},
      ),
    );
  },
  storeOffersLoadReq: (
    storeCode,
    onSuccess,
    onFailure,
    params = {},
  ) => {
    dispatch(
      storeOfferLoadActions.loadRequest(
        onSuccess,
        onFailure,
        storeCode,
        params,
      ),
    );
  },
  couponsLoadRequest: (params, onSuccess, cancelToken) => {
    dispatch(
      Actions.couponsLoadRequest(params, onSuccess, cancelToken),
    );
  },
  ordersCreateRequest: (onSuccess, onFailure) => {
    dispatch(Actions.ordersCreateRequest(onSuccess, onFailure));
  },
  resetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  applyOffer: {
    addRequest: (
      offerId,
      offerType,
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartAddOfferRequest.addRequest(
          onSuccess,
          onFailure,
          {
            offerId,
            offerType,
          },
          {},
        ),
      );
    },
    deleteRequest: (
      offerId,
      offerType,
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartDeleteOfferRequest.deleteRequest(
          onSuccess,
          onFailure,
          {
            offerId,
            offerType,
          },
          {},
        ),
      );
    },
  },
  rewardLoadRequest: () => {
    dispatch(Actions.rewardLoadRequest());
  },
  toggleScratchCard: (scratchCard) => {
    dispatch(Actions.toggleScratchCard(scratchCard));
  },
  cartDeleteRequest: (onSuccess, onFailure) => {
    dispatch(cartDeleteActions.deleteRequest(onSuccess, onFailure));
  },
  unusedOfferCountRequest: (onSuccess) => {
    dispatch(Actions.unusedOfferCountRequest(true, null, onSuccess));
  },
  updateCoachmark: (coachmark) => {
    dispatch(Actions.updateCoachmark(coachmark));
  },
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
  cartNullRequest: () => {
    dispatch(Actions.cartNullRequest());
  },
  orderDetailsRequest: (orderId, onSuccess) => {
    dispatch(Actions.orderDetailsRequest(
      orderId, onSuccess, null,
    ));
  },
  cartAvailableOffersLoadRequest: (
    offset, onSuccess, cancelToken, storeId,
  ) => {
    dispatch(
      Actions.cartAvailableOffersRequest(
        offset, 20, onSuccess, cancelToken, storeId,
      ),
    );
  },
  cartResetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  updateStateRequest: (data) => {
    dispatch(Actions.updateState(data));
  },
});

Cart.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  couponCodeRequest: PropTypes.shape({
    addRequest: PropTypes.func,
    deleteRequest: PropTypes.func,
  }).isRequired,
  deliveryPreferenceRequest: PropTypes.func.isRequired,
  paymentPreferenceRequest: PropTypes.func.isRequired,
  storeOffersLoadReq: PropTypes.func.isRequired,
  couponsLoadRequest: PropTypes.func.isRequired,
  cartLoadRequest: PropTypes.func.isRequired,
  cartNullRequest: PropTypes.func.isRequired,
  ordersCreateRequest: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  processing: PropTypes.shape({}).isRequired,
  failure: PropTypes.shape({}).isRequired,
  storeOffers: PropTypes.arrayOf({}).isRequired,
  cartDetails: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.number,
      productTransferModes: PropTypes.shape({
        homeDelivery: PropTypes.bool,
        storePickup: PropTypes.bool,
      }),
      homeDeliveryMinAmount: PropTypes.number,
      country: PropTypes.string,
      city: PropTypes.string,
      locality: PropTypes.string,
      type: PropTypes.string,
      isOnline: PropTypes.bool,
    }),
    appliedCoupon: PropTypes.shape({
      code: PropTypes.string,
      isScratchCard: PropTypes.bool,
      campaign: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    paymentPreference: PropTypes.string,
    deliveryPreference: PropTypes.string,
    totalBill: PropTypes.number,
    totalSavings: PropTypes.number,
    billBeforeDiscount: PropTypes.number,
    cartProductsCount: PropTypes.number,
    cartCustomProductsCount: PropTypes.number,
    cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
    cartCustomProducts: PropTypes.arrayOf(PropTypes.shape({})),
    homeDeliveryRemainingMOV: PropTypes.number,
    cartId: PropTypes.number,
    maxDiscountForOnlinePaymentLong: PropTypes.string,
    deliveryCharges: PropTypes.shape({
      deliveryAmount: PropTypes.number,
    }),
    cartOffers: PropTypes.arrayOf(PropTypes.shape({})),
    deliveryFee: PropTypes.number,
    platformFee: PropTypes.shape({
      intialValue: PropTypes.number,
      finalValueAndGst: PropTypes.number,
    }),
  }),
  coupons: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  customer: PropTypes.shape({
    phone_number: PropTypes.string,
  }),
  selectedAddress: PropTypes.shape({
    locationId: PropTypes.number,
    isCompleted: PropTypes.bool,
    location: PropTypes.shape({}),
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    localityName: PropTypes.string,
    landmark: PropTypes.string,
    addressType: PropTypes.string,
    addressText: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      fromCart: PropTypes.bool,
      fromRewardCoupon: PropTypes.bool,
      fromLastOrderProducts: PropTypes.bool,
      fromCheckoutStoreItems: PropTypes.bool,
      fromApplicableValueOffers: PropTypes.bool,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  applyOffer: PropTypes.shape({
    addRequest: PropTypes.func,
    deleteRequest: PropTypes.func,
  }).isRequired,
  rewardLoadRequest: PropTypes.func.isRequired,
  rewards: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  toggleScratchCard: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  cartAddRequest: PropTypes.func.isRequired,
  unusedOfferCountRequest: PropTypes.func.isRequired,
  coachmark: PropTypes.shape({
    applyOffers: PropTypes.shape({
      interval: PropTypes.number,
      lastActive: PropTypes.number,
    }),
  }).isRequired,
  updateCoachmark: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  orderDetailsRequest: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  cartId: PropTypes.number.isRequired,
  showToast: PropTypes.func.isRequired,

  cartAvailableOffersLoadRequest: PropTypes.func.isRequired,
  cartResetRequestStatus: PropTypes.func.isRequired,
  updateStateRequest: PropTypes.func.isRequired,
  cartAvailableOffers: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf({}),
  }),
  cartItems: PropTypes.shape({
    productInfo: PropTypes.shape({}),
  }),
  requestsProcessing: PropTypes.shape({}).isRequired,
};

Cart.defaultProps = {
  cartAvailableOffers: null,
  cartItems: null,
  cartDetails: null,
  coupons: null,
  selectedAddress: null,
  rewards: null,
  customer: null,
};

export default Toast(connect(mapStateToProps, mapDispatchToProps)(Cart));
