import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from 'app/utilities';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { isGuestUser } from 'app/utilities/Utils';
import { cartLoadActions } from 'app/store/cart/actions';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

function ChangeLocation(props) {
  const {
    language, isMobile, action, onCancel, address,
    notifyAction, history, isReorder, reset, isLocationSearch,
    page,
  } = props;
  const dispatch = useDispatch();
  const {
    requestsProcessing,
    requestsFailure,
  } = useSelector((state) => state.main);
  const loader = requestsProcessing[ActionTypes.ADDRESS_CHANGE_REQUEST]
    || requestsProcessing[ActionTypes.ADDRESS_DELETE_REQUEST]
    || requestsProcessing[ActionTypes.ADD_ADDRESS_REQUEST];
  const failure = requestsFailure[ActionTypes.ADDRESS_CHANGE_REQUEST]
    || requestsFailure[ActionTypes.ADDRESS_DELETE_REQUEST]
    || requestsFailure[ActionTypes.ADD_ADDRESS_REQUEST];

  let message = null;
  let cancelString = null;
  let confirmString = null;
  let header = null;
  switch (action) {
    case 'change':
      header = loader
        ? Constants.String.UPDATING_LOCATION[language] : Constants.String.UPDATE_LOCATION[language];
      message = '';
      cancelString = Constants.String.CANCEL[language];
      confirmString = Constants.String.CONTINUE[language];
      break;
    case 'set-default':
      header = Constants.String.SET_DEFAULT[language];
      message = Constants.String.SET_DEFAULT_DESCRIPTION[language];
      cancelString = Constants.String.NO[language];
      confirmString = Constants.String.YES[language];
      break;
    default:
      break;
  }

  const handleOnCancel = () => {
    onCancel();
  };

  const handleOnConfirm = () => {
    const dispatchAction = isLocationSearch ? Actions.addAddress : Actions.addressChangeRequest;
    dispatch(dispatchAction(
      address,
      {
        type: 'async',
        callback: (data, addressChangeSuccess) => {
          if (!isGuestUser()) {
            dispatch(Actions.recentAddressLoadRequest(null, null));
            reset();
          }
          addressChangeSuccess();
          dispatch(cartLoadActions.loadRequest());
          notifyAction();
          LogClevertapEvent.appLaunchedOrAddressChanged(data.res);
          LogClevertapEvent.locationSwitch(
            data.res.addressText,
          );
          if (isReorder && isMobile) {
            history.push('/cart');
            return;
          }
          dispatch(Actions.toggleManageLocation());
          if (page === 'product-details') {
            // remove query params and render rest
            window.location.replace(window.location.pathname);
          } else {
            window.location.reload();
          }
        },
      },
    ));
  };

  const content = (
    <Container
      className="text-lg-left py-5 px-7 bg-white r__action_container"
    >
      <Row className="py-2">
        <Col xs={24}>
          <h3 className="text-primary fs-3 m-0 r__action_title">
            {header}
          </h3>
        </Col>
      </Row>
      <Row className="py-2">
        <Col xs={24} className="fs-4 text-dark r__action_copy">
          {message}
        </Col>
      </Row>
      {
      loader
        ? (
          <Row
            className="py-2"
          >
            <Col
              xs={24}
              className="text-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </Col>
          </Row>
        ) : (
          <Row
            className="justify-content-center py-2 r__action_btn-wrapper"
          >
            <Col
              xs="auto"
              md
              className="py-1 px-0"
            >
              <Button
                variant={isMobile ? 'link' : 'outline-primary'}
                className="fs-5 w-100 py-3 r__action_cancel"
                onClick={handleOnConfirm}
              >
                {confirmString}
              </Button>
            </Col>
            <Col
              xs="auto"
              md
              className="py-1 px-0"
            >
              <Button
                variant={isMobile ? 'link' : 'primary'}
                className="fs-5 w-100 py-3 r__action_confirm"
                onClick={handleOnCancel}
              >
                {cancelString}
              </Button>
            </Col>
          </Row>
        )
      }
      {
      failure
        && (
        <Row>
          <Col
            xs={24}
            className="text-danger fs-5"
          >
            {Constants.String.OOPS[language]}
          </Col>
        </Row>
        )
      }
    </Container>
  );

  return content;
}

ChangeLocation.propTypes = {
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  action: PropTypes.string,
  address: PropTypes.shape({}),
  onCancel: PropTypes.func.isRequired,
  notifyAction: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
    length: PropTypes.number,
    goBack: PropTypes.func,
  }).isRequired,
  isReorder: PropTypes.bool,
  reset: PropTypes.func,
  isLocationSearch: PropTypes.bool,
  page: PropTypes.string.isRequired,
};

ChangeLocation.defaultProps = {
  action: null,
  address: null,
  isReorder: false,
  reset: () => {},
  isLocationSearch: false,
};

export default ChangeLocation;
