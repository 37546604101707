import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import CustomImage from 'app/components/common/CustomImage';
import { LOVELOCAL, getCDNImageUrl, getSEOFriendlyProductName } from 'app/utilities/Utils';
import RoundedCartCounter from 'app/components/derived/cart/RoundedCartCounter';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import { specialOfferAddedToCart } from 'clevertap/LogEvent';
import { Link } from 'react-router-dom';
import { logCTEvent } from 'app/events/Events';
import Events from 'clevertap/Event';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';

const BogoOfffer = ({
  item, cartItems, language, horizontalView,
  page, section,
}) => {
  let quantity = 0;
  const sellingStore = item.retailerProduct.sellingStores[0];
  const cartProduct = cartItems && cartItems[item.retailerProduct.retailerProductId];
  if (cartProduct && Number(cartProduct.storeId) === Number(sellingStore.store.id)) {
    quantity = cartProduct.quantity;
  }

  const pathName = {
    pathname: `/products/${getSEOFriendlyProductName(item.name)}-${item.mainId}${item?.store?.code ? `?sid=${item.store.code}` : ''}`,
    state: {
      showOfferDetail: true,
      scrollToTop: true,
    },
  };

  const addToCartSuccess = (productCount, isAdding) => {
    specialOfferAddedToCart(
      item.retailerProduct.displayName,
      productCount,
      sellingStore.sellingPrice,
      'COMBO_OFFERS',
    );
    gaLogAddOrRemoveProductFromCart(
      isAdding,
      sellingStore.sellingPrice,
      {
        item_id: item.retailerProduct.id,
        item_name: item.retailerProduct.displayName,
        price: sellingStore.sellingPrice,
        quantity: productCount,
      },
    );
  };

  // dont consider horizontalView similar to mobile view
  return (
    <Col
      id="store-top-saving-offer"
      xs={horizontalView ? 24 : 'auto'}
      className="text-wrap border border-radius-16 p-4 px-3 position-relative shadow-sm"
    >
      <Row
        className={`mx-0 align-items-center ${horizontalView ? '' : 'top-saving-card'}`}
      >
        <Col
          className={horizontalView ? '' : 'px-0'}
          xs={horizontalView ? 8 : 24}
        >
          <Row
            className={horizontalView ? 'align-items-center top-saving-card' : 'mx-0'}
          >
            <Link
              to={pathName}
              onClick={() => {
                logCTEvent(
                  Events.LL_Product_Viewed,
                  {
                    Page: page,
                    'Product Id': item.retailerProduct.id,
                    'Product name': item.retailerProduct.displayName,
                    'Retail Price': sellingStore.sellingPrice,
                    Price: sellingStore.mrp,
                    Currency: 'INR',
                    Quantity: quantity,
                    Image: item.productImage1,
                    section: section || 'NA',
                  },
                );
              }}
            >
              <Col
                className={`${horizontalView ? 'pl-0' : 'px-0'} top-saving-image`}
              >
                {
                  !!sellingStore.displayTag && (
                    <div
                      className="discount-badge"
                    >
                      <CustomRoundBadge
                        badgeText={sellingStore.displayTag}
                        isRibbon
                        badgeType="ROUND_OFFER"
                      />
                    </div>
                  )
                }
                <>
                  <div className="w-100 h-100">
                    <CustomImage
                      imageStyle="mw-100 h-100 object-fit-contain"
                      imageUrl={getCDNImageUrl(item.productImage1)}
                      imageType="OFFER"
                      imageAlt={item.name}
                    />
                  </div>
                  <div className="w-100 h-100">
                    <CustomImage
                      imageStyle="mw-100 h-100 object-fit-contain"
                      imageUrl={getCDNImageUrl(item.productImage2)}
                      imageType="OFFER"
                      imageAlt={item.name}
                    />
                  </div>
                </>
              </Col>
            </Link>
          </Row>
        </Col>
        <Col
          xs={horizontalView ? 16 : 24}
          className={horizontalView ? 'pr-0' : 'px-0'}
        >
          <Row
            className={`pb-2 mx-0 ${horizontalView ? '' : 'pt-3'}`}
          >
            <Col
              className="px-0 text-truncate-2 fs-4 font-weight-bold"
            >
              {item.name}
            </Col>
          </Row>
          <Row className={`mx-0 ${horizontalView ? '' : ''}`}>
            <span
              className="fs-4 font-weight-bold text-primary"
            >
              {`\u20B9${sellingStore.sellingPrice}`}
            </span>
            {
              !!(
                sellingStore.mrp !== -1
                || sellingStore.mrp > sellingStore.sellingPrice
              ) && (
              <Col
                xs="auto"
                className="pl-0 pr-1"
              >
                <span
                  className="pl-2 fs-5 text-black-50 text-strike-through"
                >
                  <del>
                    {`\u20B9${sellingStore.mrp}`}
                  </del>
                </span>
              </Col>
              )
            }
          </Row>
          <Row className={`mt-2 mx-0 ${horizontalView ? 'align-items-center gap-10' : ''}`}>
            <Col
              {...horizontalView ? { } : { xs: 24 }}
              className="px-0"
            >
              {
                sellingStore.unitString && (
                  <Col className={`r__store_rating word-break cart-counter text-center border border-radius-8 py-1 bg-white font-weight-bold ${horizontalView ? '' : 'mb-2'}`}>
                    {sellingStore.unitString}
                  </Col>
                )
              }
            </Col>
            <Col
              {...horizontalView ? { } : { xs: 24 }}
              className="px-0"
            >
              <RoundedCartCounter
                language={language}
                productInfo={{
                  storeName: sellingStore.store.displayName,
                  productName: item.name,
                  sellingPrice: sellingStore.sellingPrice,
                  mrp: sellingStore.mrp,
                  imageUrl: item.productImage1
                  || item.productImage2
                  || LOVELOCAL.productPlaceholder,
                  measurementDisplayText: sellingStore.unitString,
                }}
                productId={item.retailerProduct.retailerProductId}
                storeCode={sellingStore.store.id}
                quantity={quantity}
                onSuccess={addToCartSuccess}
                page={page}
                section={section}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

BogoOfffer.propTypes = {
  item: PropTypes.shape({
    store: PropTypes.shape({
      code: PropTypes.string,
      displayName: PropTypes.string,
      locality: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      storeType: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
    retailerProduct: PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      image1: PropTypes.string,
      displayName: PropTypes.string,
      sellingStores: PropTypes.arrayOf(
        PropTypes.shape({
          isFresh: PropTypes.bool,
          sellingPrice: PropTypes.number,
          store: PropTypes.shape({
            id: PropTypes.string,
            displayName: PropTypes.string,
          }),
          mrp: PropTypes.number,
          unitString: PropTypes.string,
          displayTag: PropTypes.string,
        }),
      ),
      retailerProductId: PropTypes.number,
    }),
    name: PropTypes.string,
    productImage1: PropTypes.string,
    productImage2: PropTypes.string,
    mainId: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  language: PropTypes.string,
  horizontalView: PropTypes.bool,
  page: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
};

BogoOfffer.defaultProps = {
  cartItems: null,
  language: 'en',
  horizontalView: false,
};

export default BogoOfffer;
