import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button, Col, Container, DropdownButton, Row, Spinner,
} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Svg } from 'app/components/common';
import { customerAddress, getGeoAddress } from 'api/api';
import Shimmer from 'app/shimmer/Shimmer';
import { Constants } from 'app/utilities';
import { isAuthenticated } from 'app/utilities/Utils';
import { logButtonClick } from 'app/events/Events';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';

const addressPayload = {
  addressText: '',
  location: null,
  isCompleted: false,
  isDefault: true,
  flatNumber: '',
  buildingName: '',
  streetName: '',
  localityName: '',
  landmark: '',
  pinCode: '',
  addressType: 'Home',
};

const ViewAddress = (props) => {
  const {
    handleChange, isAddAddress, notifyAction, handleSelectAddress,
    isMobile, showMoreAddresses, toggleMoreOption,
  } = props;

  const addresses = useSelector(((state) => state.main.customerAddresses));
  const recentAddresses = useSelector((state) => state.main.recentSearchedAddress);
  const language = useSelector((state) => state.main.language);
  const cartDetails = useSelector((state) => state.main.cartDetails);

  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const searchPlaces = React.createRef();
  const isLoggedInUser = isAuthenticated();

  const googleMapApi = () => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q&libraries=places&country=IN';
    script.async = true;
    script.id = 'google-apis';

    script.onload = () => {
      resolve('https://maps.googleapis.com/maps/api/js?key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q&libraries=places&country=IN');
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(script);
  });

  const init = () => googleMapApi()
    .then(() => {
      if (document.getElementById('google-apis')) {
        setLoader(false);
        searchPlaces.current.focus();
      }
    })
    .catch(() => {
      setLoader(false);
      setError(Constants.String.OOPS);
    });

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accessCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const location = {
        lat: latitude,
        lng: longitude,
      };
      getGeoAddress(`${latitude}, ${longitude}`)
        .then((res) => {
          const address = res.data.results[0].formatted_address;
          const completeAddress = {
            ...addressPayload,
            addressText: address,
            location,
            addressType: 'Other',
            isDummy: false,
            locationId: `Guest-${(new Date()).getTime()}`,
          };
          notifyAction({ ...completeAddress }, (cartDetails && cartDetails.cartProductsCount !== 0) ? 'change' : 'no-alert', true);
        })
        .catch(() => {

        });
    });
  };

  const handleSelect = () => {
    // TODO
  };

  const addRecentAddress = (address) => {
    customerAddress(
      'POST',
      {
        ...address,
        isRecentSearch: true,
        keyword: searchText,
      },
    ).then(() => {

    }).catch(() => {

    });
  };

  const moreOptionJsx = (address) => (
    <div className="border border-radius-8 bg-white">
      <div
        className="align-items-center justify-content-center py-5"
      >
        <Col
          xs={24}
          className="address-action cursor-pointer"
          onClick={() => {
            notifyAction({ ...address });
            handleChange('edit-address');
            logButtonClick(
              {
                BUTTON: buttonActions.EDIT_ADDRESS,
                SCREEN: screens.MANAGE_LOCATION,
                ADDRESS_ID: address.locationId,
              },
            );
          }}
        >
          {Constants.String.EDIT[language]}
        </Col>
        <Col
          xs={24}
          className={`address-action my-1 ${address.isDefault ? 'text-gray' : 'cursor-pointer'}`}
          onClick={() => {
            if (!address.isDefault) {
              notifyAction({ ...address }, 'delete');
              logButtonClick(
                {
                  BUTTON: buttonActions.DELETE_ADDRESS,
                  SCREEN: screens.MANAGE_LOCATION,
                  ADDRESS_ID: address.locationId,
                },
              );
            }
          }}
        >
          {Constants.String.DELETE[language]}
        </Col>
        <Col
          xs={24}
          className={`address-action ${address.isDefault ? 'text-gray' : 'cursor-pointer'}`}
          onClick={() => {
            if (!address.isDefault) {
              notifyAction({ ...address, isDefault: true }, 'set-default');
            }
          }}
        >
          {Constants.String.SET_AS_DEFAULT[language]}
        </Col>
      </div>
    </div>
  );

  const selectSearchAddress = (address, addressText) => {
    geocodeByAddress(addressText)
      .then((results) => getLatLng(results[0]))
      .then((location) => {
        const completeAddress = {
          ...addressPayload,
          addressText: address.formattedSuggestion.secondaryText,
          location,
          addressType: address.formattedSuggestion.mainText,
          isDummy: false,
          locationId: `Guest-${(new Date()).getTime()}`,
        };
        if (isLoggedInUser) {
          addRecentAddress(completeAddress);
        }
        if (isAddAddress) {
          handleSelectAddress(completeAddress);
          handleChange('add-full-address');
          return;
        }
        notifyAction({ ...completeAddress }, (cartDetails && cartDetails.cartProductsCount !== 0) ? 'change' : 'no-alert', true);
      })
      .catch(() => {
        //
      });
  };

  const searchAddressJsx = (address) => (
    <Row key={address.placeId} className="py-1">
      <Col
        xs={22}
        className="px-0 cursor-pointer"
        onClick={() => {
          selectSearchAddress(address, address.description);
        }}
      >
        <Row className="mx-0">
          <div className="d-flex">
            <Svg
              svg="locationIcon"
              width={24}
              height={24}
            />
          </div>
          &nbsp;
          <span className="font-weight-bold text-truncate">
            {address.formattedSuggestion && address.formattedSuggestion.mainText ? address.formattedSuggestion.mainText : ''}
          </span>
        </Row>
        <Row className="mx-0">
          <span className="text-truncate fs-6 text-secondary font-weight-bold">
            {address.formattedSuggestion && address.formattedSuggestion.secondaryText ? address.formattedSuggestion.secondaryText : ''}
          </span>
        </Row>
      </Col>
    </Row>
  );

  const addressJsx = (address, isRecentSearch = false, showAlert = true) => (
    <Row key={address.locationId} className="py-1 address-bar">
      <Col
        xs={20}
        className="px-0 cursor-pointer"
        onClick={() => {
          notifyAction({ locationId: `Guest-${(new Date()).getTime()}`, ...address, isDefault: true }, showAlert ? 'change' : 'no-alert', isRecentSearch);
        }}
      >
        <Row className="mx-0">
          <div className="d-flex position-relative">
            <Svg
              // eslint-disable-next-line no-nested-ternary
              svg={`${address.addressType === 'Home' ? 'homeIcon' : address.addressType === 'Work' ? 'work' : 'locationIcon'}`}
              width={24}
              height={24}
            />
            {
              address.isDefault && (
                <div className="tick-icon">
                  <Svg
                    svg="tickMark"
                    width={12}
                    height={12}
                  />
                </div>
              )
            }
          </div>
          &nbsp;
          <span className={`font-weight-bold text-truncate ${isMobile ? 'mw-200p' : 'mw-320p'}`}>
            {address.addressType}
          </span>
          {
            address.isDefault && (
              <div className="bg-gray-lighter mx-1 border-radius-4">
                <span className="fs-6 text-black px-2 align-items-center font-weight-bold">
                  Default
                </span>
              </div>
            )
          }
        </Row>
        <Row className="mx-0">
          <span className="text-truncate fs-6 text-secondary font-weight-bold">
            {address.addressText}
          </span>
        </Row>
      </Col>
      {
        !isRecentSearch && (
          <Col className="d-flex justify-content-end align-items-center px-0 address-dropdown">
            <DropdownButton
              variant="link"
              alignRight
              title={(
                <Svg
                  svg="moreOption"
                  fill="none"
                  width={32}
                  height={32}
                />
              )}
            >
              {moreOptionJsx(address)}
            </DropdownButton>
          </Col>
        )
      }
    </Row>
  );

  const completeAddresses = addresses && addresses.filter((address) => (
    address && (address.isCompleted || address.isDefault)));
  const savedAddresses = completeAddresses.filter((address) => (
    new RegExp(searchText, 'i').test(address.addressType)
  ));

  return (
    <Container fluid className="px-0 bg-white">
      <div className="">
        {
          loader ? (
            <Row
              className="justify-content-center"
            >
              <Shimmer
                shimmerType="FALLBACK"
              />
            </Row>
          ) : (
            <Row className="justify-content-center">
              <Col xs={24} className="px-0">
                <PlacesAutocomplete
                  value={searchText}
                  onChange={(e) => { setSearchText(e); }}
                  onSelect={handleSelect}
                  debounce={500}
                  shouldFetchSuggestions={searchText.length > 3}
                >
                  {({
                    getInputProps, suggestions, getSuggestionItemProps, loading,
                  }) => (
                    <div>
                      <div className="py-1 border border-radius-8 form-control form-control-lg d-flex align-items-center">
                        <span
                          className="d-flex align-items-center"
                        >
                          <Svg
                            svg="globalSearch"
                            stroke={Constants.Color.black}
                            width="20px"
                          />
                        </span>
                        <input
                          {...getInputProps({
                            ref: searchPlaces,
                            placeholder: Constants.String.SEARCH_PLACES[language],
                            className: 'pl-2 location-search-input w-100 fs-4 border-0 shadow-none',
                            autoFocus: true,
                          })}
                        />
                        <Button
                          variant="link"
                          className={searchText.length === 0 ? 'd-none' : 'text-secondary px-1 fs-2'}
                          onClick={() => {
                            setSearchText('');
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                      <Row className="mx-0 mt-3">
                        <Col
                          xs={!isLoggedInUser || isAddAddress || isMobile ? '24' : '12'}
                          className={`${isAddAddress || !isLoggedInUser ? 'px-0' : ''}
                            ${isMobile || !isLoggedInUser ? 'px-0' : 'pr-2 pl-0'}`}
                        >
                          <Button
                            block
                            variant="primary"
                            className="fs-5 py-2 border-radius-8 font-weight-black"
                            onClick={() => {
                              accessCurrentLocation();
                              logButtonClick(
                                {
                                  BUTTON: buttonActions.USE_CURRENT_LOCATION,
                                  SCREEN: screens.MANAGE_LOCATION,
                                },
                              );
                            }}
                          >
                            <span className="d-flex align-items-center justify-content-center">
                              <Svg
                                svg="currentLocation"
                                height={16}
                                width={17}
                              />
                              &nbsp;&nbsp;
                              {Constants.String.USE_CURRENT_LOCATION[language]}
                            </span>
                          </Button>
                        </Col>
                        <Col
                          xs={isMobile || !isLoggedInUser ? '24' : '12'}
                          className={`${isAddAddress || !isLoggedInUser ? 'd-none' : ''}
                            ${isMobile || !isLoggedInUser ? 'px-0 pt-2' : 'pl-2 pr-0'}`}
                        >
                          <Button
                            block
                            variant="link"
                            className="fs-5 py-2 border-primary border-radius-8 font-weight-black"
                            onClick={() => {
                              handleChange('add-address');
                              logButtonClick(
                                {
                                  BUTTON: buttonActions.ADD_NEW_ADDRESS,
                                  SCREEN: screens.MANAGE_LOCATION,
                                },
                              );
                              LogClevertapEvent.newLocationAddButton();
                            }}
                          >
                            <span className="d-flex align-items-center justify-content-center">
                              <Svg
                                svg="addLocation"
                                width={16}
                                height={16}
                              />
                              &nbsp;&nbsp;
                              {Constants.String.ADD_ADDRESS[language]}
                            </span>
                          </Button>
                        </Col>
                      </Row>
                      {
                        searchText && (
                          <Row className="border-top mt-3 mx-0">
                            <Col xs={24}>
                              {(() => {
                                let showcase = null;
                                if (loading) {
                                  showcase = (
                                    <Row
                                      className="mx-0 py-3 justify-content-center"
                                    >
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                      />
                                    </Row>
                                  );
                                } else if (!suggestions && !loading && error) {
                                  showcase = (
                                    <Row
                                      className="mx-0 py-3 justify-content-center"
                                    >
                                      <span className="text-danger">
                                        {error[language]}
                                      </span>
                                    </Row>
                                  );
                                } else if (
                                  suggestions && suggestions.length > 0
                                ) {
                                  showcase = (
                                    <>
                                      <Row className="text-secondary font-weight-bold fs-5 pt-4 pb-2">
                                        {Constants.String.SEARCH_RESULTS[language]}
                                      </Row>
                                      {
                                        suggestions.map((suggestion) => (
                                          <div
                                            {
                                              ...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: `suggestion-item ${suggestion.active ? ' active' : ''}`,
                                                },
                                              )
                                            }
                                          >
                                            {searchAddressJsx(suggestion)}
                                          </div>
                                        ))
                                      }
                                    </>
                                  );
                                }
                                return showcase;
                              })()}
                            </Col>
                          </Row>
                        )
                      }
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
          )
        }
      </div>

      {/* Saved Address */}
      {
        isLoggedInUser && !isAddAddress && savedAddresses && savedAddresses.length > 0 && (
          <Row className="border-top mt-3">
            <Col xs={24}>
              <Row className="text-secondary font-weight-bold fs-5 pt-4 pb-1">
                {Constants.String.SAVED_ADDRESSES[language]}
              </Row>
              {
                savedAddresses.slice(0, 5).map((address) => (
                  <>
                    {addressJsx(address, false, (cartDetails && cartDetails.cartProductsCount !== 0))}
                  </>
                ))
              }
              {
                showMoreAddresses
                && savedAddresses.slice(5).map((address) => (
                  <>
                    {addressJsx(address, false, (cartDetails && cartDetails.cartProductsCount !== 0))}
                  </>
                ))
              }
            </Col>
            {
              savedAddresses.length > 5 && (
                <Col className="d-flex justify-content-center" xs={24}>
                  <Button
                    variant="link"
                    onClick={() => toggleMoreOption()}
                    className="p-0"
                  >
                    {`${showMoreAddresses ? Constants.String.SEE_LESS[language] : Constants.String.SEE_MORE[language]}`}
                    <Svg
                      svg={`${showMoreAddresses ? 'upArrow' : 'downArrow'}`}
                      width={24}
                      height={24}
                    />
                  </Button>
                </Col>
              )
            }
          </Row>
        )
      }
      {/* Recent Address */}
      {
        isLoggedInUser && !searchText && !isAddAddress && savedAddresses
        && savedAddresses.length > 0 && recentAddresses && recentAddresses.length > 0 && (
          <Row className="border-top mt-3">
            <Col xs={24}>
              <Row className="text-secondary font-weight-bold fs-5 pt-4 pb-1">
                {Constants.String.RECENT_ADDRESSES[language]}
              </Row>
              {
                recentAddresses.slice(0, 2).map((address) => (
                  <>
                    {addressJsx(address, true, (cartDetails && cartDetails.cartProductsCount !== 0))}
                  </>
                ))
              }
            </Col>
          </Row>
        )
      }
    </Container>
  );
};

ViewAddress.propTypes = {
  handleChange: PropTypes.func.isRequired,
  notifyAction: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  handleSelectAddress: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isAddAddress: PropTypes.bool,
  showMoreAddresses: PropTypes.bool.isRequired,
  toggleMoreOption: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
    length: PropTypes.number,
    goBack: PropTypes.func,
  }).isRequired,
};

ViewAddress.defaultProps = {
  isAddAddress: false,
};

export default ViewAddress;
