import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScratchCard as ScratchCardJs, SCRATCH_TYPE } from 'scratchcard-js';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import jsxToString from 'jsx-to-string';
import CustomModal from './CustomModal';
import Svg from './Svg';
import '../../styles/ScratchCard.scss';
import { Constants, Utils } from '../../utilities';
import * as Actions from '../../store/Actions';
import { UNLOCK_REWARD_REQUEST } from '../../store/ActionTypes';
import ReferFriendInfo from '../../layout/refer-friends/ReferFriendInfo';

class ScratchCard extends React.Component {
  constructor() {
    super();
    this.state = {
      showReferAndEarn: false,
    };
  }

  componentDidMount = () => {
    const { language, reward } = this.props;
    const rewardValidity = Utils.rewardsValidity(
      reward.offerValidFrom,
      reward.offerValidTill,
    );
    const {
      expiringToday, expiringTomorrow, dateRange, date,
    } = rewardValidity;
    const scContainer = document.getElementById('scratchcard');
    const sc = new ScratchCardJs('#scratchcard', {
      scratchType: SCRATCH_TYPE.LINE,
      containerWidth: scContainer.offsetWidth,
      containerHeight: scContainer.offsetHeight,
      imageForwardSrc: '/images/scratch-card-now.png',
      imageBackgroundSrc: '/images/scratch-card-now.png',
      htmlBackground: jsxToString(
        <div
          {...{
            class: 'd-flex flex-column py-2 h-100 bg-white text-dark border-radius-8 scratchcard-innerhtml',
          }}
        >
          <div
            {...{
              class: 'flex-grow-1 mb-2',
            }}
          >
            <div
              {...{
                class: 'd-flex justify-content-center',
              }}
            >
              <img
                {...{
                  src: reward.isBadLuckScratchCard
                    ? '/images/sad.png'
                    : '/images/reward.png',
                  alt: '',
                }}
              />
            </div>
          </div>
          <div
            {...{
              class: 'text-center flex-grow-0',
            }}
          >
            <div
              {...{
                class: 'fs-3 font-weight-bold mb-1',
              }}
            >
              {reward.isBadLuckScratchCard
                ? reward.offerShortDescriptionHeader
                : reward.offerShortDescription}
            </div>
            <div
              {...{
                class: 'fs-4',
              }}
            >
              {reward.isBadLuckScratchCard
                ? (
                  <div
                    {...{
                      class: 'mb-6',
                    }}
                  >
                    {reward.offerShortDescription}
                  </div>
                )
                : (
                  date || dateRange || (
                    <div
                      {...{
                        class: 'font-weight-bold text-danger',
                      }}
                    >
                      {(
                  (expiringToday
                  && Constants.String.EXPIRES_TODAY[language])
                || (expiringTomorrow
                  && Constants.String.EXPIRES_TOMORROW[language])
                )}
                    </div>
                  )
                )}
            </div>
            {!reward.isBadLuckScratchCard && (
              <div
                {...{
                  class: 'text-secondary fs-5 mt-2 font-weight-normal',
                }}
              >
                {`${Constants.String.MIN_ORDER_VALUE[language]}: ₹${reward.minimumSpendAmount}`}
              </div>
            )}
          </div>
        </div>,
      ),
      clearZoneRadius: 70,
      callback: () => {
        this.markScratchCardScratched();
      },
    });
    sc.init().then();
  }

  markScratchCardScratched = () => {
    const { reward, unlockRewardRequest } = this.props;
    unlockRewardRequest(reward.id);
  }

  render() {
    const {
      language, isMobile, reward, toggleScratchCard, processing, history,
    } = this.props;
    const { showReferAndEarn } = this.state;
    const parts = new Date(reward.scratchedAllowFrom).toDateString().split(' ');
    const MainComponent = (
      <Container>
        <Row
          className={`flex-column ${isMobile ? 'text-white' : 'bg-white'}`}
        >
          {reward.createdOnOrder && isMobile
          && (
          <Col
            className="fs-3 font-weight-bold text-center"
          >
            <div>
              {Constants.String.CONGRATS[language]}
            </div>
            <div>
              {Constants.String.YOU_HAVE_WON_SCRATCH_CARD[language]}
            </div>
          </Col>
          )}
          <Col
            className="py-6 d-flex justify-content-center"
          >
            <div
              className="scratchcard-wrapper"
            >
              <div
                id="scratchcard"
                className={`scratchcard-container ${reward.isValid ? '' : 'd-none'}`}
              />
              {
                !reward.isValid && (
                <Row
                  className="mx-0 scratch-card align-items-end h-100"
                >
                  <Col
                    className="bg-white py-2 fs-4 font-weight-bold
                      text-center mb-6 text-black"
                  >
                    {`${Constants.String.AVAILABLE_ON[language]} ${
                      parts[2]} ${parts[1]}`}
                  </Col>
                </Row>
                )
            }
            </div>
          </Col>
          <Col
            className={`text-center px-8 ${isMobile ? '' : 'fs-3'}`}
          >
            {reward.isScratched
              ? (
                <>
                  <div
                    className="font-weight-bold mb-2"
                  >
                    {reward.offerLongDescriptionHeader}
                  </div>
                  <div
                    className="mb-4"
                  >
                    {reward.offerLongDescription}
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      className="py-2"
                      block
                      onClick={() => {
                        if (!processing) {
                          if (isMobile) {
                            toggleScratchCard();
                            history.push('/refer-and-earn');
                          } else {
                            this.setState({
                              showReferAndEarn: true,
                            });
                          }
                        }
                      }}
                    >
                      {Constants.String.REFER_AND_EARN_MORE[language].toUpperCase()}
                    </Button>
                    {/* <Button
                        variant="green"
                        block
                        className="py-2 d-flex align-items-center justify-content-center"
                        href={`whatsapp://send?text=${Constants.String.SHARE_REWARD[language]}`}
                        data-event-category="Social"
                        data-event-action="Share:Whatsapp"
                      >
                        <span
                          className="d-flex align-items-center mr-1"
                        >
                          <Svg
                            svg="whatsapp"
                            fill={Constants.Color.white}
                            width="24"
                          />
                        </span>
                        {Constants.String.TELL_YOUR_FRIENDS[language].toUpperCase()}
                      </Button> */}
                  </div>
                </>
              )
              : (
                <>
                  <div
                    className="font-weight-bold mb-2"
                  >
                    {Constants.String.EARN_DISCOUNT_UPTO_500[language]}
                  </div>
                  <div>
                    {
                      reward.isValid
                        ? Constants.String.REWARD_UNLOCKING_DESC[language]
                        : Constants.String.UNLOCK_REWARD_ON_PARTICULAR_DATE_DESC[language]
                          .replace(/{date}/g, `${parts[2]} ${parts[1]}`)
                    }
                  </div>
                </>
              )}
            {!isMobile && (
              <>
                <div className="mb-8" />
                <div className="mb-8" />
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
    if (isMobile) {
      return (
        <Row
          className="scratch-card-mobile-view align-items-center py-6"
        >
          <Button
            variant="link"
            className="close-button p-2"
            onClick={() => {
              if (!processing) {
                toggleScratchCard();
              }
            }}
          >
            <Svg
              svg="close"
              width="1.5rem"
              fill={Constants.Color.white}
            />
          </Button>
          <Col
            xs={24}
          >
            {MainComponent}
          </Col>
        </Row>
      );
    }
    return (
      <>
        <CustomModal
          show
          closeButton
          backdrop
          shadow
          title={isMobile ? '' : Constants.String.SCRATCH_CARD[language]}
          body={MainComponent}
          onHide={() => {
            if (!processing) {
              toggleScratchCard();
            }
          }}
        />
        <CustomModal
          show={showReferAndEarn}
          title={Constants.String.REFER_AND_EARN[language]}
          closeButton
          onHide={() => {
            toggleScratchCard();
            this.setState({
              showReferAndEarn: false,
            });
          }}
          body={(
            <ReferFriendInfo
              {...this.props}
            />
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reward: state.main.scratchCard,
  processing: state.main.requestsProcessing[UNLOCK_REWARD_REQUEST],
});

const mapDispatchToProps = (dispatch) => ({
  toggleScratchCard: () => (
    dispatch(Actions.toggleScratchCard())
  ),
  unlockRewardRequest: (scratchCardId) => (
    dispatch(
      Actions.unlockRewardRequest(scratchCardId),
    )
  ),
});

ScratchCard.propTypes = {
  language: PropTypes.string.isRequired,
  reward: PropTypes.shape({
    id: PropTypes.number,
    isScratched: PropTypes.bool,
    isBadLuckScratchCard: PropTypes.bool,
    offerValidTill: PropTypes.string,
    offerValidFrom: PropTypes.string,
    minimumSpendAmount: PropTypes.number,
    scratchedAllowFrom: PropTypes.string,
    isValid: PropTypes.bool,
    createdOnOrder: PropTypes.bool,
    offerShortDescriptionHeader: PropTypes.string,
    offerShortDescription: PropTypes.string,
    offerLongDescriptionHeader: PropTypes.string,
    offerLongDescription: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.func.isRequired,
  toggleScratchCard: PropTypes.func.isRequired,
  unlockRewardRequest: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScratchCard);
