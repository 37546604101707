import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Placeholder, Svg } from 'app/components/common';
import ProductThumb from 'app/components/common/ProductThumb';
import { SHOP_FRONT } from 'app/events/screens';
import { Constants } from 'app/utilities';

function StoreSearchResults({
  isMobile, searchResults,
  changeStoreState, language, searchText,
  history, storeName, storeId,
}) {
  const cartItems = useSelector((state) => state.main.cartItems);
  const { subCategory = [] } = searchResults || {};
  const productCount = subCategory
    ? subCategory.reduce((acc, i) => acc + i.productsWithMeasurements.length, 0)
    : 0;

  const handleBack = () => {
    changeStoreState('ALL');
  };

  return (
    <div className="">
      {
        (() => {
          if (productCount === 0) {
            return (
              <Placeholder
                language={language}
                imageSrc="/images/no-results.png"
                heading={Constants.String.NO_RESULTS_FOUND[language]}
              />
            );
          }
          return (
            productCount > 0 ? (
              <>
                <div
                  className="d-flex justify-content-space-between pb-5"
                >
                  <div className="d-flex fs-3 font-weight-black">
                    <Button
                      variant="link"
                      onClick={handleBack}
                      className="p-0 d-flex align-items-center"
                    >
                      <Svg
                        svg="leftArrow"
                        fill={Constants.Color.black}
                        width="1rem"
                      />
                    </Button>
                    &nbsp;&nbsp;
                    {`Showing ${productCount} results for “${searchText}”`}
                  </div>
                  <div className="">
                    {`${productCount} Items`}
                  </div>
                </div>
                <div>
                  {subCategory.map(({ Category, productsWithMeasurements }) => (
                    <div className="mb-5" key={Category.id}>
                      <div className="fs-3 font-weight-black mb-2">
                        {Category.name}
                      </div>
                      <div className="r__flex r__flex-20 pb-2 flex-wrap">
                        {
                            productsWithMeasurements.map((product) => (
                              <ProductThumb
                                history={history}
                                key={product[0].libraryProductId}
                                libraryProductId={product[0].libraryProductId}
                                retailProductId={product[0].retailerProductId}
                                name={product[0].name}
                                image={product[0].image}
                                mrp={product[0].mrp}
                                sellingPrice={product[0].sellingPrice}
                                measurementDisplayText={product[0].measurementDisplayText}
                                storeName={storeName}
                                storeId={storeId}
                                offerPercent={product[0].offerPercent}
                                cartItems={cartItems}
                                horizontalView={isMobile}
                                variants={product.map((i) => ({
                                  libraryProductId: i.libraryProductId,
                                  retailProductId: i.retailerProductId,
                                  name: i.name,
                                  image: i.image,
                                  mrp: i.mrp,
                                  sellingPrice: i.sellingPrice,
                                  measurementDisplayText: i.measurementDisplayText,
                                  offerPercent: i.offerPercent,
                                }))}
                                page={SHOP_FRONT}
                                section="Search"
                              />
                            ))
                          }
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null
          );
        })()
      }
    </div>
  );
}

StoreSearchResults.propTypes = {
  searchText: PropTypes.string.isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  storeId: PropTypes.number.isRequired,
  searchResults: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    productCount: PropTypes.number,
  }).isRequired,
};

export default StoreSearchResults;
