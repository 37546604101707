import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  cartProductActions, CART_PRODUCT_REQUEST,
} from 'app/store/cart/actions';
import { isGuestUser } from 'app/utilities/Utils';
import { toggleLogin } from '../../../store/Actions';
import { Constants } from '../../../utilities';
import Toast from '../../../HOC/Toast';
import { llAddedToCart, llRemovedFromCart } from '../../../events/Events';

const RoundedCartCounter = (props) => {
  const {
    cartProductReq,
    storeCode, productId, quantity,
    processing, isCart, isCheckout, onSuccess,
    showToast, customisable, toggleAddVariants,
    isOfferSellingStores, isAppExOffer, productInfo,
    isPrimaryBackground, language, isCustomProduct,
    addButtonStyle, page, section,
  } = props;

  if (
    !isCheckout
    && (processing[productId]) === (storeCode)
    && !customisable
    && !isGuestUser()
  ) {
    return (
      <div
        className="py-3"
      >
        <div
          className="cart-counter-min overflow-hidden bg-primary-light"
        >
          <div
            className="process-add-to-cart"
          />
        </div>
      </div>
    );
  }

  const processingAddToCart = (processing[productId]
  || (isCart && Object.keys(processing).length > 0)
  ) && !customisable && !isGuestUser();

  const successCallback = (count, isAdding) => ({
    type: 'sync',
    callback: () => { onSuccess(count, isAdding); },
  });

  const onFailure = {
    type: 'sync',
    callback: (data) => {
      if (data.error) {
        showToast(data.error);
      }
    },
  };

  const eventPayload = {
    Page: page || 'Cart',
    'Product name': productInfo ? productInfo.productName : 'NA',
    'Product Id': productId,
    Section: section || 'NA',
    'Store Id': storeCode,
    'Store Name': productInfo ? productInfo.storeName : 'NA',
    'Retail Price': productInfo ? productInfo.sellingPrice : 'NA',
    Price: productInfo ? productInfo.mrp : 'NA',
    Currency: 'INR',
    Discount: productInfo ? productInfo.mrp - productInfo.sellingPrice : 'NA',
  };

  return (
    quantity === 0 ? (
      <Button
        variant={isPrimaryBackground ? 'primary' : 'outline-primary'}
        className="fs-6 py-1 cart-counter-min font-weight-black border-radius-8"
        onClick={() => {
          if (customisable) {
            toggleAddVariants();
            return;
          }
          llAddedToCart(
            {
              ...eventPayload,
              quantity: 1,
            },
          );
          cartProductReq(
            storeCode,
            productId,
            quantity + 1,
            successCallback(1, true),
            onFailure,
            productInfo,
            false,
            'ADD',
          );
        }}
        disabled={processingAddToCart}
        block
        style={addButtonStyle}
      >
        {Constants.String.ADD[language].toUpperCase()}
      </Button>
    ) : ((
      isOfferSellingStores && isAppExOffer && (
        <Button
          variant="outline-secondary"
          className="fs-6 py-1 cart-counter-min border-radius-8"
          onClick={() => {
            llRemovedFromCart(
              {
                ...eventPayload,
                Quantity: quantity - 1,
              },
            );
            cartProductReq(
              storeCode,
              productId,
              quantity - 1,
              successCallback(quantity - 1, false),
              onFailure,
              productInfo,
              false,
              'DELETE',
              isCustomProduct,
            );
          }}
          disabled={processingAddToCart}
          block
        >
          {Constants.String.REMOVE[language].toUpperCase()}
        </Button>
      )) || (
      <Row
        className={`border border-radius-8 ${processingAddToCart
          ? '' : 'border-primary'} mx-0 fs-6 ${isCheckout
          ? 'min-w-70p' : 'cart-counter-min'}`}
        style={addButtonStyle}
      >
        <Col
          xs="auto"
          className={isCheckout ? 'd-none' : 'px-0'}
        >
          <Button
            variant="link"
            className={`px-0 py-0 h-100 fs-5 text-${processingAddToCart
              ? 'medium' : 'primary'} text-decoration-none w-25p`}
            onClick={() => {
              if (customisable) {
                toggleAddVariants();
                return;
              }
              llRemovedFromCart(
                {
                  ...eventPayload,
                  Quantity: quantity - 1,
                },
              );
              cartProductReq(
                storeCode,
                productId,
                quantity - 1,
                successCallback(quantity - 1, false),
                onFailure,
                productInfo,
                false,
                'UPDATE',
                isCustomProduct,
              );
            }}
            disabled={processingAddToCart}
          >
            -
          </Button>
        </Col>
        <Col
          className={`px-0 text-center text-${
            processingAddToCart ? 'medium' : 'primary'} font-weight-black py-1`}
        >
          {quantity}
        </Col>
        <Col
          xs="auto"
          className={isCheckout ? 'd-none' : 'px-0'}
        >
          <Button
            variant="link"
            className={`px-0 py-0 h-100 fs-4 text-${processingAddToCart
              ? 'medium' : 'primary'} text-decoration-none w-25p`}
            onClick={() => {
              if (customisable) {
                toggleAddVariants();
                return;
              }
              llAddedToCart(
                {
                  ...eventPayload,
                  Quantity: quantity + 1,
                },
              );
              cartProductReq(
                storeCode,
                productId,
                quantity + 1,
                successCallback(quantity + 1, true),
                onFailure,
                productInfo,
                false,
                'UPDATE',
                isCustomProduct,
              );
            }}
            disabled={processingAddToCart}
          >
            +
          </Button>
        </Col>
      </Row>
    ))
  );
};

const mapStateToProps = (state) => ({
  processing: (
    state.main.requestsProcessing[CART_PRODUCT_REQUEST]
  ),
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: () => {
    dispatch(toggleLogin());
  },

  cartProductReq: (
    storeCode, productId, quantity, onSuccess, onFailure,
    productInfo, forceAdd, actionType, isCustomProduct = false,
  ) => {
    dispatch(
      cartProductActions.request(
        storeCode, productId, quantity, onSuccess, onFailure,
        productInfo, forceAdd, actionType, isCustomProduct,
      ),
    );
  },
});

RoundedCartCounter.propTypes = {
  cartProductReq: PropTypes.func.isRequired,
  storeCode: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  processing: PropTypes.shape({}).isRequired,
  isCart: PropTypes.bool,
  isCheckout: PropTypes.bool,
  onSuccess: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  toggleAddVariants: PropTypes.func,
  customisable: PropTypes.bool,
  productInfo: PropTypes.shape({
    mrp: PropTypes.number,
    productName: PropTypes.string,
    sellingPrice: PropTypes.number,
    imageUrl: PropTypes.string,
    storeName: PropTypes.string,
  }).isRequired,
  isOfferSellingStores: PropTypes.bool,
  isAppExOffer: PropTypes.bool,
  isPrimaryBackground: PropTypes.bool,
  language: PropTypes.string,
  isCustomProduct: PropTypes.bool,
  addButtonStyle: PropTypes.shape({}),
  page: PropTypes.string,
  section: PropTypes.string,
};

RoundedCartCounter.defaultProps = {
  isCart: false,
  isCheckout: false,
  onSuccess: () => {},
  customisable: false,
  toggleAddVariants: () => {},
  isOfferSellingStores: false,
  isAppExOffer: false,
  isPrimaryBackground: false,
  language: 'en',
  isCustomProduct: false,
  addButtonStyle: {},
  page: 'Cart',
  section: '',
};

export default Toast(connect(mapStateToProps, mapDispatchToProps)(RoundedCartCounter));
