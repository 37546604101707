import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ReverseTimer = ({ targetDate }) => {
  function calculateTimeLeft() {
    const now = new Date();
    const targetTime = new Date(targetDate);
    const difference = targetTime - now;

    if (difference <= 0) {
      return {
        days: 0, hours: 0, minutes: 0, seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60));
    const hours = days + Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return {
      hours, minutes,
    };
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <span className="px-1">
        {' '}
        {timeLeft.hours}
        h
        {' '}
        :
        {' '}
        {timeLeft.minutes}
        m
      </span>
    </>
  );
};

ReverseTimer.propTypes = {
  targetDate: PropTypes.string.isRequired,
};
export default ReverseTimer;
