const String = {
  ABOUT_US: {
    en: 'About Us',
    gu: 'અમારા વિશે',
    hi: 'हमारे बारे में',
    kn: 'ನಮ್ಮ ಬಗ್ಗೆ',
    mr: 'आमच्या विषयी',
    te: 'మా గురించి',
  },
  ACCEPT: {
    en: 'Accept',
    gu: 'સ્વીકારો',
    hi: 'स्वीकार करना',
    kn: 'ಒಪ್ಪಿಕೊಳ್ಳಿ',
    mr: 'स्वीकारा',
    te: 'అంగీకరించు',
  },
  ACCEPT_CHANGES: {
    en: 'Accept Changes',
    gu: 'ફેરફારો સ્વીકારો',
    hi: 'परिवर्तन स्वीकार करें',
    kn: 'ಬದಲಾವಣೆಗಳನ್ನು ಸ್ವೀಕರಿಸಿ',
    mr: 'बदल स्वीकारा.',
    te: 'మార్పులను ఆమోదించండి',
  },
  ACCEPT_CHANGES_TO_PROCEED: {
    en: 'Please accept changes to proceed',
    gu: 'કૃપા કરીને આગળ વધવા માટે ફેરફારો સ્વીકારો',
    hi: 'कृपया आगे बढ़ने के लिए परिवर्तनों को स्वीकार करें',
    kn: 'ಮುಂದುವರಿಯಲು ದಯವಿಟ್ಟು ಬದಲಾವಣೆಗಳನ್ನು ಸ್ವೀಕರಿಸಿ',
    mr: 'पुढे जाण्यासाठी कृपया बदल स्वीकारा',
    te: 'కొనసాగుటకు దయచేసి మార్పులను ఆమోదించండి',
  },
  ACCOUNT: {
    en: 'Account',
    gu: 'એકાઉન્ટ',
    hi: 'अकाउंट',
    kn: 'ಖಾತೆ',
    mr: 'खाते',
    te: 'ఖాతా',
  },
  ADD: {
    en: 'ADD',
    gu: 'એડ કરો',
    hi: 'एड करें',
    kn: 'ಸೇರಿಸಿ',
    mr: 'जोडा',
    te: 'చేర్చండి',
  },
  ADD_ALL: {
    en: 'ADD ALL',
    gu: 'બધા એડ કરો',
    hi: 'सब एड करें',
    kn: 'ಎಲ್ಲವನ್ನು ಸೇರಿಸಿ',
    mr: 'सर्व जोडा',
    te: 'అన్నింటినీ చేర్చండి',
  },
  ADD_COMMENT: {
    en: 'Add a comment...',
    gu: 'ટિપ્પણી એડ કરો ...',
    hi: 'टिप्पणी एड करें...',
    kn: 'ಅಭಿಪ್ರಾಯ ಸೇರಿಸಿ',
    mr: 'एक टिप्पणी जोडा',
    te: 'వ్యాఖ్యను జతచేయండి',
  },
  ADD_LOCATION: {
    en: 'Add Location',
    gu: 'લોકેશન એડ કરો',
    hi: 'लोकेशन एड करें',
    kn: 'ಸ್ಥಳ ಸೇರಿಸಿ',
    mr: 'ठिकाण जोडा',
    te: 'లొకేషన్ ను చేర్చండి',
  },
  ADD_NEW_ADDRESS: {
    en: 'Add New Address',
    gu: 'નવું સરનામું ઉમેરો',
    hi: 'नया पता जोड़ें',
    kn: 'ಹೊಸ ವಿಳಾಸ ಸೇರಿಸಿ',
    mr: 'नवीन पत्ता जोडा',
    te: 'కొత్త అడ్రస్ ను జత చేయండి',
  },
  ADD_MORE_PRODUCTS: {
    en: 'ADD ITEMS',
    gu: 'સામાન ઉમેરો',
    hi: 'सामान डाले',
    kn: 'ವಸ್ತುಗಳನ್ನು ಸೇರಿಸಿ',
    mr: 'वस्तू जोडा',
    te: 'వివిధ రకాలను చేర్చండి',
  },
  ADD_TO_CART: {
    en: 'Add to Cart',
    gu: 'કાર્ટમાં નાખો',
    hi: 'कार्ट में डालें',
    kn: 'ಕಾರ್ಟ್ ಗೆ ಸೇರಿಸಿ',
    mr: 'कार्टमध्ये जोडा',
    te: 'బండిలో చేర్చండి',
  },
  ADDED: {
    en: 'Added',
    gu: 'ઉમેર્યું',
    hi: 'डाला',
    kn: 'ಸೇರಿಸಲಾಗಿದೆ',
    mr: 'जोडले आहे',
    te: 'చేర్చబడినది',
  },
  ADDRESS_LABEL_IS_REQUIRED: {
    en: 'Address Label is required',
    gu: 'એડ્રેસનું લેબલ જરૂરી છે',
    hi: 'एड्रेस का लेबल आवश्यक है',
    kn: 'ವಿಳಾಸ ಲೇಬಲ್ ಅಗತ್ಯವಿದೆ',
    mr: 'पत्ता आवश्यक आहे',
    te: 'అడ్రసు వివరాలు కావలెను',
  },
  ALERT_FOR_LOCATION_CHANGE: {
    en: 'By changing location, you will lose the products that are in your cart currently',
    gu: 'લોકેશન બદલીને, તમે હાલમાં તમારા કાર્ટમાં રહેલા ઉત્પાદનો ખોઈ દેશો',
    hi: 'लोकेशन बदलने से, आप उन उत्पादों को खो देंगे जो अभी आपके कार्ट में हैं',
    kn: 'ಸ್ಥಳ ಬದಲಾಯಿಸುವ ಮೂಲಕ ನೀವು ಪ್ರಸ್ತುತ ಕಾರ್ಟ್ ನಲ್ಲಿರುವ ಉತ್ಪನ್ನಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತಿರ',
    mr: 'ठिकाण बदलल्यास, सध्या तुमच्या कार्टमध्ये जतन केलेल्या वस्तू काढल्या जातील',
    te: 'లొకేషన్ మార్చడం వలన మీ బండిలో ప్రస్తుతం ఉన్న వస్తువులను కోల్పోతారు',
  },
  ALERT_FOR_LOCATION_DELETE: {
    en: 'You want to delete location',
    gu: 'તમે લોકેશન ડીલીટ કરવા માંગો છો',
    hi: 'आप लोकेशन डिलीट करना चाहते हैं',
    kn: 'ನೀವು ಸ್ಥಳವನ್ನು ಅಳಿಸಬೇಕೆ',
    mr: 'तुम्ही हे ठिकाण काढू इच्छिता?',
    te: 'మీరు లొకేషన్ తొలిగించదలిచారా',
  },
  ALREADY_HAVE_AN_ACCOUNT: {
    en: 'Already have an account.',
    gu: 'એકાઉન્ટ પહેલેથી જ છે.',
    hi: 'पहले से ही एक खाता है।',
    kn: 'ಈಗಾಗಲೇ ಖಾತೆ ಇದೆ',
    mr: 'आधीपासूनच एक खाते आहे.',
    te: 'ఇప్పటికే అకౌంట్ ఉంది.',
  },
  ALL_CITIES: {
    en: 'All Cities',
    gu: 'તમામ શહેરો',
    hi: 'सभी शहर',
    kn: 'ಎಲ್ಲಾ ನಗರಗಳು',
    mr: 'सर्व शहरे',
    te: 'అన్ని నగరాలు',
  },
  ALL_LOCALITIES: {
    en: 'All Localities',
    gu: 'તમામ સ્થળો',
    hi: 'सभी इलाके',
    kn: 'ಎಲ್ಲಾ ಸ್ಥಳಗಳು',
    mr: 'सगळे परिसर',
    te: 'అన్ని ప్రాంతాలు',
  },
  ALL_LOVELOCAL_SHOPS_NEAR_YOU: {
    en: 'All LoveLocal Shops Near You',
    gu: 'તમારી નજીકની તમામ લવલોકલ શોપ્સ',
    hi: 'आप के पास के सभी LoveLocal दुकानें',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರವಿರುವ ಎಲ್ಲಾ LoveLocal ಅಂಗಡಿಗಳು',
    mr: 'आपल्या जवळची सर्व LoveLocal दुकाने',
    te: 'మీ దగ్గరలో ఉన్న అన్ని LoveLocal షాపులు',
  },
  ALL_REVIEWS: {
    en: 'All Reviews',
    gu: 'બધા રીવ્યુસ',
    hi: 'सारे रिव्युस',
    kn: 'ಎಲ್ಲಾ ರಿವ್ಯಗಳು',
    mr: 'सर्व प्रतिक्रिया',
    te: 'అన్ని సమీక్షలు',
  },
  ALMOST_THERE: {
    en: 'Almost There',
    gu: 'લગભગ ત્યાં',
    hi: 'लगभग वहाँ',
    kn: 'ಬಹುತೇಕ ಇದೆ',
    mr: 'जवळच आहात',
    te: 'దరిదాపులలో ఉన్నది',
  },
  APPLIED: {
    en: 'APPLIED',
    gu: 'લાગુ કર્યું',
    hi: 'लागू हुआ',
    kn: 'ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'लागू केले आहे',
    te: 'వర్తించబడినది',
  },
  APPLIED_SUCCESSFULLY: {
    en: 'Applied Successfully',
    gu: 'સફળતાપૂર્વક લાગુ કર્યું',
    hi: 'सफलतापूर्वक लागू किया',
    kn: 'ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'यशस्वीरित्या लागू केले',
    te: 'విజయవంతంగా వర్తింపబడినది',
  },
  APPLY: {
    en: 'APPLY',
    gu: 'લાગુ કરો',
    hi: 'लागु करें',
    kn: 'ಅನ್ವಯಿಸಿ',
    mr: 'लागू करा',
    te: 'వర్తించు',
  },
  APPLY_COUPON: {
    en: 'Apply Coupon',
    gu: 'કૂપન લાગુ કરો',
    hi: 'कूपन लागू करें',
    kn: 'ಕೂಪನ್ ಅನ್ವಯಿಸಿ',
    mr: 'कूपन वापरा',
    te: 'కూపన్ ను వర్తింపచేయండి',
  },
  APPLY_COUPON_CODE: {
    en: 'Apply Coupon Code',
    gu: 'કૂપન કોડ લાગુ કરો',
    hi: 'कूपन कोड लागू करें',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ಅನ್ವಯಿಸಿ',
    mr: 'कूपन कोड वापरा',
    te: 'కూపన్ కోడ్ ను వర్తింపచేయండి',
  },
  APPLY_OFFER: {
    en: 'Apply Offer',
    gu: 'ઓફર લાગુ કરો',
    hi: 'ऑफर लागु करें',
    kn: 'ಆಫರ್ ಅನ್ವಯಿಸಿ',
    mr: 'ऑफर वापरा',
    te: 'ఆఫర్ ను వర్తింపచేయండి',
  },
  APPLY_OFFERS: {
    en: 'Apply Offers',
    gu: 'ઓફર્સ લાગુ કરો',
    hi: 'ऑफर्स लागु करें',
    kn: 'ಆಫರ್ ಗಳನ್ನು ಅನ್ವಯಿಸಿ',
    mr: 'ऑफर वापरा',
    te: 'ఆఫర్ లను వర్తింపచేయండి',
  },
  APPLY_OFFERS_COACHMARK: {
    en: 'Apply a Scratch Card to get a good discount on this order',
    gu: 'આ ઓર્ડર પર સારી છૂટ મેળવવા માટે સ્ક્રેચ કાર્ડ લાગુ કરો',
    hi: 'इस ऑर्डर पर अच्छी छूट पाने के लिए स्क्रैच कार्ड लागू करें',
    kn: 'ಈ ಆದೇಶದಲ್ಲಿ ಉತ್ತಮ ರಿಯಾಯಿತಿ ಪಡೆಯಲು ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಅನ್ನು ಅನ್ವಯಿಸಿ',
    mr: 'स्क्रॅच कार्ड वापरा आणि या ऑर्डरवर आकर्षक सूट मिळवा',
    te: 'ఈ ఆర్డర్ పై మంచి డిస్కౌంట్ పొందడానికి స్క్రాచ్ కార్డ్ ను  వర్తింపచేయండి',
  },
  APPLY_SPEND_OFFER_ALERT: {
    en: 'You can apply only an offer or coupon in an order. Do you wish to continue?',
    gu: 'તમે એક ઓર્ડરમાં ફક્ત એક ઓફર અથવા કૂપન લાગુ કરી શકો છો. શું તમે ચાલુ રાખવા માંગો છો?',
    hi: 'आप एक आर्डर में केवल एक ऑफर या कूपन लागू कर सकते हैं। क्या आप जारी रखना चाहते हैं?',
    kn: 'ನೀವು ಆರ್ಡರ್ ನಲ್ಲಿ ಆಫರ್ ಅಥವಾ ಕೂಪನ್ ಅನ್ನು ಮಾತ್ರ ಅನ್ವಯಿಸಬಹುದು.  ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುವಿರಾ?',
    mr: 'तुम्ही एका ऑर्डर साठी एकच कूपन किंवा ऑफर वापरू शकता. तुम्ही सुरू ठेऊ इच्छिता?',
    te: 'మీరు ఆఫర్ కానీ కూపన్ కానీ వక్కటే ఆర్డర్ కి వర్తింపచేయగలరు. మీరు కొనసాగించదలుచుకున్నారా ?',
  },
  APPLY_THESE_OFFERS_DURING_CHECKOUT: {
    en: 'Apply these offers during checkout',
    gu: 'ચેકઆઉટ દરમિયાન આ offersફર્સ લાગુ કરો',
    hi: 'चेकआउट के दौरान ये ऑफर लागू करें',
    kn: 'ಚೆಕ್ out ಟ್ ಸಮಯದಲ್ಲಿ ಈ ಕೊಡುಗೆಗಳನ್ನು ಅನ್ವಯಿಸಿ',
    mr: 'चेकआउट दरम्यान या ऑफर लागू करा',
    te: 'చెక్అవుట్ సమయంలో ఈ ఆఫర్లను వర్తించండి',
  },
  APPLY_VALUE_OFFER: {
    en: 'Apply Value Offer',
    gu: 'વેલ્યુ ઓફર લાગુ કરો',
    hi: 'वेल्यू ऑफर लागू करें',
    kn: 'ಮೌಲ್ಯ ಕೊಡುಗೆಯನ್ನು ಅನ್ವಯಿಸಿ',
    mr: 'मूल्य ऑफर वापरा',
    te: 'వాల్యూ ఆఫర్ ను వర్తింపచేయండి',
  },
  ARE_YOU_SURE: {
    en: 'Are You Sure?',
    gu: 'શું તમને ખાતરી છે?',
    hi: 'क्या आपको यकीन है?',
    kn: 'ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?',
    mr: 'तुम्हाला खात्री आहे?',
    te: 'మీకు సమ్మతమేనా ?',
  },
  AREA_NAME: {
    en: 'Area / Locality',
    gu: 'Area / Locality',
    hi: 'Area / Locality',
    kn: 'Area / Locality',
    mr: 'Area / Locality',
    te: 'Area / Locality',
  },
  ATTENTION: {
    en: 'Attention!',
    gu: 'સાવધાન!',
    hi: 'ध्यान दें!',
    kn: 'ಗಮನ!',
    mr: 'लक्ष द्या',
    te: 'గమనిక !',
  },
  AVAILABLE_ON: {
    en: 'Available on',
    gu: 'પર ઉપલબ્ધ',
    hi: 'पर उपलब्ध',
    kn: 'ಲಭ್ಯವಿದೆ',
    mr: 'इथे उपलब्ध आहे',
    te: 'అందుబాటులో ఉన్నది',
  },
  AVAILABLE_NOW: {
    en: 'Now Available',
    gu: 'હવે ઉપલબ્ધ છે',
    hi: 'अब उपलब्ध है',
    kn: 'ಈಗ ಲಭ್ಯ',
    mr: 'आता उपलब्ध',
    te: 'ఇప్పుడు అందుబాటులో ఉన్నాయి',
  },
  AVERAGE: {
    en: 'Average',
    gu: 'સરેરાશ',
    hi: 'औसत',
    kn: 'ಸರಾಸರಿ',
    mr: 'सरासरी',
    te: 'సగటు',
  },
  AVERAGE_FROM_RATINGS: {
    en: 'Average from {count} ratings',
    gu: '{count} રેટિંગ્સની સરેરાશ',
    hi: '{count} रेटिंग की एवरेज',
    kn: '{count} ಗಳಿಂದ ಸರಾಸರಿ',
    mr: '{count} रेटिंगवरील सरासरी',
    te: '{count}  రేటింగుల సరాసరి',
  },
  BAD_SERVICE_OF_SHOP: {
    en: 'Bad Service of Shop',
    gu: 'દુકાનની ખરાબ સેવા',
    hi: 'दुकान की खराब सेवा',
    kn: 'ಅಂಗಡಿಯ ಕೆಟ್ಟ ಸೇವೆ',
    mr: 'दुकानाची खराब सेवा',
    te: 'షాపు సేవలు బాలేదు',
  },
  BADLUCK_SCRATCH_CARD_DESC: {
    en: 'Regularly shop from LoveLocal to get more scratch cards',
    gu: 'વધુ સ્ક્રેચ કાર્ડ્સ મેળવવા માટે લવલોકલથી નિયમિત ખરીદી કરો',
    hi: 'अधिक स्क्रैच कार्ड्स प्राप्त करने के लिए लवलोकल से नियमित खरीदारी करें',
    kn: 'ಹೆಚ್ಚಿನ ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್‌ಗಳನ್ನು ಪಡೆಯಲು ಲೋ LoveLocalನಿಂದ ನಿಯಮಿತವಾಗಿ ಶಾಪಿಂಗ್ ಮಾಡಿ',
    mr: 'अधिक स्क्रॅच कार्ड मिळविण्यासाठी LoveLocal कडून नियमित खरेदी करा',
    te: 'స్క్రాచ్ కార్డ్ లు మరిన్ని పొందుటకు Lovelocal నుండి క్రమంతప్పకుండా కొనండి',
  },
  BANO_APNI_DIGITAL_DUKAN: {
    en: 'Banao Apni Digital Dukaan!',
    gu: 'બનાઓ તમારી ડિજિટલ દુકાન!',
    hi: 'बनाओ अपनी डिजिटल दुकान!',
    kn: 'ನಿಮ್ಮ ಸ್ವಂತ ಡಿಜಿಟಲ್ ಅಂಗಡಿಯನ್ನು ಮಾಡಿ!',
    mr: 'आपले ऑनलाइन डिजिटल दुकान बनवा!',
    te: 'మీ సొంత డిజిటల్ దుకాణాన్ని తెరవండి!',
  },
  BECOME_A_LOVELOCAL_SHOP: {
    en: 'Become a LoveLocal Shop',
    gu: 'લવલોકલની દુકાન બનો',
    hi: 'लवलोकल की दुकान बनें',
    kn: 'LoveLocal ಅಂಗಡಿಯಾಗಿ',
    mr: 'एक LoveLocal दुकान बना.',
    te: 'LoveLocal షాప్ అవ్వండి',
  },
  BEST_OFFERS: {
    en: 'Best Offers Near You',
    gu: 'તમારી નજીકની શ્રેષ્ઠ ઓફર્સ',
    hi: 'आपकी नजदीकी सर्वश्रेष्ठ ऑफर्स',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ ಉತ್ತಮ ಆಫರ್ ಗಳು',
    mr: 'तुमच्या जवळील सर्वोत्तम ऑफर्स',
    te: 'మీ  దగ్గరలో లభించు మంచి ఆఫర్లు',
  },
  BROWSE_PRODUCTS: {
    en: 'BROWSE PRODUCTS',
    gu: 'ઉત્પાદનોને બ્રાઉઝ કરો',
    hi: 'उत्पादों को ब्राउज करें',
    kn: 'ಉತ್ಪನ್ನಗಳನ್ನು ಹುಡುಕಿ',
    mr: 'उत्पादने ब्राउझ करा',
    te: 'ఉత్పత్తులను బ్రౌజ్ చేయండి',
  },
  BEST_OFFERS_SUBHEADING: {
    en: 'Discover the best offers in your area',
    gu: 'તમારા એરિયામાં શ્રેષ્ઠ ઓફર્સ શોધો',
    hi: 'अपने एरिआ में सबसे अच्छी ऑफ़र खोजे',
    kn: 'ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿನ ಉತ್ತಮ ಆಫರ್ ಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    mr: 'तुमच्या भागातील सर्वोत्तम ऑफर्स शोधा',
    te: 'మీ ప్రాంతంలో లభించు మంచి ఆఫర్ లను తెలుసుకోండి',
  },
  BEST_RATED_SHOPS: {
    en: 'Best Rated Shops',
    gu: 'શ્રેષ્ઠ રેટેડ શોપ્સ',
    hi: 'बेस्ट रेटेड दुकानें',
    kn: 'ಅತ್ಯುತ್ತಮ ದರದ ಅಂಗಡಿಗಳು',
    mr: 'बेस्ट रेटेड दुकाने',
    te: 'మంచి రేటింగ్ ఉన్న షాపులు',
  },
  BE_THE_FIRST_FIRST_TO_REVIEW: {
    en: 'Be the first to review and compliment this shop.',
    gu: 'સૌથી પહેલા આ દુકાનની સમીક્ષા અને સરાહના કરો.',
    hi: 'इस दुकान की समीक्षा और प्रशंसा करने वाले पहले व्यक्ति बनें।',
    kn: 'ಮೊದಲು ಅಭಿಪ್ರಾಯ ತಿಳಿಸಿ ಮತ್ತು ಈ ಅಂಗಡಿಯನ್ನು ಪ್ರಶಂಸಿಸಿ',
    mr: 'या दुकानाची समीक्षा आणि प्रशंसा करणारे पहिले व्हा.',
    te: 'ఈ దుకాణాన్నిముందుగా మీరే రివ్యూ చేయండి మరియు అభినందించండి.',
  },
  BEST_RATED_SHOPS_SUBHEADING: {
    en: 'Shops rated 4 stars and above',
    gu: '4 સ્ટાર અને તેથી વધુની દુકાનો',
    hi: 'दुकानें 4 स्टार और उससे ऊपर की',
    kn: 'ಅಂಗಡಿಗಳು 4 ಅಥವಾ ಅದಕ್ಕಿಂತ ಹೆಚ್ಚಿನ ನಕ್ಷತ್ರಗಳನ್ನು ರೇಟ್ ಮಾಡಿವೆ',
    mr: '4 किंवा त्यापेक्षा जास्त रेटिंग दिलेली दुकाने',
    te: '4 స్టార్లు ఆపైన రేటింగ్  ఉన్న షాపులు',
  },
  BETTER_LUCK_NEXT_TIME: {
    en: 'Better Luck Next Time !',
    gu: 'આવતી વખતે વધુ સારા નસીબ!',
    hi: 'अगली बार किस्मत तुम्हारा साथ देगी !',
    kn: 'ಮುಂದಿನ ಪ್ರಯತ್ನದಲ್ಲಿ ಒಳ್ಳೆಯದಾಗಲಿ !',
    mr: 'पुढच्या वेळेसाठी शुभेच्छा!',
    te: 'మరోసారి మీ అదృష్టం పరీక్షించుకోండి !',
  },
  BLOG: {
    en: 'Blog',
    gu: 'બ્લોગ',
    hi: 'ब्लॉग',
    kn: 'ಬ್ಲಾಗ್',
    mr: 'ब्लॉग',
    te: 'బ్లాగ్',
  },
  BUILDING_NAME: {
    en: 'Building / Society Name',
    gu: 'Building / Society Name',
    hi: 'Building / Society Name',
    kn: 'Building / Society Name',
    mr: 'Building / Society Name',
    te: 'Building / Society Name',
  },
  BUILDING_NAME_IS_REQUIRED: {
    en: 'Need building name to continue',
    gu: 'Need building name to continue',
    hi: 'Need building name to continue',
    kn: 'Need building name to continue',
    mr: 'Need building name to continue',
    te: 'Need building name to continue',
  },
  BUY_AT_RATE: {
    en: 'BUY @',
    gu: 'ખરીદો @',
    hi: 'खरीदें @',
    kn: 'ಖರೀದಿಸಿ @',
    mr: 'खरेदी करा @',
    te: 'కొనండి @',
  },
  BUY_NOW: {
    en: 'Buy Now',
    gu: 'હમણાં જ ખરીદો',
    hi: 'अभी खरीदें',
    kn: 'ಈಗಲೇ ಖರೀದಿಸಿ',
    mr: 'आत्ता खरेदी करा',
    te: 'ఇప్పుడే కొనండి',
  },
  BUY_THESE_PRODUCT_AT_A_DISCOUNT: {
    en: 'Buy these product bundles at a discount',
    gu: 'આ ઉત્પાદન બંડલ્સને ડિસ્કાઉન્ટ પર ખરીદો',
    hi: 'इन उत्पाद बंडलों को छूट पर खरीदें',
    kn: 'ಈ ಉತ್ಪನ್ನ ಕಟ್ಟುಗಳನ್ನು ರಿಯಾಯಿತಿಯಲ್ಲಿ ಖರೀದಿಸಿ',
    mr: 'सवलतीच्या दरात ही उत्पादने बंडल खरेदी करा',
    te: 'ఈ వస్తువులను గుత్తగా డిస్కౌంట్లో కొనండి',
  },
  BUY1_GET1_OFFER: {
    en: 'Buy 1 Get 1 Offer',
    gu: 'ખરીદો ૧ મેળવો ૧ ઓફર',
    hi: 'ख़रीदे १ पाइए १ ऑफ़र',
    kn: 'ಒಂದು ಕೊಂಡರೆ ಒಂದು ಉಚಿತ ಆಫರ್',
    mr: 'एका वर एक मोफत ऑफर',
    te: '1 కొంటె 1 ఉచితం ఆఫర్',
  },
  BUY1_GET1_OFFERS: {
    en: 'Buy 1 Get 1 Offers',
    gu: 'ખરીદો ૧ મેળવો ૧ ઓફર્સ',
    hi: 'ख़रीदे १ पाइए १ ऑफर्स',
    kn: 'ಒಂದು ಕೊಂಡರೆ ಒಂದು ಉಚಿತ ಆಫರ್ ಗಳು',
    mr: 'एका वर एक मोफत ऑफर्स',
    te: '1 కొంటె 1 ఉచితం ఆఫర్ లు',
  },
  CALL_SHOP: {
    en: 'Call Shop',
    gu: 'દુકાને કોલ કરો',
    hi: 'दुकान पे कॉल करें',
    kn: 'ಅಂಗಡಿಗೆ ಕರೆ ಮಾಡಿ',
    mr: 'दुकानात फोन करा',
    te: 'షాపుకు కాల్ చెయ్యండి',
  },
  CALL_US: {
    en: 'Call Us',
    gu: 'અમને કોલ કરો',
    hi: 'हमें कॉल करें',
    kn: 'ನಮಗೆ ಕರೆ ಮಾಡಿ',
    mr: 'आम्हाला फोन करा',
    te: 'మాకు కాల్ చెయ్యండి',
  },
  CALL_TO_ORDER: {
    en: 'Call to Order',
    gu: 'ઓર્ડર કરવા માટે કોલ કરો',
    hi: 'ऑर्डर करने के लिए फोन करें',
    kn: 'ಆರ್ಡರ್ ಮಾಡಲು ಕರೆ ಮಾಡಿ',
    mr: 'ऑर्डरसाठी कॉल करा',
    te: 'ఆర్డర్ చేయడానికి కాల్ చేయండి',
  },
  CANCEL: {
    en: 'Cancel',
    gu: 'રદ કરો',
    hi: 'रद्द करें',
    kn: 'ರದ್ದು ಪಡಿಸಿ',
    mr: 'रद्द करा',
    te: 'రద్ధు',
  },
  CART: {
    en: 'Cart',
    gu: 'કાર્ટ',
    hi: 'कार्ट',
    kn: 'ಕಾರ್ಟ್',
    mr: 'कार्ट',
    te: 'బండి',
  },
  CART_TOTAL: {
    en: 'Cart Total',
    gu: 'કાર્ટનો કુલ',
    hi: 'कार्ट कुल',
    kn: 'ಕಾರ್ಟ್ ಒಟ್ಟು',
    mr: 'कार्ट एकूण',
    te: 'బండిలో మోత్తం',
  },
  CAREERS: {
    en: 'Careers',
    gu: 'કારકિર્દી',
    hi: 'करियर',
    kn: 'ಕರಿಯರ್ಸ್',
    mr: 'करिअर',
    te: 'ఉద్యోగాలు',
  },
  CATEGORIES: {
    en: 'Categories',
    gu: 'કેટેગરીઝ',
    hi: 'केटेगरिस',
    kn: 'ವರ್ಗಗಳು',
    mr: 'श्रेणी',
    te: 'విభాగాలు',
  },
  CATEGORY: {
    en: 'Category',
    gu: 'કેટેગરી',
    hi: 'केटेगरीं',
    kn: 'ವರ್ಗ',
    mr: 'श्रेणी',
    te: 'విభాగం',
  },
  CHANGE: {
    en: 'Change',
    gu: 'બદલો',
    hi: 'बदले',
    kn: 'Change',
    mr: 'बदला',
    te: 'Change',
  },
  CHANGE_LANGUAGE: {
    en: 'Change Language',
    gu: 'ભાષા બદલો',
    hi: 'भाषा बदले',
    kn: 'ಭಾಷೆ ಬದಲಾಯಿಸಿ',
    mr: 'भाषा बदला',
    te: 'భాషను మార్చండి',
  },
  CHANGE_LOCAL_LANGUAGE_DESC: {
    en: 'Choose a local language of your preference',
    gu: 'તમારી પસંદગીની સ્થાનિક ભાષા પસંદ કરો',
    hi: 'अपनी पसंद की स्थानीय भाषा चुनें',
    kn: 'ನಿಮ್ಮ ಆದ್ಯತೆಯ ಸ್ಥಳೀಯ ಭಾಷೆಯನ್ನು ಆರಿಸಿ',
    mr: 'आपल्या पसंतीची भाषा निवडा',
    te: 'మీకు నచ్చిన ప్రాంతీయ భాషను ఎంచుకోండి',
  },
  CHANGE_LOCATION: {
    en: 'Change Location',
    gu: 'લોકેશન બદલો',
    hi: 'लोकेशन बदलें',
    kn: 'ಸ್ಥಳ ಬದಲಾಯಿಸಿ',
    mr: 'ठिकाण बदला',
    te: 'లొకేషన్ ను  మార్చండి',
  },
  CHANGE_LOCATION_NOTE: {
    en: 'To add these products to your cart,we would need to change your location.',
    gu: 'આ ઉત્પાદનોને તમારા કાર્ટમાં ઉમેરવા માટે, અમારે તમારું લોકેશન બદલવું પડશે.',
    hi: 'इन उत्पादों को अपनी कार्ट में जोड़ने के लिए, हमें आपका लोकेशन बदलना होगा।',
    kn: 'ಈ ಉತ್ಪನ್ನಗಳನ್ನು ನಿಮ್ಮ ಕಾರ್ಟ್ ಗೆ ಸೇರಿಸಲು ನಾವು ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಬದಲಾಯಿಸಬೇಕಾಗಿದೆ',
    mr: 'ही उत्पादने तुमच्या कार्ट मध्ये जोडण्यासाठी, आम्हाला तुमचे ठिकाण बदलावे लागेल.',
    te: 'ఈ వస్తువులను మీ బండిలో చేర్చుటకు మేము మీ లొకేషన్ ను మార్చవలెను',
  },
  CHANGE_PASSWORD: {
    en: 'Change Password',
    gu: 'પાસવર્ડ બદલો',
    hi: 'पासवर्ड बदलें',
    kn: 'ಪಾಸ್ ವರ್ಡ್ ಬದಲಾಯಿಸಿ',
    mr: 'पासवर्ड बदला',
    te: 'పాస్ వర్డ్ మార్చండి',
  },
  CHAT_WITH_SUPPORT: {
    en: 'Chat with support',
    gu: 'સપોર્ટ સાથે વાતચીત',
    hi: 'सपोर्ट के साथ बात करें',
    kn: 'ಸಪೋರ್ಟ್ ನೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಿ',
    mr: 'मदतीसाठी चर्चा करा',
    te: 'మా ప్రతినిధితో సంప్రదించండి',
  },
  CHAT_SUPPORT: {
    en: 'Chat Support',
    gu: 'ચેટ સપોર્ટ',
    hi: 'चैट सपोर्ट',
    kn: 'ಚಾಟ್ ಬೆಂಬಲ',
    mr: 'चर्चा मदत',
    te: 'చాటింగ్ ద్వారా సహాయం',
  },
  CHAT_WITH_US: {
    en: 'Chat with us',
    gu: 'અમારી સાથે વાત કરો',
    hi: 'हम से बात करे',
    kn: 'ನಮ್ಮೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಿ',
    mr: 'आमच्याशी चर्चा करा',
    te: 'మాతో చాట్ చెయ్యండి',
  },
  CHECKOUT: {
    en: 'Checkout',
    gu: 'ચેકઆઉટ',
    hi: 'चेकआउट',
    kn: 'ಚೆಕ್ ಔಟ್',
    mr: 'तपासा',
    te: 'తనిఖీ చేయండి',
  },
  CHOOSE_LANGUAGE: {
    en: 'Choose Language',
    gu: 'ભાષા પસંદ કરો',
    hi: 'भाषा चुनें',
    kn: 'ಭಾಷೆ ಆರಿಸಿ',
    mr: 'भाषा निवडा',
    te: 'భాషను ఎంచుకోండి',
  },
  CLEAR: {
    en: 'Clear',
    gu: 'સાફ',
    hi: 'साफ़',
    kn: 'ತೆರವುಗೊಳಿಸಿ',
    mr: 'सर्व काढून टाका',
    te: 'ఖాళీ',
  },
  CLOSED: {
    en: 'Closed',
    gu: 'બંધ',
    hi: 'बंद',
    kn: 'ಮುಚ್ಚಿದೆ',
    mr: 'बंद आहे',
    te: 'మూసిఉన్నది',
  },
  COD: {
    en: 'Cash on Delivery',
    gu: 'વસ્તુ મળે ત્યારે રોકડા રૂપિયા આપવા',
    hi: 'वस्तु मिलने पर नकद राशि देना',
    kn: 'ತಲುಪಿದಾಗ ನಗದು ಪಾವತಿಸುವಿಕೆ',
    mr: 'वितरण वेळी पैसे देणे.',
    te: 'వస్తువులు అందిన తరువాత డబ్బు చెల్లించడం',
  },
  COD_EXTRA_DELIVERY_CHARGE: {
    en: '{extraDeliveryCharge}/- will be charged extra for Cash on Delivery Orders',
    gu: 'કૅશ ઑન ડીલિવરીના ઑર્ડરો માટે {extraDeliveryCharge}/- અલગથી વસૂલવામાં આવશે',
    hi: '{extraDeliveryCharge}/- कैश ऑन डिलीवरी ऑर्डर के लिए अतिरिक्त शुल्क लिया जाएगा',
    kn: 'ಕ್ಯಾಶ್ ಆನ್ ಡೆಲಿವರಿ ಆರ್ಡರ್ ಗಳ ಮೇಲೆ {extraDeliveryCharge}/- ವಿಧಿಸಲಾಗುತ್ತದೆ',
    mr: 'कॅश ऑन डिलिव्हरी ऑर्डरसाठी {extraDeliveryCharge}/- अतिरिक्त आकारले जाईल',
    te: 'నగదుపై డెలవరీ ఆర్డర్లకు {extraDeliveryCharge} వసూలు చేయబడుతుంది.',
  },
  COMING_SOON_PLACEHOLDER_HEADING: {
    en: 'We look forward to serving you soon!',
    gu: 'અમે જલ્દી જ તમારી સેવા કરવા માટે રાહ જોઈશું!',
    hi: 'हम आपको शीघ्र सेवा देने को तत्पर हैं!',
    kn: 'ಶೀಘ್ರದಲ್ಲೇ ನಿಮಗೆ ಸೇವೆ ಸಲ್ಲಿಸಲು ನಾವು ಎದುರು ನೋಡುತ್ತಿದ್ದೇವೆ!',
    mr: 'आम्ही आपणास लवकरात लवकर सेवा देण्यास तत्पर आहोत!',
    te: 'త్వరలోనే మీకు  సేవలు అందించుటకు మేము ఎదురుచూస్తున్నాము !',
  },
  COMING_SOON_PLACEHOLDER_SUBHEADING: {
    en: 'We are working around the clock to get shops up in your neighborhood.',
    gu: 'અમે તમારા પાડોશની દુકાનો લાવવા માટે ચોવીસ કલાક કામ કરી રહ્યા છીએ.',
    hi: 'हम आपके पड़ोस की दुकानों को लाने के लिए चौबीसों घंटे काम कर रहे हैं।',
    kn: 'ನಿಮ್ಮ ನೆರೆಹೊರೆಯಲ್ಲಿ ಅಂಗಡಿ ಪಡೆಯಲು ನಾವು ಗಡಿಯಾರದ ಸುತ್ತ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇವೆ.',
    mr: 'आम्ही आपल्या आजूबाजूच्या भागात दुकाने उपलब्ध करून देण्यासाठी चोवीस तास कार्यरत आहोत.',
    te: 'మీ చుట్టుపక్కల షాపులను చేర్చడానికి మేము రోజంతా పనిచేస్తున్నాము',
  },
  COMPLETE_ADDRESS: {
    en: 'Complete Address',
    gu: 'પૂરું એડ્રેસ',
    hi: 'पूरा एड्रेस',
    kn: 'ಸಂಪೂರ್ಣ ವಿಳಾಸ',
    mr: 'संपूर्ण पत्ता',
    te: 'పూర్తి అడ్రెస్సు',
  },
  COMPLIMENTS_FROM_CUSTOMERS: {
    en: 'Compliments from Customers',
    gu: 'ગ્રાહકો તરફથી પ્રશંસા',
    hi: 'ग्राहकों से तारीफ',
    kn: 'ಗ್ರಾಹಕರಿಂದ ಅಭಿನಂದನೆಗಳು',
    mr: 'ग्राहकांकडून कौतुक',
    te: 'వినియోగదారుల నుండి అభినందనలు',
  },
  CONDITION_NOT_MET: {
    en: 'Condition not met!',
    gu: 'શરત પૂરી નથી થઈ!!',
    hi: 'शर्त पूरी नहीं हुई!',
    kn: 'ಷರತ್ತು ಪೂರೈಸಲಿಲ್ಲ!',
    mr: 'अट पूर्ण झाली नाही!',
    te: 'షరతును పాటించలేదు !',
  },
  CONFIRM_ORDER: {
    en: 'Confirm Order',
    gu: 'ઓર્ડરની પુષ્ટિ કરો',
    hi: 'ऑर्डर कन्फ़र्म करें',
    kn: 'Confirm Order',
    mr: 'ऑर्डरची खात्री करा',
    te: 'Confirm Order',
  },
  CONGRATS: {
    en: 'Congrats!',
    gu: 'અભિનંદન!',
    hi: 'बधाई!',
    kn: 'ಅಭಿನಂದನೆಗಳು!',
    mr: 'अभिनंदन!',
    te: 'కంగ్రాట్స్ !',
  },
  CONGRATULATIONS: {
    en: 'Congratulations',
    gu: 'અભિનંદન',
    hi: 'बधाई हो',
    kn: 'ಅಭಿನಂದನೆಗಳು',
    mr: 'अभिनंदन',
    te: 'అభినందనలు',
  },
  CONTACT_US: {
    en: 'Contact Us',
    gu: 'અમારો સંપર્ક કરો',
    hi: 'हमारा संपर्क करें',
    kn: 'ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ',
    mr: 'आम्हाला संपर्क करा',
    te: 'మమ్మలిని సంప్రదించండి',
  },
  CONTINUE: {
    en: 'Continue',
    gu: 'ચાલુ રાખો',
    hi: 'जारी रखें',
    kn: 'ಮುಂದುವರಿಸಿ',
    mr: 'सुरू ठेवा',
    te: 'కొనసాగించు',
  },
  CONTINUE_SHOPPING: {
    en: 'Continue Shopping',
    gu: 'ખરીદારી ચાલુ રાખો',
    hi: 'खरीदारी जारी रखें',
    kn: 'ಶಾಪಿಂಗ್ ಮುಂದುವರಿಸಿ',
    mr: 'खरेदी सुरू ठेवा',
    te: 'కొనడం కొనసాగించండి',
  },
  COPIED: {
    en: 'COPIED',
    gu: 'કૉપી કરવામાં આવ્યું',
    hi: 'प्रतिलिपि किया गया',
    kn: 'ಕಾಪಿ ಮಾಡಲಾಗಿದೆ',
    mr: 'कॉपी केले',
    te: 'కాపీ చేయబడింది',
  },
  COPY: {
    en: 'COPY',
    gu: 'કોપી',
    hi: 'कॉपी',
    kn: 'ನಕಲು',
    mr: 'कॉपी',
    te: 'కాపీ',
  },
  COPY_COUPON_CODE: {
    en: 'COPY COUPON CODE',
    gu: 'કૂપન કોડ કોપી કરો',
    hi: 'कूपन कोड कॉपी करें',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ನಕಲಿಸಿ',
    mr: 'कूपन कोड कॉपी करा',
    te: 'కూపన్ కోడ్ ను కాపీ చేయండి',
  },
  COPY_LINK: {
    en: 'Copy Link',
    gu: 'લિંક કૉપી કરો',
    hi: 'लिंक की प्रतिलिपि बनाएँ',
    kn: 'ಲಿಂಕ್ ಕಾಪಿ ಮಾಡಿ',
    mr: 'लिंक कॉपी करा',
    te: 'కాపీ లింక్',
  },
  COMBO_OFFERS: {
    en: 'Combo Offers',
    gu: 'Combo ઓફર્સ',
    hi: 'Combo ऑफ़र',
    kn: 'Combo Offers',
    mr: 'Combo Offers',
    te: 'Combo Offers',
  },
  COMPLETE_ADDRESS_REQUIRED: {
    en: 'Complete Address Required',
    gu: 'સંપૂર્ણ સરનામું જરૂરી છે',
    hi: 'पूर्ण पता आवश्यक है',
    kn: 'ಸಂಪೂರ್ಣ ವಿಳಾಸ ಅಗತ್ಯ',
    mr: 'पूर्ण पत्ता आवश्यक आहे',
    te: 'అడ్రస్ ను పూర్తి చేయండి',
  },
  CANNOT_DELIVER_TO_LOCATION: {
    en: 'Cannot Deliver to this location',
    gu: 'આ સ્થળે પહોંચાડી શકીએ તેમ નથી',
    hi: 'इस स्थान पर वितरित नहीं किया जा सकता',
    kn: 'ಈ ಸ್ಥಳಕ್ಕೆ ವಿತರಿಸಲಾಗುವುದಿಲ್ಲ',
    mr: 'या ठिकाणी वितरित करू शकत नाही',
    te: 'ఈ లొకేషన్ కు డెలివరీ చేయలేం.',
  },
  ALL_COMBO_OFFERS: {
    en: 'All Combo Offers',
    gu: 'તમામ કોમ્બો ઑફર્સ',
    hi: 'सभी कॉम्बो ऑफर',
    kn: 'ಎಲ್ಲಾ ಕಾಂಬೊ ಕೊಡುಗೆಗಳು',
    mr: 'सर्व कॉम्बो ऑफर',
    te: 'అన్ని కాంబో ఆఫర్‌లు',
  },
  COUPON_APPLIED: {
    en: 'Coupon Applied',
    gu: 'કૂપન લાગુ કર્યું',
    hi: 'कूपन लागू हुआ',
    kn: 'ಕೂಪನ್ ನಮೂದಿಸಲಾಗಿದೆ',
    mr: 'कूपन लागू केले',
    te: 'కూపన్ వర్తించబడినది',
  },
  COUPON_APPLIED_ON_THE_BILL: {
    en: 'Coupon applied on the bill',
    gu: 'આ બિલ પર કુપન લાગુ કર્યું',
    hi: 'इस बिल पर कूपन लागू किया',
    kn: 'ಬಿಲ್ ನ ಮೇಲೆ ಕೂಪನ್ ಕೋಡ್ ನಮೂದಿಸಲಾಗಿದೆ',
    mr: 'बिलावर कूपन लागू केले आहे',
    te: 'బిల్లుకు కూపన్ వర్తించబడినది',
  },
  COUPON_APPLY_SUCCESS_DESC: {
    en: 'Coupon has been successfully applied. Enjoy your offer!',
    gu: 'કૂપન સફળતાપૂર્વક લાગુ કરવામાં આવ્યું છે. તમારી ઓફરનો આનંદ માણો!',
    hi: 'कूपन सफलतापूर्वक लागू किया गया है। अपनी ऑफर का आनंद लें!',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಮೂದಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಆಫರ್ ಅನ್ನು ಆನಂದಿಸಿ',
    mr: 'कूपन यशस्वीरीत्या लागू केले आहे, ऑफर चा आनंद घ्या',
    te: 'విజయవంతంగా కూపన్  వర్తించబడినది. మీ ఆఫర్ ను ఆశ్వాదించండి!',
  },
  COUPON_CODE: {
    en: 'Coupon Code',
    gu: 'કૂપન કોડ',
    hi: 'कूपन कोड',
    kn: 'ಕೂಪನ್ ಕೋಡ್',
    mr: 'कूपन कोड',
    te: 'కూపన్ కోడ్',
  },
  COUPON_CODE_COPIED: {
    en: 'Coupon code copied',
    gu: 'કૂપન કોડ કોપી કર્યો',
    hi: 'कूपन कोड कॉपी किया गया',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ನಕಲಿಸಲಾಗಿದೆ',
    mr: 'कूपन कोड कॉपी केला',
    te: 'కూపన్ కోడ్ కాపీ చేయబడినది',
  },
  CURRENTLY_NOT_SERVICING: {
    en: 'Currently Not-Servicing',
    gu: 'હાલમાં સેવા ઉપલબ્ધ નથી',
    hi: 'वर्तमान में सेवा उपलब्ध नहीं है',
    kn: 'ಪ್ರಸ್ತುತ ಸೇವೆ ಸಲ್ಲಿಸುತ್ತಿಲ್ಲ',
    mr: 'सध्या सेवेत नाही',
    te: 'ప్రస్తుతం సేవలు అందించుటలేదు',
  },
  CUSTOMER_PROFILE: {
    en: 'Customer Profile',
    gu: 'ગ્રાહકની પ્રોફાઇલ',
    hi: 'ग्राहक की प्रोफ़ाइल',
    kn: 'ಗ್ರಾಹಕರ ಪ್ರೊಫೈಲ್',
    mr: 'ग्राहक प्रोफाइल',
    te: 'కొనుగోలుదారుని ప్రొఫైల్',
  },
  CUSTOMER_DETAILS: {
    en: 'Customer Details',
    gu: 'ગ્રાહક વિગતો',
    hi: 'उपभोक्ता विवरण',
    kn: 'ಗ್ರಾಹಕರ ವಿವರಗಳು',
    mr: 'ग्राहक तपशील',
    te: 'వినియోగదారుల వివరాలు',
  },
  CUSTOMISABLE: {
    en: 'Customizable',
    gu: 'કસ્ટમાઇઝેબલ',
    hi: 'कस्टमाइज़ेबल',
    kn: 'ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾಗಿದೆ',
    mr: 'सानुकूलित करण्यायोग्य',
    te: 'అనుకూలీకరించదగిన',
  },
  DAILY_FLASH_SALE: {
    en: 'Daily Flash Sale',
    gu: 'દૈનિક ફ્લેશ વેચાણ',
    hi: 'दैनिक फ्लैश बिक्री',
    kn: 'ದೈನಂದಿನ ಫ್ಲ್ಯಾಶ್ ಮಾರಾಟ',
    mr: 'दररोज फ्लॅश सेल',
    te: 'ప్రతి రోజు ఫ్లాష్ అమ్మకాలు',
  },
  DATE: {
    en: 'Date',
    gu: 'તારીખ',
    hi: 'तारीख',
    kn: 'ದಿನಾಂಕ',
    mr: 'तारीख',
    te: 'తారీకు',
  },
  DATE_OF_BIRTH: {
    en: 'Date of Birth',
    gu: 'જન્મ તારીખ',
    hi: 'जन्म तारीख',
    kn: 'ಹುಟ್ಟಿದ ದಿನಾಂಕ',
    mr: 'जन्म तारीख',
    te: 'పుట్టిన తేదీ',
  },
  DEFAULT: {
    en: 'Default',
    gu: 'ડિફોલ્ટ',
    hi: 'डिफ़ॉल्ट',
    kn: 'ಡೀಫಾಲ್ಟ್',
    mr: 'मूलभूत',
    te: 'డిఫాల్ట్',
  },
  DELIVER_TO_HOME: {
    en: 'Deliver to Home',
    gu: 'ઘરે ડીલિવરી',
    hi: 'घर पर वितरित करें',
    kn: 'ಮನೆಗೆ ಡೆಲಿವರಿ ಮಾಡಿ',
    mr: 'घरपोच वितरित करा',
    te: 'ఇంటికి డెలివరీ',
  },
  DELETE: {
    en: 'Delete',
    gu: 'ડીલીટ',
    hi: 'डिलीट',
    kn: 'ಅಳಿಸಿ',
    mr: 'काढून टाका',
    te: 'తొలిగించు',
  },
  DELIGHTED: {
    en: 'Delighted',
    gu: 'આનંદ',
    hi: 'प्रसन्न',
    kn: 'ಸಂತೋಷವಾಯಿತು',
    mr: 'आनंदी केले',
    te: 'ఆనందంగా ఉన్నది',
  },
  DELIVER_TO: {
    en: 'Deliver To',
    gu: 'તેને આપો',
    hi: 'उसे देदो',
    kn: 'ಗೆ ತಲುಪಿಸಿ',
    mr: 'यांना वितरित करा',
    te: 'డెలివరీ చేయవలసిన',
  },
  DELIVERED: {
    en: 'Delivered',
    gu: 'પહોંચાડી દીધો',
    hi: 'पहुंचा दिया',
    kn: 'ತಲುಪಿಸಲಾಗಿದೆ',
    mr: 'वितरित केले.',
    te: 'డెలివేరి చేయబడినది',
  },
  DELIVERY: {
    en: 'Delivery',
    gu: 'ડિલિવરી',
    hi: 'वितरण',
    kn: 'ಡೆಲಿವರಿ',
    mr: 'वितरण',
    te: 'డెలివరీ',
  },
  DELIVERY_ADDRESS: {
    en: 'Delivery Address',
    gu: 'ડિલિવરી એડ્રેસ',
    hi: 'डिलीवरी एड्रेस',
    kn: 'ಡೆಲಿವರಿ ವಿಳಾಸ',
    mr: 'वितरण पत्ता',
    te: 'డెలివేరి చేయవలసిన అడ్రసు',
  },
  DELIVERY_CHARGE: {
    en: 'Delivery Fee',
    gu: 'ડિલિવરી ફી',
    hi: 'डिलीवरी फि',
    kn: 'ಡೆಲಿವರಿ ಶುಲ್ಕ',
    mr: 'मोफत वितरण',
    te: 'డెలివేరి రుసుము',
  },
  DELIVERY_OPTIONS: {
    en: 'Delivery Options',
    gu: 'ડિલિવરી વિકલ્પો',
    hi: 'डिलीवरी विकल्प',
    kn: 'ಡೆಲಿವರಿ ಆಯ್ಕೆಗಳು',
    mr: 'वितरण पर्याय',
    te: 'డెలివేరి ఆప్షన్ లు',
  },
  DELIVER_TO_MY_LOCATION: {
    en: 'Deliver to my location',
    gu: 'મારા સ્થળે પહોંચાડો',
    hi: 'मेरे स्थान पर वितरित करें',
    kn: 'ನನ್ನ ಸ್ಥಳಕ್ಕೆ ಡೆಲಿವರಿ ಮಾಡಿ',
    mr: 'माझ्या स्थानावर वितरित करा',
    te: 'నా లొకేషన్ కు డెలివరీ చేయండి',
  },
  DELIVER_TO_LOCATION: {
    en: 'Deliver to location',
    gu: 'આ સ્થળે પહોંચાડો',
    hi: 'इस स्थान पर डिलीवर करें',
    kn: 'ಸ್ಥಳಕ್ಕೆ ಡೆಲಿವರಿ ಮಾಡಿ',
    mr: 'स्थानावर वितरित करा',
    te: 'లొకేషన్ కు డెలివరీ',
  },
  PICKUP_FROM_STORE: {
    en: 'Pickup from shop',
    gu: 'દુકાનમાંથી લઈ જાઓ',
    hi: 'दुकान से उठाएँ',
    kn: 'ಅಂಗಡಿಯಿಂದ ತೆಗೆದುಕೊಳ್ಳಿ',
    mr: 'दुकानातून उचल करा',
    te: 'దుకాణంనుంచి పికప్ చేసుకోండి',
  },
  PICK_DELIVERY_METHOD: {
    en: 'Pick Delivery Method',
    gu: 'ડીલિવરીની પદ્ધતિ પસંદ કરો',
    hi: 'वितरण का तरीका चुनें',
    kn: 'ಡೆಲಿವರಿ ವಿಧಾನ ತೆಗೆದುಕೊಳ್ಳುವುದು',
    mr: 'वितरण पद्धत निवडा',
    te: 'పిక్ డెలివరీ మెథడ్',
  },
  DELIVERY_OPTIONS_ERROR: {
    en: 'Please select your Delivery Preference',
    gu: 'કૃપા કરીને તમારી ડિલિવરી પ્રાથમિકતા પસંદ કરો',
    hi: 'कृपया अपनी डिलीवरी प्राथमिकता चुनें',
    kn: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಡೆಲಿವರಿ ಆದ್ಯತೆಯನ್ನು ಆರಿಸಿ',
    mr: 'कृपया आपली वितरण पर्याय निवडा',
    te: 'దయచేసి మీ డెలివేరి ప్రాధాన్యతలను ఎంచుకోండి',
  },
  DELIVERY_TIME: {
    en: 'Delivery Time',
    gu: 'ડિલિવરીનો સમય',
    hi: 'डिलीवरी का समय',
    kn: 'ಡೆಲಿವರಿ ಸಮಯ',
    mr: 'वितरण वेळ',
    te: 'డెలివేరి సమయం',
  },
  DIDNOT_GET_OTP: {
    en: "Didn't get OTP?",
    gu: 'ઓટીપી નથી મળ્યો?',
    hi: 'ओटीपी नहीं मिला?',
    kn: 'OTP ಸಿಗಲಿಲ್ಲವೇ?',
    mr: 'OTP मिळाली नाही?',
    te: 'OTP అందలేదా ?',
  },
  DIDNOT_RECEIVE_YOUR_ORDER: {
    en: 'Didn’t receive your order?',
    gu: 'તમારો ઓર્ડર મળ્યો નથી?',
    hi: 'आपका ऑर्डर प्राप्त नहीं हुआ?',
    kn: 'ನಿಮ್ಮ ಆದೇಶವನ್ನು ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?',
    mr: 'ऑर्डर मिळाली नाही?',
    te: 'మీ ఆర్డర్ అందలేదా ?',
  },
  DIRECTION: {
    en: 'Directions',
    gu: 'માર્ગદર્શક',
    hi: 'दिशा-निर्देश',
    kn: 'ದಿಕ್ಕು',
    mr: 'दिशानिर्देश',
    te: 'దిక్కు',
  },
  DISCOUNT: {
    en: 'Discount',
    gu: 'ડિસ્કાઉન્ટ',
    hi: 'डिस्काउंट',
    kn: 'ರಿಯಾಯಿತಿ',
    mr: 'डिस्काउंट',
    te: 'డిస్కౌంట్',
  },
  DISCOUNT_APPLIED: {
    en: 'Discount Applied',
    gu: 'ડિસ્કાઉન્ટ લાગુ કર્યું',
    hi: 'डिस्काउंट लागू हुआ',
    kn: 'ರಿಯಾಯಿತಿ ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'डिस्काउंट लागू केले',
    te: 'డిస్కౌంట్ వర్తించబడినది',
  },
  DISCOUNT_BREAKUP: {
    en: 'Discount Breakup',
    gu: 'ડિસ્કાઉન્ટ બ્રેકઅપ',
    hi: 'डिस्काउंट ब्रेकअप',
    kn: 'ರಿಯಾಯಿತಿ ವಿಘಟನೆ',
    mr: 'Discount Breakup',
    te: 'డిస్కౌంట్ ను విడకొట్టడం',
  },
  DISCOUNT_COUPONS: {
    en: 'Discount Coupons',
    gu: 'ડિસ્કાઉન્ટ કુપન્સ',
    hi: 'डिस्काउंट कूपन्स',
    kn: 'ರಿಯಾಯಿತಿ ಕೂಪನ್ ಗಳು',
    mr: 'डिस्काउंट कूपन',
    te: 'డిస్కౌంట్ కూపన్ లు',
  },
  DISCOUNT_EXPIRES_TODAY_HURRY: {
    en: '₹{discount} discount expires today. Hurry!',
    gu: '₹{discount} ડિસ્કાઉન્ટ આજે પૂરું થાય છે. જલ્દી કરો!',
    hi: '₹{discount} डिस्काउंट आज समाप्त हो रहा है। जल्दी कीजिये!',
    kn: '₹{discount} ರಿಯಾಯಿತಿ ಇಂದು ಕೊನೆಗೊಳ್ಳುತ್ತದೆ. ತ್ವರೆ ಮಾಡಿ!',
    mr: '₹{discount} डिस्काउंट आज संपत आहे, त्वरा करा!',
    te: 'త్వరపడండి! ₹{discount} డిస్కౌంట్ ఈ రోజుతో ముగుస్తుంది',
  },
  DISCOVER_SHOPS_NEAR_YOU: {
    en: 'Discover Shops Near You',
    gu: 'તમારી નજીકની દુકાનો શોધો',
    hi: 'आपके आस-पास की दुकानें खोजे',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ ಅಂಗಡಿಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    mr: 'तुमच्या जवळची दुकाने शोधा',
    te: 'మీ దగ్గరలోని షాపులను తెలుసుకోండి',
  },
  DISMISS: {
    en: 'Dismiss',
    gu: 'કાઢી નાંખો',
    hi: 'निकाल दें',
    kn: 'ವಜಾಗೊಳಿಸಿ',
    mr: 'रद्द करा',
    te: 'రద్దుచేయు',
  },
  DISPATCHED: {
    en: 'Dispatched',
    gu: 'મોકલી દીધું',
    hi: 'भेज दिया',
    kn: 'ರವಾನಿಸಲಾಗಿದೆ',
    mr: 'रवाना केले आहे',
    te: 'పంపబడినది',
  },
  DISTANCE: {
    en: 'Distance',
    gu: 'અંતર',
    hi: 'दूरी',
    kn: 'ದೂರ',
    mr: 'अंतर',
    te: 'దూరం',
  },
  DO_YOU_HAVE_REFERRAL_CODE: {
    en: 'Do You Have a Referral Code',
    gu: 'શું તમારી પાસે રેફરલ કોડ છે',
    hi: 'क्या आपके पास रेफरल कोड है',
    kn: 'ನೀವು ರೆಫರಲ್ ಕೋಡ್ ಹೊಂದಿದ್ದೀರಾ',
    mr: 'तुमच्याकडे संदर्भ कोड आहे?',
    te: 'మీ వద్ద రిఫరల్ కోడ్ ఉన్నదా',
  },
  DONE: {
    en: 'Done',
    gu: 'થઈ ગયું',
    hi: 'हो गया',
    kn: 'ಮುಗಿದಿದೆ',
    mr: 'पूर्ण झाले',
    te: 'అయ్యింది',
  },
  DONT_CHANGE: {
    en: "Don't Change",
    gu: 'બદલો નહીં',
    hi: 'मत बदलो',
    kn: 'ಬದಲಾಯಿಸಬೇಡಿ',
    mr: 'बदलू नका',
    te: 'మార్చవద్దు',
  },
  DONT_DELETE: {
    en: "Don't Delete",
    gu: 'હટાવો નહિ',
    hi: 'हटाएं नहीं',
    kn: 'ಅಳಿಸಬೇಡಿ',
    mr: 'काढू नका.',
    te: 'తొలిగించవద్దు',
  },
  DONT_LOSE_HEART: {
    en: "Don't Lose Heart",
    gu: 'દુઃખી ના થાવ',
    hi: 'दुखी न हो',
    kn: 'ಹೃದಯವನ್ನು ಕಳೆದುಕೊಳ್ಳಬೇಡಿ',
    mr: 'हृदय गमावू नका',
    te: 'నిరాశ చెందద్దు',
  },
  DONT_REMOVE: {
    en: "Don't Remove",
    gu: 'દૂર કરશો નહીં',
    hi: 'दूर न करें',
    kn: 'ತೆಗೆಯಬೇಡಿ',
    mr: 'काढू नका',
    te: 'తొలిగించవద్దు',
  },
  DOWNLOAD_LOVELOCAL_PARTNER_APP: {
    en: 'Download LoveLocal Partner App',
    gu: 'લવલોકલ પાર્ટનર એપ ડાઉનલોડ કરો',
    hi: 'लवलोकल पार्टनर ऐप डाउनलोड करें',
    kn: 'LoveLocal ಪಾರ್ಟ್ನರ್ ಅಪ್ ಡೌನ್ಲೋಡ್ ಮಾಡಿ',
    mr: 'LoveLocal पार्टनर ॲप डाऊनलोड करा',
    te: 'LoveLocal పార్టనర్ ఆప్ ను డౌన్ లోడ్ చేసుకోండి',
  },
  DOWNLOAD_THE_LOVELOCAL_APP: {
    en: 'Download the LoveLocal app.',
    gu: 'લવલોકલ એપ ડાઉનલોડ કરો',
    hi: 'लवलोकल ऐप डाउनलोड करें',
    kn: 'LoveLocal ಆಪ್ ಡೌನ್ಲೋಡ್ ಮಾಡಿ',
    mr: 'LoveLocal ॲप डाऊनलोड करा',
    te: 'LoveLocal ఆప్ ను డౌన్లోడ్ చేసుకోండి',
  },
  EARN_DISCOUNT_UPTO_500: {
    en: 'Earn discounts upto ₹500',
    gu: '₹500 સુધીનું ડિસ્કાઉન્ટ મેળવો',
    hi: '₹500 तक की छूट',
    kn: '₹500 ವರೆಗೂ ರಿಯಾಯಿತಿ ಗಳಿಸಿ',
    mr: '₹500 पर्यंत डिस्काउंट मिळवा',
    te: '₹500 దాకా డిస్కౌంట్ పొందండి',
  },
  EDIT: {
    en: 'Edit',
    gu: 'એડિટ કરો',
    hi: 'एडिट करें',
    kn: 'ತಿದ್ದು',
    mr: 'संपादित करा',
    te: 'సవరించు',
  },
  EDIT_ADDRESS: {
    en: 'Edit Address',
    gu: 'એડ્રેસ એડિટ કરો',
    hi: 'एड्रेस एडिट करें',
    kn: 'ವಿಳಾಸ ತಿದ್ದಿ',
    mr: 'पत्ता संपादित करा',
    te: 'అడ్రసు ను సవరించండి',
  },
  EMAIL_ADDRESS: {
    en: 'Email Address',
    gu: 'ઇમેઇલ એડ્રેસ',
    hi: 'ईमेल एड्रेस',
    kn: 'ಇಮೇಲ್ ವಿಳಾಸ',
    mr: 'ईमेल पत्ता',
    te: 'ఈమెయిల్ అడ్రసు',
  },
  ENTER_LOCATION: {
    en: 'Enter Location',
    gu: 'લોકેશન નાખો',
    hi: 'लोकेशन डालें',
    kn: 'ಸ್ಥಳ ನಮೂದಿಸಿ',
    mr: 'ठिकाण प्रविष्ट करा',
    te: 'లొకేషన్ ను నమోదుచేయండి',
  },
  ENTER_MOBILE: {
    en: 'Enter mobile number',
    gu: 'મોબાઇલ નંબર નાખો',
    hi: 'मोबाइल नंबर दर्ज करें',
    kn: 'ಮೊಬೈಲ್ ನಂಬರ್ ನಮೂದಿಸಿ',
    mr: 'मोबाईल नंबर प्रविष्ट करा',
    te: 'మొబైలు నెంబరును నమోదుచేయండి',
  },
  ENTER_COUPON_CODE: {
    en: 'Enter Coupon Code',
    gu: 'કૂપન કૉડ દાખલ કરો',
    hi: 'कूपन कोड दर्ज करें',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ನಮೂದಿಸಿ',
    mr: 'कूपन कोड प्रविष्ट करा',
    te: 'కూపన్ కోడ్ ను ఎంటర్ చేయండి',
  },
  ENTER_OTP: {
    en: 'Enter OTP',
    gu: 'ઓટીપી નાખો',
    hi: 'ओटीपी दर्ज करें',
    kn: 'OTP ನಮೂದಿಸಿ',
    mr: 'OTP प्रविष्ट करा',
    te: 'OTP ని నమోదుచేయండి',
  },
  ESTABLISHED_IN: {
    en: 'Established In',
    gu: 'માં સ્થપાયેલ',
    hi: 'में स्थापित',
    kn: 'ಸ್ಥಾಪಿಸಲಾಗಿದೆ',
    mr: 'मध्ये स्थापना केली',
    te: 'స్థాపించబడింది',
  },
  EXCELLENT: {
    en: 'Excellent',
    gu: 'ઉત્તમ',
    hi: 'उत्कृष्ट',
    kn: 'ಅತ್ಯುತ್ತಮ',
    mr: 'उत्कृष्ट',
    te: 'Excellent',
  },
  EXCITING_COMBO_OFFERS: {
    en: 'Exciting Combo Offers',
    gu: 'આકર્ષક કૉમ્બો ઑફર્સ',
    hi: 'रोमांचक कॉम्बो ऑफर',
    kn: 'ಅತ್ಯಾಕರ್ಷಕ ಕಾಂಬೊ ಕೊಡುಗೆಗಳು',
    mr: 'रोमांचक कॉम्बो ऑफर',
    te: 'అద్భుతమైన కాంబో ఆఫర్‌లు',
  },
  EXCITING_OFFERS: {
    en: 'Exciting Offers',
    gu: 'ઉત્તેજક ઓફર્સ',
    hi: 'रोमांचक ऑफर',
    kn: 'ಅತ್ಯಾಕರ್ಷಕ ಕೊಡುಗೆಗಳು',
    mr: 'आकर्षक ऑफर',
    te: 'ఉత్తేజకరమైన ఆఫర్లు',
  },
  EXPIRES_TODAY: {
    en: 'Expires Today!',
    gu: 'આજે પૂરું થાય છે!',
    hi: 'आज समाप्त हो रहा है!',
    kn: 'ಇಂದು ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ!',
    mr: 'आज समाप्त होईल!',
    te: 'ఈ రోజుతో ముగుస్తుంది !',
  },
  EXPIRES_TOMORROW: {
    en: 'Expires Tomorrow!',
    gu: 'આવતીકાલે પૂરું થાય છે!',
    hi: 'कल समाप्त हो रहा है!',
    kn: 'ನಾಳೆ ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ!',
    mr: 'उद्या समाप्त होईल!',
    te: 'రేపటితో  ముగుస్తుంది !',
  },
  EXPIRING_ON: {
    en: 'Expiring on',
    gu: 'પર પૂરું થાય છે',
    hi: 'पर समाप्त हो रहा है',
    kn: 'ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತಿದೆ',
    mr: 'यावेळी समाप्त होईल',
    te: 'ముగుస్తుంది',
  },
  EXPLORE: {
    en: 'Explore',
    gu: 'શોધો',
    hi: 'खोजे',
    kn: 'ಅನ್ವೇಷಿಸಿ',
    mr: 'पहा',
    te: 'వెతకండి',
  },
  EXPLORE_CATEGORIES: {
    en: 'Explore Categories',
    gu: 'શ્રેણીઓ શોધો',
    hi: 'श्रेणियाँ ढूंढे',
    kn: 'ವರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    mr: 'श्रेणीमध्ये पहा',
    te: 'కాటగరీలను వెతకండి',
  },
  EXPLORE_FAQS: {
    en: 'Explore FAQs',
    gu: 'વારંવાર પૂછાતા પ્રશ્નો વિષે જાણો',
    hi: 'अक्सर पूछे जाने वाले प्रश्नों को जाने',
    kn: 'ಎಫ್ ಎ ಕ್ಯೂ ಅನ್ವೇಷಿಸಿ',
    mr: 'प्रश्न पाहा',
    te: 'FAQ లను వెతకండి',
  },
  EXPLORE_NEW_LOVELOCAL_SHOPS_IN_YOUR_AREA: {
    en: 'Explore new LoveLocal shops in your area',
    gu: 'તમારા વિસ્તારમાં નવી લવલોકલ દુકાનો શોધો',
    hi: 'अपने क्षेत्र में नई लोवेलोकल दुकाने खोजे',
    kn: 'ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಹೊಸ LoveLocal ಅಂಗಡಿಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    mr: 'आपल्या क्षेत्रातील नवीन LoveLocal शॉप्स एक्सप्लोर करा',
    te: 'మీ ప్రాంతంలో ఉన్న క్రొత్త LoveLocal షాపులను వెతకండి',
  },
  EXPLORE_SHOPS: {
    en: 'EXPLORE SHOPS',
    gu: 'દુકાનો શોધો',
    hi: 'दुकाने खोजे',
    kn: 'ಅಂಗಡಿಗಳು ಅನ್ವೇಷಿಸಿ',
    mr: 'दुकाने पाहा',
    te: 'షాపులను వెతకండి',
  },
  FACEBOOK: {
    en: 'Facebook',
    gu: 'ફેસબુક',
    hi: 'फेसबुक',
    kn: 'ಫೇಸ್ಬುಕ್',
    mr: 'फेसबुक',
    te: 'ఫేస్ బుక్',
  },
  FARTHEST_DISTANCE_ALERT: {
    en: 'You are placing a Shop Pick order. {shopName} is <b>{distance} Km away</b> from your location.',
    gu: 'તમે શોપ પિક ઓર્ડર આપી રહ્યા છો. {shopName} એ તમારા સ્થાનથી <b>{distance} કિ.મી. દૂર</b> છે.',
    hi: 'आप एक शॉप पिक ऑर्डर दे रहे हैं। {shopName} अपने स्थान से <b>{distance} किमी दूर</b> है।',
    kn: 'ನೀವು ಮಳಿಗೆ ಆಯ್ಕೆ ಆದೇಶವನ್ನು ನೀಡುತ್ತಿರುವಿರಿ. {shopName} ನಿಮ್ಮ ಸ್ಥಳದಿಂದ <b> {distance} ಕಿ.ಮೀ ದೂರದಲ್ಲಿದೆ </b>.',
    mr: 'आपण शॉप पिक ऑर्डर देत आहात. आपल्या स्थानावरून {shopName} <b> {distance} किमी दूर </b> आहे.',
    te: 'మీరు షాపు పిక్ అప్ ఆర్డర్ పెడుతున్నారు. మీ లొకేషన్ నుండి {shopName} <b>{distance} Km దూరంలో ఉన్నది</b>.',
  },
  FAVOURITE: {
    en: 'Favourite',
    gu: 'મનપસંદ',
    hi: 'पसंदीदा',
    kn: 'ನೆಚ್ಚಿನ',
    mr: 'आवडते',
    te: 'ఇష్టమైన',
  },
  FAVOURITES: {
    en: 'Favourites',
    gu: 'મનપસંદ',
    hi: 'पसंदीदा',
    kn: 'ಮೆಚ್ಚಿನವುಗಳು',
    mr: 'आवडीचे',
    te: 'ఇష్టాలు',
  },
  FAQS: {
    en: 'FAQs',
    gu: 'વારંવાર પૂછાતા પ્રશ્નો',
    hi: 'अक्सर पूछे जाने वाले प्रश्न',
    kn: 'ಎಫ್ ಎ ಕ್ಯೂ ಗಳು',
    mr: 'प्रश्न',
    te: 'FAQ లు',
  },
  FAQS_DESC: {
    en: 'All frequently asked questions',
    gu: 'બધા વારંવાર પૂછાતા પ્રશ્નો',
    hi: 'सभी अक्सर पूछे जाने वाले प्रश्न',
    kn: 'ಎಲ್ಲಾ ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು',
    mr: 'सर्व वारंवार विचारले जाणारे प्रश्न',
    te: 'తరచుగా అడిగే  అన్ని ప్రశ్నలు',
  },
  FIRST_NAME: {
    en: 'First Name',
    gu: 'પહેલું નામ',
    hi: 'पहला नाम',
    kn: 'ಮೊದಲ ಹೆಸರು',
    mr: 'नाव',
    te: 'మొదటి పేరు',
  },
  FLAT_NUMBER: {
    en: 'House / Flat number',
    gu: 'House / Flat number',
    hi: 'House / Flat number',
    kn: 'House / Flat number',
    mr: 'House / Flat number',
    te: 'House / Flat number',
  },
  FLAT_NUMBER_IS_REQUIRED: {
    en: 'Need house/flat number to continue',
    gu: 'Need house/flat number to continue',
    hi: 'Need house/flat number to continue',
    kn: 'Need house/flat number to continue',
    mr: 'Need house/flat number to continue',
    te: 'Need house/flat number to continue',
  },
  FOLLOW_US: {
    en: 'Follow Us',
    gu: 'અમને અનુસરો',
    hi: 'हमारा अनुसरण करें',
    kn: 'ನಮನ್ನು ಫಾಲೋ ಮಾಡಿ',
    mr: 'फॉल्लो करा',
    te: 'మమ్మలిని అనుసరించండి',
  },
  FOR: {
    en: 'for',
    gu: 'માટે',
    hi: 'के लिये',
    kn: 'ಗಾಗಿ',
    mr: 'साठी',
    te: 'యొక్క',
  },
  FOR_CAREERS: {
    en: 'For Careers',
    gu: 'કારકિર્દી માટે',
    hi: 'करियर के लिए',
    kn: 'ವೃತ್ತಿಜೀವನಕ್ಕಾಗಿ',
    mr: 'करिअर साठी',
    te: 'ఉద్యోగాల కొరకు',
  },
  FOR_PARTNERSHIP: {
    en: 'For Partnership',
    gu: 'ભાગીદારી માટે',
    hi: 'साझेदारी के लिए',
    kn: 'ಪಾಲುದಾರಿಕೆಗಾಗಿ',
    mr: 'पार्टनरशिप साठी',
    te: 'భాగస్వామ్యం కొరకు',
  },
  FREE_GIFT_AND_FLAT_DISCOUNTS_ON_MEETING_MOV: {
    en: 'Free Gift and Flat Discounts on meeting MOV',
    gu: 'MOV ને મળવા પર મફત ઉપહાર અને ફ્લેટ ડિસ્કાઉન્ટ',
    hi: 'MOV को पूरा करने पर मुफ्त उपहार और फ्लैट छूट',
    kn: 'MOV ಅನ್ನು ಭೇಟಿ ಮಾಡುವಾಗ ಉಚಿತ ಉಡುಗೊರೆ ಮತ್ತು ಫ್ಲಾಟ್ ರಿಯಾಯಿತಿಗಳು',
    mr: 'एमओव्हीला भेटण्यासाठी मोफत भेट आणि फ्लॅट सवलत',
    te: 'MOV ను కలవడానికి ఉచిత బహుమతి మరియు ఫ్లాట్ డిస్కౌంట్',
  },
  FREE_GIFT_OFFER: {
    en: 'Free Gift Offer',
    gu: 'ફ્રી ગિફ્ટ ઓફર',
    hi: 'फ्री गिफ्ट ऑफर',
    kn: 'ಉಚಿತ ಕೊಡುಗೆ ಆಫರ್',
    mr: 'मोफत भेट ऑफर',
    te: 'ఉచిత బహుమతి ఆఫర్',
  },
  FREE_GIFT_OFFERS: {
    en: 'Free Gift Offers',
    gu: 'ફ્રી ગિફ્ટ ઓફર્સ',
    hi: 'फ्री गिफ्ट ऑफर्स',
    kn: 'ಉಚಿತ ಕೊಡುಗೆ ಆಫರ್ ಗಳು',
    mr: 'मोफत भेट ऑफर्स',
    te: 'ఉచిత బహుమతి ఆఫర్లు',
  },
  FREE_PRODUCT: {
    en: 'Free Product',
    gu: 'નિઃશુલ્ક ઉત્પાદન',
    hi: 'नि: शुल्क उत्पाद',
    kn: 'ಉಚಿತ ಉತ್ಪನ್ನ',
    mr: 'मोफत उत्पादन',
    te: 'ఉచిత వస్తువు',
  },
  FRESH: {
    en: 'Fresh',
    gu: 'તાજા',
    hi: 'ताज़ा',
    kn: 'ತಾಜಾ',
    mr: 'ताजे',
    te: 'క్రొత్త',
  },
  FROM_YOUR_NEIGHBOURHOOD: {
    en: 'From your neighborhood',
    gu: 'તમારા પાડોશમાંથી',
    hi: 'अपने पड़ोस से',
    kn: 'ನಿಮ್ಮ ನೆರೆಹೊರೆಯಿಂದ',
    mr: 'आपल्या शेजारचे अनुसरण करा',
    te: 'మీ పొరుగున ఉన్న',
  },
  FROM_YOUR_NEIGHBOURHOOD_TO_SELL_ON_LOVELOCAL: {
    en: 'from your neighbourhood to sell on LoveLocal',
    gu: 'તમારા પડોશમાંથી લવલોકલ પર વેચવા માટે',
    hi: 'अपने पड़ोस से लवलोकल  पर बेचने के लिए',
    kn: 'ನಿಮ್ಮ ನೆರೆಹೊರೆಯಿಂದ LoveLocalನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಲು',
    mr: 'आपल्या आसपासच्या लोकांकडून LoveLocal वर विक्रीसाठी',
    te: 'LoveLocal లో అమ్ముటకు మీ పొరుగున ఉన్న',
  },
  GENDER: {
    en: 'Gender',
    gu: 'જાતિ',
    hi: 'लिंग',
    kn: 'ಲಿಂಗ',
    mr: 'लिंग',
    te: 'లింగం',
  },
  GET_DIRECTION: {
    en: 'Get Direction',
    gu: 'દિશા નિર્દેશો મેળવો',
    hi: 'दिशा प्राप्त करें',
    kn: 'ನಿರ್ದೇಶನ ಪಡೆಯಿರಿ',
    mr: 'दिशानिर्दश मिळवा',
    te: 'దారి తెలుసుకోండి',
  },
  GET_DISCOUNT_FOR_EACH_OTHER: {
    en: 'Get ₹100 discount for each friend you refer',
    gu: 'દરેક મિત્ર તમે નો સંદર્ભ લો માટે ₹ 100 ડિસ્કાઉન્ટ મેળવો',
    hi: 'प्रत्येक दोस्त उल्लेख के लिए ₹ 100 की छूट पाएं',
    kn: 'ನೀವು ರೆಫರ್ ಪ್ರತಿ ಸ್ನೇಹಿತರಿಗೆ ₹ 100 ರಿಯಾಯಿತಿ ಪಡೆಯಿರಿ',
    mr: 'मिळवा ₹100 सूट तुम्ही आमंत्रित केलेल्या प्रत्येक मित्रासाठी',
    te: 'మీరు చెప్పిన ప్రతి స్నేహితునికి 100 డిస్కౌంట్ పొందండి',
  },
  GET_STARTED: {
    en: 'Get Started',
    gu: 'શરૂ કરો',
    hi: 'शुरू करें',
    kn: 'ಪ್ರಾರಂಭಿಸಿ',
    mr: 'सुरुवात करा',
    te: 'ప్రారంభించండి',
  },
  GET_THESE_PRODUCT_BUNDLES_AT_A_DISCOUNT: {
    en: 'Get these product bundles at a discount',
    gu: 'આ ઉત્પાદન બંડલ્સને ડિસ્કાઉન્ટ પર મેળવો',
    hi: 'इन उत्पाद बंडलों को छूट पर प्राप्त करें',
    kn: 'ಈ ಉತ್ಪನ್ನ ಕಟ್ಟುಗಳನ್ನು ರಿಯಾಯಿತಿಯಲ್ಲಿ ಪಡೆಯಿರಿ',
    mr: 'ही उत्पादन बंडल एका सवलतीत मिळवा',
    te: 'ఈ వస్తువులను గుత్తగా డిస్కౌంట్లో పొందండి',
  },
  GO_BACK: {
    en: 'Go Back',
    gu: 'પાછા જાવ',
    hi: 'वापस जाओ',
    kn: 'ಹಿಂದೆ ಹೋಗು',
    mr: 'मागे जा',
    te: 'వెనక్కి వెళ్ళండి',
  },
  GO_TO_CART: {
    en: 'Go to Cart',
    gu: 'કાર્ટ પર જાઓ',
    hi: 'कार्ट पर जाऐ',
    kn: 'ಕಾರ್ಟ್‌ಗೆ ಹೋಗಿ',
    mr: 'कार्ट मध्ये जा',
    te: 'బండిలో చూడండి',
  },
  GOOD: {
    en: 'Good',
    gu: 'ગુડ',
    hi: 'अच्छा',
    kn: 'ಒಳ್ಳೆಯದು',
    mr: 'चांगले',
    te: 'మంచిది',
  },
  GREAT: {
    en: 'GREAT!',
    gu: 'સરસ!',
    hi: 'महान!',
    kn: 'ಗ್ರೇಟ್!',
    mr: 'छान!',
    te: 'గ్రేట్ !',
  },
  GREAT_PRICES_OF_PRODUCTS: {
    en: 'Great Prices of Products',
    gu: 'ઉત્પાદનો શ્રેષ્ઠ કિંમતો',
    hi: 'उत्पादों की सबसे अच्छी कीमतें',
    kn: 'ಉತ್ಪನ್ನಗಳ ಉತ್ತಮ ಬೆಲೆಗಳು',
    mr: 'उत्पादनाच्या आकर्षक किमती',
    te: 'వస్తువుల ఆకర్షణీయమైన ధరలు',
  },
  GREAT_QUALITY_OF_PRODUCTS: {
    en: 'Great Quality of Products',
    gu: 'ઉત્પાદનોની શ્રેષ્ઠ ગુણવત્તા',
    hi: 'उत्पादों की सबसे अच्छी गुणवत्ता',
    kn: 'ಉತ್ಪನ್ನಗಳ ಉತ್ತಮ ಗುಣಮಟ್ಟ',
    mr: 'उत्कृष्ट गुणवत्तेची उत्पादने',
    te: 'మంచి నాణ్యత గల వస్తువులు',
  },
  GREAT_SERVICE_OF_SHOP: {
    en: 'Great Service of Shop',
    gu: 'દુકાનની શ્રેષ્ઠ સેવા',
    hi: 'दुकान की सबसे अच्छी सेवा',
    kn: 'ಅಂಗಡಿಯ ಉತ್ತಮ ಸೇವೆ',
    mr: 'दुकानाची उत्तम सेवा',
    te: 'షాపు  యొక్క గొప్ప సేవలు',
  },
  HIGH_PRICES_OF_PRODUCTS: {
    en: 'High Prices of Products',
    gu: 'ઉત્પાદનોની વધુ કિંમતો',
    hi: 'उत्पाद की उच्च कीमतों',
    kn: 'ಉತ್ಪನ್ನಗಳ ಹೆಚ್ಚಿನ ಬೆಲೆಗಳು',
    mr: 'उत्पादनांच्या‌ जास्त किमती',
    te: 'వస్తువుల అధిక  ధరలు',
  },
  HELP: {
    en: 'Help',
    gu: 'મદદ કરો',
    hi: 'मदद',
    kn: 'ಸಹಾಯ',
    mr: 'मदत',
    te: 'సహాయం',
  },
  HOME: {
    en: 'Home',
    gu: 'ઘર',
    hi: 'घर',
    kn: 'ಹೊಂ',
    mr: 'मुख्यपृष्ठ',
    te: 'హోమ్',
  },
  HOME_DELIVERY: {
    en: 'Home Delivery',
    gu: 'હોમ ડિલિવરી',
    hi: 'होम डिलीवरी',
    kn: 'ಹೋಮ್ ಡೆಲಿವರಿ',
    mr: 'घरपोच वितरण',
    te: 'ఇంటికి డెలివేరి',
  },
  HOME_DELIVERY_AVAILABLE: {
    en: 'Home Delivery Available',
    gu: 'હોમ ડિલિવરી ઉપલબ્ધ',
    hi: 'होम डिलिवरी उपलब्ध',
    kn: 'ಹೋಮ್ ಡೆಲಿವರಿ ಲಭ್ಯವಿದೆ',
    mr: 'घरपोच वितरण उपलब्ध',
    te: 'ఇంటికి డెలివేరి చేయబడును',
  },
  HOW_IT_WORKS: {
    en: 'How it works',
    gu: 'તે કેવી રીતે કામ કરે છે',
    hi: 'यह काम किस प्रकार करता है',
    kn: 'ಇದು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತದೆ',
    mr: 'हे कसे काम करते',
    te: 'ఇది ఎలా పనిచేస్తుందంటే',
  },
  IMPORTANT: {
    en: 'IMPORTANT!',
    gu: 'મહત્વપૂર્ણ!',
    hi: 'महत्वपूर्ण!',
    kn: 'ಮುಖ್ಯವಾದ!',
    mr: 'महत्वाचे!',
    te: 'ముఖ్యమైన !',
  },
  INCL_OF_ALL_TAXES: {
    en: 'Incl. of all taxes',
    gu: 'બધા કર સહીત',
    hi: 'सभी करों सहित',
    kn: 'ಎಲ್ಲಾ ತೆರಿಗೆಗಳನ್ನು ಒಳಗೊಂಡಂತೆ',
    mr: 'सर्व करांसह',
    te: 'అన్ని పన్నులతో సహా',
  },
  INSTAGRAM: {
    en: 'Instagram',
    gu: 'ઇન્સ્ટાગ્રામ',
    hi: 'इंस्टाग्राम',
    kn: 'ಇನ್ಸ್ಟಾಗ್ರಾಮ್',
    mr: 'इंस्टाग्राम',
    te: 'ఇనస్టాగ్రామ్',
  },
  INVITATION_MESSAGE: {
    en: 'You will get a notification once this shop is accepting online ordering',
    gu: 'આ દુકાન ઓનલાઇન ઓર્ડર સ્વીકારવાનું ચાલુ કરશે ત્યારે તમને સૂચના મળશે',
    hi: 'जब यह दुकान ऑनलाइन ऑर्डर स्वीकारना शुरू करेगी तब आपको सूचित किया जाएगा',
    kn: 'ಈ ಅಂಗಡಿಯು ಆನ್‌ಲೈನ್ ಆರ್ಡರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿದ ನಂತರ ನಿಮಗೆ ನೋಟಿಫಿಕೇಶನ್ ಸಿಗುತ್ತದೆ',
    mr: 'या दुकानाने ऑनलाईन वितरण सुरू केल्यानंतर तुम्हाला सूचना मिळेल',
    te: 'ఈ షాపు ఆన్లైన్ ఆర్డర్ లను తీసుకోవడం మొదలు పెట్టగానే మీకు నోటిఫికేషన్ అందుతుంది',
  },
  INVITATION_SENT: {
    en: 'Your Invitation has been Sent',
    gu: 'તમારું આમંત્રણ મોકલાઈ ગયું છે',
    hi: 'आपका निमंत्रण भेज दिया गया है',
    kn: 'ನಿಮ್ಮ ಆಮಂತ್ರಣವನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ',
    mr: 'आपले आमंत्रण पाठविले गेले आहे',
    te: 'మీ ఆహ్వానం పంపబడినది',
  },
  INVITE: {
    en: 'Invite',
    gu: 'આમંત્રણ',
    hi: 'आमंत्रण',
    kn: 'ಆಹ್ವಾನಿಸಿ',
    mr: 'आमंत्रित करा',
    te: 'ఆహ్వానించు',
  },
  INVITE_A_LOCAL_SHOP: {
    en: 'Invite a local shop',
    gu: 'સ્થાનિક દુકાનને આમંત્રણ આપો',
    hi: 'स्थानीय दुकान को आमंत्रित करें',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿಯನ್ನು ಆಹ್ವಾನಿಸಿ',
    mr: 'स्थानिक दुकानाला आमंत्रित करा',
    te: 'మీ ప్రాంతంలోని షాపును ఆహ్వానించండి',
  },
  INVITE_SHOPS_TO_DELIVER_ONLINE: {
    en: 'Invite Shops to Deliver Online',
    gu: 'ઓનલાઇન પહોંચાડવા માટે દુકાનોને આમંત્રણ આપો',
    hi: 'ऑनलाइन पहुंचने के लिए दुकानों को आमंत्रित करें',
    kn: 'ಅಂಗಡಿಗಳು ಆನ್ಲೈನ್ ಡೆಲಿವರಿ ಆಹ್ವಾನಿಸಿ',
    mr: 'दुकानांना ऑनलाईन वितरणासाठी आमंत्रित करा',
    te: 'ఆన్లైన్ లో డెలివర్ చేయడానికి షాపులను ఆహ్వానించండి',
  },
  INVITED: {
    en: 'Invited',
    gu: 'આમંત્રિત',
    hi: 'आमंत्रित',
    kn: 'ಆಹ್ವಾನಿಸಲಾಗಿದೆ',
    mr: 'आमंत्रित केले',
    te: 'ఆహ్వానించబడ్డారు',
  },
  IN_LOCALITY: {
    en: 'in {locality}',
    gu: 'in {locality}',
    hi: 'in {locality}',
    kn: 'in {locality}',
    mr: 'in {locality}',
    te: 'in {locality}',
  },
  ITEM: {
    en: 'Item',
    gu: 'વસ્તુ',
    hi: 'चीज़',
    kn: 'ವಸ್ತು',
    mr: 'वस्तू',
    te: 'వస్తువు',
  },
  ITEM_TOTAL: {
    en: 'Item Total',
    gu: 'કુલ વસ્તુઓ',
    hi: 'कुल वस्तुएँ',
    kn: 'ವಸ್ತುಗಳ ಒಟ್ಟು',
    mr: 'एकूण वस्तू',
    te: 'వస్తువుల మోత్తం',
  },
  ITEMS: {
    en: 'Items',
    gu: 'વસ્તુઓ',
    hi: 'चीज़ें',
    kn: 'ವಸ್ತುಗಳು',
    mr: 'वस्तू',
    te: 'వస్తువులు',
  },
  JOIN_SHOP: {
    en: 'Join Shop',
    gu: 'દુકાનમાં જોડાઓ',
    hi: 'दुकान से जुड़ें',
    kn: 'ಅಂಗಡಿ ಸೇರಿ',
    mr: 'दुकानात सामील व्हा',
    te: 'షాపులో చేరండి',
  },
  LANDMARK: {
    en: 'Landmark',
    gu: 'સીમાચિહ્ન',
    hi: 'सीमा चिन्ह',
    kn: 'ಹೆಗ್ಗುರುತು',
    mr: 'खूण',
    te: 'లాండ్ మార్క్',
  },
  LAST_NAME: {
    en: 'Last Name',
    gu: 'સરનેમ',
    hi: 'सरनेम',
    kn: 'ಕೊನೆ ಹೆಸರು',
    mr: 'आडनाव',
    te: 'చివరి పేరు',
  },
  LEGAL: {
    en: 'Legal',
    gu: 'કાયદેસર',
    hi: 'कानूनी',
    kn: 'ಕಾನೂನು',
    mr: 'कायदेशीर',
    te: 'చట్టపరమైన',
  },
  LINKEDIN: {
    en: 'Linkedin',
    gu: 'લિંક્ડઈન',
    hi: 'लिंक्डइन',
    kn: 'ಲಿಂಕ್ಡಿನ್',
    mr: 'Linkedin',
    te: 'లింక్డ్ఇన్',
  },
  LIST_OF_ALL_YOUR_FAVOURITE_SHOPS: {
    en: 'List of all your favourite shops',
    gu: 'તમારી બધી મનપસંદ દુકાનની સૂચિ',
    hi: 'अपनी सभी पसंदीदा दुकानों की सूची',
    kn: 'ನಿಮ್ಮ ಎಲ್ಲಾ ನೆಚ್ಚಿನ ಅಂಗಡಿಯ ಪಟ್ಟಿ',
    mr: 'तुमच्या आवडीच्या दुकानांची यादी',
    te: 'మీకు ఇష్టమైన షాపుల జాబిత',
  },
  LOCALITY_NAME_IS_REQUIRED: {
    en: 'Need locality to continue',
    gu: 'Need locality to continue',
    hi: 'Need locality to continue',
    kn: 'Need locality to continue',
    mr: 'Need locality to continue',
    te: 'Need locality to continue',
  },
  LOCATE_ME: {
    en: 'Locate Me',
    gu: 'મને શોધો',
    hi: 'मुझे ढूढ़ें',
    kn: 'ನನ್ನನ್ನು ಪತ್ತೆ(ಲೋಕೇಟ್) ಮಾಡಿ',
    mr: 'माझे ठिकाण शोधा',
    te: 'నన్ను గుర్తించండి',
  },
  LOCATE_ME_ERROR: {
    en: 'Oops! Unable to locate you!',
    gu: 'અરેરે! તમને શોધવામાં અસમર્થ!',
    hi: 'ऊप्स! आपको ढूंढने में असमर्थ!',
    kn: 'ಅಯ್ಯೋ!ನಿಮ್ಮನ್ನು ಪತ್ತೆ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ',
    mr: 'ओ हो! तुमचे ठिकाण शोधणे अयशस्वी झाले!',
    te: 'oops! మిమ్మలిని గుర్తించలేక పోయాము !',
  },
  LOCATION: {
    en: 'Location',
    gu: 'લોકેશન',
    hi: 'लोकेशन',
    kn: 'ಸ್ಥಳ',
    mr: 'ठिकाण',
    te: 'లొకేషన్',
  },
  LOG_IN: {
    en: 'LOG IN',
    gu: 'લોગ ઈન કરો',
    hi: 'लॉग इन करें',
    kn: 'ಲಾಗ್ ಇನ್ ಆಗಿ',
    mr: 'लॉग इन',
    te: 'లాగ్ఇన్',
  },
  LOGIN_OR_SIGN_UP_TO_PLACE_ORDER: {
    en: 'Login or sign up to place your order',
    gu: 'તમારો ઓર્ડર આપવા માટે લોગ ઇન કરો અથવા સાઇન અપ કરો',
    hi: 'अपना ऑर्डर देने के लिए लॉग इन या साइन अप करें',
    kn: 'ನಿಮ್ಮ ಆದೇಶವನ್ನು ನೀಡಲು ಲಾಗಿನ್ ಮಾಡಿ ಅಥವಾ ಸೈನ್ ಅಪ್ ಮಾಡಿ',
    mr: 'ऑर्डर करण्यासाठी लॉग इन किंवा साइन अप करा',
    te: 'మీ ఆర్డర్ను పెట్టడానికి లాగ్ఇన్ లేదా సైన్అప్  అవ్వండి',
  },
  LOGOUT: {
    en: 'Logout',
    gu: 'લૉગ આઉટ',
    hi: 'लॉग आउट',
    kn: 'ಲಾಗ್ ಔಟ್',
    mr: 'बाहेर पडा',
    te: 'లాగ్ ఔట్',
  },
  LOVE_LOCAL: {
    en: 'LoveLocal',
    gu: 'લવલોકલ',
    hi: 'लवलोकल',
    kn: 'ಲವ್ ಲೋಕಲ್',
    mr: 'लवलोकल',
    te: 'లవ్ లోకల్',
  },
  LOVELOCAL_COUPON_APPLIED: {
    en: 'LoveLocal Coupon Applied',
    gu: 'લવલોકલ કૂપન લાગુ કર્યું',
    hi: 'लवलोकल कूपन लागू हुआ',
    kn: 'LoveLocal ಕೂಪನ್ ಅನ್ನು ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'LoveLocal कूपन लागू केले',
    te: 'LoveLocal కూపన్ వర్తించబడింది',
  },
  LOVELOCAL_COUPONS: {
    en: 'LoveLocal Coupons',
    gu: 'લવલોકલ કુપન્સ',
    hi: 'लवलोकल कूपन्स',
    kn: 'LoveLocal ಕೂಪನ್ ಗಳು',
    mr: 'LoveLocal कूपन',
    te: 'LoveLocal కూపన్లు',
  },
  LOVELOCAL_REWARD_APPLIED: {
    en: 'LoveLocal Reward Applied',
    gu: 'લવલોકલ રિવોર્ડ લાગુ કર્યું',
    hi: 'लवलोकल रिवॉर्ड लागू हुआ',
    kn: 'LoveLocal ಬಹುಮಾನ (ರಿವಾರ್ಡ್) ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'LoveLocal बक्षीस लागू केले',
    te: 'LoveLocal బహుమతి వర్తించబడింది',
  },
  LOVELOCAL_REWARDS: {
    en: 'Scratch Cards',
    gu: 'સ્ક્રેચ કાર્ડ્સ',
    hi: 'स्क्रैच कार्ड्स',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್‌ಗಳು',
    mr: 'स्क्रॅच कार्ड',
    te: 'స్క్రాచ్ కార్డ్ లు',
  },
  LOVELOCAL_SHOPS_IN_YOUR_AREA: {
    en: 'LoveLocal Shops in Your Area',
    gu: 'તમારા એરિયામાં લવલોકલ દુકાનો',
    hi: 'आपके एरिया में लवलोकल दुकानें',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ LoveLocal ಅಂಗಡಿಗಳು',
    mr: 'तुमच्या परिसरातील LoveLocal दुकाने',
    te: 'మీ ప్రాంతంలో ఉన్న LoveLocal షాపులు',
  },
  LOVELOCAL_SHOPS_IN_LOCALITY: {
    en: 'LoveLocal Shops in {locality}',
    gu: '{locality} માં લવલોકલ દુકાનો',
    hi: '{locality} में लवलोकल की दुकानें',
    kn: '{locality} ನಲ್ಲಿನ ಲವ್ ಲೋಕಲ್ ಅಂಗಡಿಗಳು',
    mr: '{locality} मधील LoveLocal दुकाने',
    te: '{locality} లోని LoveLocal షాపులు',
  },
  LOVELOCAL_SHOPS_NEAR_YOU: {
    en: 'LoveLocal Shops Near You',
    gu: 'તમારી નજીકની લવલોકલ દુકાનો',
    hi: 'आपके आस-पास की लवलोकल दुकानों',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ LoveLocal ಅಂಗಡಿಗಳು',
    mr: 'तुमच्या जवळील LoveLocal दुकाने',
    te: 'మీ దగ్గరలోని LoveLocal షాపులు',
  },
  LOVELOCAL_USER: {
    en: 'LoveLocal user',
    gu: 'લવલોકલ વપરાશકર્તા',
    hi: 'लवलोकल उपयोगकर्ता',
    kn: 'LoveLocal ಬಳಕೆದಾರರು',
    mr: 'LoveLocal वापरकर्ता',
    te: 'LoveLocal వినియోగదారు',
  },
  LOOKING_FOR_SOMETHING_ELSE: {
    en: 'Looking for Something else?',
    gu: 'બીજું કંઇક શોધી રહ્યાં છો?',
    hi: 'कुछ और खोज रहे हैं?',
    kn: 'ಮತ್ತೇನನ್ನಾದರೂ ಎದುರು ನೋಡುತ್ತಿದ್ದೀರಾ?',
    mr: 'दुसरे काहीतरी शोधत आहात?',
    te: 'ఇంకా దేనికోసమైనా చూస్తున్నారా?',
  },
  LOW_VARIETY_OF_PRODUCTS: {
    en: 'Low Variety of Products',
    gu: 'ઉત્પાદનોની ઓછી વેરાયટી',
    hi: 'उत्पाद की कम विविधता',
    kn: 'ಕಡಿಮೆ ವೈವಿಧ್ಯಮಯ ಉತ್ಪನ್ನಗಳು',
    mr: 'उत्पादनांची कमी विविधता',
    te: 'తక్కువ రకాల వస్తువులు',
  },
  LOYALTY_POINT_HISTORY: {
    en: 'Loyalty Point History',
    gu: 'વફાદારી બિંદુ ઇતિહાસ',
    hi: 'वफादारी बिंदु इतिहास',
    kn: 'ಲಾಯಲ್ಟಿ ಪಾಯಿಂಟ್ ಇತಿಹಾಸ (ಹಿಸ್ಟರಿ)',
    mr: 'निष्ठा गुण इतिहास',
    te: 'Loyalty Point History',
  },
  MAKE_PAYMENT: {
    en: 'Make Payment',
    gu: 'ચુકવણી કરો',
    hi: 'भुगतान करो',
    kn: 'ಪಾವತಿ ಮಾಡಿ',
    mr: 'पेमेंट करा',
    te: 'చెల్లింపులు చేయండి',
  },
  MANAGE_ADDRESSES: {
    en: 'Manage Addresses',
    gu: 'એડ્રેસ મેનેજ કરો',
    hi: 'एड्रेस मेनेज करें',
    kn: 'ನಿಮ್ಮ ವಿಳಾಸಗಳನ್ನು ನಿರ್ವಹಿಸಿ',
    mr: 'आपले पत्ते व्यवस्थापित करा.',
    te: 'అడ్రస్సులను సరిచూసుకోండి',
  },
  SAVED_ADDRESSES: {
    en: 'Saved Addresses',
    gu: 'એડ્રેસ મેનેજ કરો',
    hi: 'एड्रेस मेनेज करें',
    kn: 'ನಿಮ್ಮ ವಿಳಾಸಗಳನ್ನು ನಿರ್ವಹಿಸಿ',
    mr: 'आपले पत्ते व्यवस्थापित करा.',
    te: 'అడ్రస్సులను సరిచూసుకోండి',
  },
  MANAGE_DELIVERY_ADDRESS: {
    en: 'Manage your delivery addresses',
    gu: 'તમારું ડિલિવરી એડ્રેસ મેનેજ કરો',
    hi: 'अपना डिलीवरी एड्रेस मेनेज करें',
    kn: 'ನಿಮ್ಮ ಡೆಲಿವರಿ ವಿಳಾಸಗಳನ್ನು ನಿರ್ವಹಿಸಿ',
    mr: 'तुमचा वितरण पत्ता व्यवस्थापित करा',
    te: 'మీ డెలివేరి అడ్రస్సులను సరిచూసుకోండి',
  },
  MANAGE_YOUR_ADDRESS: {
    en: 'Manage Your Address',
    gu: 'તમારું એડ્રેસ મેનેજ કરો',
    hi: 'अपना एड्रेस मेनेज करें',
    kn: 'ನಿಮ್ಮ ವಿಳಾಸ ನಿರ್ವಹಿಸಿ',
    mr: 'आपला पत्ता व्यवस्थापित करा',
    te: 'మీ అడ్రసును సరిచూసుకోండి',
  },
  MIN_AMOUNT: {
    en: 'Min. Shopping Amount',
    gu: 'ન્યૂનતમ ખરીદીની રકમ',
    hi: 'न्यूनतम खरीदारी राशि',
    kn: 'ಕನಿಷ್ಠ ಶಾಪಿಂಗ್ ಮೊತ್ತ',
    mr: 'किमान खरेदी रक्कम',
    te: 'మీరు కొనవలసిన కనీస మోత్తం',
  },
  MIN_ORDER_VALUE: {
    en: 'Min. Order Value',
    gu: 'ન્યૂનતમ ઓર્ડર મૂલ્ય',
    hi: 'न्यूनतम ऑर्डर मूल्य',
    kn: 'ಕನಿಷ್ಠ ಆರ್ಡರ್ ಮೌಲ್ಯ',
    mr: 'किमान ऑर्डर मूल्य',
    te: 'కనీస ఆర్డర్ విలువ',
  },
  MIN_SPEND_AMOUNT: {
    en: 'Min. Spend Amount',
    gu: 'ન્યૂનતમ ખર્ચની રકમ',
    hi: 'न्यूनतम व्यय राशि',
    kn: 'ಕನಿಷ್ಠ ಖರ್ಚು ಮೊತ್ತ',
    mr: 'किमान खर्च रक्कम',
    te: 'మీరు ఖర్చుపెట్టిన కనీస మోత్తం',
  },
  MINIMUM_ORDER: {
    en: 'Minimum Order',
    gu: 'ન્યૂનતમ ઓર્ડર',
    hi: 'न्यूनतम ऑर्डर',
    kn: 'ಕನಿಷ್ಠ ಆರ್ಡರ್',
    mr: 'किमान ऑर्डर',
    te: 'కనీస ఆర్డర్',
  },
  MOBILE_NUMBER_SHOULD_CONTAIN_TEN_DIGITS: {
    en: 'Mobile Number should contain 10 digits',
    gu: 'મોબાઇલ નંબરમાં ૧૦ અંકો હોવા જોઈએ',
    hi: 'मोबाइल नंबर में १० अंक होने चाहिए',
    kn: 'ಮೊಬೈಲ್ ನಂಬರ್ 10 ಡಿಜಿಟ್ ಹೊಂದಿರಬೇಕು',
    mr: 'मोबाईल क्रमांक 10 अंकी असावा',
    te: 'మొబైలు నెంబరు 10 అంకెలలో ఉండవలెను',
  },
  MONEY: {
    en: 'Money',
    gu: 'પૈસા',
    hi: 'पैसे',
    kn: 'ಹಣ',
    mr: 'पैसे',
    te: 'సొమ్ము',
  },
  MORE_OFFERS: {
    en: 'More Offers',
    gu: 'વધુ ઓફર્સ',
    hi: 'अधिक ऑफ़र',
    kn: 'ಹೆಚ್ಚು ಆಫರ್ ಗಳು',
    mr: 'अधिक ऑफर्स',
    te: 'మరిన్ని  ఆఫర్లు',
  },
  MY_SHOP_STORY: {
    en: 'My Shop’s Story',
    gu: 'મારી દુકાનની સ્ટોરી',
    hi: 'मेरी दुकान की कहानी',
    kn: 'ನನ್ನ ಅಂಗಡಿಯ ಕಥೆ',
    mr: 'माझ्या दुकानाची गोष्ट',
    te: 'నా షాప్ కథ',
  },
  MRP: {
    en: 'MRP',
    gu: 'એમ આર પી',
    hi: 'एम आर पी',
    kn: 'ಎಂ.ಆರ್.ಪಿ',
    mr: 'MRP',
    te: 'MRP',
  },
  MYORDERS: {
    en: 'My Orders',
    gu: 'મારા ઓર્ડર',
    hi: 'मेरेऑर्डर',
    kn: 'ನನ್ನ ಆರ್ಡರ್ ಗಳು',
    mr: 'माझ्या ऑर्डर',
    te: 'నా ఆర్డర్లు',
  },
  NEW: {
    en: 'NEW',
    gu: 'નવું',
    hi: 'नवीन',
    kn: 'ಹೊಸ',
    mr: 'नवीन',
    te: 'క్రొత్త',
  },
  NEWLY_OPENED_SHOPS: {
    en: 'Newly Opened Shops',
    gu: 'નવી ખુલેલ દુકાનો',
    hi: 'नई खुली दुकानें',
    kn: 'ಹೊಸದಾಗಿ ತೆರೆದ ಅಂಗಡಿಗಳು',
    mr: 'नव्याने उघडलेल्या दुकाने',
    te: 'కొత్తగా తెరిచిన షాపులు',
  },
  PREMIUM_SHOPS: {
    en: 'Premium Shops',
    gu: 'પ્રીમિયમ દુકાનો',
    hi: 'प्रीमियम दुकानें',
    kn: 'ಪ್ರೀಮಿಯಂ ಅಂಗಡಿಗಳು',
    mr: 'प्रीमियम दुकाने',
    te: 'ప్రీమియం దుకాణాలు',
  },
  PREMIUM_SHOPS_HEADING: {
    en: 'Featured Shops Around You',
    gu: 'તમારી આસપાસ વૈશિષ્ટિકૃત દુકાનો',
    hi: 'आपके आस-पास के चुनिंदा दुकानें',
    kn: ' ನಿಮ್ಮ ಸುತ್ತಲೂ ವೈಶಿಷ್ಟ್ಯಗೊಳಿಸಿದ ಅಂಗಡಿಗಳು',
    mr: 'तुमच्या आसपास वैशिष्ट्यीकृत दुकाने',
    te: 'మీ చుట్టూ ఉన్న ప్రత్యేక దుకాణాలు',
  },
  PREMIUM_SHOPS_SUBHEADING: {
    en: 'Discover Shops with the Best Service Quality Around You',
    gu: 'તમારી આસપાસ શ્રેષ્ઠ સેવા ગુણવત્તા સાથે દુકાનો શોધો',
    hi: 'अपने आस-पास सर्वोत्तम सेवा वाली दुकानें खोजें',
    kn: 'ನಿಮ್ಮ ಸುತ್ತಮುತ್ತಲಿನ ಅತ್ಯುತ್ತಮ ಸೇವೆಯ ಗುಣಮಟ್ಟದೊಂದಿಗೆ ಅಂಗಡಿಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    mr: 'तुमच्या सभोवतालच्या सर्वोत्तम सेवा गुणवत्तेसह दुकाने शोधा',
    te: 'మీ చుట్టూ ఉన్న అత్యుత్తమ సేవా నాణ్యతతో దుకాణాలను కనుగొనండి',
  },
  NEXT: {
    en: 'Next',
    gu: 'પછીનું',
    hi: 'अगला',
    kn: 'ಮುಂದೆ',
    mr: 'पुढील',
    te: 'తరువాత',
  },
  NO: {
    en: 'No',
    gu: 'ના',
    hi: 'नहीं',
    kn: 'ಇಲ್ಲ',
    mr: 'नाही',
    te: 'వద్దు',
  },
  NO_AVAILABILITY_OF_OFFERS: {
    en: 'No Availability of Offers',
    gu: 'ઓફર્સ ઉપલબ્ધ નથી',
    hi: 'ऑफर्स  उपलब्ध नहीं है',
    kn: 'ಕೊಡುಗೆಗಳ ಲಭ್ಯತೆ ಇಲ್ಲ',
    mr: 'ऑफर उपलब्ध नाहीत',
    te: 'ఆఫర్ల లభ్యత లేదు',
  },
  NO_DELIVERY: {
    en: 'No Delivery',
    gu: 'ડિલિવરી નહીં',
    hi: 'डिलीवरी नहीं',
    kn: 'ಡೆಲಿವರಿ ಇಲ್ಲ',
    mr: 'वितरण नाही',
    te: 'డెలివేరి వద్దు',
  },
  NO_LOVELOCAL_SHOPS_DES: {
    en: 'No LoveLocal shops here yet :(',
    gu: 'હજી સુધી અહીં કોઈ લવલોકલ દુકાનો નથી :(',
    hi: 'अभी तक यहाँ कोई लवलोकल दुकानें नहीं हैं :(',
    kn: 'ಇಲ್ಲಿ ಸದ್ಯಕ್ಕೆ ಯಾವುದೇ LoveLocal ಅಂಗಡಿಗಳಿಲ್ಲ :(',
    mr: 'अद्याप इथे कोणतेही LoveLocal दुकाने नाहीत:(',
    te: 'ఇక్కడ ఇంకా LoveLocal షాప్ లు లేవు :(',
  },
  NO_OFFERS_AVAILABLE: {
    en: 'No Offers Available',
    gu: 'ઓફર્સ ઉપલબ્ધ નથી',
    hi: 'ऑफर्स  उपलब्ध नहीं है',
    kn: 'ಯಾವುದೇ ಕೊಡುಗೆಗಳು ಲಭ್ಯವಿಲ್ಲ',
    mr: 'ऑफर उपलब्ध नाहीत',
    te: 'ఆఫర్లు ఏవి అందుబాటులో లేవు',
  },
  NO_OFFERS_FOUND: {
    en: 'No offers running at the moment',
    gu: 'અત્યારે કોઈ ઓફર ચાલુ નથી',
    hi: 'फिलहाल कोई ऑफर नहीं चल रहा है',
    kn: 'ಈ ಸಮಯದಲ್ಲಿ ಯಾವುದೇ ಆಫರ್ ಗಳು ಚಾಲ್ತಿಯಲ್ಲಿಲ್ಲ',
    mr: 'सध्या कोणत्याही ऑफर उपलब्ध नाहीत',
    te: 'ప్రస్తుతం  ఆఫర్లు ఏవీ లేవు',
  },
  NO_OFFERS_SUBHEADING: {
    en: 'But check back in a few days,our retailers provide some of the best offers out there.',
    gu: 'પરંતુ થોડા દિવસોમાં પાછા તપાસો, અમારા રિટેલરો ત્યાં કેટલીક શ્રેષ્ઠ ઓફર આપે છે.',
    hi: 'लेकिन कुछ दिनों में वापस देखें, हमारे रिटेलरों वहां कुछ बेहतरीन ऑफर देंते हैं।',
    kn: 'ಆದರೆ ಕೆಲವೇ ದಿನಗಳಲ್ಲಿ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ, ನಮ್ಮ ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿಗಳು ಅಲ್ಲಿ ಕೆಲವು ಉತ್ತಮ ಕೊಡುಗೆಗಳನ್ನು ಒದಗಿಸುತ್ತಾರೆ.',
    mr: 'परंतु काही दिवसात परत तपासा, आमचे विक्रेते उत्कृष्ट ऑफर देतील.',
    te: 'కొన్ని రోజులలో తిరిగి చూడండి, మా రిటైలర్లు అక్కడ మంచి ఆఫర్లు పెడతారు.',
  },
  NO_ORDERS_FOUND: {
    en: "You haven't placed an order as yet",
    gu: 'તમે હજી સુધી કોઈ ઓર્ડર આપ્યો નથી',
    hi: 'आपने अभी तक कोई ऑर्डर नहीं दिया है',
    kn: 'ನೀವು ಇನ್ನೂ ಆರ್ಡರ್ ಅನ್ನು ನೀಡಿಲ್ಲ',
    mr: 'तुम्ही अद्याप कोणतीही ऑर्डर दिलेली नाही.',
    te: 'మీరు ఇంకా ఆర్డర్ పెట్టలేదు',
  },
  NO_ORDERS_SUBHEADING: {
    en: "Let's start by picking the products you would like today.",
    gu: 'ચાલો આજે તમને ગમતા ઉત્પાદનોને પસંદ કરીને શરુ કરીએ.',
    hi: 'आइए आज उन उत्पादों को चुनकर शुरू करें जिन्हें आप पसंद करते हैं।',
    kn: 'ಇಂದು ನೀವು ಬಯಸುವ ಉತ್ಪನ್ನಗಳನ್ನು ಆರಿಸುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸೋಣ.',
    mr: 'तुमच्या आवडीची उत्पादने निवडून सुरुवात करुया.',
    te: 'ఈ రోజు మీకు నచ్చిన వస్తువులను ఎంచుకోవడం ప్రారంభించండి',
  },
  NO_PHONE_NUMBER: {
    en: 'No Phone Number!',
    gu: 'ફોન નંબર નથી!',
    hi: 'फ़ोन नंबर नहीं है!',
    kn: 'ಫೋನ್ ನಂಬರ್ ಇಲ್ಲ!',
    mr: 'फोन नंबर नाही!',
    te: 'ఫోన్ నెంబరు లేదు !',
  },
  NO_PRODUCTS_FOUND: {
    en: 'No Products Found',
    gu: 'કોઈ ઉત્પાદનો મળ્યાં નથી',
    hi: 'कोई उत्पाद नहीं मिला',
    kn: 'ಉತ್ಪನ್ನ ಕಂಡುಬಂದಿಲ್ಲ',
    mr: 'कोणतीही उत्पादने सापडली नाहीत.',
    te: 'వస్తువులు లభించలేదు',
  },
  NO_RATINGS: {
    en: 'No ratings',
    gu: 'કોઈ રેટિંગ્સ નથી',
    hi: 'कोई रेटिंग नहीं',
    kn: 'ರೇಟಿಂಗ್ ಗಳು ಇಲ್ಲ',
    mr: 'मूल्यांकन नाही',
    te: 'రేటింగ్స్ లేవు',
  },
  NO_RESULTS_FOUND: {
    en: 'Sorry, No Results Found',
    gu: 'માફ કરશો, કોઈ પરિણામ મળ્યા નથી',
    hi: 'माफ करना, कोई नतीजे नही मिले',
    kn: 'ಕ್ಷಮಿಸಿ, ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ',
    mr: 'माफ करा, काहीही सापडले नाही',
    te: 'క్షమించండి, ఎటువంటి ఫలితాలు లభించలేదు',
  },
  NO_SEARCH_RESULTS_FOUND: {
    en: 'No result found. Try searching something else.',
    gu: 'કોઈ પરિણામ મળ્યું નથી. બીજું કંઈક શોધવાનો પ્રયાસ કરો.',
    hi: 'कोई परिणाम नहीं मिला। कुछ और खोजने का प्रयास करें।',
    kn: 'ಯಾವುದೇ ಫಲಿತಾಂಶ ಕಂಡುಬಂದಿಲ್ಲ. ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಹುಡುಕಲು ಪ್ರಯತ್ನಿಸಿ.',
    mr: 'कोणताही परिणाम आढळला नाही. दुसरे काहीतरी शोधण्याचा प्रयत्न करा.',
    te: 'ఫలితం కనుగొనబడలేదు. వేరొకదానిని వెతకడానికి ప్రయత్నించండి.',
  },
  NO_SHOPS_FOUND: {
    en: 'No Shops Found',
    gu: 'કોઈ દુકાનો મળી નથી',
    hi: 'कोई दुकानें नहीं मिलीं',
    kn: 'ಯಾವುದೇ ಅಂಗಡಿಗಳು ಕಂಡುಬಂದಿಲ್ಲ',
    mr: 'कोणतीही दुकाने सापडली नाहीत',
    te: 'షాపులేవీ కనబడలేదు',
  },
  NO_TOUCH_PICKUP: {
    en: 'No-touch pickup from shop',
    gu: 'દુકાનેથી નો-ટચ પિકઅપ',
    hi: 'दुकान से नो-टच पिकअप',
    kn: 'ಅಂಗಡಿಯಿಂದ ಸ್ಪರ್ಶರಹಿತ ತೆಗೆದುಕೊಳ್ಳುವಿಕೆ',
    mr: 'दुकानातून स्पर्श रहित उचल',
    te: 'దుకాణంనుంచి నో-టచ్ పికప్',
  },
  NO_TOUCH_PICKUP_SUB_TEXT: {
    en: 'Pick the parcel from the shop at your convenience.',
    gu: 'તમારી અનુકૂળતાએ દુકાનમાંથી પાર્સલ લઈ જાઓ',
    hi: 'अपनी सुविधानुसार दुकान से पार्सल उठाएँ।',
    kn: 'ಪಾರ್ಸೆಲ್ ಅನ್ನು ನಿಮಗೆ ಅನುಕೂಲವಾದ ಅಂಗಡಿಯಿಂದ ತೆಗೆದುಕೊಳ್ಳಿ',
    mr: 'तुमच्या सोयीनुसार दुकानातून पार्सल घ्या.',
    te: 'మీకు వీలైనప్పుడు దుకాణంనుంచి పార్సెల్ ను తీసుకోండి',
  },
  NOT_SERVICING_IN_YOUR_AREA: {
    en: 'NOT SERVICING IN YOUR AREA',
    gu: 'તમારા એરિયામાં સર્વિસ આપતા નથી',
    hi: 'आपके ऐरिया में सर्विस उपलब्ध नहीं है',
    kn: 'ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಸೇವೆ ಮಾಡುತ್ತಿಲ್ಲ',
    mr: 'आपल्या क्षेत्रात सेवा उपलब्ध नाही.',
    te: 'మీ ప్రాంతంలో సేవలు అందించుట లేదు',
  },
  NOT_VERY_HELPFUL: {
    en: 'Not very helpful',
    gu: 'ખૂબ મદદરૂપ નથી',
    hi: 'बहुत मददगार नहीं',
    kn: 'ಹೆಚ್ಚು ಸಹಾಯಕವಾಗುವುದಿಲ್ಲ',
    mr: 'उपयुक्त नाही',
    te: 'అంత సహాయకరంగా లేదు',
  },
  NOTE: {
    en: 'Note',
    gu: 'નોંધ',
    hi: 'ध्यान दें',
    kn: 'ಸೂಚನೆ',
    mr: 'टीप',
    te: 'గమనిక',
  },
  NOTE_NO_PAYMENT_THIS_MOMENT: {
    en: 'Note: Payment will not be taken at this moment',
    gu: 'નોંધ: અત્યારે ચુકવણી લેવામાં આવશે નહીં',
    hi: 'ध्यान दें : इस समय भुगतान नहीं लिया जाएगा',
    kn: 'ಸೂಚನೆ: ಈ ಕ್ಷಣದಲ್ಲಿ ಪಾವತಿಯನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ',
    mr: 'टीप: यावेळी पेमेंट यावेळी स्वीकारता येणार नाही.',
    te: 'గమనిక : ప్రస్తుతం చెల్లింపులు తీసుకొనబడవు',
  },
  NOTE_TO_RETAILER: {
    en: 'Your note to the retailer',
    gu: 'રિટેલરને તમારી નોંધ',
    hi: 'रिटेलर को आपका नोट',
    kn: 'ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿಗೆ ನಿಮ್ಮ ಸೂಚನೆ',
    mr: 'तुमची विकेत्यासाठी टीप',
    te: 'రిటైలర్ కు మీ సూచన',
  },
  NOTIFICATIONS: {
    en: 'Notifications',
    gu: 'સૂચનાઓ',
    hi: 'सूचनाएं',
    kn: 'ನೋಟಿಫಿಕೇಶನ್ಸ್',
    mr: 'सूचना',
    te: 'నోటిఫికేషన్లు',
  },
  OFF: {
    en: 'Off',
    gu: 'બંધ',
    hi: 'बंध',
    kn: 'ಆಫ್',
    mr: 'सूट',
    te: 'ఆఫ్',
  },
  OFFER: {
    en: 'Offer',
    gu: 'ઓફર',
    hi: 'ऑफर',
    kn: 'ಆಫರ್',
    mr: 'ऑफर',
    te: 'ఆఫర్',
  },
  OFFER_ALERT: {
    en: 'Offer Alert',
    gu: 'ઓફર ચેતવણી',
    hi: 'ऑफर चेतावनी',
    kn: 'ಎಚ್ಚರಿಕೆ ನೀಡಿ',
    mr: 'ऑफर सूचना',
    te: 'ఆఫర్ అలర్ట్',
  },
  OFFER_APPLIED: {
    en: 'Offer applied!',
    gu: 'ઓફર લાગુ!',
    hi: 'ऑफर लागू!',
    kn: 'ಆಫರ್ ಅನ್ವಯಿಸಲಾಗಿದೆ!',
    mr: 'ऑफर लागू केली',
    te: 'ఆఫర్ వర్తించబడినది!',
  },
  OFFER_APPLIED_DESC: {
    en: 'This offer has been successfully applied. Enjoy!',
    gu: 'આ ઓફર સફળતાપૂર્વક લાગુ કરવામાં આવી છે. આનંદ કરો!',
    hi: 'यह ऑफ़र सफलतापूर्वक लागू किया गया है। आनंद लें!',
    kn: 'ಈ ಆಫರ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ. ಆನಂದಿಸಿ!',
    mr: 'ही ऑफर यशस्वीरित्या लागू केली गेली आहे. आनंद घ्या!',
    te: 'ఈ ఆఫర్ విజయవంతంగా  వర్తించబడినది. ఆశ్వాదించండి!',
  },
  OFFER_APPLIED_ON_BIL: {
    en: 'Offer applied on the bill',
    gu: 'ઓફર બિલ પર લાગુ',
    hi: 'ऑफर बिल पर लागू',
    kn: 'ಬಿಲ್ನಲ್ಲಿ ಆಫರ್ ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'बिलावर ऑफर लागू केली आहे',
    te: 'బిల్లుకు ఆఫర్ వర్తించబడినది',
  },
  OFFER_AT: {
    en: 'Offer at {shopName}',
    gu: '{shopName} પર ઓફર',
    hi: '{shopName} पर ऑफ़र',
    kn: '{shopName} ನಲ್ಲಿ ಆಫರ್',
    mr: '{shopName} येथील ऑफर',
    te: '{shopName} లో ఆఫర్',
  },
  OFFER_PRICE: {
    en: 'Offer Price',
    gu: 'ઓફર પ્રાઈઝ',
    hi: 'ऑफर प्राइज़',
    kn: 'ಆಫರ್ ಬೆಲೆ',
    mr: 'ऑफर किंमत',
    te: 'ఆఫర్ ధర',
  },
  OFFER_REMOVED: {
    en: 'Offer removed!',
    gu: 'ઓફર કાઢી નાખી!',
    hi: 'ऑफ़र हटाया गया!',
    kn: 'ಆಫರ್ ತೆಗೆದುಹಾಕಲಾಗಿದೆ!',
    mr: 'ऑफर काढली',
    te: 'ఆఫర్ తొలిగించబడినది !',
  },
  OFFER_REMOVED_DESC: {
    en: 'This offer has been removed.',
    gu: 'આ ઓફર કાઢી નાખવામાં આવી છે.',
    hi: 'इस ऑफर को हटा दिया गया है।',
    kn: 'ಈ ಆಫರ್ ಅನ್ನು ತೆಗೆದುಹಾಕಲಾಗಿದೆ.',
    mr: 'ही ऑफर काढली आहे.',
    te: 'ఈ ఆఫర్ తొలిగించబడినది',
  },
  OFFERS: {
    en: 'Offers',
    gu: 'ઓફર્સ',
    hi: 'ऑफर्स',
    kn: 'ಆಫರ್ ಗಳು',
    mr: 'ऑफर',
    te: 'ఆఫర్లు',
  },
  OFFERS_AVAILABLE: {
    en: 'offers are available',
    gu: 'ઓફર્સ ઉપલબ્ધ છે',
    hi: 'ऑफर्स उपलब्ध हैं',
    kn: 'ಆಫರ್ ಗಳು ಲಭ್ಯವಿದೆ',
    mr: 'ऑफर उपलब्ध आहेत',
    te: 'ఆఫర్లు అందుబాటులోఉన్నాయి',
  },
  OFFERS_FROM_SHOP: {
    en: 'Offers from your local shop',
    gu: 'તમારી સ્થાનિક દુકાનમાંથી ઓફર્સ',
    hi: 'अपने स्थानीय दुकान से ऑफर',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳು',
    mr: 'तुमच्या स्थानिक दुकानातील ऑफर',
    te: 'మీ స్థానిక దుకాణాల్లో ఆఫర్లు',
  },
  OH_NO: {
    en: 'Oh no! Something broke',
    gu: 'અરે નહિ! કંઈક તૂટી ગયું',
    hi: 'अरे नहीं! कुछ टूट गया',
    kn: 'ಓಹ್ ಇಲ್ಲ!  ಏನೋ ಮುರಿಯಿತು',
    mr: 'अरेरे! काहीतरी समस्या!',
    te: 'Oh no! Something broke',
  },
  OK_GOT_IT: {
    en: 'OKAY, GOT IT',
    gu: 'ઠીક છે, સમજાઈ ગયું',
    hi: 'ठीक है समझ आ गया',
    kn: 'ಸರಿ, ಅರ್ಥವಾಯಿತು',
    mr: 'ठीक आहे, समजले.',
    te: 'సరే, అర్థమైంది',
  },
  OKAY: {
    en: 'Okay',
    gu: 'ઠીક છે',
    hi: 'ठीक है',
    kn: 'ಸರಿ',
    mr: 'ठीक आहे',
    te: 'సరే',
  },
  OKAY_CHANGE_LOCATION: {
    en: 'Okay, Change Location',
    gu: 'ઠીક છે, લોકેશન બદલો',
    hi: 'ठीक है, लोकेशन बदलें',
    kn: 'ಸರಿ,ಸ್ಥಳ ಬದಲಾಯಿಸಿ',
    mr: 'ठीक आहे, ठिकाण बदला.',
    te: 'సరే , లొకేషన్ మార్చండి',
  },
  OKAY_DELETE_LOCATION: {
    en: 'Okay, Delete Location',
    gu: 'ઠીક છે, ડીલીટ લોકેશન',
    hi: 'ठीक है, डिलीट लोकेशन',
    kn: 'ಸರಿ,ಸ್ಥಳ ಅಳಿಸಿ',
    mr: 'ठीक आहे, ठिकाण काढा',
    te: 'సరే , లొకేషన్ తొలిగించండి',
  },
  ONBOARDING_HEADING_1: {
    en: 'Easy & Convenient',
    gu: 'સરળ અને અનુકૂળ',
    hi: 'आसान और सुविधाजनक',
    kn: 'ಸುಲಭ ಮತ್ತು ಅನುಕೂಲಕರ',
    mr: 'सोपे आणि सुलभ',
    te: 'సులభమైన మరియు సౌకర్యవంతమైన',
  },
  ONBOARDING_TEXT_1: {
    en: 'Order Online from your trusted local shops',
    gu: 'તમારી વિશ્વસનીય સ્થાનિક દુકાનમાંથી ઓનલાઇન ઓર્ડર કરો',
    hi: 'अपने विश्वसनीय स्थानीय दुकानों से ऑनलाइन ऑर्डर करें',
    kn: 'ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳಿಂದ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಆರ್ಡರ್ ಮಾಡಿ',
    mr: 'तुमच्या विश्वासू स्थानिक दुकानातून ऑनलाईन ऑर्डर करा',
    te: 'మీరు నమ్మిన స్థానిక దుకాణాల నుండి ఆన్లైన్ లో ఆర్డర్ పెట్టండి',
  },
  ONCE_YOU_FAVOURITE_A_SHOP_IT_WILL_APPEAR_HERE: {
    en: 'Once you favourite a shop,it will appear here',
    gu: 'એકવાર તમે કોઈ દુકાન પસંદ કરશો, તે અહીં દેખાશે',
    hi: 'एक बार जब आप किसी दुकान को पसंद करते हैं, तो वो यहां दिखाई देगी',
    kn: 'ಒಮ್ಮೆ ನೀವು ಅಂಗಡಿಯನ್ನು ಫೇವರಿಟ್ ಮಾಡಿದರೆ, ಅದು ಇಲ್ಲಿ ಕಾಣಿಸುತ್ತದೆ',
    mr: 'तुम्ही एखादे आवडीचे दुकान निवडल्यानंतर इथे दिसेल',
    te: 'మీరు వకసారి షాప్ ను ఇష్టపడితే , అది ఇక్కడ కనిపిస్తుంది',
  },
  ONE_OR_MORE_SHOPS_NOT_DOING_HOME_DELIVERY: {
    en: 'One or more shops aren’t doing home delivery.',
    gu: 'એક અથવા વધુ દુકાનો હોમ ડિલિવરી કરી રહી નથી.',
    hi: 'एक या अधिक दुकानें होम डिलीवरी नहीं कर रही हैं।',
    kn: 'ಒಂದು ಅಥವಾ ಅಧಿಕ ಶಾಪ್ ಗಳು ಮನೆಗೆ ಡೆಲಿವರಿ ಮಾಡುತ್ತಿಲ್ಲ.',
    mr: 'एक किंवा अधिक दुकाने घरपोच वितरण करत नाहीत',
    te: 'వొకటి లేక అంతకంటే ఎక్కువ షాపులు ఇంటికి డెలివేరి చేయుటలేదు',
  },
  ONE_SCRATCH_CARD_EXPIRES_TODAY_HURRY: {
    en: '1 Scratch Card expires today. Hurry!',
    gu: '૧ સ્ક્રેચ કાર્ડ આજે સમાપ્ત થાય છે. જલ્દી કરો!',
    hi: '१ स्क्रेच कार्ड आज समाप्त हो रहा हे। जल्दी कीजिये!',
    kn: '1 ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಇಂದು ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ.  ತ್ವರೆ ಮಾಡಿ!',
    mr: '1 स्क्रॅच कार्डची वैधता आज संपेल, त्वरा करा!',
    te: 'త్వరపడండి !1 స్క్రాచ్ కార్డ్  ఈ రోజుతో ముగుస్తుంది',
  },
  ONLINE_PAYMENT: {
    en: 'Online Payment',
    gu: 'ઓનલાઇન ચુકવણી',
    hi: 'भुगतान ऑनलाइन',
    kn: 'ಆನ್ಲೈನ್ ಪಾವತಿ',
    mr: 'ऑनलाईन पेमेंट',
    te: 'ఆన్లైన్ చెల్లింపులు',
  },
  OOPS: {
    en: 'Oops! Something went wrong. Please try again.',
    gu: 'અરેરે! કંઈક ખોટું થયું. મહેરબાની કરીને ફરીથી પ્રયતન કરો.',
    hi: 'ऊप्स! कुछ गलत हो गया। कृपया फिर से प्रयास करें।',
    kn: 'ಅಯ್ಯೋ!  ಏನೋ ತಪ್ಪಾಗಿದೆ.  ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ.',
    mr: 'अरेरे! काहीतरी समस्या आहे. कृपया पुन्हा प्रयत्न करा.',
    te: 'అయ్యో! ఎక్కడో పొరపాటు జరిగినది. దయచేసి మళ్ళీ ప్రయత్నించండి.',
  },
  OPEN: {
    en: 'Open',
    gu: 'ખુલ્લા',
    hi: 'खुला हुआ',
    kn: 'ತೆರೆಯಿರಿ',
    mr: 'उघडे आहे',
    te: 'తెరచి',
  },
  OPTIONAL: {
    en: 'optional',
    gu: 'વૈકલ્પિક',
    hi: 'वैकल्पिक',
    kn: 'ಐಚ್ಛಿಕ',
    mr: 'पर्यायी',
    te: 'ఐచ్చికం',
  },
  BUY_AGAIN: {
    en: 'Buy Again',
    gu: 'ફરીથી ખરીદો',
    hi: 'फिर से खरीदें',
    kn: 'ಮತ್ತೆ ಖರೀದಿಸಿ',
    mr: 'पुन्हा खरेदी करा',
    te: 'మళ్లీ కొనండి',
  },
  ORDER_CANCELLED: {
    en: 'Order Cancelled',
    gu: 'ઓર્ડર રદ કરાયો',
    hi: 'ऑर्डर रद्द होगया',
    kn: 'ಆರ್ಡರ್ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ',
    mr: 'ऑर्डर रद्द केली',
    te: 'ఆర్డర్ రద్దు చేయబడినది',
  },
  ORDER_DELIVERY_SHOPS: {
    en: 'Your order would be delivered from {shopCount} shops',
    gu: 'તમારો ઓર્ડર {shopCount} દુકાનોમાંથી આપવામાં આવશે',
    hi: 'आपका ऑर्डर {shopCount} दुकानों से दिया जाएगा',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು {shopCount} ಅಂಗಡಿಗಳಿಂದ ತಲುಪಿಸಲಾಗುತ್ತದೆ',
    mr: 'तुमची ऑर्डर {shopCount} दुकानातून वितरित केली जाईल',
    te: 'మీ ఆర్డర్ {shopCount} షాపుల నుండి పంపబడును',
  },
  ORDER_DETAILS: {
    en: 'Order Details',
    gu: 'ઓર્ડરની વિગતો',
    hi: 'ऑर्डर का विवरण',
    kn: 'ಆರ್ಡರ್ ವಿವರಗಳು',
    mr: 'ऑर्डर ची माहिती',
    te: 'ఆర్డర్ వివరాలు',
  },
  ORDER_SUMMERY: {
    en: 'Order Summary',
    gu: 'ઓર્ડર સારાંશ',
    hi: 'ऑर्डर सारांश',
    kn: 'ಆದೇಶ ಸಾರಾಂಶ',
    mr: 'ऑर्डर सारांश',
    te: 'కొనుగోలు వివరణ',
  },
  ORDER_FROM_YOUR_LOCAL_SHOPS: {
    en: 'Order from your local shops',
    gu: 'તમારી સ્થાનિક દુકાનમાંથી ઓર્ડર કરો',
    hi: 'अपनी स्थानीय दुकानों से ऑर्डर करें',
    kn: 'ನಿಮ್ಮ ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳಿಂದ ಆರ್ಡರ್ ಮಾಡಿ',
    mr: 'स्थानिक दुकानातून ऑर्डर करा',
    te: 'మీ స్థానిక దుకాణాల నుండి ఆర్డర్ చెయ్యండి',
  },
  ORDER_ID: {
    en: 'Order ID',
    gu: 'ઓર્ડર આઈડી',
    hi: 'ऑर्डर आईडी',
    kn: 'ಆರ್ಡರ್ ಐಡಿ',
    mr: 'ऑर्डर आयडी',
    te: 'ఆర్డర్ ID',
  },
  ORDER_MODIFICATIONS: {
    en: 'Order Modifications',
    gu: 'ઓર્ડરમાં ફેરફાર',
    hi: 'ऑर्डरमें बदलाव',
    kn: 'ಆರ್ಡರ್ ಮಾರ್ಪಾಡುಗಳು',
    mr: 'ऑर्डर मध्ये बदल',
    te: 'ఆర్డర్ లో మార్పులు',
  },
  ORDER_HAS_BEEN_MODIFIED: {
    en: 'Your order has been modified.',
    gu: 'તમારા ઓર્ડરમાં ફેરફાર કરવામાં આવ્યો છે.',
    hi: 'आपके ऑर्डर को संशोधित कर दिया गया है।',
    kn: 'ನಿಮ್ಮ ಆದೇಶವನ್ನು ಮಾರ್ಪಡಿಸಲಾಗಿದೆ.',
    mr: 'तुमची ऑर्डर सुधारली गेली आहे.',
    te: 'మీ ఆర్డర్ సవరించబడింది.',
  },
  PLEASE_REVIEW_IT: {
    en: 'Please review it.',
    gu: 'કૃપા કરીને તેની સમીક્ષા કરો.',
    hi: 'कृपया इसकी समीक्षा करें।',
    kn: 'ದಯವಿಟ್ಟು ಅದನ್ನು ಪರಿಶೀಲಿಸಿ.',
    mr: 'कृपया त्याचे पुनरावलोकन करा.',
    te: 'దయచేసి దానిని సమీక్షించండి.',
  },
  MODIFIED_ITEMS: {
    en: 'Modified Items',
    gu: 'સંશોધિત વસ્તુઓ',
    hi: 'संशोधित आइटम',
    kn: 'ಮಾರ್ಪಡಿಸಿದ ವಸ್ತುಗಳು',
    mr: 'सुधारित आयटम',
    te: 'సవరించిన అంశాలు',
  },
  ORDER_NOW: {
    en: 'Order Now',
    gu: 'અત્યારે ઓર્ડર કરો',
    hi: 'अभी ऑर्डर करें',
    kn: 'ಈಗ ಆರ್ಡರ್ ಮಾಡಿ',
    mr: 'आता ऑर्डर करा',
    te: 'ఇప్పుడే ఆర్డర్ చెయ్యండి',
  },
  ORDER_UPDATED: {
    en: 'Your Order has been updated,',
    gu: 'તમારો ઓર્ડર અપડેટ કરવામાં આવ્યો છે,',
    hi: 'आपका ऑर्डर अपडेट कर दिया गया है,',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ನವಿಕರಿಸಲಾಗಿದೆ',
    mr: 'तुमची ऑर्डर अद्ययावत केली आहे,',
    te: 'మీ ఆర్డర్ అప్ డేట్ చేయబడినది',
  },
  ORDERS: {
    en: 'Orders',
    gu: 'ઓર્ડર્સ',
    hi: 'ऑर्डर्स',
    kn: 'ಆರ್ಡರ್ ಗಳು',
    mr: 'ऑर्डर',
    te: 'ఆర్డర్లు',
  },
  OTP_IS_REQUIRED: {
    en: 'OTP is required',
    gu: 'ઓટીપી જરૂરી છે',
    hi: 'ओटीपी की आवश्यकता है',
    kn: 'OTP ಅಗತ್ಯವಿದೆ',
    mr: 'OTP आवश्यक आहे.',
    te: 'OTP అవసరము',
  },
  OTP_SHOULD_CONTAIN_FOUR_DIGITS: {
    en: 'OTP should contain 4 digits',
    gu: 'ઓટીપીમાં 4 અંકો હોવા જોઈએ',
    hi: 'ओटीपी में 4 अंक होने चाहिए',
    kn: 'OTP 4 ಡಿಜಿಟ್ ಹೊಂದಿರಬೇಕು',
    mr: 'OTP मध्ये 4 अंक असावेत',
    te: 'OTP 4 అంకెలలో ఉండవలెను',
  },
  OUT_FOR_DELIVERY: {
    en: 'Out for Delivery',
    gu: 'ડીલિવરી માટે નીકળી ગયેલ છે',
    hi: 'वितरण के लिए निकल चुका',
    kn: 'ಡೆಲಿವರಿಗೆ ಕಳುಹಿಸಲಾಗಿದೆ',
    mr: 'वितरणासाठी बाहेर',
    te: 'డెలివరీ చేసేందుకు తీసుకువస్తున్నారు',
  },
  OUT_OF_STOCK: {
    en: 'Out of Stock',
    gu: 'સ્ટોકમાં નથી',
    hi: 'स्टॉक में नहीं है',
    kn: 'ಸ್ಟಾಕ್ ಇಲ್ಲ',
    mr: 'उपलब्ध नाही',
    te: 'సరుకు అందుబాటులో లేదు',
  },
  PACKED: {
    en: 'Packed',
    gu: 'પેક્ડ',
    hi: 'पैक्ड',
    kn: 'ಪ್ಯಾಕ್ ಮಾಡಿದೆ',
    mr: 'पॅक केले आहे',
    te: 'ప్యాక్ చేయబడినది',
  },
  PAID: {
    en: 'PAID',
    gu: 'ચૂકવેલ',
    hi: 'भुगतान किया है',
    kn: 'ಪಾವತಿಸಲಾಗಿದೆ',
    mr: 'पेमेंट पूर्ण',
    te: 'చెల్లించెను',
  },
  PARTNER_WITH_US: {
    en: 'Partner With Us',
    gu: 'અમારી સાથે ભાગીદાર',
    hi: 'हमारे साथ भागीदार',
    kn: 'ನಮ್ಮೊಂದಿಗೆ ಪಾಲುದಾರರಾಗಿ',
    mr: 'आमचे भागीदार बना',
    te: 'మాతో భాగస్వామి',
  },
  PAST_ORDERS: {
    en: 'Past Orders',
    gu: 'પહેલાના ઓર્ડર્સ',
    hi: 'पिछले ऑर्डर्स',
    kn: 'ಹಿಂದಿನ ಆರ್ಡರ್ ಗಳು',
    mr: 'मागील ऑर्डर',
    te: 'గత ఆర్డర్లు',
  },
  PAST_PRODUCTS: {
    en: 'Recommendations from Past Orders',
    gu: 'પાછલા ઓર્ડરની ભલામણો',
    hi: 'पिछले आदेश से सिफारिशें',
    kn: 'ಹಿಂದಿನ ಆರ್ಡರ್ ಗಳಿಂದ ಶಿಫಾರಸುಗಳು',
    mr: 'मागील ऑर्डर शी संबंधित',
    te: 'గత ఆర్డర్ ల నుండి సిఫార్సులు',
  },
  PAST_PRODUCTS_SUBHEADING: {
    en: 'Products previously bought by you',
    gu: 'તમારા દ્વારા અગાઉ ખરીદેલ ઉત્પાદનો',
    hi: 'पहले आपके द्वारा खरीदे गए उत्पाद',
    kn: 'ಈ ಹಿಂದೆ ನೀವು ಖರೀದಿಸಿದ ಉತ್ಪನ್ನಗಳು',
    mr: 'तुम्ही आधी खरेदी केलेली उत्पादने',
    te: 'ఇదివరకు మీరు కొన్న వస్తువులు',
  },
  PAYABLE_AMOUNT: {
    en: 'Payable Amount',
    gu: 'ચૂકવવાપાત્ર રકમ',
    hi: 'भुगतान योग्य राशि',
    kn: 'ಪಾವತಿಸಬೇಕು ಮೊತ್ತ',
    mr: 'देय रक्कम',
    te: 'చెల్లించవలసిన మోత్తం',
  },
  PAYMENT_ID: {
    en: 'Payment ID',
    gu: 'ચુકવણી આઈડી',
    hi: 'भुगतान आईडी',
    kn: 'ಪಾವತಿ ಐಡಿ',
    mr: 'पेमेंट आयडी',
    te: 'పేమెంట్ ID',
  },
  PAYMENT_METHOD: {
    en: 'Payment Method',
    gu: 'ચૂકવણીની પદ્ધતિ',
    hi: 'भुगतान विधि',
    kn: 'ಪಾವತಿ ವಿಧ',
    mr: 'पेमेंट पद्धत',
    te: 'చెల్లింపు విధానం',
  },
  ORDER_PAYMENT: {
    en: 'Order Payment',
    gu: 'ઓર્ડર ચુકવણી',
    hi: 'ऑर्डर भुगतान',
    kn: 'ಆರ್ಡರ್ ಪಾವತಿ',
    mr: 'ऑर्डर पेमेंट',
    te: 'ఆర్డర్ చెల్లింపు',
  },
  PAYMENT_MODE: {
    en: 'Payment Mode',
    gu: 'ચુકવણીનો પ્રકાર',
    hi: 'भुगतान का प्रकार',
    kn: 'ಪಾವತಿ ಮೋಡ್',
    mr: 'पेमेंट पद्धत',
    te: 'చెల్లింపు విధానం',
  },
  PAYMENT_MODES_ACCEPTED: {
    en: 'This Shop accepts only {paymentMode}',
    gu: 'આ દુકાન ફક્ત {paymentMode} સ્વીકારે છે',
    hi: 'यह दुकान केवल {paymentMode} स्वीकार करती है',
    kn: 'ಈ ಅಂಗಡಿ {paymentMode} ಮಾತ್ರ ಸ್ವೀಕರಿಸುತ್ತದೆ',
    mr: 'हे दुकान फक्त {paymentMode} स्वीकारते',
    te: 'ఈ షాపు {paymentMode} మాత్రమే ఆమోదిస్తుంది',
  },
  PAYMENT_MODES_LOCKED: {
    en: 'Payment Mode is locked to {paymentMode} because of Applied Coupon',
    gu: 'ચુકવણીનો પ્રકાર લાગુ કરેલ કૂપનને કારણે લોક{paymentMode} થયેલ છે',
    hi: 'भुगतान का प्रकार लागू कूपन के कारण {paymentMode} बंद है',
    kn: 'ಕೂಪನ್ ಅನ್ವಯಿಸಿರುವ ಕಾರಣ ಪಾವತಿ ಮೋಡ್ ಅನ್ನು {paymentMode} ಗೆ ಲಾಕ್ ಮಾಡಲಾಗಿದೆ',
    mr: 'कूपन लागू केल्यामुळे {paymentMode} हा पेमेंट पर्याय वापरता येईल',
    te: 'వర్తింపచేసిన కూపన్ వలన {paymentMode} చెల్లింపు విధానం లాక్ చేయబడినది',
  },
  PAYMENT_PENDING: {
    en: 'Payment Pending',
    gu: 'ચુકવણી બાકી!',
    hi: 'भुगतान लंबित',
    kn: 'ಪಾವತಿ ಬಾಕಿ ಇದೆ',
    mr: 'पेमेंट प्रलंबित आहे',
    te: 'చెల్లింపులు పెండింగ్ లో ఉన్నది',
  },
  PAYMENT_PENDING_IN_POPUP: {
    en: 'Payment Pending!',
    gu: 'ચુકવણી બાકી!',
    hi: 'भुगतान लंबित',
    kn: 'ಪಾವತಿ ಬಾಕಿ ಇದೆ!',
    mr: 'पेमेंट प्रलंबित!',
    te: 'చెల్లింపు  పెండింగ్ లో ఉన్నది !',
  },
  PAYMENT_PREFERENCE_PICKED: {
    en: 'Payment Preference Picked by you',
    gu: 'તમારા દ્વારા ચુકવણીની પસંદગી',
    hi: 'आपके द्वारा भुगतान की पसंदगी',
    kn: 'ನೀವು ಆರಿಸಿರುವ ಪಾವತಿ ಆದ್ಯತೆ',
    mr: 'तुम्ही निवडलेला पेमेंट पर्याय',
    te: 'మీరు ఎంచుకున్న  చెల్లింపు ప్రాధాన్యత',
  },
  PAYMENT_RECEIVED: {
    en: 'Payment Received',
    gu: 'ચુકવણી પ્રાપ્ત',
    hi: 'भुगतान प्राप्त',
    kn: 'ಪಾವತಿ ಸ್ವೀಕರಿಸಲಾಗಿದೆ',
    mr: 'पेमेंट मिळाले',
    te: 'చెల్లింపు అందుకున్నాము',
  },
  PHONE_NUMBER: {
    en: 'Phone Number',
    gu: 'ફોન નંબર',
    hi: 'फ़ोन नंबर',
    kn: 'ಫೋನ್ ನಂಬರ್',
    mr: 'फोन क्रमांक',
    te: 'ఫోన్ నెంబరు',
  },
  PHONE_NUMBER_IS_REQUIRED: {
    en: 'Mobile Number is required',
    gu: 'મોબાઇલ નંબર જરૂરી છે',
    hi: 'मोबाइल नंबर आवश्यक है',
    kn: 'ಮೊಬೈಲ್ ನಂಬರ್ ಅಗತ್ಯವಿದೆ',
    mr: 'मोबाईल क्रमांक आवश्यक आहे',
    te: 'మొబైలు నెంబరు అవసరము',
  },
  PICKUP_NOT_DONE: {
    en: 'Pickup Not Done!',
    gu: 'પિકઅપ ન થયું!',
    hi: 'पिकअप नहीं हुआ!',
    kn: 'ಪಿಕಪ್ ಮುಗಿದಿಲ್ಲ!',
    mr: 'दुकानातून घेणे अयशस्वी झाले!',
    te: 'ఇంకా తీసుకెళ్ళలేదు!',
  },
  PICKUP_YOUR_ORDER_FROM_SHOP: {
    en: 'Pick up your order from the shop',
    gu: 'તમારો ઓર્ડર દુકાનથી લઇ લો',
    hi: 'दुकान से अपना ऑर्डर ले लें',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ಅಂಗಡಿಯಿಂದ ಪಿಕ್ ಅಪ್ ಮಾಡಿಕೊಳ್ಳಿ',
    mr: 'दुकानात जाऊन तुमची ऑर्डर घ्या.',
    te: 'షాపు నుండి ఆర్డర్ చేసిన వస్తువులను తీసుకువెళ్ళ వలేను',
  },
  PICKUP_YOUR_ORDER_FROM_SHOP_DESC: {
    en: 'This shop isn’t providing delivery services right now. You will have to visit the shop to collect your parcel after placing order.',
    gu: 'આ દુકાન હમણાં ડિલિવરી સેવાઓ આપતી નથી. ઓર્ડર આપ્યા પછી તમારું પાર્સલ લેવા માટે તમારે દુકાનની મુલાકાત લેવી પડશે.',
    hi: 'यह दुकान अभी डिलीवरी सेवाएँ प्रदान नहीं कर रही है। ऑर्डर देने के बाद आपको अपने पार्सल को लेने के लिए दुकान पर जाना होगा।',
    kn: 'ಈ ಅಂಗಡಿ ಇದೀಗ ವಿತರಣಾ ಸೇವೆಗಳನ್ನು ಒದಗಿಸುತ್ತಿಲ್ಲ.  ಆರ್ಡರ್ ಅನ್ನು ನೀಡಿದ ನಂತರ ನಿಮ್ಮ ಪಾರ್ಸೆಲ್ ಸಂಗ್ರಹಿಸಲು ನೀವು ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡಬೇಕಾಗುತ್ತದೆ.',
    mr: 'हे दुकान सध्या वितरण सेवा देत नाही, तुम्हाला ऑर्डर दिल्यानंतर दुकानात जाऊन ऑर्डर घ्यावी लागेल',
    te: 'ఈ షాపు ప్రస్తుతం డెలివేరి సేవలు అందించుట లేదు. ఆర్డర్ పెట్టాక మీరు షాపుకు వెళ్ళి పార్శిల్ పొందవలసి ఉంటుంది.',
  },
  PIN_CODE: {
    en: 'Pincode',
    gu: 'Pincode',
    hi: 'Pincode',
    kn: 'Pincode',
    mr: 'Pincode',
    te: 'Pincode',
  },
  PIN_CODE_IS_REQUIRED: {
    en: 'Need pincode to continue',
    gu: 'Need pincode to continue',
    hi: 'Need pincode to continue',
    kn: 'Need pincode to continue',
    mr: 'Need pincode to continue',
    te: 'Need pincode to continue',
  },
  PIN_CODE_SHOULD_CONTAIN_SIX_DIGITS: {
    en: 'Pincode should contain 6 digits',
    gu: 'પિન કોડમાં 6 અંકો હોવા જોઈએ',
    hi: 'पिन कोड में 6 अंक होने चाहिए',
    kn: 'ಅಂಚೆ ಸಂಖ್ಯೆ 6 ಡಿಜಿಟ್ ಹೊಂದಿರಬೇಕು',
    mr: 'पिन कोड मध्ये सहा अंक असणे आवश्यक आहे',
    te: 'పిన కోడ్ 6 అంకెలలో ఉండవలెను',
  },
  PLACE_ORDER: {
    en: 'PLACE ORDER',
    gu: 'ઓર્ડર કરો',
    hi: 'ऑर्डर करें',
    kn: 'ಆರ್ಡರ್ ಪ್ಲೇಸ್ ಮಾಡಿ',
    mr: 'ऑर्डर करा',
    te: 'ఆర్డర్ పెట్టండి',
  },
  PLACEHOLDER_COUPON_CODE: {
    en: 'Enter Coupon Code',
    gu: 'કૂપન કોડ નાખો',
    hi: 'कूपन कोड डालें',
    kn: 'ಕೂಪನ್ ಕೋಡ್ ನಮೂದಿಸಿ',
    mr: 'कूपन कोड प्रविष्ट करा',
    te: 'కూపన్ కోడ్ ను  నమోదుచేయండి',
  },
  PLACEHOLDER_SPECIAL_NOTE: {
    en: 'Add any special note',
    gu: 'કોઈપણ ખાસ નોંધ ઉમેરો',
    hi: 'कोई विशेष नोट जोड़ें',
    kn: 'ಏನಾದರೂ ವಿಶೇಷವಾಗಿ ಸೂಚನೆ ಸೇರಿಸಿ',
    mr: 'एखादी टीप जोडा',
    te: 'ఏదైన ప్రత్యేక సూచనను జత చేయండి',
  },
  PLEASE: {
    en: 'Please',
    gu: 'કૃપા કરીને',
    hi: 'कृपया करके',
    kn: 'ದಯವಿಟ್ಟು',
    mr: 'कृपया',
    te: 'దయచేసి',
  },
  PLEASE_ENTER_FIRST_NAME: {
    en: 'Please Enter First Name',
    gu: 'કૃપા કરીને પહેલું નામ નાખો',
    hi: 'कृपया पहला नाम दर्ज करें',
    kn: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೊದಲ ಹೆಸರು ನಮೂದಿಸಿ',
    mr: 'कृपया नाव प्रविष्ट करा',
    te: 'దయచేసి మొదటి పేరును నమోదు చేయండి',
  },
  PLEASE_ENTER_PHONE_NUMBER: {
    en: 'Welcome! Please enter your phone number',
    gu: 'સ્વાગત છે! કૃપા કરીને તમારો ફોન નંબર નાખો',
    hi: 'स्वागत हे! कृपया अपना फोन नंबर दर्ज करें',
    kn: 'ಸ್ವಾಗತ! ದಯವಿಟ್ಟು ನಿಮ್ಮ ಫೋನ್ ನಂಬರ್ ನಮೂದಿಸಿ',
    mr: 'नमस्कार! कृपया तुमचा फोन नंबर प्रविष्ट करा',
    te: 'స్వాగతం ! దయచేసి మీ ఫోన్ నెంబరును నమోదు చేయండి',
  },
  PLEASE_ENTER_VALID_EMAIL_ID: {
    en: 'Please enter valid email id',
    gu: 'કૃપા કરી માન્ય ઇમેઇલ આઈડી નાખો',
    hi: 'कृपया मान्य ईमेल आईडी दर्ज करें',
    kn: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ ನಮೂದಿಸಿ',
    mr: 'कृपया वैध ईमेल पत्ता प्रविष्ट करा',
    te: 'దయచేసి సరి అయిన ఈమెయిల్ ఐడి ని నమోదు చేయండి',
  },
  PLEASE_ENTER_VALID_MOBILE_NUMBER: {
    en: 'Please Enter Valid Mobile Number',
    gu: 'કૃપા કરી માન્ય મોબાઇલ નંબર નાખો',
    hi: 'कृपया मान्य मोबाइल नंबर दर्ज करें',
    kn: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಮೊಬೈಲ್ ನಂಬರ್ ನಮೂದಿಸಿ',
    mr: 'कृपया मोबाईल क्रमांक प्रविष्ट करा',
    te: 'దయచేసి సరి అయిన మొబైలు నెంబర్ ని నమోదు చేయండి',
  },
  PLEASE_ENTER_VALID_OTP: {
    en: 'Please Enter Valid OTP',
    gu: 'કૃપા કરી માન્ય ઓટીપી નાખો',
    hi: 'कृपया मान्य ओटीपी दर्ज करें',
    kn: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ OTP ನಮೂದಿಸಿ',
    mr: 'वैध OTP प्रविष्ट करा',
    te: 'దయచేసి సరి అయిన OTPని నమోదు చేయండి',
  },
  PLEASE_ENTER_VALID_PIN_CODE: {
    en: 'Please Enter Valid PIN Code',
    gu: 'કૃપા કરી માન્ય પિન કોડ નાખો',
    hi: 'कृपया मान्य पिन कोड दर्ज करें',
    kn: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಅಂಚೆ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ',
    mr: 'कृपया वैध पिन कोड प्रविष्ट करा',
    te: 'దయచేసి సరి అయిన పిన్కోడ్ ని నమోదు చేయండి',
  },
  PLEASE_NOTE: {
    en: 'Please Note!',
    gu: 'કૃપયા નોંધો!',
    hi: 'कृपया ध्यान दें!',
    kn: 'ದಯವಿಟ್ಟು ಗಮನಿಸಿ!',
    mr: 'कृपया लक्षात ठेवा!',
    te: 'దయచేసి గమనించండి!',
  },
  PLEASE_PICKUP_YOUR_ORDER: {
    en: "Please visit {shopName} Shop to pickup your order. It's ready for you",
    gu: 'તમારા ઓર્ડરને લેવા માટે કૃપા કરીને {shopName} દુકાનની મુલાકાત લો. તમારો ઓર્ડર તૈયાર છે',
    hi: 'कृपया अपना ऑर्डर लेने के लिए {shopName} दुकान पर जाएं। आपका ऑर्डर तैयार है',
    kn: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆರ್ಡರ್ ಪಿಕ್ ಅಪ್ ಮಾಡಲು  {shopName} ಅಂಗಡಿಗೆ ಭೇಟಿ ಮಾಡಿ. ಅದು ನಿಮಗಾಗಿ ಸಿದ್ಧವಾಗಿದೆ',
    mr: 'कृपया {shopName} येथे जाऊन तुमची ऑर्डर स्वीकारा, ऑर्डर तयार आहे.',
    te: 'దయచేసి {shopName} షాపుకు వచ్చి మీ ఆర్డర్ తీసుకువెళ్ళండి. అవి రెఢీగా ఉన్నాయి.',
  },
  PLEASE_SELECT: {
    en: 'Please select',
    gu: 'કૃપા કરી પસંદ કરો',
    hi: 'कृपया चुने',
    kn: 'ದಯವಿಟ್ಟು ಆರಿಸಿ',
    mr: 'कृपया निवडा',
    te: 'దయచేసి  ఎంచుకోండి',
  },
  PLEASE_TELL_US_MORE: {
    en: 'Please tell us more',
    gu: 'કૃપા કરી અમને વધુ જણાવો',
    hi: 'कृपया हमें और बताएँ',
    kn: 'ದಯವಿಟ್ಟು ನಮಗೆ ಇನ್ನಷ್ಟು ಹೇಳಿ',
    mr: 'कृपया अजून काहीतरी सांगा',
    te: 'దయచేసి మాకు మరింత చెప్పండి',
  },
  POINTS_BURNED: {
    en: 'Points Burned',
    gu: 'પોઇન્ટ બળી ગયા',
    hi: 'पॉइंट्स जल गए',
    kn: 'Points Burned',
    mr: 'वापरलेले गुण',
    te: 'ఉపయోగించుకున్న పాయింట్లు',
  },
  POINTS_EARNED: {
    en: 'Points Earned',
    gu: 'પોઇન્ટ મળ્યા',
    hi: 'पॉइंट्स  मिले',
    kn: 'ಸಂಪಾಡಿಸಿದ ಪಾಯಿಂಟ್ಸ್',
    mr: 'मिळालेले गुण',
    te: 'సంపాదించుకున్న పాయింట్లు',
  },
  POOR_QUALITY_OF_PRODUCTS: {
    en: 'Poor Quality of Products',
    gu: 'ઉત્પાદનોની નબળી ગુણવત્તા',
    hi: 'उत्पाद की खराब गुणवत्ता',
    kn: 'ಉತ್ಪನ್ನಗಳ ಕಳಪೆ ಗುಣಮಟ್ಟ',
    mr: 'उत्पादनांची खराब गुणवत्ता',
    te: 'నాసిరకమైన వస్తువులు',
  },
  POPULAR: {
    en: 'Popular',
    gu: 'પ્રખ્યાત',
    hi: 'लोकप्रिय',
    kn: 'ಜನಪ್ರಿಯ',
    mr: 'लोकप्रिय',
    te: 'పేరెన్నికగన్న',
  },
  POPULAR_CITIES: {
    en: 'Popular Cities',
    gu: 'લોકપ્રિય શહેરો',
    hi: 'लोकप्रिय शहर',
    kn: 'ಜನಪ್ರಿಯ ನಗರಗಳು',
    mr: 'लोकप्रिय शहरे',
    te: 'ప్రసిద్ధ నగరాలు',
  },
  POPULAR_CATEGORIES: {
    en: 'Popular Categories',
    gu: 'લોકપ્રિય શ્રેણીઓ',
    hi: 'लोकप्रिय श्रेणियां',
    kn: 'ಜನಪ್ರಿಯ ವಿಭಾಗಗಳು',
    mr: 'लोकप्रिय श्रेणी',
    te: 'జనాదరణ పొందిన విభాగాలు',
  },
  POPULAR_LOCALITIES: {
    en: 'Popular Localities',
    gu: 'લોકપ્રિય સ્થળો',
    hi: 'लोकप्रिय इलाके',
    kn: 'ಜನಪ್ರಿಯ ಪ್ರದೇಶಗಳು',
    mr: 'लोकप्रिय परिसर',
    te: 'జనాదరణ పొందిన ప్రాంతాలు',
  },
  POPULAR_PRODUCTS: {
    en: 'Popular Products',
    gu: 'લોકપ્રિય ઉત્પાદનો',
    hi: 'लोकप्रिय उत्पाद',
    kn: 'ಜನಪ್ರಿಯ ಉತ್ಪನ್ನಗಳು',
    mr: 'लोकप्रिय उत्पादने',
    te: 'పేరెన్నికగన్న వస్తువులు',
  },
  PREVIOUSLY_ORDERED: {
    en: 'Previously Ordered',
    gu: 'અગાઉ આપેલ ઓર્ડર',
    hi: 'पहले ऑर्डर किए गए',
    kn: 'ಹಿಂದೆ ಆದೇಶಿಸಲಾಗಿದ',
    mr: 'आधी ऑर्डर केलेले',
    te: 'గతంలో ఆర్డర్ పెట్టారు',
  },
  PRIVACY_POLICY: {
    en: 'Privacy Policy',
    gu: 'ગોપનીયતા નીતિ',
    hi: 'गोपनीयता नीति',
    kn: 'ಗೌಪ್ಯ ನೀತಿ',
    mr: 'गोपनीयता धोरण',
    te: 'గోప్యతా విధానం',
  },
  PRODUCT_COACHMARK: {
    en: 'We have combined the variants of products. Click on the ADD Button to select and add individual variants to cart.',
    gu: 'અમે ઉત્પાદનોના પ્રકારોને જોડ્યા છે. કાર્ટમાં અલગ-અલગ-ઉત્પાદન પસંદ કરવા અને ઉમેરવા માટે એડ બટન પર ક્લિક કરો.',
    hi: 'हमने उत्पादों के वेरिएंट को जोड़ दिया है। चुनने के लिए एड बटन पर क्लिक करें और कार्ट में अलग-अलग वेरिएंट डाले।',
    kn: 'ನಾವು ಉತ್ಪನ್ನಗಳ ವೇರಿಯಂಟ್ ಗಳನ್ನು ಸಂಯೋಜಿಸಿದ್ದೇವೆ.  ಕಾರ್ಟ್‌ಗೆ ಪ್ರತ್ಯೇಕ ವೇರಿಯಂಟ್ ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಮತ್ತು ಸೇರಿಸಲು ADD ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.',
    mr: 'आम्ही उत्पादनांचे प्रकार एकत्रित केले आहेत. कार्टमध्ये स्वतंत्र प्रकार निवडण्यासाठी आणि जोडण्यासाठी जोडा बटणावर क्लिक करा.',
    te: 'మేము వస్తువుల వేరియంట్ లను కలిపాము. చేర్చండి బటన్ ను క్లిక్ చేసి ఎంచుకున్న వకో  వేరియంట్ ను బండి లోకి చేర్చండి',
  },
  PRODUCT_DETAILS: {
    en: 'Product Details',
    gu: 'ઉત્પાદન વિગતો',
    hi: 'उत्पाद विवरण',
    kn: 'ಉತ್ಪನ್ನ ವಿವರಗಳು',
    mr: 'उत्पादन तपशील',
    te: 'వస్తువు వివరాలు',
  },
  PRODUCTS: {
    en: 'Products',
    gu: 'ઉત્પાદનો',
    hi: 'उत्पादों',
    kn: 'ಉತ್ಪನ್ನಗಳು',
    mr: 'उत्पादने',
    te: 'వస్తువులు',
  },
  PROFESSIONAL_SERVICE: {
    en: 'Professional Service',
    gu: 'વ્યવસાયિક સેવા',
    hi: 'व्यावसायिक सेवा',
    kn: 'ವೃತ್ತಿಪರ ಸೇವೆ',
    mr: 'व्यावसायिक सेवा',
    te: 'నిపుణుల సేవలు',
  },
  QUALITY_PRODUCTS: {
    en: 'Quality Products',
    gu: 'ગુણવત્તા ઉત્પાદનો',
    hi: 'गुणवत्ता वाला उत्पाद',
    kn: 'ಗುಣಮಟ್ಟದ ಉತ್ಪನ್ನಗಳು',
    mr: 'गुणवत्तापूर्ण उत्पादने',
    te: 'నాణ్యమైన వస్తువులు',
  },
  QUESTION: {
    en: 'Question',
    gu: 'પ્રશ્ન',
    hi: 'प्रश्न',
    kn: 'ಪ್ರಶ್ನೆ',
    mr: 'प्रश्न',
    te: 'ప్రశ్న',
  },
  QUESTIONS: {
    en: 'Questions',
    gu: 'પ્રશ્નો',
    hi: 'प्रश्नो',
    kn: 'ಪ್ರಶ್ನೆಗಳು',
    mr: 'प्रश्न',
    te: 'ప్రశ్నలు',
  },
  RATE_REVIEW_THIS_ORDER: {
    en: 'Rate & Review this order',
    gu: 'આ ઓર્ડરને રેટ અને સમીક્ષા કરો',
    hi: 'इस ऑर्डर का मूल्यांकन और समीक्षा करें',
    kn: 'ಈ ಆದೇಶವನ್ನು ರೇಟ್ ಮಾಡಿ ಮತ್ತು ಪರಿಶೀಲಿಸಿ',
    mr: 'या ऑर्डर बद्दल प्रतिक्रिया आणि मूल्यांकन द्या',
    te: 'ఈ ఆర్డర్ కు రేటింగ్ ఇచ్చి, సమీక్ష రాయండి',
  },
  RATE_YOUR_EXPERIENCE: {
    en: 'Rate Your Experience',
    gu: 'તમારું અનુભવ રેટ',
    hi: 'अपने अनुभव का मूल्यांकन',
    kn: 'ನಿಮ್ಮ ಅನುಭವವನ್ನು ರೇಟ್ ಮಾಡಿ',
    mr: 'तुमच्या अनुभवाबद्दल मूल्यांकन द्या',
    te: 'మీ  అనుభవానికి రేటింగ్ ఇవ్వండి',
  },
  RATE_YOUR_EXPERIENCE_DESC: {
    en: 'How was your experience with your recent order from {shopName}?',
    gu: '{shopName} થી તમારા તાજેતરના ઓર્ડર સાથે તમારો અનુભવ કેવો હતો?',
    hi: '{shopName} से अपने हाल के ऑर्डर के साथ अपने अनुभव कैसा रहा?',
    kn: '{shopName} ನಿಂದ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಆದೇಶದೊಂದಿಗೆ ನಿಮ್ಮ ಅನುಭವ ಹೇಗಿತ್ತು?',
    mr: '{shopName} मधील अलीकडील ऑर्डर विषयी तुमचा अनुभव कसा होता?',
    te: '{shopName} నుండి మీరు ఇటీవల పొందిన ఆర్డర్ పై మీ అనుభవం ఏమిటి?',
  },
  RATINGS: {
    en: 'Ratings',
    gu: 'રેટિંગ્સ',
    hi: 'रेटिंग्स',
    kn: 'ರೇಟಿಂಗ್ ಗಳು',
    mr: 'मूल्यांकन',
    te: 'రేటింగ్లు',
  },
  RECEIVED: {
    en: 'Received',
    gu: 'મળી ગયું',
    hi: 'मिल गया',
    kn: 'ಸ್ವೀಕರಿಸಲಾಗಿದೆ',
    mr: 'प्राप्त झाले',
    te: 'అందుకున్న',
  },
  RECOMMENDED_OFFERS: {
    en: 'Recommended offers',
    gu: 'ભલામણ કરવામાં આવેલી ઑફરો',
    hi: 'अनुशंसित ऑफर',
    kn: 'ಶಿಫಾರಸು ಮಾಡಲಾದ ಕೊಡುಗೆಗಳು',
    mr: 'शिफारस केलेल्या ऑफर्स',
    te: 'సిఫార్సు చేయబడిన ఆఫర్లు',
  },
  REFER_A_FRIEND_WIN_A_SCRATCH_CARD_35: {
    en: 'Refer a friend and get Rs 35/- OFF!',
    gu: 'ફ્રેન્ડને રીફર કરો. સ્ક્રેચકાર્ડ જીતો!',
    hi: 'फ्रेन्डको रिफर करे। स्क्रैचकार्ड जीते!',
    kn: 'ಸ್ನೇಹಿತರನ್ನು ರೆಫರ್ ಮಾಡಿ.  ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಗೆದ್ದಿರಿ!',
    mr: 'मित्राला आमंत्रित करा आणि स्क्रॅच कार्ड जिंका.',
    te: 'స్నేహితునికి చెప్పండి. స్క్రాచ్ కార్డ్ గెలవండి !',
  },
  REFER_A__SHOP: {
    en: 'Refer a Shop',
    gu: 'લોકલ દુકાન રીફર કરો',
    hi: 'लोकल दुकान रिफर करें',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿ ರೆಫರ್ ಮಾಡಿ',
    mr: 'स्थानिक दुकानाला आमंत्रित करा',
    te: 'ఏదయిన స్థానిక దుకాణాన్ని చూడండి',
  },
  REFER_SHOP: {
    en: 'Refer Shop',
    gu: 'લોકલ દુકાન રીફર કરો',
    hi: 'लोकल दुकान रिफर करें',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿ ರೆಫರ್ ಮಾಡಿ',
    mr: 'स्थानिक दुकानाला आमंत्रित करा',
    te: 'ఏదయిన స్థానిక దుకాణాన్ని చూడండి',
  },
  REFER_A_LOCAL_SHOP: {
    en: 'Refer a local shop',
    gu: 'લોકલ દુકાન રીફર કરો',
    hi: 'लोकल दुकान रिफर करें',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿ ರೆಫರ್ ಮಾಡಿ',
    mr: 'स्थानिक दुकानाला आमंत्रित करा',
    te: 'ఏదయిన స్థానిక దుకాణాన్ని చూడండి',
  },
  REFER_AND_EARN: {
    en: 'Refer a Friend',
    gu: 'ફ્રેન્ડને રીફર કરો',
    hi: 'फ्रेन्ड को रिफर करे',
    kn: 'ಸ್ನೇಹಿತರನ್ನು ರೆಫರ್ ಮಾಡಿ',
    mr: 'मित्रास आमंत्रित करा',
    te: 'స్నేహితునికి చెప్పండి',
  },
  REFER_AND_EARN_DESC: {
    en: 'Refer a friend and we’ll give both of you ₹100/- OFF on the next order',
    gu: 'મિત્રને રીફર કરો અને પછીના ઓર્ડર પર અમે તમને બંનેને ₹100/- ઓફ આપીશું',
    hi: 'किसी मित्र को रिफर करें और हम अगले ऑर्डर पर आप दोनों को ₹100/- ऑफ देंगे',
    kn: 'ಸ್ನೇಹಿತನನ್ನು ರೆಫರ್ ಮಾಡಿ ಮತ್ತು ನಾವು ನಿಮ್ಮಿಬ್ಬರಿಗೂ ನಿಮ್ಮ ಮುಂದಿನ ಆರ್ಡರ್ ಮೇಲೆ ₹100/- ಕಡಿತ ಕೊಡುತ್ತೇವೆ',
    mr: 'मित्राला आमंत्रित करा. पुढील ऑर्डर साठी आम्ही दोघांनी ₹100/- रुपये सूट देऊ',
    te: 'స్నేహితునికి చెప్పండి, తరువాత చేసే ఆర్డర్ పై మీ ఇద్దరికీ ₹100/- తగ్గింపు లభించును',
  },
  REFER_AND_EARN_MORE: {
    en: 'Refer & Earn More',
    gu: 'રીફર કરો અને વધુ કમાઓ',
    hi: 'रिफर करे और अधिक कमाएँ',
    kn: 'ರೆಫರ್ ಮಾಡಿ ಮತ್ತು ಹೆಚ್ಚು ಸಂಪಾದಿಸಿ',
    mr: 'आमंत्रित करा आणि अधिक कमवा',
    te: 'చెప్పండి & మరిన్ని గెలవండి',
  },
  READY_TO_ORDER_FROM_TOP_LOCAL_SHOP: {
    en: 'Ready to order from top local shops near you?',
    gu: 'તમારી નજીકની ટોચની સ્થાનિક દુકાનોમાંથી ઓર્ડર આપવા માટે તૈયાર છો?',
    hi: 'अपने आस-पास की टोप की स्थानीय दुकानों से ऑर्डर करने के लिए तैयार हैं?',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳಿಂದ ಆದೇಶಿಸಲು ಸಿದ್ಧರಿದ್ದೀರಾ?',
    mr: 'आपल्या जवळच्या स्थानिक दुकानातून ऑर्डर करण्यास सज्ज आहात?',
    te: 'మీ సమీపంలోని పేరొందిన స్థానిక దుకాణాలలో ఆర్డర్ చేయడానికి సిద్ధంగా ఉన్నారా?',
  },
  REFER_FRIENDS: {
    en: 'REFER FRIENDS',
    gu: 'મિત્રોનો સંદર્ભ આપો',
    hi: 'दोस्तों का संदर्भ दे',
    kn: 'ಸ್ನೇಹಿತರನ್ನು ರೆಫರ್ ಮಾಡಿ',
    mr: 'मित्रांना आमंत्रित करा',
    te: 'స్నేహితులకు చెప్పండి',
  },
  REFER_LOCAL_SHOPS: {
    en: 'Refer  Local Shops',
    gu: 'લોકલ દુકાનો રીફર કરો',
    hi: 'लोकल दुकाने रिफर करें',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳನ್ನು ರೆಫರ್ ಮಾಡಿ',
    mr: 'स्थानिक दुकानांना आमंत्रित करा',
    te: 'స్థానిక దుకాణాలను చూడండి',
  },
  REFER_LOCAL_SHOPS_DES: {
    en: "Share detail of a local shop in your neighborhood and we'll bring them online",
    gu: 'તમારા પાડોશની લોકલ દુકાનની વિગતો શેર કરો અને અમે તેમને ઓનલાઇન લાવીશું',
    hi: 'अपने पड़ोस की एक लोकल दुकान की डिटेल्स दे और हम उन्हें ऑनलाइन लाएंगे',
    kn: 'ನಿಮ್ಮ ನೆರೆಹೊರೆಯ ಸ್ಥಳೀಯ ಅಂಗಡಿ ವಿವರವನ್ನು ಹಂಚಿಕೊಳ್ಳಿ ಮತ್ತು ನಾವು ಅವರನ್ನು ಆನ್ಲೈನ್ ತರುತ್ತೇವೆ',
    mr: 'तुमच्या आवडीच्या स्थानिक दुकानाचा तपशील द्या, आम्ही त्यांना ऑनलाईन आणू.',
    te: 'మీ చుట్టుపక్కల షాపుల వివరాలను తెలియచేసిన మేము వాటిని ఆన్ లైన్ చేస్తాము',
  },
  REFER_NOW: {
    en: 'Refer Now',
    gu: 'અત્યારે જ રીફર કરો',
    hi: 'अभी रिफर करें',
    kn: 'ಈಗಲೇ ರೆಫರ್ ಮಾಡಿ',
    mr: 'आत्ता आमंत्रित करा',
    te: 'ఇప్పుడే చూడండి',
  },
  REFERRAL_CODE: {
    en: 'Referral Code',
    gu: 'રેફરલ કોડ',
    hi: 'रेफरल कोड',
    kn: 'ರೆಫರಲ್ ಕೋಡ್',
    mr: 'आमंत्रण कोड',
    te: 'రేఫెరెల్ కోడ్',
  },
  REFERRAL_CODE_COPIED: {
    en: 'Referral code copied',
    gu: 'રેફરલ કોડ કોપિ કર્યો',
    hi: 'रेफरल कोड कॉपी किया',
    kn: 'ರೆಫರಲ್ ಕೋಡ್ ಅನ್ನು ನಕಲಿಸಲಾಗಿದೆ',
    mr: 'आमंत्रण कोड कॉपी केला',
    te: 'రేఫెరెల్ కోడ్ కాపీ చేయబడినది',
  },
  REFERRAL_COUPON_APPLIED: {
    en: 'Referral Coupon Applied!',
    gu: 'રેફરલ કૂપન લાગુ!',
    hi: 'रेफरल कूपन लागू!',
    kn: 'ರೆಫರಲ್ ಕೋಡ್ ಅನ್ನು ಅನ್ವಯಿಸಲಾಗಿದೆ!',
    mr: 'आमंत्रण कूपन लागू केले!',
    te: 'రేఫెరెల్ కోడ్ వర్తించబడినది',
  },
  REFERRAL_SHARE_TEXT: {
    en: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
    gu: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
    hi: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
    kn: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
    mr: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
    te: "Hey, I'm using LoveLocal to get all my daily needs delivered from local shops near me.\n\nUse Coupon Code: *{referral}* and get *₹35 OFF* on your first order.\n\nDownload the app now: ",
  },
  REJECT_CHANGES: {
    en: 'Reject Changes',
    gu: 'ફેરફારોને નકારો',
    hi: 'परिवर्तन अस्वीकार करें',
    kn: 'ಬದಲಾವಣೆಗಳನ್ನು ನಿರಾಕರಿಸಿ',
    mr: 'बदल अमान्य करा',
    te: 'మార్పులను  తిరస్కరించు',
  },
  REMIND: {
    en: 'Remind',
    gu: 'યાદ કરાવવું',
    hi: 'याद दिलाना',
    kn: 'ನೆನಪಿಸು',
    mr: 'पुन्हा आठवण करून द्या',
    te: 'గుర్తు చేయు',
  },
  REMOVE: {
    en: 'Remove',
    gu: 'દૂર કરો',
    hi: 'हटा दें',
    kn: 'ತೆಗೆಯಿರಿ',
    mr: 'काढून टाका',
    te: 'తొలగించు',
  },
  READ_MY_STORY: {
    en: 'READ MY STORY',
    gu: 'મારી સ્ટોરી વાંચો',
    hi: 'मेरी कहानी पढ़ें',
    kn: 'ನನ್ನ ಕಥೆ ಓದಿ',
    mr: 'माझी कथा वाचा',
    te: 'నా కథను చదవండి',
  },
  REMOVE_APP_EXCLUSIVE_DESC: {
    en: 'Removing this offer will also remove the product from your cart.',
    gu: 'આ ઓફરને દૂર કરવાથી તમારા કાર્ટમાંથી ઉત્પાદન પણ દૂર થશે.',
    hi: 'इस ऑफर को हटाने से आपकी कार्ट से उत्पाद भी निकल जाएगा।',
    kn: 'ಈ ಆಫರ್ ಅನ್ನು ತೆಗೆಯುವುದರಿಂದ ನಿಮ್ಮ ಕಾರ್ಟ್ ನಲ್ಲಿರುವ ಉತ್ಪನ್ನವನ್ನು ತೆಗೆದು ಹಾಕುತ್ತದೆ',
    mr: 'ही ऑफर काढल्यास हे उत्पादन ही तुमच्या कार्टमधून काढण्यात येईल.',
    te: 'ఈ ఆఫర్ ను తొలిగించడం వలన బండిలోని వస్తువు కూడా తొలిగించబడును',
  },
  REMOVE_OFFER: {
    en: 'Remove Offer?',
    gu: 'ઓફર દૂર કરો?',
    hi: 'ऑफर हटा दें?',
    kn: 'ಆಫರ್ ತೆಗೆಯುವುದೇ?',
    mr: 'ऑफर काढू?',
    te: 'ఆఫర్ తొలిగించమంటారా ?',
  },
  REMOVE_SPEND_OFFER_ALERT: {
    en: 'Do you wish to remove this offer',
    gu: 'શું તમે આ ઓફરને દૂર કરવા માંગો છો',
    hi: 'क्या आप इस ऑफ़र को निकालना चाहते हैं',
    kn: 'ನೀವು ಈ ಆಫರ್ ತೆಗೆಯಲು ಬಯಸುತ್ತೀರಾ',
    mr: 'तुम्ही ही ऑफर काढू इच्छिता?',
    te: 'ఈ ఆఫర్ను మీరు తొలిగించదలుచుకున్నారా',
  },
  REPEAT_YOUR_ORDER: {
    en: 'Repeat your Order',
    gu: 'તમારો ઓર્ડર રિપીટ કરવા માંગો છો?',
    hi: 'अपना ओर्डर दोहराना चाहते हैं?',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ಪುನರಾವರ್ತಿಸಿ',
    mr: 'आपल्या ऑर्डरची पुनरावृत्ती करा',
    te: 'మీ ఆర్డర్ ను  తిరిగి చేయండి',
  },
  REPLACE_OFFER: {
    en: 'Replace Offer?',
    gu: 'ઓફર બદલો?',
    hi: 'ऑफर बदले?',
    kn: 'ಆಫರ್ ಅನ್ನು ಬದಲಾಯಿಸುವುದೇ?',
    mr: 'ऑफर बदला?',
    te: 'ఆఫర్ మారుస్తారా ?',
  },
  REPLACE_OFFER_DESC: {
    en: 'Only one Value Offer can be applied per shop. Do you want to replace it?',
    gu: 'દુકાન દીઠ માત્ર એક જ ઓફર લાગુ થઈ શકે છે. શું તમે તેને બદલવા માંગો છો?',
    hi: 'प्रति दुकान केवल एक ऑफर लागू किया जा सकता है। क्या आप इसे बदलना चाहते हैं?',
    kn: 'ಪ್ರತಿ ಅಂಗಡಿಗೆ ಒಂದು ಮೌಲ್ಯ ಆಫರ್ ಅನ್ನು ಮಾತ್ರ ಅನ್ವಯಿಸಬಹುದು.  ನೀವು ಅದನ್ನು ಬದಲಾಯಿಸಲು ಬಯಸುವಿರಾ?',
    mr: 'एका दुकानात एकच मूल्य ऑफर वापरता येईल, तुम्ही बदलू इच्छिता?',
    te: 'వొక షాపుకి వొక వాల్యూ ఆఫర్ మాత్రమే వర్తిస్తుంది. మీరు దానిని మార్చదలుచుకున్నారా ?',
  },
  REQUEST_FOR_LOYALTY_POINT: {
    en: 'Request For Loyalty Point',
    gu: 'લોયલ્ટી પોઇન્ટ માટે વિનંતી',
    hi: 'लॉयल्टी पॉइंट के लिए अनुरोध',
    kn: 'ಲಾಯಲ್ಟಿ ಪಾಯಿಂಟ್ಗಾಗಿ ವಿನಂತಿ ಮಾಡಿ',
    mr: 'निष्ठा गुणांसाठी विनंती',
    te: 'లోయల్టీ పాయింట్ కొరకు అభ్యర్ధన',
  },
  RESEND_OTP: {
    en: 'Resend OTP',
    gu: 'ઓટીપી ફરીથી મોકલો',
    hi: 'ओटीपी फिरसे भेजें',
    kn: 'OTP ಮತ್ತೆ ಕಳುಹಿಸಿ',
    mr: 'OTP पुन्हा पाठवा',
    te: 'OTPని తిరిగి పంపండి',
  },
  RETRY: {
    en: 'Retry',
    gu: 'ફરી પ્રયાસ કરો',
    hi: 'फिर से कोशिश करें',
    kn: 'ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ',
    mr: 'पुन्हा प्रयत्न करा',
    te: 'తిరిగి  ప్రయత్నించండి',
  },
  RETURN_REFUND_CANCELLATION_POLICY: {
    en: 'Return, Refund & Cancellation policy',
    gu: 'રીટર્ન, રિફંડ અને રદ કરવાની નીતિ',
    hi: 'वापसी, धनवापसी और रद्दीकरण नीति',
    kn: 'ರಿಟರ್ನ್, ಮರುಪಾವತಿ ಮತ್ತು ರದ್ದತಿ ನೀತಿ',
    mr: 'परत, परतावा आणि रद्द करण्याचे धोरण',
    te: 'రిటర్న్, రీఫండ్ & కాన్సిలేషన్ పాలసీ',
  },
  REWARD_APPLIED: {
    en: 'Reward Applied',
    gu: 'ઇનામ લાગુ',
    hi: 'इनाम लागू',
    kn: 'ರಿವಾರ್ಡ್ ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'बक्षीस लागू केले.',
    te: 'బహుమతి వర్తించబడినది',
  },
  REWARD_APPLIED_ON_THE_BILL: {
    en: 'Reward applied on the bill',
    gu: 'ઇનામ બિલ પર લાગુ',
    hi: 'इनाम बिल पर लागू हुआ',
    kn: 'ಬಿಲ್ ಮೇಲೆ ರಿವಾರ್ಡ್ ಅನ್ವಯಿಸಲಾಗಿದೆ',
    mr: 'बिलासोबत बक्षीस लागू केले आहे',
    te: 'బిల్లుపై బహుమతి వర్తించబడినది',
  },
  REWARD_APPLY_SUCCESS_DESC: {
    en: 'Reward has been successfully applied. Enjoy your offer!',
    gu: 'ઇનામ સફળતાપૂર્વક લાગુ કરવામાં આવ્યો છે. તમારી ઓફરનો આનંદ માણો!',
    hi: 'इनाम सफलतापूर्वक लागू कर दिया गया है। अपनी ऑफर का आनंद लें!',
    kn: 'ರಿವಾರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಆಫರ್ ಅನ್ನು ಆನಂದಿಸಿ!',
    mr: 'ऑफर यशस्वीरीत्या लागू करण्यात आली आहे, ऑफरचा आनंद घ्या',
    te: 'బహుమతి విజయవంతంగా వర్తించబడినది. ఆఫర్ ను ఆస్వాదించండి !',
  },
  REWARD_SUCCESSFULLY_APPLIED: {
    en: 'Reward has been successfully applied. Enjoy your offer!',
    gu: 'ઇનામ સફળતાપૂર્વક લાગુ કરવામાં આવ્યું છે. તમારી ઓફરનો આનંદ માણો!',
    hi: 'इनाम सफलतापूर्वक लागू कर दिया गया है। अपनी ऑफरका आनंद लें!',
    kn: 'ರಿವಾರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಆಫರ್ ಆನಂದಿಸಿ!',
    mr: 'बक्षीस यशस्वीरीत्या लागू करण्यात आले आहे, ऑफरचा आनंद घ्या.',
    te: 'బహుమతి విజయవంతంగా వర్తించబడినది. ఆఫర్ ను ఆశ్వాదించండి !',
  },
  REWARD_UNLOCKING_DESC: {
    en: 'Swipe back and forth to find out if you have won a reward',
    gu: 'તમે ઇનામ જીત્યા છો કે નહીં તે શોધવા આગળ અને પાછળ સ્વાઇપ કરો',
    hi: 'आगे और पीछे स्वाइप करके पता लगाए कि क्या आपने इनाम जीता है',
    kn: 'ನೀವು ರಿವಾರ್ಡ್ ಅನ್ನು ಗೆದ್ದಿದ್ದೀರಾ ಎಂದು ಕಂಡುಹಿಡಿಯಲು ಹಿಂದಕ್ಕೆ ಮತ್ತು ಮುಂದಕ್ಕೆ ಸ್ವೈಪ್ ಮಾಡಿ',
    mr: 'तुम्ही काय जिंकले आहे हे जाणून घेण्यासाठी मागे पुढे स्वाइप करा',
    te: 'మీరు బహుమతి గెలుచుకున్నారో లేదో తెలుసుకొనుటకు గీకండి',
  },
  SATISFIED: {
    en: 'SATISFIED',
    gu: 'સંતોષ',
    hi: 'संतुष्ट',
    kn: 'ತೃಪ್ತಿ',
    mr: 'समाधानी',
    te: 'సంతృప్తి చెందెను',
  },
  SHARE_THIS_SHOP_VIA: {
    en: 'Share this shop via',
    gu: 'મારફતે આ દુકાનને શૅર કરો',
    hi: 'के माध्यम से इस दुकान को साझा करें',
    kn: 'ಈ ಅಂಅಗಡಿಯನ್ನು ಮೂಲಕ ವಿನಿಮಯ ಮಾಡಿ',
    mr: 'द्वारे हे दुकान सामायिक करा',
    te: 'ఈ షాప్ ను షేర్ చేయండి',
  },
  SHOP_OFFERS_AVAILABLE: {
    en: 'Shop Offers Available',
    gu: 'દુકાનની ઉપલબ્ધ ઑફરો',
    hi: 'दुकान का उपलब्ध प्रस्ताव',
    kn: 'ಅಂಗಡಿ ಕೊಡುಗೆಗಳು ಲಭ್ಯ',
    mr: 'शॉप ऑफर उपलब्ध',
    te: 'అందుబాటులో ఉన్న షాప్ ఆఫర్లు',
  },
  SAVE: {
    en: 'Save',
    gu: 'સેવ',
    hi: 'सेव',
    kn: 'ಉಳಿಸಿ',
    mr: 'जतन करा',
    te: 'పొదుపు',
  },
  SCRATCH_CARD: {
    en: 'Scratch Card',
    gu: 'સ્ક્રેચ કાર્ડ',
    hi: 'स्क्रैच कार्ड',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್‌',
    mr: 'स्क्रॅच कार्ड',
    te: 'స్క్రాచ్ కార్డ్',
  },
  SCRATCH_CARD_AVAILABLE: {
    en: 'Scratch Card available!',
    gu: 'સ્ક્રેચ કાર્ડ ઉપલબ્ધ!',
    hi: 'स्क्रैच कार्ड उपलब्ध!',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಲಭ್ಯವಿದೆ!',
    mr: 'स्क्रॅच कार्ड उपलब्ध!',
    te: 'స్క్రాచ్ కార్డ్ అందుబాటులో ఉన్నది!',
  },
  SCRATCH_CARD_COUPON_DISCOUNT: {
    en: 'Scratch Card/Coupon Discount',
    gu: 'સ્ક્રેચ કાર્ડ/કૂપન ડિસ્કાઉન્ટ',
    hi: 'स्क्रैच कार्ड/कूपन डिस्काउंट',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ / ಕೂಪನ್ ರಿಯಾಯಿತಿ',
    mr: 'स्क्रॅच कार्ड/कूपन डिस्काउंट',
    te: 'స్క్రాచ్ కార్డ్ /కూపన్ డిస్కౌంట్',
  },
  APPLY_COUPONS_OR_OFFERS_AND_SAVE_MORE: {
    en: 'Apply Coupons / Offers & Save More!',
    gu: 'Apply Coupons / Offers & Save More!',
    hi: 'Apply Coupons / Offers & Save More!',
    kn: 'Apply Coupons / Offers & Save More!',
    mr: 'Apply Coupons / Offers & Save More!',
    te: 'Apply Coupons / Offers & Save More!',
  },
  // delete below only in checkout.jsx remain
  SCRATCH_CARD_AND_COUPON: {
    en: 'Scratch cards and Coupons',
    gu: 'સ્ક્રેચ કાર્ડ્સ અને કૂપનો',
    hi: 'कार्ड और कूपन स्क्रैच करें',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಗಳು ಮತ್ತು ಕೂಪನ್ ಗಳು',
    mr: 'स्क्रॅच कार्ड आणि कूपन',
    te: 'కార్డులు మరియు కూపన్లను స్క్రాచ్ చేయండి',
  },
  // SPECIAL_DISCOUNT_UPTO_X_AMOUNT: {
  //   en: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  //   gu: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  //   hi: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  //   kn: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  //   mr: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  //   te: 'Special 5% discount (upto ₹ 25) when you make online payment!',
  // },
  // VALID_ONLY_ON_ONE_TRANSACTION: {
  //   en: 'Valid only on one transaction every day.',
  //   gu: 'Valid only on one transaction every day.',
  //   hi: 'Valid only on one transaction every day.',
  //   kn: 'Valid only on one transaction every day.',
  //   mr: 'Valid only on one transaction every day.',
  //   te: 'Valid only on one transaction every day.',
  // },
  SCRATCH_CARDS: {
    en: 'Scratch Cards',
    gu: 'સ્ક્રેચ કાર્ડ્સ',
    hi: 'स्क्रैच कार्ड्स',
    kn: 'ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್‌ಗಳು',
    mr: 'स्क्रॅच कार्ड',
    te: 'స్క్రాచ్ కార్డ్స్',
  },
  SCRATCH_NOW: {
    en: 'Scratch Now',
    gu: 'અત્યારે સ્ક્રેચ કરો',
    hi: 'अभी स्क्रेच करें',
    kn: 'ಈಗ ಸ್ಕ್ರಾಚ್ ಮಾಡಿ',
    mr: 'आता स्क्रॅच करा',
    te: 'ఇప్పుడే గీకండి',
  },
  SEARCH: {
    en: 'Search',
    gu: 'શોધો',
    hi: 'खोजे',
    kn: 'ಹುಡುಕಿ',
    mr: 'शोधा',
    te: 'వెతకండి',
  },
  SEARCH_ITEMS: {
    en: 'Search Items',
    gu: 'આઇટમ્સ શોધો',
    hi: 'आइटम खोजें',
    kn: 'ಉತ್ಪನ್ನಗಳನ್ನು ಹುಡುಕಿ',
    mr: 'वस्तू शोधा',
    te: 'వస్తువులను వెతకండి',
  },
  SEARCH_PLACES: {
    en: 'Search Area or Apartment name',
    gu: 'Search Area or Apartment name',
    hi: 'Search Area or Apartment name',
    kn: 'Search Area or Apartment name',
    mr: 'Search Area or Apartment name',
    te: 'Search Area or Apartment name',
  },
  SEARCH_PRODUCTS_AND_BRANDS_ACROSS_SHOPS: {
    en: 'Search products and brands across shops',
    gu: 'દુકાનોમાં ઉત્પાદનો અને બ્રાન્ડ્સ શોધો',
    hi: 'दुकानों में उत्पाद और ब्रांड खोजे',
    kn: 'ಅಂಗಡಿಗಳಲ್ಲಿ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಬ್ರ್ಯಾಂಡ್‌ಗಳನ್ನು ಹುಡುಕಿ',
    mr: 'सर्व दुकानात वस्तू आणि उत्पादने शोधा',
    te: 'షాపులలో వస్తువులు మరియు బ్రాండ్ ల కొరకు వెతకండి',
  },
  SEARCH_SHOP_NEAR_YOU: {
    en: 'Search for a Shop near you',
    gu: 'તમારી નજીકની દુકાન શોધો',
    hi: 'आपके पास कि दुकान खोजें',
    kn: 'ನಿಮ್ಮ ಹತ್ತಿರದ ಅಂಗಡಿಗಾಗಿ ಹುಡುಕಿ',
    mr: 'तुमच्या भागातील एखादे दुकान शोधा',
    te: 'మీకు దగ్గర లోని షాపును వెతకండి',
  },
  SEE_ALL: {
    en: 'See All',
    gu: 'બધું જુઓ',
    hi: 'सब देखे',
    kn: 'ಎಲ್ಲಾ ನೋಡಿ',
    mr: 'सर्व पहा',
    te: 'అన్నీ చూడండి',
  },
  SEE_ORDER_DETAILS: {
    en: 'SEE ORDER DETAILS',
    gu: 'ઓર્ડરની વિગતો જુઓ',
    hi: 'ऑर्डर का विवरण देखें',
    kn: 'ಆರ್ಡರ್ ವಿವರಗಳನ್ನು ನೋಡಿ',
    mr: 'ऑर्डर तपशील पहा',
    te: 'ఆర్డర్ వివరాలు చూడండి',
  },
  SETUP_YOUR_LOCATION: {
    en: 'SETUP YOUR LOCATION',
    gu: 'તમારું સ્થાન સેટ કરો',
    hi: 'अपना स्थान सेट करें',
    kn: 'ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಹೊಂದಿಸಿ',
    mr: 'आपले ठिकाण निवडा',
    te: 'మీ లొకేషన్ ను సెట్అప్ చేయండి',
  },
  SHOP_FROM_YOUR_LOCAL_SHOPS: {
    en: 'Shop from all your local shops on LoveLocal',
    gu: 'લવલોકલ પર તમારી બધી સ્થાનિક દુકાનમાંથી ખરીદી કરો',
    hi: 'लवलोकल पर अपनी सभी स्थानीय दुकानों से खरीदारी करें',
    kn: 'ಲವ್‌ಲೋಕಲ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳಿಂದ ಶಾಪಿಂಗ್ ಮಾಡಿ',
    mr: 'आपल्या सर्व स्थानिक दुकानांमधून LoveLocal वर खरेदी करा',
    te: 'LoveLocal లోని మీ స్థానిక దుకాణాలన్నిటి నుండి కొనండి',
  },
  SELL_ON_LOVELOCAL: {
    en: 'Sell on LoveLocal',
    gu: 'લવલોકલ પર વેચો',
    hi: 'लवलोकल पर बेचें',
    kn: 'LoveLocal ನಲ್ಲಿ ಮಾರಿ',
    mr: 'LoveLocal वर विक्री करा',
    te: 'LoveLocalలో  అమ్మండి',
  },
  SELL_ON_LOVELOCAL_DESC: {
    en: '5 minute me apni LoveLocal Online Shop ka setup karen. Ye service FREE hai!',
    gu: '૫ મિનિટમાં તમારી લવલોકલ ઓનલાઇન દુકાનનું સેટઅપ કરો. આ સેવા મફત છે!',
    hi: '५ मिनट में अपनी  लवलोकल ऑनलाइन दुकान का सेट अप करें। यह सेवा निःशुल्क है!',
    kn: '5 ನಿಮಿಷದಲ್ಲಿ ನಿಮ್ಮ LoveLocal ಆನ್ಲೈನ್ ಅಂಗಡಿಯನ್ನು ಸೆಟಪ್ ಮಾಡಿ. ಈ ಸೇವೆ ಉಚಿತವಾಗಿದೆ!',
    mr: '5 मिनिटात तुमच्या LoveLocal दुकानाची मांडणी करा. ही सेवा अगदी मोफत आहे!',
    te: 'మీ షాపును 5 నిమిషాలలో LoveLocal లో ఆన్ లైన్ చేయవచ్చు. ఈ సేవలు ఉచితం !',
  },
  SENT: {
    en: 'Sent',
    gu: 'મોકલ્યો',
    hi: 'भेज दिया',
    kn: 'ಕಳುಹಿಸಲಾಗಿದೆ',
    mr: 'पाठविले',
    te: 'పంపబడినది',
  },
  SET_LOCATION_TEXT: {
    en: 'Set your location & Shop from shops near you',
    gu: 'તમારી નજીકની દુકાનમાંથી તમારું લોકેશન અને દુકાન સેટ કરો',
    hi: 'अपने आस-पास की दुकानों में से अपनी लोकेशन और दुकान सेट करें',
    kn: 'ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಸೆಟ್ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಹತ್ತಿರದ ಅಂಗದಿಗಳಿಂದ ಶಾಪ್ ಮಾಡಿ',
    mr: 'तुमचे ठिकाण निवडा आणि जवळच्या दुकानातून खरेदी करा',
    te: 'మీ లొకేషన్ ను గుర్తించండి & మీ దగ్గరలోని షాపులలో కొనండి',
  },
  SHARE: {
    en: 'Share',
    gu: 'શેર કરો',
    hi: 'शेयर करें',
    kn: 'ಹಂಚಿ',
    mr: 'सामायिक करा',
    te: 'తెలియచేయండి',
  },
  SHARE_ON_WHATSAPP: {
    en: 'SHARE ON WHATSAPP',
    gu: 'વોટ્સેપ પર શેર કરો',
    hi: 'व्हाट्सएप पर शेयर करें',
    kn: 'ವಾಟ್ಸಾಪ್ ನಲ್ಲಿ ಹಂಚಿ',
    mr: 'व्हॉट्सॲप वर सामायिक करा',
    te: 'వాట్శాప్ లో పంచుకోండి',
  },
  SHARE_OPTIONS: {
    en: 'Share Options',
    gu: 'વિકલ્પો શૅર કરો',
    hi: 'साझा करने के विकल्प',
    kn: 'ಆಯ್ಕೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ',
    mr: 'सामायिक करण्याचे पर्याय',
    te: 'షేర్ ఆప్షన్లు',
  },
  SHOP: {
    en: 'Shop',
    gu: 'ખરીદો',
    hi: 'ख़रीदे',
    kn: 'ಅಂಗಡಿ',
    mr: 'दुकान',
    te: 'షాపు',
  },
  SHOP_BY_BUY1_GET1_OFFERS: {
    en: 'Shop By Buy1  Get1 Offers',
    gu: 'ખરીદો૧ મેળવો૧ ઓફર્સ દ્વારા ખરીદો',
    hi: 'ख़रीदे१ पाइए१ ऑफर्स से ख़रीदे',
    kn: 'ಒಂದು ಕೊಂಡರೆ ಒಂದು ಉಚಿತ ಆಫರ್ ಗಳನ್ನು ಶಾಪ್ ಮಾಡಿ',
    mr: 'एकावर एक मोफत ऑफर द्वारे खरेदी करा',
    te: '1 కొంటె 1 పొందండి ఆఫర్ల వారీగా కొనండి',
  },
  SHOP_BY_CATEGORY: {
    en: 'Shop By Category',
    gu: 'કેટેગરી પ્રમાણે ખરીદી કરો',
    hi: 'केटेगरीं के अनुसार खरीदारी करें',
    kn: 'ವರ್ಗದಿಂದ ಶಾಪ್ ಮಾಡಿ',
    mr: 'श्रेणीद्वारे खरेदी',
    te: 'విభాగాల వారీగా కొనండి',
  },
  SHOP_BY_DISCOUNT_OFFERS: {
    en: 'Shop By Discount Offers',
    gu: 'ડિસ્કાઉન્ટ ઓફર્સ દ્વારા ખરીદી કરો',
    hi: 'डिस्काउंट ऑफर द्वारा खरीदारी करें',
    kn: 'ರಿಯಾಯಿತಿ ಆಫರ್ ಗಳಿಂದ ಶಾಪ್ ಮಾಡಿ',
    mr: 'डिस्काउंट ऑफर द्वारे खरेदी',
    te: 'డిస్కౌంట్ ఆఫర్ల  వారీగా కొనండి',
  },
  SHOP_CLOSED: {
    en: 'Shop is closed right now,but taking online orders',
    gu: 'દુકાન હમણાં બંધ છે, પરંતુ ઓનલાઇન ઓર્ડર લે છે',
    hi: 'दुकान अभी बंद है, लेकिन ऑनलाइन ऑर्डर ले रहे हैं',
    kn: 'ಈಗ ಅಂಗಡಿ ಮುಚ್ಚಿದೆ, ಆದರೆ ಆನ್ಲೈನ್ ಆರ್ಡರ್ ಗಳನ್ನು ಸ್ವೀಕರಿಸುತ್ತಿದೆ',
    mr: 'दुकान सध्या बंद आहे परंतु ऑनलाईन ऑर्डर स्वीकारली जाईल',
    te: 'ప్రస్తుతం షాపు మూసివున్నది , కాని ఆన్లైన్ ఆర్డర్లు తీసుకుంటున్నారు',
  },
  SHOP_CLOSED_DESCRIPTION: {
    en: 'Your order will be processed once the shop has opened',
    gu: 'એકવાર દુકાન ખોલ્યા પછી તમારા ઓર્ડર પર પ્રક્રિયા કરવામાં આવશે',
    hi: 'दुकान खुलने के बाद आपके ऑर्डर पर कार्रवाई की जाएगी',
    kn: 'ಅಂಗಡಿ ತೆರೆದ ನಂತರ ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತದೆ',
    mr: 'दुकान उघडल्यानंतर तुमच्या ऑर्डरची प्रक्रिया करण्यात येईल',
    te: 'షాపు తెరిచిన వెంటనే మీ ఆర్డర్ పంపబడును',
  },
  SHOP_COUPONS: {
    en: 'Shop Coupons',
    gu: 'કુપન્સ ખરીદો',
    hi: 'कूपन्स ख़रीदे',
    kn: 'ಕೂಪನ್ ಶಾಪ್ ಮಾಡಿ',
    mr: 'दुकान कूपन',
    te: 'షాపు కూపన్లు',
  },
  SHOP_DETAILS: {
    en: 'Shop Details',
    gu: 'દુકાનની વિગતો',
    hi: 'दुकान का विवरण',
    kn: 'ಅಂಗಡಿ ವಿವರಗಳು',
    mr: 'दुकान तपशील',
    te: 'షాపు వివరాలు',
  },
  SHOP_DESCRIPTION: {
    en: 'Shop Description',
    gu: 'દુકાનનું વર્ણન',
    hi: 'दुकान विवरण',
    kn: 'ಅಂಗಡಿ ವಿವರಣೆ',
    mr: 'दुकान तपशील',
    te: 'షాపు వివరాలు',
  },
  SHOP_IMAGES: {
    en: 'Shop Images',
    gu: 'દુકાનના ફોટા',
    hi: 'दुकानके फोटो',
    kn: 'ಚಿತ್ರಗಳನ್ನು ಶಾಪ್ ಮಾಡಿ',
    mr: 'दुकान प्रतिमा',
    te: 'షాపు ఇమేజ్ లు',
  },
  SHOP_IS_WAITING: {
    en: '{shopName} Shop is waiting for your payment to complete the order.',
    gu: '{shopName} ઓર્ડર પૂરો કરવા માટે દુકાન તમારી ચુકવણીની રાહ જોઈ રહી છે.',
    hi: '{shopName} दुकान ऑर्डर पूरा करने के लिए आपके भुगतान की प्रतीक्षा कर रही है।',
    kn: 'ಈ ಆರ್ಡರ್ ಪೂರ್ಣಗೊಳಿಸಲು {shopName} ಅಂಗಡಿ ನಿಮ್ಮ ಪಾವತಿಗಾಗಿ ಕಾಯುತ್ತಿದೆ',
    mr: '{shopName} हे दुकान तुमची ऑर्डर पूर्ण करण्यासाठी पेमेंट च्या प्रतीक्षेत आहे.',
    te: '{shopName} షాపు మీ ఆర్డర్ పూర్తి చేయుటకు చెల్లింపు కొరకు వేచిఉన్నది.',
  },
  SHOP_MORE_TO_REDUCE_DELIVERY_FEE: {
    en: 'Minimum Order Value (MOV) for the shop is ₹{mov}. Shop for ₹{amount} more to reduce Delivery Fee',
    gu: 'દુકાન માટે ન્યૂનતમ ઓર્ડર વેલ્યુ (MOV) ₹ {MOV} છે. ડિલિવરી ફી ઘટાડવા માટે {{રકમ} વધુની ખરીદી કરો',
    hi: 'दुकान के लिए न्यूनतम ऑर्डर वेल्यू (MOV) ₹{mov} है। डिलीवरी शुल्क कम करने के लिए ₹{amount} और खरीदारी करें',
    kn: 'ಅಂಗಡಿಯ ಕನಿಷ್ಠ ಆದೇಶ ಮೌಲ್ಯ (MOV) ₹ {mov} ಆಗಿದೆ. ಡೆಲಿವರಿ ಶುಲ್ಕವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ₹{amount} ಗೆ ಹೆಚ್ಚು ಶಾಪಿಂಗ್ ಮಾಡಿ',
    mr: 'या दुकानसाठी किमान ऑर्डर मूल्य (MOV) ₹{mov} आहे. वितरण शुल्क कमी करण्यासाठी अधिक {amount}  खरेदी करा',
    te: 'షాపు కనీస ఆర్డర్ విలువ (MOV) ₹{mov}. ఇంకా ₹{amount}లు కొని డెలివరీ ఛార్జీలు తగ్గించుకోండి',
  },
  SHOP_MORE_TO_GET_FREE_DELIVERY: {
    en: 'Minimum Order Value (MOV) for the shop is ₹{mov}. Shop for ₹{amount} more to get FREE Delivery',
    gu: 'દુકાન માટે ન્યૂનતમ ઓર્ડર વેલ્યુ (MOV) ₹ {MOV} છે. મફત ડિલિવરી મેળવવા માટે {amount} વધુની ખરીદી કરો',
    hi: 'दुकान के लिए न्यूनतम ऑर्डर वेल्यू (MOV) ₹{mov} है। मुफ़्त डिलीवरी पाने के लिए ₹{amount} और ख़रीदें',
    kn: 'ಅಂಗಡಿಯ ಕನಿಷ್ಠ ಆದೇಶ ಮೌಲ್ಯ (MOV) ₹ {mov} ಆಗಿದೆ. ಡೆಲಿವರಿ ಶುಲ್ಕವನ್ನು ಕಡಿಮೆ ಉಚಿತ ಡೆಲಿವರಿ ಮಾಡಲು ₹{amount} ಗೆ ಹೆಚ್ಚು ಶಾಪಿಂಗ್ ಮಾಡಿ',
    mr: 'या दुकानसाठी किमान ऑर्डर मूल्य (MOV) ₹{mov} आहे. मोफत वितरण मिळवण्यासाठी {amount} रुपयांची खरेदी करा',
    te: 'షాపు కనీస ఆర్డర్ విలువ (MOV) ₹{mov}. ఇంకా ₹{amount}లు కొని ఉచిత డెలివరీ పొందండి',
  },
  SHOP_NAME: {
    en: 'Shop Name',
    gu: 'દુકાનનું નામ',
    hi: 'दुकान का नाम',
    kn: 'ಅಂಗಡಿ ಹೆಸರು',
    mr: 'दुकानाचे नाव',
    te: 'షాపు పేరు',
  },
  SHOP_OFFERS: {
    en: 'Shop Offers',
    gu: 'ઓફર્સ ખરીદો',
    hi: 'ऑफर्स ख़रीदे',
    kn: 'ಅಂಗಡಿ ಆಫರ್ ಗಳು',
    mr: 'दुकान ऑफर्स',
    te: 'షాపు ఆఫర్లు',
  },
  SHOP_PRODUCTS: {
    en: 'Shop Products',
    gu: 'દુકાનના ઉત્પાદનો',
    hi: 'उत्पाद खऱीदें',
    kn: 'ಅಂಗಡಿ ಉತ್ಪನ್ನಗಳು',
    mr: 'उत्पादने खरेदी करा',
    te: 'షాప్ ఉత్పత్తులు',
  },
  SEARCH_RESULTS: {
    en: 'Search Results',
    gu: 'શોધના પરિણામો',
    hi: 'खोज परिणाम',
    kn: 'ಫಲಿತಾಂಶಗಳನ್ನು ಹುಡುಕಿ',
    mr: 'शोध परिणाम',
    te: 'ఫలితాలకోసం సెర్చ్ చేయండి',
  },
  SHOP_PICKUP: {
    en: 'Pick Up from Shop',
    gu: 'દુકાનથી લઇ લો',
    hi: 'दुकान से ले लें',
    kn: 'ಅಂಗಡಿಯಿಂದ ಪಿಕ್ ಮಾಡಿ',
    mr: 'दुकानातून घ्या',
    te: 'షాపు నుండి తీసుకువెళ్లండి',
  },
  SHOP_PICKUP_DESC: {
    en: 'Pick the parcel from shop at your convenience',
    gu: 'તમારી સુવિધા પ્રમાણે દુકાનમાંથી પાર્સલ લઇ લો',
    hi: 'अपनी सुविधानुसार दुकान से पार्सल ले लें',
    kn: 'ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕೆ ತಕ್ಕಂತೆ ಅಂಗಡಿಯಿಂದ ಪಾರ್ಸೆಲ್ ಆರಿಸಿ',
    mr: 'आपल्या सोयीनुसार दुकानातून पार्सल घ्या',
    te: 'మీకు వీలైనప్పుడు షాపు నుండి పార్శిల్ తీసుకెళ్ళవలేను',
  },
  SHOP_PICKUP_ONLY: {
    en: 'Shop Pickup Only',
    gu: 'ફક્ત દુકાનથી લો',
    hi: 'सिर्फ दुकान से ले',
    kn: 'ಪಿಕ್ ಅಪ್ ಮಾತ್ರ ಶಾಪ್ ಮಾಡಿ',
    mr: 'फक्त दुकानातून घेणे',
    te: 'షాపు నుండి  తీసుకువెల్లవలెను',
  },
  SHOP_REVIEWS: {
    en: 'Shop Reviews',
    gu: 'દુકાનના રિવ્યુઝ',
    hi: 'दुकान के रिव्यूज़',
    kn: 'ಅಂಗಡಿ ರಿವ್ಯೂಗಳು',
    mr: 'दुकान प्रतिक्रिया',
    te: 'షాపు సమీక్షలు',
  },
  SHOP_TIMING: {
    en: 'Shop Timings',
    gu: 'દુકાનનો સમય',
    hi: 'दुकान का समय',
    kn: 'ಅಂಗಡಿ ಸಮಯ',
    mr: 'दुकानाची वेळ',
    te: 'షాపు వేళలు',
  },
  SHOPS: {
    en: 'Shops',
    gu: 'દુકાનો',
    hi: 'दुकानों',
    kn: 'ಅಂಗಡಿಗಳು',
    mr: 'दुकान',
    te: 'షాపులు',
  },
  SHOWING_SHOPS_IN_ANDHERI: {
    en: 'Showing shops in Andheri, Mumbai by default',
    gu: 'મૂળભૂત રીતે અંધેરી, મુંબઇમાં દુકાનો બતાવી રહ્યા છે',
    hi: 'डिफ़ॉल्ट रूप से अंधेरी, मुंबई में दुकानें दिखा रहा है',
    kn: 'ಪೂರ್ವನಿಯೋಜಿತವಾಗಿ ಮುಂಬೈನ ಅಂಧೇರಿಯಲ್ಲಿನ ಅಂಗಡಿಗಳನ್ನು ತೋರಿಸಲಾಗುತ್ತಿದೆ',
    mr: 'मूलतः अंधेरी, मुंबईमधील दुकाने दर्शवित आहे',
    te: 'డిఫాల్టగా ముంబైలోని అంధేరిలో దుకాణాలను చూపుతోంది',
  },
  SIGN_IN: {
    en: 'Sign In',
    gu: 'સાઇન ઇન',
    hi: 'साइन इन',
    kn: 'ಸೈನ್ ಇನ್ ಮಾಡಿ',
    mr: 'साइन इन करा',
    te: 'సైన్ ఇన్',
  },
  SLOW_ORDER_DELIVERY: {
    en: 'Slow Order Delivery',
    gu: 'ધીમો ઓર્ડર ડિલિવરી',
    hi: 'धीमी ऑर्डर डिलीवरी',
    kn: 'ನಿಧಾನಗತಿಯ ಆದೇಶ ವಿತರಣೆ',
    mr: 'हळू ऑर्डर वितरण',
    te: 'ఆర్డర్ డెలివేరి నెమ్మదిగా ఉన్నది',
  },
  SLOW_ORDER_PROCESSING_SPEED: {
    en: 'Slow Order Processing Speed',
    gu: 'ધીમી ઓર્ડર પ્રોસેસીંગ સ્પીડ',
    hi: 'धीमी ऑर्डर प्रोसेसिंग स्पीड',
    kn: 'ನಿಧಾನ ಆದೇಶ ಪ್ರಕ್ರಿಯೆ ವೇಗ',
    mr: 'हळू ऑर्डर प्रक्रिया वेग',
    te: 'ఆర్డర్ ప్రొసెసింగ్ వేగం నెమ్మదిగా ఉన్నది',
  },
  SOCIAL_SHARE_COPIED_DESC1: {
    en: 'Below text is copied on your clipboard',
    gu: 'અહીં નીચેનું લખાણ ક્લિપબૉર્ડ પર કૉપી થઈ ગયું છે',
    hi: 'नीचे दिए गए पाठ की प्रतिलिपि आपके क्लिपबोर्ड पर बनाई गई है',
    kn: 'ಕೆಳಗಿನ ಪಠ್ಯವನ್ನು ನಿಮ್ಮ ಕ್ಲಿಪ್ ಬೋರ್ಡ್ ಗೆ ಕಾಪಿ ಮಾಡಲಾಗಿದೆ',
    mr: 'खालील मजकूर तुमच्या क्लिपबोर्डवर कॉपी केला आहे',
    te: 'ఈ కింది టెక్స్ట్ మీ క్లిప్ బోర్డుపై కాపీ చేయబడింది',
  },
  SOCIAL_SHARE_COPIED_DESC2: {
    en: ', paste it while sharing your on any social media.',
    gu: ', કોઈપણ સોશિયલ મીડિયા પર તમારી શેર કરતી વખતે તેને પેસ્ટ કરો.',
    hi: ', किसी भी सोशल मीडिया पर अपना साझा करते हुए इसे पेस्ट करें।',
    kn: ', ನಿಮ್ಮ ಯಾವುದೇ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮದಲ್ಲಿ ಹಂಚಿಕೊಳ್ಳುವಾಗ ಅದನ್ನು ಅಂಟಿಸಿ.',
    mr: ', कोणत्याही सोशल मीडियावर शेअर करताना ते पेस्ट करा.',
    te: ', ఏదైనా సోషల్ మీడియాలో మీ భాగస్వామ్యం చేస్తున్నప్పుడు దాన్ని అతికించండి.',
  },
  SORRY: {
    en: 'Sorry :(',
    gu: 'માફ કરશો :(',
    hi: 'माफ़ करना :(',
    kn: 'ಕ್ಷಮಿಸಿ :(',
    mr: 'माफ करा :(',
    te: 'క్షమించండి:(',
  },
  SPECIAL_OFFERS: {
    en: 'Special Offers',
    gu: 'ખાસ ઓફર્સ',
    hi: 'खास ऑफर्स',
    kn: 'ವಿಶೇಷ ಆಫರ್ ಗಳು',
    mr: 'खास ऑफर',
    te: 'ప్రత్యేక ఆఫర్లు',
  },
  START_SELLING: {
    en: 'Start Selling',
    gu: 'વેચાણ શરૂ કરો',
    hi: 'बेचना शुरू करें',
    kn: 'ಮಾರಲು ಪ್ರಾರಂಭಿಸಿ',
    mr: 'विक्री सुरू करा',
    te: 'అమ్మడం మొదలుపెట్టండి',
  },
  START_SELLING_NOW: {
    en: 'Start Selling Now',
    gu: 'હવે વેચવાનું શરુ કરો',
    hi: 'अभी बेचना शुरू करें',
    kn: 'ಮಾರಾಟ ಮಾಡಲು ಈಗಲೇ ಪ್ರಾರಂಭಿಸಿ',
    mr: 'विक्रीस सुरुवात करा',
    te: 'ఇప్పుడే అమ్మడం ప్రారంభించండి',
  },
  STORE_HAS_NO_PHONE_NUMBER_DESC: {
    en: 'This shop hasn’t updated their phone number yet. Thanks for your interest. We’ll get in touch with them separately.',
    gu: 'આ દુકાનમાં તેમનો ફોન નંબર હજી અપડેટ થયો નથી. તમારી રુચિ બદલ આભાર. અમે તેમના સંપર્કમાં અલગથી રહીશું.',
    hi: 'इस दुकान ने अभी तक अपना फ़ोन नंबर अपडेट नहीं किया है आपकी रुचि के लिए धन्यवाद। हम उनके साथ अलग से संपर्क करेंगे।',
    kn: 'ಈ ಅಂಗಡಿ ಅವರ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಇನ್ನೂ ನವೀಕರಿಸಿಲ್ಲ.  ನಿಮ್ಮ ಆಸಕ್ತಿಗೆ ಧನ್ಯವಾದಗಳು.  ನಾವು ಅವರೊಂದಿಗೆ ಪ್ರತ್ಯೇಕವಾಗಿ ಸಂಪರ್ಕ ಸಾಧಿಸುತ್ತೇವೆ.',
    mr: 'या दुकानांनी अद्याप फोन नंबर अद्ययावत केला नाही, तुमच्या रूचीसाठी धन्यवाद, आम्ही लवकरच त्यांच्याशी संपर्क करू.',
    te: 'ఈ షాపు వారు  ఫోను నెంబరును ఇంకా అప్డేట్  చేయలేదు. మీరు చూపుతున్న ఆసక్తికి ధన్యవాదాలు. మేము వారిని విడిగా సంప్రదిస్తాము',
  },
  STORE_INVITE: {
    en: 'Invite your favorite local shops to LoveLocal',
    gu: 'તમારી પસંદની લોકલ દુકાનને લવલોકલ પર આમંત્રિત કરો',
    hi: 'अपने पसंदीदा लोकल दुकानों को लवलोकलपे आमंत्रित करें',
    kn: 'ನಿಮ್ಮ ನೆಚ್ಚಿನ ಸ್ಥಳೀಯ ಅಂಗಡಿಯನ್ನು LoveLocal ಗೆ ಆಹ್ವಾನಿಸಿ',
    mr: 'तुमच्या आवडीच्या स्थानिक दुकानांना LoveLocal वर येण्यासाठी आमंत्रित करा',
    te: 'మీప్రాంతము లోని మీకు ఇష్టమైన షాపులను LoveLocalకు  ఆహ్వానించండి',
  },
  STORE_PICKUP: {
    en: 'Shop Pickup',
    gu: 'દુકાનથી લઇ લો',
    hi: 'दुकान से ले लें',
    kn: 'ಅಂಗಡಿ ಪಿಕ್ ಅಪ್',
    mr: 'दुकानातून घेणे',
    te: 'షాప్ పిక్ అప్',
  },
  STREET_NAME: {
    en: 'Street Name',
    gu: 'Street Name',
    hi: 'Street Name',
    kn: 'Street Name',
    mr: 'Street Name',
    te: 'Street Name',
  },
  START_SHOPPING: {
    en: 'Start Shopping',
    gu: 'ખરીદી શરૂ કરો',
    hi: 'खरीदारी शुरू करें',
    kn: 'ಶಾಪಿಂಗ್ ಶುರು ಮಾಡಿ',
    mr: 'खरेदीस सुरुवात करा.',
    te: 'కొనడం ప్రారంభించండి',
  },
  SELECT_LANGUAGE: {
    en: 'Select Language',
    gu: 'ભાષા પસંદ કરો',
    hi: 'भाषा का चयन करें',
    kn: 'భాషను ఎంచుకోండి',
    mr: 'भाषा निवडा',
    te: 'ಭಾಷೆ ಆರಿಸಿ',
  },

  LANGUAGE_SETTTINGS: {
    en: 'Language Settings',
    gu: 'ભાષા પસંદ કરો',
    hi: 'भाषा का चयन करें',
    kn: 'భాషను ఎంచుకోండి',
    mr: 'भाषा निवडा',
    te: 'ಭಾಷೆ ಆರಿಸಿ',
  },
  SELECT_DELIVERY_METHOD: {
    en: 'Select Delivery Method',
    gu: 'ડીલિવરીની પદ્ધતિ પસંદ કરો',
    hi: 'वितरण विधि का चयन करें',
    kn: 'ಡೆಲಿವರಿ ವಿಧಾನ ಆಯ್ಕೆಮಾಡಿ',
    mr: 'वितरण पद्धत निवडा',
    te: 'డెలివరీ చేస్ పద్ధతిని ఎంచుకోండి',
  },
  SELECT_PAYMENT_METHOD: {
    en: 'Select Payment Method',
    gu: 'ચૂકવણીની પદ્ધતિ પસંદ કરો',
    hi: 'भुगतान विधि का चयन करें',
    kn: 'ಪಾವತಿ ವಿಧಾನ ಆಯ್ಕೆಮಾಡಿ',
    mr: 'पेमेंट पद्धत निवडा',
    te: 'నగదు చెల్లించే ఒక విధానాన్ని ఎంచుకోండి',
  },
  SELECT_A_PAYMENT_METHOD: {
    en: 'Select a payment method',
    gu: 'ચૂકવણીની પદ્ધતિ પસંદ કરો',
    hi: 'भुगतान विधि का चयन करें',
    kn: 'ಪಾವತಿ ವಿಧಾನ ಆಯ್ಕೆಮಾಡಿ',
    mr: 'पेमेंट पद्धत निवडा',
    te: 'డబ్బు చెల్లించే ఒక పద్ధతిని ఎంచుకోండి',
  },
  STREET_NAME_IS_REQUIRED: {
    en: 'Need street name to continue',
    gu: 'Need street name to continue',
    hi: 'Need street name to continue',
    kn: 'Need street name to continue',
    mr: 'Need street name to continue',
    te: 'Need street name to continue',
  },
  SUBMIT: {
    en: 'Submit',
    gu: 'સબમિટ કરો',
    hi: 'सबमिट करें',
    kn: 'ಸಲ್ಲಿಸಿ',
    mr: 'जमा करा',
    te: 'సమర్పించండి',
  },
  SUBMIT_FEEDBACK: {
    en: 'Submit Feedback',
    gu: 'પ્રતિસાદ સબમિટ કરો',
    hi: 'प्रतिपुष्टि दें',
    kn: 'ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಸಲ್ಲಿಸಿ',
    mr: 'प्रतिक्रिया द्या',
    te: 'అభిప్రాయాలను సమర్పించండి',
  },
  SUGGESTED_PRODUCT: {
    en: 'Suggested Product',
    gu: 'સૂચવેલ ઉત્પાદન',
    hi: 'सुझाए गए उत्पाद',
    kn: 'ಸೂಚಿಸಲಾದ ಉತ್ಪನ್ನ',
    mr: 'सुचवलेले उत्पादन',
    te: 'సూచించిన వస్తువు',
  },
  SUPERFAST_DELIVERY: {
    en: 'Superfast Delivery',
    gu: 'સુપરફાસ્ટ ડિલિવરી',
    hi: 'सुपरफास्ट डिलीवरी',
    kn: 'ಅತಿವೇಗದ ವಿತರಣೆ',
    mr: 'वेगवान वितरण',
    te: 'సూపర్  ఫాస్ట్ గా డెలివేరి',
  },
  SUPERFAST_ORDER_PROCESSING: {
    en: 'Superfast Order Processing',
    gu: 'સુપરફાસ્ટ ઓર્ડર પ્રોસેસીંગ',
    hi: 'सुपरफास्ट ऑर्डर प्रोसेसिंग',
    kn: 'ಅತಿವೇಗದ ಆರ್ಡರ್ ಪ್ರಕ್ರಿಯೆ',
    mr: 'वेगवान ऑर्डर प्रक्रिया',
    te: 'ఆర్డర్ ప్రొసెసింగ్ సూపర్ ఫాస్ట్ గా ఉన్నది',
  },
  SUPERFAST_PROCESSING: {
    en: 'Superfast Processing',
    gu: 'સુપરફાસ્ટ પ્રોસેસીંગ',
    hi: 'सुपरफास्ट प्रोसेसिंग',
    kn: 'ಅತಿವೇಗದ ಪ್ರಕ್ರಿಯೆ',
    mr: 'वेगवान प्रक्रिया',
    te: 'సూపర్ ఫాస్ట్ గా ప్రొసెసింగ్',
  },
  SUPERSTAR_SHOP: {
    en: 'Superstar Shop',
    gu: 'સુપરસ્ટાર શોપ',
    hi: 'सुपर स्टार दुकान',
    kn: 'ಸೂಪರ್ಸ್ಟಾರ್ ಅಂಗಡಿ',
    mr: 'सुपरस्टार दुकान',
    te: 'సూపర్ స్టార్ షాపు',
  },
  SUPPORT: {
    en: 'SUPPORT',
    gu: 'આધાર',
    hi: 'सहयोग',
    kn: 'ಬೆಂಬಲ',
    mr: 'मदत',
    te: 'మద్దతు',
  },
  SUPPORT_DESCRIPTION: {
    en: 'LoveLocal Call Support',
    gu: 'લવલોકલ કોલ સપોર્ટ',
    hi: 'लवलोकल कॉल सपोर्ट',
    kn: 'LoveLocal ಸಪೋರ್ಟ್ ಗೆ ಕರೆ ಮಾಡಿ',
    mr: 'LoveLocal कॉलद्वारे मदत',
    te: 'LoveLocal కాల్ సపోర్ట్',
  },
  SURE_YOU_WANT_TO_REMOVE_SHOP_AS_FAVOURITE: {
    en: 'Sure you want to remove this shop from your favorites',
    gu: 'ખાતરી કરો કે તમે આ દુકાનને તમારા મનપસંદમાંથી દૂર કરવા માંગો છો',
    hi: 'सुनिश्चित करें कि आप अपने पसंदीदा से इस दुकान को हटाना चाहते हैं',
    kn: 'ಖಚಿತವಾಗಿ ಈ ಅಂಗಡಿಯನ್ನು ನೀವು ನಿಮ್ಮ ಫಾವರೇಟ್ಸ್ ನಿಂದ ತೆಗೆಯಬೇಕೆ',
    mr: 'या दुकानाला आवडत्या दुकान यादीतून काढण्याची खात्री करा',
    te: 'నిజంగా ఇది మీకు ఇష్టమైన షాపు కాదా',
  },
  TAP_HERE_TO_UPDATE_THE_LOCATION: {
    en: 'Tap here to update the location',
    gu: 'સ્થળને અપડેટ કરવા માટે અહીં ટૅપ કરો',
    hi: 'स्थान अद्यतन करने के लिए यहाँ टैप करें',
    kn: 'ಸ್ಥಳ ಅಪ್ ಡೇಟ್ ಮಾಡಲು ಇಲ್ಲಿ ಟ್ಯಾಪ್ ಮಾಡಿ',
    mr: 'स्थान अद्यावत करण्यासाठी येथे टॅप करा',
    te: 'లొకేషన్ ను అప్డేట్ చేసేందుకు ఇక్కడ ట్యాప్ చేయండి.',
  },
  SELECT_ONE_REASON_DESC: {
    en: 'Select at least one reason to proceed',
    gu: 'આગળ વધવા માટે ઓછામાં ઓછું એક કારણ પસંદ કરો',
    hi: 'आगे बढ़ने के लिए कम से कम एक कारण चुनें',
    kn: 'ಮುಂದುವರಿಯಲು ಕನಿಷ್ಠ ಒಂದು ಕಾರಣವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    mr: 'पुढे जाण्यासाठी किमान एक कारण निवडा',
    te: 'కొనసాగడానికి కనీసం ఒక కారణమైనా ఎంచుకోండి',
  },
  TELL_US_WHAT_YOU_LIKED_MOST: {
    en: 'Tell us what you liked most',
    gu: 'તમને સૌથી વધુ શું ગમ્યું તે અમને કહો',
    hi: 'हमें बताएं कि आपको क्या पसंद आया',
    kn: 'ನೀವು ಹೆಚ್ಚು ಇಷ್ಟಪಟ್ಟದ್ದನ್ನು ನಮಗೆ ತಿಳಿಸಿ',
    mr: 'तुम्हाला सर्वाधिक काय आवडले ते सांगा',
    te: 'మీకు ఏది బాగా నచ్చిందో మాకు చెప్పండి',
  },
  TELL_YOUR_FRIENDS: {
    en: 'Tell Your Friends',
    gu: 'તમારા મિત્રોને કહો',
    hi: 'अपने दोस्तों को बताएँ',
    kn: 'ನಿಮ್ಮ ಸ್ನೇಹಿತರಿಗೆ ತಿಳಿಸಿ',
    mr: 'मित्रांना सांगा',
    te: 'మీ స్నేహితులకు తెలియచేయండి',
  },
  TERMS_CONDITION: {
    en: 'Terms & Condition',
    gu: 'શરતો અને નિયમો',
    hi: 'नियम और शर्तें',
    kn: 'ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು',
    mr: 'नियम व अटी',
    te: 'నిబంధనలు మరియు షరతులు',
  },
  THANKS_FOR_YOUR_INTEREST: {
    en: 'Thanks for your interest!',
    gu: 'તમારી રુચિ બદલ આભાર!',
    hi: 'आपकी रुचि के लिए धन्यवाद!',
    kn: 'ನಿಮ್ಮ ಆಸಕ್ತಿಗಾಗಿ ಧನ್ಯವಾದಗಳು!',
    mr: 'अापण दाखविलेल्या रूचीबद्दल धन्यवाद!',
    te: 'మీ ఆసక్తికి ధన్యవాదాలు!',
  },
  THANKS_FOR_YOUR_INTEREST_DESC: {
    en: "Our team will soon get in touch with the shop. We'll update you once it's live on LoveLocal.",
    gu: 'અમારી ટીમ ટૂંક સમયમાં જ દુકાન સાથે સંપર્ક કરશે. એકવાર તે LoveLocal પર લાઇવ થાય પછી અમે તમને અપડેટ કરીશું.',
    hi: 'हमारी टीम जल्द ही दुकान से संपर्क करेगी। LoveLocal पर लाइव होते ही हम आपको अपडेट करेंगे।',
    kn: 'ನಮ್ಮ ತಂಡವು ಶೀಘ್ರದಲ್ಲೇ ಅಂಗಡಿಯೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸುತ್ತದೆ. LoveLocal ನಲ್ಲಿ ಲೈವ್ ಆದ ನಂತರ ನಾವು ನಿಮ್ಮನ್ನು ನವೀಕರಿಸುತ್ತೇವೆ.',
    mr: 'आमची टीम लवकरच या दुकानाशी संपर्क साधेल. एकदा ते LoveLocal वर लाइव्ह झाल्यावर आम्ही आपल्याला अद्यतनित करू.',
    te: 'మా బృందం త్వరలోనే షాపును సంప్రదిస్తుంది. అది LoveLocal లోకి రాగానే మీకు తెలియాజేస్తాము',
  },
  THIS_IS_HELPFUL: {
    en: 'This is helpful',
    gu: 'આ મદદરૂપ છે',
    hi: 'यह मददगार है',
    kn: 'ಇದು ಉಪಯುಕ್ತವಾಗಿದೆ',
    mr: 'हे उपयुक्त आहे',
    te: 'ఇది సహాయపడుతుంది',
  },
  THIS_OFFER_WILL_BE: {
    en: 'This offer will be listed during checkout. Apply it there.',
    gu: 'આ ઓફર ચેકઆઉટ દરમિયાન સૂચિબદ્ધ થશે. તે ત્યાં લાગુ પડે છે.',
    hi: 'यह ऑफर चेकआउट के दौरान सूचीबद्ध किया जाएगा। यह वहाँ लागू करें।',
    kn: 'ಚೆಕ್ out ಟ್ ಸಮಯದಲ್ಲಿ ಈ ಕೊಡುಗೆಯನ್ನು ಪಟ್ಟಿ ಮಾಡಲಾಗುತ್ತದೆ.  ಅದನ್ನು ಅಲ್ಲಿ ಅನ್ವಯಿಸಿ.',
    mr: 'ही ऑफर चेकआउट वेळी दिसेल. आत्ता लागू करा.',
    te: 'ఈ ఆఫర్ తనిఖీ చేసేటప్పుడు చూపబడుతుంది. అప్పుడు వర్తింపచేయండి.',
  },
  THIS_OFFER_WILL_BE_LISTED_DURING_CHECKOUT: {
    en: 'This offer will be listed during checkout. You can apply it there.',
    gu: 'આ ઓફર ચેકઆઉટ દરમિયાન સૂચિબદ્ધ કરવામાં આવશે. તમે તેને ત્યાં અરજી કરી શકો છો.',
    hi: 'यह ऑफर चेकआउट के दौरान सूचीबद्ध होगा। आप इसे वहां लागू कर सकते हैं।',
    kn: 'ಚೆಕ್ out ಟ್ ಸಮಯದಲ್ಲಿ ಈ ಕೊಡುಗೆಯನ್ನು ಪಟ್ಟಿ ಮಾಡಲಾಗುತ್ತದೆ. ನೀವು ಅದನ್ನು ಅಲ್ಲಿ ಅನ್ವಯಿಸಬಹುದು.',
    mr: 'ही ऑफर चेकआउट दरम्यान सूचीबद्ध केली जाईल. आपण तेथे ते लागू करू शकता.',
    te: 'చెక్అవుట్ సమయంలో ఈ ఆఫర్ జాబితా చేయబడుతుంది. మీరు దానిని అక్కడ దరఖాస్తు చేసుకోవచ్చు.',
  },
  TO: {
    en: 'to',
    gu: 'પ્રતિ',
    hi: 'प्रति',
    kn: 'ಗೆ',
    mr: 'ते',
    te: 'యొక్క',
  },
  TO_PROCEED: {
    en: 'to proceed.',
    gu: 'આગળ વધવા માટે',
    hi: 'आगे बढ़ने के लिए',
    kn: 'ಮುಂದುವರೆಯಲು.',
    mr: 'सुरू ठेवण्यासाठी.',
    te: 'కొనసాగుటకు',
  },
  TOP_PICKS_FOR_YOU: {
    en: 'Top Picks For You',
    gu: 'તમારા માટે ટોચની ચૂંટણીઓ',
    hi: 'आपके लिए टोप की पिक्स',
    kn: 'ನಿಮಗಾಗಿ ಉನ್ನತ ಆಯ್ಕೆಗಳು',
    mr: 'आपल्यासाठी शीर्ष निवडी',
    te: 'మీ కోసం ఎక్కువగ కొన్నవి',
  },
  TOP_PICKS_FOR_YOU_SUBHEADING: {
    en: 'Shops running the most exciting offers',
    gu: 'સૌથી ઉત્તેજક ઓફર્સ ચલાવનારી દુકાન',
    hi: 'सबसे रोमांचक ऑफ़र चलाने वाली दुकानें',
    kn: 'ಅತ್ಯಂತ ರೋಮಾಂಚಕಾರಿ ಕೊಡುಗೆಗಳನ್ನು ನಡೆಸುತ್ತಿರುವ ಅಂಗಡಿಗಳು',
    mr: 'सर्वात रोमांचक ऑफर चालविणारी दुकाने',
    te: 'ఎంతో ఆకర్షణీయమైన ఆఫర్లను ఇస్తున్న షాపులు',
  },
  TOP_SAVING_OFFERS: {
    en: 'Top Savings',
    gu: 'ટોચની બચત',
    hi: 'शीर्ष बचत',
    kn: 'ಉನ್ನತ ಉಳಿತಾಯ',
    mr: 'सर्वाधिक बचत',
    te: 'టాప్ సేవింగ్స్',
  },
  TOP_SAVINGS: {
    en: 'Top Savings From Local Shops',
    gu: 'સ્થાનિક દુકાનમાંથી ટોચની બચત',
    hi: 'स्थानीय दुकानों से शीर्ष बचत',
    kn: 'ಸ್ಥಳೀಯ ಅಂಗಡಿಗಳಿಂದ ಉನ್ನತ ಉಳಿತಾಯ',
    mr: 'स्थानिक दुकानातून सर्वाधिक बचत',
    te: 'స్థానిక దుకాణాలలో టాప్ సేవింగ్స్',
  },
  TOP_SAVINGS_FOR_YOU: {
    en: 'Top Savings For You',
    gu: 'તમારા માટે ટોચની બચત',
    hi: 'आपके लिए टोप की बचत',
    kn: 'ನಿಮಗಾಗಿ ಉನ್ನತ ಉಳಿತಾಯ',
    mr: 'आपल्यासाठी शीर्ष बचत',
    te: 'మీ కోసం ఎక్కువ పొదుపు ఇచ్చేవి',
  },
  TOP_SAVINGS_SUBHEADING: {
    en: 'Max discounts from your favorite shops',
    gu: 'તમારી પસંદની સ્થાનિક દુકાનોમાંથી સૌથી વધુ ડિસ્કાઉન્ટ',
    hi: 'अपने पसंदीदा स्थानीय दुकानों से सबसे अधिक डिस्काउंट',
    kn: 'Max discounts from your favorite shops',
    mr: 'Max discounts from your favorite shops',
    te: 'Max discounts from your favorite shops',
  },
  TOTAL: {
    en: 'Total',
    gu: 'કુલ',
    hi: 'कुल',
    kn: 'ಒಟ್ಟು',
    mr: 'एकूण',
    te: 'మోత్తం',
  },
  TOTAL_AMOUNT: {
    en: 'Total Amount',
    gu: 'કુલ રકમ',
    hi: 'कुल रकम',
    kn: 'ಒಟ್ಟು ಮೊತ್ತ',
    mr: 'एकूण रक्कम',
    te: 'మోత్తం సొమ్ము',
  },
  TOTAL_YOU_SAVED: {
    en: 'Total You Saved',
    gu: 'તમે કુલ બચત કરો છો',
    hi: 'आपके द्वारा बचाया गया कुल',
    kn: 'ನೀವು ಉಳಿಸಿರುವ ಒಟ್ಟು ಹಣ',
    mr: 'तुम्ही केलेली एकूण बचत',
    te: 'మీరు సేవ్ చేసిన మొత్తం',
  },
  TOTAL_YOU_PAY: {
    en: 'Total You Pay',
    gu: 'તમે કુલ ચૂકવો છો',
    hi: 'आप कुल भुगतान करते हैं',
    kn: 'ನೀವು ಪಾವತಿಸಬೇಕಾದ ಒಟ್ಟು ಹಣ',
    mr: 'तुम्ही देत असलेली एकूण रक्कम',
    te: 'మీరు చెల్లించాల్సిన మొత్తం',
  },
  TOTAL_YOU_PAID: {
    en: 'Total You Paid',
    gu: 'તમે ચૂકવેલ કુલ',
    hi: 'कुल आपने भुगतान किया',
    kn: 'ನೀವು ಪಾವತಿಸಿದ ಒಟ್ಟು',
    mr: 'तुम्ही दिलेले एकूण',
    te: 'మీరు చెల్లించిన మొత్తం',
  },
  TRACK_ORDER: {
    en: 'Track Order',
    gu: 'ઑર્ડરને ટ્રેક કરો',
    hi: 'ऑर्डर ट्रैक करें',
    kn: 'ಆರ್ಡರ್ ಟ್ರ್ಯಾಕ್ ಮಾಡಿ',
    mr: 'ऑर्डर ट्रॅक करा',
    te: 'ట్రాక్ ఆర్డర్',
  },
  COD_PAYMENT_DESC: {
    en: 'Please make your payment in cash to the delivery executive.',
    gu: 'કૃપા કરીને ડિલિવરી એક્ઝિક્યુટિવને તમારી ચુકવણી રોકડમાં કરો.',
    hi: 'कृपया डिलीवरी एक्जीक्यूटिव को अपना भुगतान नकद में करें।',
    kn: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪಾವತಿಯನ್ನು ವಿತರಣಾ ಕಾರ್ಯನಿರ್ವಾಹಕರಿಗೆ ನಗದು ರೂಪದಲ್ಲಿ ಮಾಡಿ.',
    mr: 'कृपया डिलिव्हरी एक्झिक्युटिव्हला तुमचे पेमेंट रोख स्वरूपात करा.',
    te: 'దయచేసి మీ చెల్లింపును డెలివరీ ఎగ్జిక్యూటివ్‌కు నగదు రూపంలో చేయండి.',
  },
  ORDER_ACCEPT_ONLINE_PAYMENT_DESC: {
    en: 'You can make your payment once the retailer has accepted the order. You will be notified.',
    gu: 'એકવાર રિટેલરે ઓર્ડર સ્વીકારી લીધા પછી તમે તમારી ચુકવણી કરી શકો છો. તમને જાણ કરવામાં આવશે.',
    hi: 'रिटेलर द्वारा ऑर्डर स्वीकार करने के बाद आप अपना भुगतान कर सकते हैं। आपको सूचित किया जाएगा।',
    kn: 'ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿ ಆದೇಶವನ್ನು ಸ್ವೀಕರಿಸಿದ ನಂತರ ನೀವು ನಿಮ್ಮ ಪಾವತಿಯನ್ನು ಮಾಡಬಹುದು. ನಿಮಗೆ ಸೂಚನೆ ನೀಡಲಾಗುವುದು.',
    mr: 'किरकोळ विक्रेत्याने ऑर्डर स्वीकारल्यानंतर तुम्ही तुमचे पेमेंट करू शकता. तुम्हाला सूचित केले जाईल.',
    te: 'రిటైలర్ ఆర్డర్‌ను ఆమోదించిన తర్వాత మీరు మీ చెల్లింపు చేయవచ్చు. మీకు తెలియజేయబడుతుంది.',
  },
  TRANSACTION_ARE_SAFE: {
    en: 'All transactions are safe and secure',
    gu: 'આ એપ્લિકેશન પરના તમામ વ્યવહારો સલામત અને સુરક્ષિત છે',
    hi: 'इस ऐप पर सभी लेनदेन सलामत और सुरक्षित हैं',
    kn: 'ಈ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿನ ಎಲ್ಲಾ ವಹಿವಾಟುಗಳು ಸುರಕ್ಷಿತ ಮತ್ತು ಸುರಕ್ಷಿತವಾಗಿವೆ',
    mr: 'या अ‍ॅपवरील सर्व व्यवहार आहेत.',
    te: 'ఈ ఆప్ లో అన్ని లావాదేవీలు సురక్షితం మరియు భద్రమయినది',
  },
  TWITTER: {
    en: 'Twitter',
    gu: 'ટ્વિટર',
    hi: 'ट्विटर',
    kn: 'ಟ್ವಿಟ್ಟರ್',
    mr: 'ट्विटर',
    te: 'ట్విటర్',
  },
  UNAVAILABLE: {
    en: 'Unavailable',
    gu: 'અનુપલબ્ધ',
    hi: 'अनुपलब्ध',
    kn: 'ಅಲಭ್ಯ',
    mr: 'उपलब्ध नाही',
    te: 'అందుబాటులోలేవు',
  },
  UNLOCK_REWARD_ON_PARTICULAR_DATE_DESC: {
    en: 'Unlock this coupon on {date} to find out what you’ve won',
    gu: 'તમે શું જીત્યું છે તે જાણવા માટે આ કૂપનને {date} પર અનલોક કરો',
    hi: 'आपने जो जीता है, उसे जानने के लिए इस कूपन को {date} पर अनलॉक करें',
    kn: 'ನೀವು ಗೆದ್ದದ್ದನ್ನು ಕಂಡುಹಿಡಿಯಲು {date} ದಂದು ಈ ಕೂಪನ್ ಅನ್ನು ಅನ್ಲಾಕ್ ಮಾಡಿ',
    mr: 'तुम्ही काय जिंकले आहे हे जाणून घेण्यासाठी कूपन {date} या दिवशी उघडा',
    te: 'మీరు ఏమి గెలిచారో తెలుసుకోవడానికి {date} న ఈ కూపన్ ను అనలాక్ చేయండి',
  },
  UNSATISFIED: {
    en: 'Unsatisfied',
    gu: 'અસંતુષ્ટ',
    hi: 'असंतुष्ट',
    kn: 'ಅತೃಪ್ತಿ',
    mr: 'असमाधानी',
    te: 'అసంతృప్తిగా',
  },
  UPDATE: {
    en: 'Update',
    gu: 'અપડેટ',
    hi: 'अपडेट',
    kn: 'ನವೀಕರಿಸಿ',
    mr: 'अद्ययावत करा',
    te: 'అప్డేట్',
  },
  UPDATED_QUANTITY: {
    en: 'Updated Quantity',
    gu: 'અપડેટ કરેલ જથ્થો',
    hi: 'अपडेट की गई मात्रा',
    kn: 'ನವೀಕರಿಸಿದ ಪ್ರಮಾಣ',
    mr: 'अद्ययावत संख्या',
    te: 'ఎన్ని కావాలో అప్డేట్ చేయండి',
  },
  UPDATED_PRICE: {
    en: 'Updated Price',
    gu: 'અપડેટેડ ભાવ',
    hi: 'अपडेटेड मूल्य',
    kn: 'ನವೀಕರಿಸಿದ ಬೆಲೆ',
    mr: 'अपडेटेड किंमत',
    te: 'నవీకరించబడిన ధర',
  },
  VALID_TILL: {
    en: 'Valid Till',
    gu: 'સુધી માન્ય',
    hi: 'तक मान्य',
    kn: 'ವರೆಗೂ ಮಾನ್ಯ',
    mr: 'पर्यंत वैध',
    te: 'వరకు చెల్లుతుంది',
  },
  VALUE: {
    en: 'Value',
    gu: 'મૂલ્ય',
    hi: 'मूल्य',
    kn: 'ಮೌಲ್ಯ',
    mr: 'मूल्य',
    te: 'విలువ',
  },
  VALUE_OFFER: {
    en: 'Value Offer',
    gu: 'વેલ્યુ ઓફર',
    hi: 'वेल्यू ऑफर',
    kn: 'ಮೌಲ್ಯ ಆಫರ್',
    mr: 'मूल्य ऑफर',
    te: 'వాల్యూ ఆఫర్',
  },
  VALUE_OFFER_APPLIED: {
    en: 'Value Offer Applied!',
    gu: 'વેલ્યુ ઓફર લાગુ!',
    hi: 'वेल्यू ऑफर लागू!',
    kn: 'ಮೌಲ್ಯ ಕೊಡುಗೆ ಅನ್ವಯಿಸಲಾಗಿದೆ!',
    mr: 'मूल्य ऑफर लागू केली!',
    te: 'వాల్యూ ఆఫర్ వర్తింపచేయబడినది!',
  },
  VALUE_OFFER_APPLIED_DESC: {
    en: 'Offer has been successfully applied. Happy shopping!',
    gu: 'ઓફર સફળતાપૂર્વક લાગુ કરવામાં આવી છે. હેપી શોપિંગ!',
    hi: 'ऑफ़र को सफलतापूर्वक लागू किया गया है। खरीदारी के लिए शुभकामनाएं!',
    kn: 'ಕೊಡುಗೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ. ಹ್ಯಾಪಿ ಶಾಪಿಂಗ್!',
    mr: 'ऑफर यशस्वीरीत्या लागू केली आहे. खरेदीचा आनंद घ्या!',
    te: 'ఆఫర్ విజయవంతంగా వర్తింపచేయబడినది. సంతోషంగా కొనుక్కోండి!',
  },
  VALUE_OFFER_AVAILABLE: {
    en: '{offerCount} Value offers available',
    gu: '{offerCount} વેલ્યુ ઓફર્સ ઉપલબ્ધ છે',
    hi: '{offerCount} वेल्यू ऑफर्स उपलब्ध है',
    kn: '{offerCount} ಮೌಲ್ಯ ಆಫರ್ ಲಭ್ಯವಿದೆ',
    mr: '{offerCount} मूल्य ऑफर उपलब्ध',
    te: '{offerCount} వాల్యూ ఆఫర్లు అందుబాటులోవున్నవి',
  },
  VALUE_OFFER_DISCOUNT: {
    en: 'Value Offer Discount',
    gu: 'વેલ્યુ ઓફર ડિસ્કાઉન્ટ',
    hi: 'वेल्यू ऑफर डिस्काउंट',
    kn: 'ಮೌಲ್ಯ ಆಫರ್ ರಿಯಾಯಿತಿ',
    mr: 'मूल्य ऑफर किंमत',
    te: 'వాల్యూ ఆఫర్ డిస్కౌంట్',
  },
  VALUE_OFFERS: {
    en: 'Value Offers',
    gu: 'વેલ્યુ ઓફર્સ',
    hi: 'वेल्यू ऑफर्स',
    kn: 'ಮೌಲ್ಯ ಆಫರ್ ಗಳು',
    mr: 'मूल्य ऑफर',
    te: 'వాల్యూ ఆఫర్లు',
  },
  VALUE_OFFERS_FOR_YOU: {
    en: 'Value Offers For You',
    gu: 'તમારા માટે મૂલ્ય ersફર્સ',
    hi: 'आपके लिए मूल्य प्रस्ताव',
    kn: 'ನಿಮಗಾಗಿ ಮೌಲ್ಯ ಕೊಡುಗೆಗಳು',
    mr: 'आपल्यासाठी मूल्य ऑफर',
    te: 'మీ కోసం విలువ ఆఫర్లు',
  },
  VIEW_ALL_ORDERS: {
    en: 'View all orders',
    gu: 'તમામ ઓર્ડર જુઓ',
    hi: 'सभी ऑर्डर देखें',
    kn: 'ಎಲ್ಲಾ ಆದೇಶಗಳನ್ನು ವೀಕ್ಷಿಸಿ',
    mr: 'सर्व ऑर्डर पहा',
    te: 'అన్ని ఆర్డర్లను చూడండి',
  },
  VIEW_DETAILS: {
    en: 'View Details',
    gu: 'વિગતો જુઓ',
    hi: 'विवरण देखें',
    kn: 'ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ',
    mr: 'तपशील पहा',
    te: 'వివరాలు చూడండి',
  },
  VIEW_MORE: {
    en: 'View More',
    gu: 'વધુ જુઓ',
    hi: 'और देखें',
    kn: 'ಇನ್ನಷ್ಟು ವೀಕ್ಷಿಸಿ',
    mr: 'अजून पहा',
    te: 'మరిన్ని చూడండి',
  },
  VIEW_PAST_ORDERS: {
    en: 'View past orders',
    gu: 'પાછલા ઓર્ડર જુઓ',
    hi: 'पिछले ऑर्डर देखें',
    kn: 'ಹಿಂದಿನ ಆರ್ಡರ್ ಗಳನ್ನು ವೀಕ್ಷಿಸಿ',
    mr: 'मागील ऑर्डर पहा',
    te: 'గత  ఆర్డర్లు చూడండి',
  },
  VISIT: {
    en: 'Visit',
    gu: 'મુલાકાત લો',
    hi: 'मुलाकात ले',
    kn: 'ಭೇಟಿ',
    mr: 'भेट द्या',
    te: 'సందర్శించండి',
  },
  VISIT_SHOP: {
    en: 'Visit Shop',
    gu: 'દુકાનની મુલાકાત લો',
    hi: 'दुकान पर जाएँ',
    kn: 'ಮಳಿಗೆಗೆ ಭೇಟಿ ನೀಡಿ',
    mr: 'दुकानात भेट द्या',
    te: 'షాపును సందర్శించండి',
  },
  VISIT_SHOP_TO_COLLECT_ORDER: {
    en: 'You’ll have to visit the shop to collect your order',
    gu: 'તમારો ઓર્ડર લેવા માટે તમારે દુકાનની મુલાકાત લેવી પડશે',
    hi: 'आपको अपना ऑर्डर लेने के लिए दुकान पर जाना होगा',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಲು ನೀವು ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡಬೇಕಾಗುತ್ತದೆ',
    mr: 'ऑर्डर स्वीकारण्यासाठी करण्यासाठी आपल्याला दुकानात जावे लागेल.',
    te: 'షాపుకు  వచ్చి ఆర్డర్ తీసుకెళ్ళవలేను',
  },
  WANT_TO_REPEAT: {
    en: 'Want to repeat',
    gu: 'રિપીટ કરવા માંગો છો',
    hi: 'दोहराना चाहते हैं',
    kn: 'ಪುನರಾವರ್ತಿಸಲು ಬಯಸುವಿರಾ',
    mr: 'पुन्हा करू इच्छिता',
    te: 'తిరిగి ప్రయత్నిస్తారా',
  },
  WANT_TO_SELL_ON_LOVELOCAL: {
    en: 'Want to Sell on LoveLocal?',
    gu: 'શું તમે લવલોકલ પર વેચવા માંગો છો?',
    hi: 'क्या आप लवलोकल पर बेचना चाहते हैं?',
    kn: 'LoveLocal ನಲ್ಲಿ ಮಾರಬೇಕೇ?',
    mr: 'LoveLocal वर विक्री करू इच्छिता?',
    te: 'LoveLocal లో అమ్మ దలుచుకున్నారా ?',
  },
  WE_CAN_NOT_DO_HOME_DELIVERY: {
    en: 'We cannot do home delivery due to lack of staff because of COVID-19. Kindly visit our shop to pick up your order.',
    gu: 'COVID-19 ને કારણે સ્ટાફના અભાવને કારણે અમે હોમ ડિલિવરી કરી શકતા નથી. તમારો ઓર્ડર લેવા માટે કૃપા કરીને અમારી દુકાનની મુલાકાત લો.',
    hi: 'हम COVID-19 के कारण कर्मचारियों की कमी के कारण होम डिलीवरी नहीं कर सकते। अपना ऑर्डर लेने के लिए कृपया हमारी दुकान पर जाएं।',
    kn: 'COVID-19 ಕಾರಣ ಸಿಬ್ಬಂದಿ ಕೊರತೆಯಿಂದಾಗಿ ನಾವು ಮನೆ ವಿತರಣೆಯನ್ನು ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ.  ನಿಮ್ಮ ಆರ್ಡರ್ ಅನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ದಯವಿಟ್ಟು ನಮ್ಮ ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡಿ.',
    mr: 'कोव्हिड 19 मुळे स्टाफ कमी असल्याने आम्ही घरपोच वितरण देऊ शकत नाही, कृपया दुकानात जाऊन तुमची ऑर्डर घ्या.',
    te: 'COVID-19 కారణంగా సిబ్బంది కొరత వలన ఇంటికి డెలివేరి చేయలేము. దయచేసి మా షాపు  వద్ద ఆర్డర్ చేసిన వస్తువులు తీసుకొనవలెను',
  },
  WHAT_DID_THE_SHOP_IMPRESS_YOU_WITH: {
    en: 'What did the shop impress you with?',
    gu: 'દુકાન તમને શું પ્રભાવિત કરી?',
    hi: 'दुकान ने आपको क्या प्रभावित किया?',
    kn: 'ಅಂಗಡಿ ನಿಮ್ಮನ್ನು ಏನು ಆಕರ್ಷಿಸಿತು?',
    mr: 'तुम्हाला दुकान बद्दल काय आवडले?',
    te: 'ఈ షాపు మిమ్మల్నిఏవిధంగా ఆకట్టుకున్నది ?',
  },
  WHAT_WENT_WRONG: {
    en: 'What went wrong?',
    gu: 'શું ખોટું થયું હતું?',
    hi: 'क्या गलत हुआ?',
    kn: 'ಏನು ತಪ್ಪಾಗಿದೆ?',
    mr: 'काहीतरी त्रुटी आहे?',
    te: 'ఎక్కడ పొరపాటు జరిగినది?',
  },
  WHAT_WERE_YOU_UNHAPPY_WITH: {
    en: 'What were you unhappy with?',
    gu: 'તમે શેનાથી નાખુશ હતા?',
    hi: 'आप किससे  नाखुश थे?',
    kn: 'ನೀವು ಏನು ಅಸಮಾಧಾನ ಹೊಂದಿದ್ದೀರಿ?',
    mr: 'आपण कश्याबद्दल असमाधानी आहात?',
    te: 'మీకు అసంతృప్తి కలిగించిన  విషయం ఏమిటి?',
  },
  WHATSAPP: {
    en: 'WhatsApp',
    gu: 'વૉટ્સએપ',
    hi: 'WhatsApp',
    kn: 'ವಾಟ್ಸಾಪ್',
    mr: 'व्हाट्सअप',
    te: 'వాట్సాప్',
  },
  WHATS_WERE_YOU_IMPRESSED_WITH: {
    en: 'What were you impressed with?',
    gu: 'તમે શેનાથી પ્રભાવિત થયા હતા?',
    hi: 'आप किससे प्रभावित थे?',
    kn: 'ನೀವು ಏನು ಪ್ರಭಾವಿತರಾಗಿದ್ದೀರಿ?',
    mr: 'तुम्हाला सर्वाधिक काय आवडले?',
    te: 'మిమ్మలిని ఆకట్టుకున్న విషయం ఏమిటి?',
  },
  WHERE_IS_THE_LOVE: {
    en: 'Where is the love?',
    gu: 'લવ ક્યાં છે?',
    hi: 'लव कहा है?',
    kn: 'ಪ್ರೀತಿ ಎಲ್ಲಿದೆ?',
    mr: 'तुमची आवड कुठे आहे?',
    te: 'లవ్ ఎక్కడ ఉన్నది ?',
  },
  WIDE_VARIETY_OF_PRODUCT: {
    en: 'Wide Variety of Products',
    gu: 'ઉત્પાદનોની વિવિધતા',
    hi: 'उत्पाद की व्यापक विविधता',
    kn: 'ವ್ಯಾಪಕ ವೈವಿಧ್ಯಮಯ ಉತ್ಪನ್ನಗಳು',
    mr: 'उत्पादनांची विस्तृत विविधता',
    te: 'అనేక రకాల వస్తువులు',
  },
  WIN_GREAT_DISCOUNTS_DESC: {
    en: 'Win great discounts for each friend you refer',
    gu: 'તમારા દ્વારા રિફર દરેક મિત્ર માટે શાનદાર ડિસ્કાઉન્ટ જીતો',
    hi: 'आपके द्वारा रिफर प्रत्येक मित्र के लिए शानदार डिस्काउंट जीतें',
    kn: 'ನೀವು ರೆಫರ್ ಮಾಡುವ ಪ್ರತಿಯೊಬ್ಬ ಸ್ನೇಹಿತರಿಗೂ ಉತ್ತಮ ರಿಯಾಯಿತಿಯನ್ನು ನೀಡಿ',
    mr: 'तुम्ही मित्रांना आमंत्रित केलेल्या प्रत्येक आमंत्रानासाठी भरघोस डिस्काउंट जिंका',
    te: 'మీరు సూచించే ప్రతి స్నేహితుడి వలన మీరు గొప్ప డిస్కౌంట్లను గెలుచుకుంటారు',
  },
  WRITE_A_REVIEW: {
    en: 'Write a review',
    gu: 'રીવ્યુ લખો',
    hi: 'रिव्यू लिखे',
    kn: 'ವಿಮರ್ಶೆಯನ್ನು ಬರೆಯಿರಿ',
    mr: 'प्रतिक्रिया द्या',
    te: 'వక సమీక్ష రాయండి',
  },
  WRITE_REVIEW: {
    en: 'Write Review',
    gu: 'રીવ્યુ લખો',
    hi: 'रिव्यू लिखें',
    kn: 'ರಿವ್ಯೂ ಬರೆಯಿರಿ',
    mr: 'प्रतिक्रिया नोंदवा',
    te: 'సమీక్ష రాయండి',
  },
  YAY: {
    en: 'Yay!',
    gu: 'અરે!',
    hi: 'अरे!',
    kn: 'ವಾಹ್!',
    mr: 'ये!',
    te: 'అవును  !',
  },
  YES_REMOVE: {
    en: 'Yes Remove it',
    gu: 'હા, તેને દૂર કરો',
    hi: 'हां, इसे हटा दें',
    kn: 'ಹೌದು ಅದನ್ನು ತೆಗೆದುಹಾಕಿ',
    mr: 'होय काढून टाका',
    te: 'అవును దానిని తొలిగించండి',
  },
  YES_REMOVE_IT: {
    en: 'Yes Remove it',
    gu: 'હા, તેને દૂર કરો',
    hi: 'Yes Remove i',
    kn: 'ಹೌದು ಅದನ್ನು ತೆಗೆದುಹಾಕಿ',
    mr: 'होय काढून टाका',
    te: 'అవును దాన్ని తొలిగించండి',
  },
  YOUR_NAME: {
    en: 'Your Name',
    gu: 'તમારું નામ',
    hi: 'तुम्हारा नाम',
    kn: 'ನಿಮ್ಮ ಹೆಸರು',
    mr: 'आपले नाव',
    te: 'మీ పేరు',
  },
  YOU_DID_NOT_FIND_THIS_ANSWER_HELPFUL: {
    en: 'You did not find this answer helpful',
    gu: 'તમને આ જવાબ મદદગાર લાગ્યો નથી',
    hi: 'आपको यह उत्तर मददगार नहीं लगा',
    kn: 'ಈ ಉತ್ತರವು ನಿಮಗೆ ಸಹಾಯಕವಾಗಲಿಲ್ಲ',
    mr: 'आपणास हे उत्तर उपयुक्त वाटले नाही',
    te: 'ఈ సమాధానం మీకు సహాయకరంగా లేదు',
  },
  YOU_FOUND_THIS_ANSWER_HELPFUL: {
    en: 'You found this answer helpful',
    gu: 'તમને આ જવાબ મદદરૂપ થયો',
    hi: 'आपको यह उत्तर मददगार लगा',
    kn: 'ಈ ಉತ್ತರವು ನಿಮಗೆ ಸಹಾಯಕವಾಗಿದೆ',
    mr: 'आपल्याला हे उत्तर उपयुक्त वाटले',
    te: 'ఈ సమాధానం మీకు సహాయకరంగా ఉంది',
  },
  YOU_HAVE_UNUSED_SCRATCH_CARDS: {
    en: 'You have {count} unused Scratch Card(s)',
    gu: 'તમારી પાસે {count} નહિ વપરાયેલ સ્ક્રેચ કાર્ડ (ઓ) છે',
    hi: 'आपके पास {count} अप्रयुक्त स्क्रेच कार्ड है',
    kn: 'ನೀವು {count} ಬಳಕೆಯಾಗದ ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್‌ ಹೊಂದಿದ್ದಿರಿ',
    mr: 'तुमच्याकडे {count} न वापरलेली स्क्रॅच कार्ड आहेत.',
    te: 'మీ వద్ద  ఉపయోగించని {count} స్క్రాచ్ కార్డ్ ఉన్నాయి',
  },
  YOU_HAVE_WON_SCRATCH_CARD: {
    en: 'You won a scratch card!',
    gu: 'તમે એક સ્ક્રેચ કાર્ડ જીત્યું છે!',
    hi: 'आपने एक स्क्रैच कार्ड जीता है!',
    kn: 'ನೀವು ಸ್ಕ್ರ್ಯಾಚ್ ಕಾರ್ಡ್ ಗೆದ್ದಿದಿರ',
    mr: 'तुम्ही एक स्क्रॅच कार्ड जिंकले आहे!',
    te: 'మీరు స్క్రాచ్ కార్డ్ గెలుచుకున్నారు',
  },
  YOU_SAVE: {
    en: 'You Save',
    gu: 'તમે બચાવો',
    hi: 'आप बचाते हैं',
    kn: 'ನೀವು ಉಳಿಸಿ',
    mr: 'तुम्ही जतन करा',
    te: 'మీరు పొదుపు చేసినది',
  },
  YOUR_ACCOUNT: {
    en: 'Your Account',
    gu: 'તમારું ખાતું',
    hi: 'आपका खाता',
    kn: 'ನಿಮ್ಮ ಖಾತೆ',
    mr: 'तुमचे खाते',
    te: 'మీ ఖాతా',
  },
  YOUR_ADDRESS: {
    en: 'Your Address',
    gu: 'તમારું એડ્રેસ',
    hi: 'आपका एड्रेस',
    kn: 'ನಿಮ್ಮ ವಿಳಾಸ',
    mr: 'तुमचा पत्ता',
    te: 'మీ అడ్రసు',
  },
  YOUR_FEEDBACK: {
    en: 'Your Feedback',
    gu: 'તમારો અભિપ્રાય',
    hi: 'आपका अभिप्राय',
    kn: 'ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ',
    mr: 'तुमची प्रतिक्रिया',
    te: 'మీ అభిప్రాయాలు',
  },
  YOUR_MESSAGE: {
    en: 'Your Message',
    gu: 'તમારો સંદેશો',
    hi: 'आपका सन्देश',
    kn: 'ನಿಮ್ಮ ಸಂದೇಶ',
    mr: 'तुमचा संदेश',
    te: 'మీ సందేశం',
  },
  YOUR_OFFERS: {
    en: 'Your Offers',
    gu: 'તમારી ઓફર્સ',
    hi: 'आपकी ऑफर्स',
    kn: 'ನಿಮ್ಮ ಆಫರ್ ಗಳು',
    mr: 'तुमच्या ऑफर',
    te: 'మీ ఆఫర్లు',
  },
  YOUR_ORDER: {
    en: 'Your Order',
    gu: 'તમારો ઓર્ડર',
    hi: 'आपका ऑर्डर',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್',
    mr: 'तुमची ऑर्डर',
    te: 'మీ ఆర్డర్',
  },
  YOUR_ORDERS: {
    en: 'Your Orders',
    gu: 'તમારા ઓર્ડર્સ',
    hi: 'आपके ऑर्डर्स',
    kn: 'ನಿಮ್ಮ ಆರ್ಡರ್ ಗಳು',
    mr: 'तुमच्या ऑर्डर',
    te: 'మీ ఆర్డర్లు',
  },
  YOUR_PROFILE: {
    en: 'Your Profile',
    gu: 'તમારી પ્રોફાઇલ',
    hi: 'आपकी प्रोफ़ाइल',
    kn: 'ನಿಮ್ಮ ಪ್ರೊಫೈಲ್',
    mr: 'तुमची प्रोफाइल',
    te: 'మీ ప్రొఫైల్',
  },
  YOUR_REFERRAL_CODE: {
    en: 'YOUR REFERRAL CODE',
    gu: 'તમારો રેફરલ કોડ',
    hi: 'आपका रेफरल कोड',
    kn: 'ನಿಮ್ಮ ರೆಫರಲ್ ಕೋಡ್',
    mr: 'तुमचा आमंत्रण कोड',
    te: 'మీ రేఫెరెల్ కోడ్',
  },
  YOUR_SELECTED_ADDRESS: {
    en: 'Your Selected Address',
    gu: 'તમારું પસંદ કરેલું એડ્રેસ',
    hi: 'आपका चुना हुआ एड्रेस',
    kn: 'ನೀವು ಆರಿಸಿದ ವಿಳಾಸಗಳು',
    mr: 'तुम्ही निवडलेला पत्ता',
    te: 'మీరు ఎంచుకున్న అడ్రసు',
  },
  YOUR_SHOPPING_LIST: {
    en: 'Your Shopping List',
    gu: 'તમારી ખરીદીની સૂચિ',
    hi: 'आपकी खरीदारी सूची',
    kn: 'ನಿಮ್ಮ ಶಾಪಿಂಗ್ ಪಟ್ಟಿ',
    mr: 'तुमची खरेदी यादी',
    te: 'మీరు కొనదలచిన జాబిత',
  },
  YOUR_CART: {
    en: 'Your Cart',
    gu: 'તમારું કાર્ટ',
    hi: 'आपका कार्ट',
    kn: 'ನಿಮ್ಮ ಕಾರ್ಟ್',
    mr: 'तुमचा कार्ट',
    te: 'మీ కార్ట్',
  },
  YOUR_TEXT_GOES_HERE: {
    en: 'Your text goes here',
    gu: 'તમારું લખાણ અહીં જાય છે',
    hi: 'आपका पाठ यहाँ जाता है',
    kn: 'ನಿಮ್ಮ ಪಠ್ಯ ಇಲ್ಲಿಗೆ ಹೋಗುತ್ತದೆ',
    mr: 'तुमचा मजकूर इथे दिसेल',
    te: 'మీరు వ్రాసినది ఇక్కడ జతచేయబడుతుంది',
  },
  YOUR_TOTAL_SAVINGS: {
    en: 'Your Total Savings',
    gu: 'તમારી કુલ બચત',
    hi: 'आपकी कुल बचत',
    kn: 'ನಿಮ್ಮ ಒಟ್ಟು ಉಳಿತಾಯ',
    mr: 'आपली एकूण बचत',
    te: 'మీరు  చేసిన మొత్తం పొదుపు',
  },
  YOUTUBE: {
    en: 'YouTube',
    gu: 'યુટ્યુબ',
    hi: 'यूट्यूब',
    kn: 'ಯೌಟ್ಯೂಬ್',
    mr: 'यूट्यूब',
    te: 'యూట్యూబ్',
  },
  MALE: {
    en: 'Male',
    gu: 'પુરુષ',
    hi: 'पुरुष',
    kn: 'ಪುರುಷ',
    mr: 'पुरुष',
    te: 'పురుషుడు',
  },
  FEMALE: {
    en: 'Female',
    gu: 'સ્ત્રી',
    hi: 'महिला',
    kn: 'ಹೆಣ್ಣು',
    mr: 'स्त्री',
    te: 'స్త్రీ',
  },
  // Required String Translations
  PRODUCT_CATEGORY_AVAILABLE: {
    en: 'Product Categories Available on this Shop',
    gu: 'Product Categories Available on this Shop',
    hi: 'Product Categories Available on this Shop',
    kn: 'Product Categories Available on this Shop',
    mr: 'Product Categories Available on this Shop',
    te: 'Product Categories Available on this Shop',
  },
  SHOP_FAQ_DESC: {
    en: 'Frequently asked questions about {shopName}',
    gu: 'Frequently asked questions about {shopName}',
    hi: 'Frequently asked questions about {shopName}',
    kn: 'Frequently asked questions about {shopName}',
    mr: 'Frequently asked questions about {shopName}',
    te: 'Frequently asked questions about {shopName}',
  },
  HOD_NOT_AVAILABLE: {
    en: '*Home Delivery not available at this location',
    gu: '*આ સ્થાન પર હોમ ડિલિવરી ઉપલબ્ધ નથી',
    hi: '*इस स्थान पर होम डिलीवरी उपलब्ध नहीं है',
    kn: '*ಈ ಸ್ಥಳದಲ್ಲಿ ಹೋಮ್ ಡೆಲಿವರಿ ಲಭ್ಯವಿಲ್',
    mr: '*या ठिकाणी होम डिलिव्हरी उपलब्ध नाही',
    te: '*ఈ  ప్రదేశంలో  హోమ్ డెలివరీ  అందుబాటులో  లేదు',
  },
  MOV_DESC1: {
    en: 'Add items worth ₹{homeDeliveryRemainingMov} to avail home delivery service',
    gu: 'હોમ ડિલિવરી સેવા મેળવવા માટે ₹{homeDeliveryRemainingMov} ની કિંમતની વસ્તુઓ ઉમેરો',
    hi: 'होम डिलीवरी सेवा का लाभ उठाने के लिए ₹{homeDeliveryRemainingMov} मूल्य के आइटम जोड़ें',
    kn: 'ಹೋಮ್ ಡೆಲಿವರಿ ಸೇವೆಯನ್ನು ಪಡೆಯಲು ₹{homeDeliveryRemainingMov} ಮೌಲ್ಯದ ಐಟಂಗಳನ್ನು ಸೇರಿಸಿ',
    mr: 'होम डिलिव्हरी सेवेचा लाभ घेण्यासाठी ₹{homeDeliveryRemainingMov} किमतीच्या वस्तू जोडा',
    te: 'హోమ్ డెలివరీ సేవను పొందడానికి ₹{homeDeliveryRemainingMov} విలువైన వస్తువులను జోడించండి',
  },
  MOV_DESC2: {
    en: 'Delivery to your location available only for orders above ₹{homeDeliveryMov} from this shop.',
    gu: 'આ દુકાનમાંથી ફક્ત ₹{homeDeliveryMov} થી વધુના ઓર્ડર માટે તમારા સ્થાન પર ડિલિવરી ઉપલબ્ધ છે',
    hi: 'इस दुकान से केवल ₹{homeDeliveryMov} से ऊपर के ऑर्डर के लिए आपके स्थान पर डिलीवरी उपलब्ध है.',
    kn: 'ಮ್ಮ ಸ್ಥಳಕ್ಕೆ ಡೆಲಿವರಿ ಈ ಅಂಗಡಿಯಿಂದ ₹{homeDeliveryMov} ರೂಪಾಯಿಗಿಂತ ಹೆಚ್ಚಿನ ಆರ್ಡರ್‌ಗಳಿಗೆ ಮಾತ್ರ ಲಭ್ಯವಿದೆ',
    mr: 'या दुकानातून फक्त ₹{homeDeliveryMov} वरील ऑर्डरसाठी तुमच्या स्थानावर डिलिव्हरी उपलब्ध आहे',
    te: 'ఈ దుకాణం నుండి ₹{homeDeliveryMov} కంటే ఎక్కువ ఆర్డర్‌లకు మాత్రమే అందుబాటులో ఉన్న మీ స్థానానికి డెలివరీ చేయండి',
  },
  MONTHLY_SAMAN: {
    en: 'Monthly Samaan',
    gu: 'માસિક સામન',
    hi: 'मासिक सामान',
    kn: 'ಮಾಸಿಕ ಸಮನ್',
    mr: 'मासिक सामना',
    te: 'నెలవారీ సమన్',
  },
  MONTHLY_SAMAN_SUBHEADING: {
    en: 'Get monthly essentials delivered on fingertips',
    gu: 'માસિક આવશ્યક વસ્તુઓ આંગળીના ટેરવે મેળવો',
    hi: 'मासिक आवश्यक वस्तुएं उंगलियों पर प्राप्त करें',
    kn: 'ಮಾಸಿಕ ಅಗತ್ಯ ವಸ್ತುಗಳನ್ನು ನಿಮ್ಮ ಬೆರಳ ತುದಿಯಲ್ಲಿ ಪಡೆಯಿರಿ',
    mr: 'मासिक जीवनावश्यक वस्तू बोटांच्या टोकावर मिळवा',
    te: 'నెలవారీ నిత్యావసరాలను మీ వేలికొనలకు అందజేయండి',
  },
  VISIT_STORE: {
    en: 'Visit Store',
    gu: 'સ્ટોર મુલાકાત',
    hi: 'स्टोर जाएँ',
    kn: 'స్టోర్సం దర్శించండి',
    mr: 'दुकान भेट',
    te: 'స్టోర్ సందర్శించండి',
  },
  PENDING_ITEMS_IN_CART: {
    en: 'Pending items in cart',
    gu: 'Pending items in cart',
    hi: 'Pending items in cart',
    kn: 'Pending items in cart',
    mr: 'Pending items in cart',
    te: 'Pending items in cart',
  },
  ADD_ITEMS_IN_CART: {
    en: 'Add item in cart',
    gu: 'Add item in cart',
    hi: 'Add item in cart',
    kn: 'Add item in cart',
    mr: 'Add item in cart',
    te: 'Add item in cart',
  },
  HOME_DELIVERY_AVAILABLE_ON_THIS_ORDER: {
    en: 'Home delivery service is available on this order',
    gu: 'Home delivery service is available on this order',
    hi: 'Home delivery service is available on this order',
    kn: 'Home delivery service is available on this order',
    mr: 'Home delivery service is available on this order',
    te: 'Home delivery service is available on this order',
  },
  FREE: {
    en: 'Free',
    gu: 'Free',
    hi: 'Free',
    kn: 'Free',
    mr: 'Free',
    te: 'Free',
  },
  FREE_SAMPLE: {
    en: 'Free Sample',
    gu: 'Free Sample',
    hi: 'Free Sample',
    kn: 'Free Sample',
    mr: 'Free Sample',
    te: 'Free Sample',
  },
  CANCEL_ORDER: {
    en: 'Cancel Order',
    gu: 'Cancel Order',
    hi: 'Cancel Order',
    kn: 'Cancel Order',
    mr: 'Cancel Order',
    te: 'Cancel Order',
  },
  CANCELLATION_REASON: {
    en: 'Cancellation reason',
    gu: 'Cancellation reason',
    hi: 'Cancellation reason',
    kn: 'Cancellation reason',
    mr: 'Cancellation reason',
    te: 'Cancellation reason',
  },
  CANCEL_ORDER_TITLE: {
    en: 'Why do you want to cancel this order?',
    gu: 'Why do you want to cancel this order?',
    hi: 'Why do you want to cancel this order?',
    kn: 'Why do you want to cancel this order?',
    mr: 'Why do you want to cancel this order?',
  },
  EXPIRES_ON: {
    en: 'Expires on',
    gu: 'Expires on',
    hi: 'Expires on',
    kn: 'Expires on',
    mr: 'Expires on',
    te: 'Expires on',
  },
  YOUR_GOTO_ITEMS: {
    en: 'Your go-to items',
    gu: 'Your go-to items',
    hi: 'Your go-to items',
    kn: 'Your go-to items',
    mr: 'Your go-to items',
    te: 'Your go-to items',
  },
  YOUR_GOTO_ITEMS_SUBHEADING: {
    en: 'Based on your past purchases',
    gu: 'Based on your past purchases',
    hi: 'Based on your past purchases',
    kn: 'Based on your past purchases',
    mr: 'Based on your past purchases',
    te: 'Based on your past purchases',
  },
  MAX_SAVINGS: {
    en: 'Max Savings',
    gu: 'Max Savings',
    hi: 'Max Savings',
    kn: 'Max Savings',
    mr: 'Max Savings',
    te: 'Max Savings',
  },
  MAX_SAVINGS_SUBHEADING: {
    en: 'Exciting deals you cannot miss out on',
    gu: 'Exciting deals you cannot miss out on',
    hi: 'Exciting deals you cannot miss out on',
    kn: 'Exciting deals you cannot miss out on',
    mr: 'Exciting deals you cannot miss out on',
    te: 'Exciting deals you cannot miss out on',
  },
  BUY_1_GET_1: {
    en: 'Buy One, Get One Free',
    gu: 'Buy One, Get One Free',
    hi: 'Buy One, Get One Free',
    kn: 'Buy One, Get One Free',
    mr: 'Buy One, Get One Free',
    te: 'Buy One, Get One Free',
  },
  GET_FREE_PRODUCTS: {
    en: 'Get FREE products on your order',
    gu: 'Get FREE products on your order',
    hi: 'Get FREE products on your order',
    kn: 'Get FREE products on your order',
    mr: 'Get FREE products on your order',
    te: 'Get FREE products on your order',
  },
  SUPER_SAVER_COUPONS: {
    en: 'Super Saver Coupons for you',
    gu: 'Super Saver Coupons for you',
    hi: 'Super Saver Coupons for you',
    kn: 'Super Saver Coupons for you',
    mr: 'Super Saver Coupons for you',
    te: 'Super Saver Coupons for you',
  },
  IN_FOCUS_TODAY: {
    en: 'In Focus Today',
    gu: 'In Focus Today',
    hi: 'In Focus Today',
    kn: 'In Focus Today',
    mr: 'In Focus Today',
    te: 'In Focus Today',
  },
  MUST_TRY: {
    en: 'Must Try',
    gu: 'Must Try',
    hi: 'Must Try',
    kn: 'Must Try',
    mr: 'Must Try',
    te: 'Must Try',
  },
  MOST_SEARCHED_AND_BOUGHT: {
    en: 'Most Searched & Bought',
    gu: 'Most Searched & Bought',
    hi: 'Most Searched & Bought',
    kn: 'Most Searched & Bought',
    mr: 'Most Searched & Bought',
    te: 'Most Searched & Bought',
  },
  MORE_PRODUCT_FROM_THIS_STORE: {
    en: 'More Products From This Store',
    gu: 'More Products From This Store',
    hi: 'More Products From This Store',
    kn: 'More Products From This Store',
    mr: 'More Products From This Store',
    te: 'More Products From This Store',
  },
  UNLOCK_DISCOUNT: {
    en: 'UNLOCK DISCOUNT',
    gu: 'UNLOCK DISCOUNT',
    hi: 'UNLOCK DISCOUNT',
    kn: 'UNLOCK DISCOUNT',
    mr: 'UNLOCK DISCOUNT',
    te: 'UNLOCK DISCOUNT',
  },
  SHOP_NOW: {
    en: 'SHOP NOW',
    gu: 'SHOP NOW',
    hi: 'SHOP NOW',
    kn: 'SHOP NOW',
    mr: 'SHOP NOW',
    te: 'SHOP NOW',
  },
  PREVIOUS_SEARCH: {
    en: 'Previous Searches',
    gu: 'Previous Searches',
    hi: 'Previous Searches',
    kn: 'Previous Searches',
    mr: 'Previous Searches',
    te: 'Previous Searches',
  },
  TRENDING_SEARCH: {
    en: 'Trending Searches',
    gu: 'Trending Searches',
    hi: 'Trending Searches',
    kn: 'Trending Searches',
    mr: 'Trending Searches',
    te: 'Trending Searches',
  },
  POPULAR_BRANDS: {
    en: 'Popular Brands',
    gu: 'Popular Brands',
    hi: 'Popular Brands',
    kn: 'Popular Brands',
    mr: 'Popular Brands',
    te: 'Popular Brands',
  },
  SAVE_ADDRESS_AS: {
    en: 'Save address as',
    gu: 'Save address as',
    hi: 'Save address as',
    kn: 'Save address as',
    mr: 'Save address as',
    te: 'Save address as',
  },
  WORK: {
    en: 'Work',
    gu: 'Work',
    hi: 'Work',
    kn: 'Work',
    mr: 'Work',
    te: 'Work',
  },
  OTHER: {
    en: 'Other',
    gu: 'Other',
    hi: 'Other',
    kn: 'Other',
    mr: 'Other',
    te: 'Other',
  },
  SELECT_ADDRESS: {
    en: 'Select Address',
    gu: 'Select Address',
    hi: 'Select Address',
    kn: 'Select Address',
    mr: 'Select Address',
    te: 'Select Address',
  },
  NEED_ADDRESS_TYPE: {
    en: 'Need address type to continue',
    gu: 'Need address type to continue',
    hi: 'Need address type to continue',
    kn: 'Need address type to continue',
    mr: 'Need address type to continue',
    te: 'Need address type to continue',
  },
  USE_CURRENT_LOCATION: {
    en: 'USE CURRENT LOCATION',
    gu: 'USE CURRENT LOCATION',
    hi: 'USE CURRENT LOCATION',
    kn: 'USE CURRENT LOCATION',
    mr: 'USE CURRENT LOCATION',
    te: 'USE CURRENT LOCATION',
  },
  ADD_ADDRESS: {
    en: 'ADD ADDRESS',
    gu: 'ADD ADDRESS',
    hi: 'ADD ADDRESS',
    kn: 'ADD ADDRESS',
    mr: 'ADD ADDRESS',
    te: 'ADD ADDRESS',
  },
  RECENT_ADDRESSES: {
    en: 'Recent Addresses',
    gu: 'Recent Addresses',
    hi: 'Recent Addresses',
    kn: 'Recent Addresses',
    mr: 'Recent Addresses',
    te: 'Recent Addresses',
  },
  SET_AS_DEFAULT: {
    en: 'Set as Default',
    gu: 'Set as Default',
    hi: 'Set as Default',
    kn: 'Set as Default',
    mr: 'Set as Default',
    te: 'Set as Default',
  },
  DELETE_ADDRESS: {
    en: 'Delete Address',
    gu: 'Delete Address',
    hi: 'Delete Address',
    kn: 'Delete Address',
    mr: 'Delete Address',
    te: 'Delete Address',
  },
  DELETE_ADDRESS_DESCRIPTION: {
    en: 'Are you sure you want to delete this address?',
    gu: 'Are you sure you want to delete this address?',
    hi: 'Are you sure you want to delete this address?',
    kn: 'Are you sure you want to delete this address?',
    mr: 'Are you sure you want to delete this address?',
    te: 'Are you sure you want to delete this address?',
  },
  SET_DEFAULT: {
    en: 'Set as Default',
    gu: 'Set as Default',
    hi: 'Set as Default',
    kn: 'Set as Default',
    mr: 'Set as Default',
    te: 'Set as Default',
  },
  UPDATE_ADDRESS: {
    en: 'UPDATE ADDRESS',
    gu: 'UPDATE ADDRESS',
    hi: 'UPDATE ADDRESS',
    kn: 'UPDATE ADDRESS',
    mr: 'UPDATE ADDRESS',
    te: 'UPDATE ADDRESS',
  },
  SAVE_ADDRESS: {
    en: 'SAVE ADDRESS',
    gu: 'SAVE ADDRESS',
    hi: 'SAVE ADDRESS',
    kn: 'SAVE ADDRESS',
    mr: 'SAVE ADDRESS',
    te: 'SAVE ADDRESS',
  },
  SEE_LESS: {
    en: 'SEE LESS',
    gu: 'SEE LESS',
    hi: 'SEE LESS',
    kn: 'SEE LESS',
    mr: 'SEE LESS',
    te: 'SEE LESS',
  },
  SEE_MORE: {
    en: 'SEE MORE',
    gu: 'SEE MORE',
    hi: 'SEE MORE',
    kn: 'SEE MORE',
    mr: 'SEE MORE',
    te: 'SEE MORE',
  },
  VALID_FROM: {
    en: 'Valid from',
    gu: 'Valid from',
    hi: 'Valid from',
    kn: 'Valid from',
    mr: 'Valid from',
    te: 'Valid from',
  },
  SET_DEFAULT_DESCRIPTION: {
    en: 'Set this Address as Default',
    gu: 'Set this Address as Default',
    hi: 'Set this Address as Default',
    kn: 'Set this Address as Default',
    mr: 'Set this Address as Default',
    te: 'Set this Address as Default',
  },
  CLEAR_CART: {
    en: 'Clear Cart',
    gu: 'Clear Cart',
    hi: 'Clear Cart',
    kn: 'Clear Cart',
    mr: 'Clear Cart',
    te: 'Clear Cart',
  },
  CLEARING_CART: {
    en: 'Clearing Cart',
    gu: 'Clearing Cart',
    hi: 'Clearing Cart',
    kn: 'Clearing Cart',
    mr: 'Clearing Cart',
    te: 'Clearing Cart',
  },
  UPDATE_LOCATION: {
    en: 'Update Location',
    gu: 'Update Location',
    hi: 'Update Location',
    kn: 'Update Location',
    mr: 'Update Location',
    te: 'Update Location',
  },
  UPDATING_LOCATION: {
    en: 'Updating Location',
    gu: 'Updating Location',
    hi: 'Updating Location',
    kn: 'Updating Location',
    mr: 'Updating Location',
    te: 'Updating Location',
  },
  CLEAR_CART_DESCRIPTION: {
    en: 'By changing your location , certain products may not be serviceable',
    gu: 'By changing your location , certain products may not be serviceable',
    hi: 'By changing your location , certain products may not be serviceable',
    kn: 'By changing your location , certain products may not be serviceable',
    mr: 'By changing your location , certain products may not be serviceable',
    te: 'By changing your location , certain products may not be serviceable',
  },
  YES: {
    en: 'Yes',
    gu: 'Yes',
    hi: 'Yes',
    kn: 'Yes',
    mr: 'Yes',
    te: 'Yes',
  },
  DISMISS_ORDER_TRACKER: {
    en: 'DISMISS',
    gu: 'DISMISS',
    hi: 'DISMISS',
    kn: 'DISMISS',
    mr: 'DISMISS',
    te: 'DISMISS',
  },
  REVIEW_ORDER: {
    en: 'REVIEW ORDER',
    gu: 'REVIEW ORDER',
    hi: 'REVIEW ORDER',
    kn: 'REVIEW ORDER',
    mr: 'REVIEW ORDER',
    te: 'REVIEW ORDER',
  },
  PAY_AT_STORE: {
    en: 'Pay at Store',
    gu: 'Pay at Store',
    hi: 'Pay at Store',
    kn: 'Pay at Store',
    mr: 'Pay at Store',
    te: 'Pay at Store',
  },
  PAY_ONLINE_AT_STORE: {
    en: 'Pay Online at Store',
    gu: 'Pay Online at Store',
    hi: 'Pay Online at Store',
    kn: 'Pay Online at Store',
    mr: 'Pay Online at Store',
    te: 'Pay Online at Store',
  },
  PAY_ONLINE_ON_DELIVERY: {
    en: 'Pay Online On Delivery',
    gu: 'Pay Online On Delivery',
    hi: 'Pay Online On Delivery',
    kn: 'Pay Online On Delivery',
    mr: 'Pay Online On Delivery',
    te: 'Pay Online On Delivery',
  },
  PAY_WITH_SIMPL: {
    en: 'Pay Later with Simpl',
    gu: 'Pay Later with Simpl',
    hi: 'Pay Later with Simpl',
    kn: 'Pay Later with Simpl',
    mr: 'Pay Later with Simpl',
    te: 'Pay Later with Simpl',
  },
  BALANCE_AMOUNT: {
    en: 'Available Balance',
    gu: 'Available Balance',
    hi: 'Available Balance',
    kn: 'Available Balance',
    mr: 'Available Balance',
    te: 'Available Balance',
  },
  YOU_ARE_NOT_ELIGIBLE: {
    en: 'You are not eligible to use this method',
    gu: 'You are not eligible to use this method',
    hi: 'You are not eligible to use this method',
    kn: 'You are not eligible to use this method',
    mr: 'You are not eligible to use this method',
    te: 'You are not eligible to use this method',
  },

  INSUFFICIENT_FUNDS_IN_YOUR_SIMPL_ACCOUNT: {
    en: 'Insufficient Funds in your Simpl Account',
    gu: 'Insufficient Funds in your Simpl Account',
    hi: 'Insufficient Funds in your Simpl Account',
    kn: 'Insufficient Funds in your Simpl Account',
    mr: 'Insufficient Funds in your Simpl Account',
    te: 'Insufficient Funds in your Simpl Account',
  },

  LINK_ACCOUNT_TO_ENABLE_SIMPL: {
    en: 'Link account to enable one tap checkout',
    gu: 'Link account to enable one tap checkout',
    hi: 'Link account to enable one tap checkout',
    kn: 'Link account to enable one tap checkout',
    mr: 'Link account to enable one tap checkout',
    te: 'Link account to enable one tap checkout',
  },

  MAKE_PAYMENT_TO_COMPLETE_ORDER: {
    en: 'Make Payment to Complete Order',
    gu: 'Make Payment to Complete Order',
    hi: 'Make Payment to Complete Order',
    kn: 'Make Payment to Complete Order',
    mr: 'Make Payment to Complete Order',
    te: 'Make Payment to Complete Order',
  },
  PAYMENT_VIA_SIMPL: {
    en: 'Payment via Simpl',
    gu: 'Payment via Simpl',
    hi: 'Payment via Simpl',
    kn: 'Payment via Simpl',
    mr: 'Payment via Simpl',
    te: 'Payment via Simpl',
  },
  PICK_UP_THE_PARCEL_FROM: {
    en: 'Pick the parcel from the shop at your convenience',
    gu: 'Pick the parcel from the shop at your convenience',
    hi: 'Pick the parcel from the shop at your convenience',
    kn: 'Pick the parcel from the shop at your convenience',
    mr: 'Pick the parcel from the shop at your convenience',
    te: 'Pick the parcel from the shop at your convenience',
  },
  CONTINUE_TO_PAYMENT: {
    en: 'Continue To Payment',
    gu: 'Continue To Payment',
    hi: 'Continue To Payment',
    kn: 'Continue To Payment',
    mr: 'Continue To Payment',
    te: 'Continue To Payment',
  },
  PAY_ONLINE_NOW: {
    en: 'Pay Online Now',
    gu: 'Pay Online Now',
    hi: 'Pay Online Now',
    kn: 'Pay Online Now',
    mr: 'Pay Online Now',
    te: 'Pay Online Now',
  },
  SHOP_CATEGORIES: {
    en: 'SHOP CATEGORIES',
    gu: 'SHOP CATEGORIES',
    hi: 'SHOP CATEGORIES',
    kn: 'SHOP CATEGORIES',
    mr: 'SHOP CATEGORIES',
    te: 'SHOP CATEGORIES',
  },
  TOP_SAVING_PRODUCTS: {
    en: 'Top Saving Products',
    gu: 'Top Saving Products',
    hi: 'Top Saving Products',
    kn: 'Top Saving Products',
    mr: 'Top Saving Products',
    te: 'Top Saving Products',
  },
  FEATURED: {
    en: 'Featured',
    gu: 'Featured',
    hi: 'Featured',
    kn: 'Featured',
    mr: 'Featured',
    te: 'Featured',
  },
  FREE_SAMPLE_STR: {
    en: 'FREE Samples with all orders!',
    gu: 'FREE Samples with all orders!',
    hi: 'FREE Samples with all orders!',
    kn: 'FREE Samples with all orders!',
    mr: 'FREE Samples with all orders!',
    te: 'FREE Samples with all orders!',
  },
  LEFT_STR: {
    en: 'Left',
    gu: 'Left',
    hi: 'Left',
    kn: 'Left',
    mr: 'Left',
    te: 'Left',
  },
  OFFER_ENDS_IN: {
    en: 'Offer ends in',
    gu: 'Offer ends in',
    hi: 'Offer ends in',
    kn: 'Offer ends in',
    mr: 'Offer ends in',
    te: 'Offer ends in',
  },
  SHOP_OFFLINE: {
    en: 'Shop Offline 🙁',
    gu: 'Shop Offline 🙁',
    hi: 'Shop Offline 🙁',
    kn: 'Shop Offline 🙁',
    mr: 'Shop Offline 🙁',
    te: 'Shop Offline 🙁',
  },
  SHOP_OFFLINE_DESC: {
    en: 'Please place your order from another shop near you',
    gu: 'Please place your order from another shop near you',
    hi: 'Please place your order from another shop near you',
    kn: 'Please place your order from another shop near you',
    mr: 'Please place your order from another shop near you',
    te: 'Please place your order from another shop near you',
  },
  VIEW_OTHER_SHOPS: {
    en: 'VIEW OTHER SHOPS',
    gu: 'VIEW OTHER SHOPS',
    hi: 'VIEW OTHER SHOPS',
    kn: 'VIEW OTHER SHOPS',
    mr: 'VIEW OTHER SHOPS',
    te: 'VIEW OTHER SHOPS',
  },
  SHOP_IS_OFFLINE: {
    en: 'Shop is Offline',
    gu: 'Shop is Offline',
    hi: 'Shop is Offline',
    kn: 'Shop is Offline',
    mr: 'Shop is Offline',
    te: 'Shop is Offline',
  },
  PLATFORM_FEE_BREAKUP: {
    en: 'Platform Fee Breakup',
    gu: 'Platform Fee Breakup',
    hi: 'Platform Fee Breakup',
    kn: 'Platform Fee Breakup',
    mr: 'Platform Fee Breakup',
    te: 'Platform Fee Breakup',
  },
  PLATFORM_FEE: {
    en: 'Platform Fee',
    gu: 'Platform Fee',
    hi: 'Platform Fee',
    kn: 'Platform Fee',
    mr: 'Platform Fee',
    te: 'Platform Fee',
  },
  GST: {
    en: 'GST',
    gu: 'GST',
    hi: 'GST',
    kn: 'GST',
    mr: 'GST',
    te: 'GST',
  },
  THE_SHOP_IS_CLOSED_FOR_THE_DAY: {
    en: 'The shop is closed for the day!',
    gu: 'The shop is closed for the day!',
    hi: 'The shop is closed for the day!',
    kn: 'The shop is closed for the day!',
    mr: 'The shop is closed for the day!',
    te: 'The shop is closed for the day!',
  },
  YOU_CAN_STILL_DESC: {
    en: 'You can still place your order. It will be delivered to you tomorrow morning',
    gu: 'You can still place your order. It will be delivered to you tomorrow morning',
    hi: 'You can still place your order. It will be delivered to you tomorrow morning',
    kn: 'You can still place your order. It will be delivered to you tomorrow morning',
    mr: 'You can still place your order. It will be delivered to you tomorrow morning',
    te: 'You can still place your order. It will be delivered to you tomorrow morning',
  },
  ORDER_FASTER: {
    en: 'Order faster & easier',
    gu: 'Order faster & easier',
    hi: 'Order faster & easier',
    kn: 'Order faster & easier',
    mr: 'Order faster & easier',
    te: 'Order faster & easier',
  },
  LOVELOCAL_APP: {
    en: 'with the LoveLocal App',
    gu: 'with the LoveLocal App',
    hi: 'with the LoveLocal App',
    kn: 'with the LoveLocal App',
    mr: 'with the LoveLocal App',
    te: 'with the LoveLocal App',
  },
  GET_THE_APP: {
    en: 'GET THE APP',
    gu: 'GET THE APP',
    hi: 'GET THE APP',
    kn: 'GET THE APP',
    mr: 'GET THE APP',
    te: 'GET THE APP',
  },
};

const Color = {
  black: '#131314',
  black72: '#000000B8',
  black50: '#C2C2C2',
  danger: '#FD003A',
  dangerDark: '#a30000',
  dark: '#333333',
  link: '#b8238e',
  medium: '#666666',
  mediumLight: '#646466',
  primary: '#b8238e',
  primaryDark: '#630048',
  primaryLight: '#fff2fb',
  pink: '#e065be',
  pinkLight: '#fff2fb',
  pinkPale: '#ffebf9',
  secondary: '#999999',
  white: '#ffffff',
  dangerLight: '#E72064',
  brown: '#8a584b',
  violet: '#2b2ba0',
  yellow: '#f9ed32',
  gray: '#a0a0a3',
  grey: '#f4f4f4',
  greyLight: '#dcdce0',
  grayLighter: '#f0f0f5',
  green: '#38b823',
  greenLight: '#ebfff3',
  blue: '#0067d7',
  blueLight: '#d6eaff',
  orange: '#d76200',
  orangeLight: '#ffe9d6',
  violetLight: '#bfbfff',
};

export default {
  String,
  Color,
};
