import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { Constants, Utils } from '../../utilities';
import { Svg } from '../../components/common';
import Toast from '../../HOC/Toast';

function SocialShare(props) {
  const {
    language, showToast, isMobile, referralCode,
  } = props;
  const referralTextToShare = Constants.String.REFERRAL_SHARE_TEXT[language]
    .replace(/{referral}/g, referralCode);
  const [copied, setCopied] = useState(false);

  const socialShareMedia = [
    {
      mediaName: Constants.String.WHATSAPP[language],
      mediaIconJsx: (
        <WhatsappShareButton
          url="https://lovelocal.page.link/b2c_refferal"
          title={referralTextToShare}
        >
          <Svg
            svg="socialShareWhatsapp"
            width="73px"
          />
        </WhatsappShareButton>
      ),
    },
    {
      mediaName: Constants.String.FACEBOOK[language],
      mediaIconJsx: (
        <FacebookShareButton
          url="https://lovelocal.page.link/b2c_refferal"
          quote={referralTextToShare}
        >
          <Svg
            svg="socialShareFacebook"
            width="73px"
          />
        </FacebookShareButton>
      ),
    },
    {
      mediaName: Constants.String.TWITTER[language],
      mediaIconJsx: (
        <TwitterShareButton
          url="https://lovelocal.page.link/b2c_refferal"
          title={referralTextToShare}
        >
          <Svg
            svg="socialShareTwitter"
            width="73px"
            fillOuter="#55acee"
            fillInner="#f1f2f2"
          />
        </TwitterShareButton>
      ),
    },
    {
      mediaName: Constants.String.COPY_LINK[language],
      mediaIconJsx: (
        <Button
          onClick={() => {
            Utils.copyToClipboard(referralTextToShare.concat('https://lovelocal.page.link/b2c_refferal'));
            setCopied(true);
            if (isMobile) { showToast(Constants.String.COPIED[language]); }
            setTimeout(() => {
              setCopied(false);
            }, 5000);
          }}
          variant="link"
          className="p-0"
        >
          <Svg
            svg="socialShareCopy"
            width="73px"
            fill={Constants.Color.pinkPale}
            stroke={Constants.Color.primary}
          />
        </Button>
      ),
    },
  ];

  return (
    <Container>
      {
        isMobile && (
          <Row className="p-4 border-bottom fs-3 font-weight-black">
            {Constants.String.SHARE_OPTIONS[language]}
          </Row>
        )
      }
      <Row className={`${isMobile ? 'py-6' : 'py-4'} px-0 mx-0`}>
        {
          socialShareMedia.map((item) => (
            <Col xs={6} key={item.mediaName} className="d-flex align-items-center justify-content-center flex-column">
              <Row className="mx-0">
                {item.mediaIconJsx}
              </Row>
              <Row className="mx-0 text-center py-2">
                {item.mediaName}
              </Row>
            </Col>
          ))
        }
      </Row>
      {
        copied && (
          <Row className="fs-5 mx-0 pb-6">
            <Col xs={24} className="p-2 bg-pink-pale text-primary">
              <Svg
                svg="copy"
                width="1.2rem"
                fill={Constants.Color.primary}
              />
              <b>{Constants.String.SOCIAL_SHARE_COPIED_DESC1[language]}</b>
              {Constants.String.SOCIAL_SHARE_COPIED_DESC2[language]}
            </Col>
            <Col xs={24} className="p-2 bg-gray-lighter">
              {referralTextToShare.concat('https://lovelocal.page.link/b2c_refferal')}
            </Col>
          </Row>
        )
      }
    </Container>
  );
}

SocialShare.propTypes = {
  language: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  referralCode: PropTypes.string.isRequired,
};

export default Toast(SocialShare);
