import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Constants, Utils } from '../../utilities';
import Svg from '../../components/common/Svg';
import { popularCityAndLocality } from '../../../api/api';

const { LOVELOCAL } = Utils;
const FooterMobile = ({ language, refLocalStores }) => {
  const [cityCount, setCityCount] = useState(0);
  const [localityCount, setLocalityCount] = useState(0);

  const handleLoad = () => {
    popularCityAndLocality(
      'GET',
      {
        count: true,
      },
    ).then((res) => {
      setCityCount(res.data.cityCount);
      setLocalityCount(res.data.localityCount);
    });
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <Container
      className="py-9"
    >
      <Row
        className="text-white justify-content-between flex-row mb-3 align-items-center"
      >
        <Col>
          <Row>
            <Col
              xs="auto"
            >
              <img
                src="/images/footer-shopkeeper.png"
                alt="Sell Now"
                height="80px"
                width="80px"
              />
            </Col>
            <Col
              className="d-flex flex-column justify-content-center pl-0"
            >
              <div
                className="text-yellow font-weight-bold mb-2"
              >
                {Constants.String.BANO_APNI_DIGITAL_DUKAN[language]}
              </div>
              <div
                className="bg-yellow text-center p-1"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={LOVELOCAL.partnerPlayStore}
                  className="text-violet fs-6"
                >
                  {Constants.String.START_SELLING_NOW[language]}
                </a>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs="auto"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.becomeShop}
          >
            <Svg
              svg="logo-ll"
              fill1={Constants.Color.white}
              fill2={Constants.Color.primary}
              width="7rem"
            />
          </a>
          <div className="mt-6 mb-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.playstore}
            >
              <Svg
                svg="googlePlay"
                width="9rem"
              />
            </a>
          </div>
          <div className="mt-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.appstore}
            >
              <Svg
                svg="appStore"
                height="9rem"
              />
            </a>
          </div>
        </Col>
      </Row>

      <Row
        className="pt-3 text-white"
      >
        <Col>
          <h5
            className="text-gray mb-6"
          >
            {Constants.String.POPULAR_CITIES[language]}
          </h5>
          <Row>
            <Col>
              {
                Utils.popularCities.slice(0, 6).map((item) => (
                  (
                    <Row
                      key={item.title}
                      className="mx-0 mb-4 justify-content-start"
                    >
                      <Link
                        to={item.urlPath}
                        className="text-white"
                        onClick={() => {
                          if (refLocalStores) {
                            refLocalStores();
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </Row>
                  )
                ))
              }
            </Col>
            {/* <Col>
              {
                Utils.popularCities.slice(6).map((item) => (
                  (
                    <Row
                      key={item.title}
                      className="mx-0 mb-4 justify-content-start"
                    >
                      <Link
                        to={item.urlPath}
                        className="text-white"
                        onClick={() => {
                          if (refLocalStores) {
                            refLocalStores();
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </Row>
                  )
                ))
              }
              {
                cityCount > 0 ? (
                  <Link
                    to="/india/all"
                    className="text-yellow"
                  >
                    <Row
                      className="mx-0 mb-0 justify-content-start"
                    >
                      {Constants.String.ALL_CITIES[language]}
                    </Row>
                    <Row
                      className="mx-0 mb-4 justify-content-start"
                    >
                      {cityCount > 40 ? '(40+)' : `(${cityCount})`}
                    </Row>
                  </Link>
                ) : ''
              }
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row
        className="pt-3 text-white"
      >
        <Col>
          <h5
            className="text-gray mb-6"
          >
            {Constants.String.POPULAR_LOCALITIES[language]}
          </h5>
          <Row>
            <Col>
              {
                Utils.popularLocalities.slice(0, 6).map((item) => (
                  (
                    <Row
                      key={item.title}
                      className="mx-0 mb-4 justify-content-start"
                    >
                      <Link
                        to={item.urlPath}
                        className="text-white"
                        onClick={() => {
                          if (refLocalStores) {
                            refLocalStores();
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </Row>
                  )
                ))
              }
            </Col>
            <Col>
              {
                Utils.popularLocalities.slice(6).map((item) => (
                  (
                    <Row
                      key={item.title}
                      className="mx-0 mb-4 justify-content-start"
                    >
                      <Link
                        to={item.urlPath}
                        className="text-white"
                        onClick={() => {
                          if (refLocalStores) {
                            refLocalStores();
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </Row>
                  )
                ))
              }
              {/* {
                localityCount > 0 ? (
                  <Link
                    to="/india/all"
                    className="text-yellow"
                  >
                    <Row
                      className="mx-0 mb-0 justify-content-start"
                    >
                      {Constants.String.ALL_LOCALITIES[language]}
                    </Row>
                    <Row
                      className="mx-0 mb-4 justify-content-start"
                    >
                      {localityCount > 500 ? '(500+)' : `(${localityCount})`}
                    </Row>
                  </Link>
                ) : ''
              } */}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row
        className="pt-3 text-white"
      >
        <Col>
          <h5
            className="text-gray mb-6"
          >
            {Constants.String.POPULAR_CATEGORIES[language]}
          </h5>
          <Row>
            <Col>
              {
                Utils.popularCategories.slice(0, 3).map((item) => (
                  (
                    <div
                      className="mb-4"
                    >
                      {item.title}
                    </div>
                  )
                ))
              }
            </Col>
            <Col>
              {
                Utils.popularCategories.slice(3).map((item) => (
                  (
                    <div
                      className="mb-4"
                    >
                      {item.title}
                    </div>
                  )
                ))
              }
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row
        className="pt-3 mx-0"
      >
        <Col
          className="border-top border-gray"
        />
      </Row>

      <Row
        className="pt-4 text-white align-items-center"
      >
        <Col
          xs={8}
          className="pr-3"
        >
          <a
            target="_blank"
            href={LOVELOCAL.aboutUs}
            rel="noopener noreferrer"
            className="text-white"
          >
            {Constants.String.ABOUT_US[language]}
          </a>
        </Col>
        <Col
          xs={8}
          className="pr-3"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${LOVELOCAL.contactUs}`}
            className="text-white"
          >
            {Constants.String.CONTACT_US[language]}
          </a>
        </Col>
        <Col
          xs={8}
          className="pr-3"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.blog}
            className="text-white"
          >
            {Constants.String.BLOG[language]}
          </a>
        </Col>
      </Row>
      <Row
        className="pt-3 text-white align-items-center"
      >
        <Col
          xs={8}
          className="pr-3"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.privacyPolicy}
            className="text-white"
          >
            {Constants.String.PRIVACY_POLICY[language]}
          </a>
        </Col>
        <Col
          className="pr-3"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.tnc}
            className="text-white"
          >
            {Constants.String.TERMS_CONDITION[language]}
          </a>
        </Col>
      </Row>

      <Row
        className="pt-4 text-white"
      >
        <Col
          xs="auto"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${LOVELOCAL.career}`}
            className="text-white"
          >
            {`${Constants.String.CAREERS[language]} :`}
            {LOVELOCAL.career}
          </a>
        </Col>
      </Row>

      <Row
        className="pt-4 mx-0"
      >
        <Col
          className="border-top border-gray"
        />
      </Row>

      <Row
        className="pt-4"
      >
        <Col
          xs={24}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="px-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.facebook}
              className="text-white"
            >
              <Svg
                svg="facebook"
                fill={Constants.Color.white}
                width="1rem"
              />
            </a>
          </div>
          <div className="px-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.linkedin}
              className="text-white"
            >
              <Svg
                svg="linkedIn"
                fill={Constants.Color.white}
                width="1rem"
              />
            </a>
          </div>
          <div className="px-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.twitter}
              className="text-white"
            >
              <Svg
                svg="twitter"
                fill={Constants.Color.white}
                width="1rem"
              />
            </a>
          </div>
          <div className="px-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.youtube}
              className="text-white"
            >
              <Svg
                svg="youtube"
                fill={Constants.Color.white}
                width="1rem"
              />
            </a>
          </div>
          <div className="px-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.insta}
              className="text-white"
            >
              <Svg
                svg="instagram"
                fill={Constants.Color.white}
                width="1rem"
              />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

FooterMobile.propTypes = {
  language: PropTypes.string.isRequired,
  refLocalStores: PropTypes.func,
};

FooterMobile.defaultProps = {
  refLocalStores: () => {},
};

export default FooterMobile;
