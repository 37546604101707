import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import Uniqid from 'uniqid';
import QueryString from 'query-string';
import Wrapper from './Wrapper';
import PartnerWrapper from './pages/partner/Wrapper';
import PartnerWrapperNew from './pages/partner-new/wrapper';
import './styles/theme.scss';
import * as Freshchat from '../freshchat/Freshchat';
import { isAuthenticated } from './utilities/Utils';
import {
  getDeviceId, setDeviceId,
} from './utilities/Storage';
import LocalStores from './pages/seo-stores/LocalStores';
import AllCityAndLocality from './layout/footer/AllCityAndLocality';
import Shimmer from './shimmer/Shimmer';
import ErrorBoundary from './components/common/ErrorBoundary';
import BuildherMission from './pages/partner/BuildherMission';
import { cqSetAccount } from './criteoq';
import StoreDetails from './pages/shop-front/StoreDetails';
import RepeatProductsSeeAll from './pages/shop-front/layout/see-all/RepeatProductsSeeAll';
import ComboOffersSeeAll from './pages/shop-front/layout/see-all/ComboOffersSeeAll';
import CategoryProductsSeeAll from './pages/shop-front/layout/see-all/CategoryProductsSeeAll';
import TopSavingSeeAll from './pages/shop-front/layout/see-all/TopSavingSeeAll';
import Success from './pages/success/Success';

const Home = lazy(() => import('./pages/home/Home'));
const Stores = lazy(() => import('./pages/stores/Stores'));
const DiscountOfferList = lazy(() => import('./pages/offers/DiscountOfferList'));
const Account = lazy(() => import('./pages/account/Account'));
const CategoryLanding = lazy(() => import('./pages/category/CategoryLanding'));
const CustomerProfile = lazy(() => import('./pages/account/CustomerProfile'));
const Favourites = lazy(() => import('./pages/favourites/Favourites'));
const ManageAddress = lazy(() => import('./layout/address/ManageAddress'));
const Language = lazy(() => import('./pages/changeLanguage/Language'));
const GlobalSearchV2 = lazy(() => import('./pages/global_search/GlobalSearchV2'));
const OrderList = lazy(() => import('./pages/orders/OrderList'));
const OrderDetails = lazy(() => import('./pages/orders/OrderDetails'));
const LastOrderProducts = lazy(() => import('./pages/LastOrderProducts/LastOrderProducts'));
const StoreWiseLastOrderProducts = lazy(() => import('./pages/LastOrderProducts/StoreWiseLastOrderProducts'));
const CategoryList = lazy(() => import('./pages/category/CategoryList'));
const PastProducts = lazy(() => import('./pages/past-products/PastProducts'));
const BOGOOfferList = lazy(() => import('./pages/offers/BOGOOfferList'));
const SpendOfferList = lazy(() => import('./pages/offers/SpendOfferList'));
const Offers = lazy(() => import('./pages/offers/Offers'));
const CouponList = lazy(() => import('./pages/offers/CouponList'));
const Reorder = lazy(() => import('./pages/reorder/Reorder'));
const Terms = lazy(() => import('./pages/legal/Terms'));
const PrivacyPolicy = lazy(() => import('./pages/legal/PrivacyPolicy'));
const TglsTerms = lazy(() => import('./pages/legal/TglsTerms'));
const MobikwikTerms = lazy(() => import('./pages/legal/MobikwikTerms'));
const ReferFriendInfo = lazy(() => import('./layout/refer-friends/ReferFriendInfo'));
const ReferFriendsTnC = lazy(() => import('./layout/refer-friends/ReferFriendsTnC'));
const RewardList = lazy(() => import('./pages/offers/RewardList'));
const RewardCoupon = lazy(() => import('./pages/offers/RewardCoupon'));
const Cart = lazy(() => import('./pages/cart/Cart'));
const GuestUserCart = lazy(() => import('./pages/cart/GuestUserCart'));
const StoreCouponList = lazy(() => import('./pages/offers/StoreCouponList'));
const OnlineProductScreenNew = lazy(() => import('./pages/product-screen-new'));
const PopularProducts = lazy(() => import('./pages/popular_products/PopularProducts'));
const SubmitFeedback = lazy(() => import('./layout/order/SubmitFeedback'));
const ApplicableValueOffers = lazy(() => import('./layout/checkout/ApplicableValueOffers'));
const TopSavingOfferList = lazy(() => import('./pages/offers/TopSavingOfferList'));
const FAQ = lazy(() => import('./pages/account/FAQs'));
const GuestLocation = lazy(() => import('./layout/account/GuestLocation'));
const PartnerHome = lazy(() => import('./pages/partner-new/Home'));
const PartnerAbout = lazy(() => import('./pages/partner/About'));
const PartnerCEOLetters = lazy(() => import('./pages/partner/CEOLetter'));
const PartnerAKVitalVoice = lazy(() => import('./pages/partner/AKVitalVoices'));
const PartnerMpaaniFunding = lazy(() => import('./pages/partner/MpaaniFunding'));
const PartnerThankYou = lazy(() => import('./pages/partner/ThankYou'));
const PreSeriesBFunding = lazy(() => import('./pages/partner/PreSeriesBFunding'));
const MonthlySamanList = lazy(() => import('./pages/offers/MonthlySamanList'));
const MaxSavingsProductList = lazy(() => import('./pages/offers/see-all-offers/MaxSavingsProductList'));
const BuyOneGetOneOfferList = lazy(() => import('./pages/offers/see-all-offers/BuyOneGetOneOfferList'));
const BrandProductsListing = lazy(() => import('./components/derived/brands/BrandProductsListing'));
const SuperSavingsCouponList = lazy(() => import('./components/derived/offer/SuperSavingsCouponList'));
const Trending = lazy(() => import('./pages/trending/Trending'));

const isMobile = window.screen.width < 992;

class App extends React.Component {
  constructor() {
    super();
    this.deviceId = null;
  }

  componentDidMount = () => {
    this.deviceId = getDeviceId();
    if (!this.deviceId) {
      const id = Uniqid();
      this.deviceId = id;
      setDeviceId(id);
    }
    if (isAuthenticated()) {
      Freshchat.initFreshchat();
    }
    cqSetAccount();
  }

  componentDidUpdate() {
    if (this.deviceId) {
      if (!getDeviceId()) {
        setDeviceId(this.deviceId);
      }
    } else {
      const id = Uniqid();
      this.deviceId = id;
      setDeviceId(id);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Wrapper
                {...props}
                page="home"
                Component={Home}
                isWhitePage
              />
            )}
          />
          <Route
            exact
            path="/success/:orderId"
            render={(props) => (
              <Wrapper
                {...props}
                page="success"
                Component={Success}
                isWhitePage
              />
            )}
          />
          <Route
            path="/shops"
            exact
            render={(props) => (
              <Wrapper
                {...props}
                page="shops"
                pageType="ALL_SHOPS"
                Component={Stores}
              />
            )}
          />
          <Route
            path="/shops/c/:urlCat"
            exact
            render={(props) => (
              <Wrapper
                {...props}
                page="shops"
                pageType="CATEGORY_SHOPS"
                Component={Stores}
              />
            )}
          />
          <Route
            path="/shops/g/:groupId"
            exact
            render={(props) => (
              <Wrapper
                {...props}
                page="shops"
                Component={Stores}
              />
            )}
          />
          <Route
            path="/shops/repeat-products/:storeCode"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="shops-repeat-products"
                    Component={RepeatProductsSeeAll}
                    isWhitePage
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/shops/combo-offers/:storeCode"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="shops-combo-offers"
                    Component={ComboOffersSeeAll}
                    isWhitePage
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/shops/category-products/:storeCode"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="shops-category-products"
                    Component={CategoryProductsSeeAll}
                    isWhitePage
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/shops/top-saving-products/:storeCode"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="shops-top-saving-products"
                    Component={TopSavingSeeAll}
                    isWhitePage
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/login"
            render={(props) => (
              !(isAuthenticated())
                ? (
                  <Wrapper
                    {...props}
                    page="home"
                    isLogin
                    Component={Home}
                    isWhitePage
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/account"
            render={(props) => (
              isMobile && isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="account"
                    Component={Account}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/guest-location"
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="guest-location"
                    Component={GuestLocation}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/refer-and-earn"
            render={(props) => (
              isAuthenticated() ? (
                <>
                  {
                    isMobile ? (
                      <Wrapper
                        {...props}
                        page="refer-and-earn"
                        Component={ReferFriendInfo}
                      />
                    )
                      : (
                        <Redirect
                          to={{
                            pathname: '/offers',
                            state: { showReferAndEarn: true },
                          }}
                        />
                      )
                  }
                </>
              ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/refer-friend-tnc"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="refer-friend-tnc"
                    Component={ReferFriendsTnC}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/address"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="address"
                    Component={ManageAddress}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/address/:addressId"
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="edit-address"
                    Component={ManageAddress}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/add-location"
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="add-location"
                    Component={ManageAddress}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/language"
            exact
            render={(props) => (
              isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="language"
                    Component={Language}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/faq"
            exact
            render={(props) => (
              <Wrapper
                {...props}
                page="faq"
                Component={FAQ}
              />
            )}
          />
          <Route
            path="/cart"
            render={(props) => (
              isMobile && isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="cart"
                    Component={Cart}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/guestUserCart"
            render={(props) => (
              isMobile && !isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="cart"
                    Component={GuestUserCart}
                  />
                )
                : <Redirect to="/" />
            )}
          />
          <Route
            path="/categories/:categoryId"
            render={(props) => {
              const queryParam = QueryString.parse(props.location.search);
              const { productId } = queryParam;
              if (isAuthenticated()) {
                if (isMobile && productId) {
                  return (
                    <Redirect
                      to={`/product-details?${QueryString.stringify(queryParam)}`}
                    />
                  );
                }
                return (
                  <Wrapper
                    {...props}
                    page="category-landing"
                    Component={CategoryLanding}
                  />
                );
              }
              return <Redirect to="/" />;
            }}
          />
          <Route
            path="/pc/:pCat/:sCat"
            render={(props) => (
              <Wrapper
                {...props}
                page="category-landing"
                Component={CategoryLanding}
              />
            )}
          />
          <Route
            path="/pc/:pCat"
            render={(props) => (
              <Wrapper
                {...props}
                page="category-landing"
                Component={CategoryLanding}
              />
            )}
          />
          <Route
            path="/pc"
            render={(props) => (
              <Wrapper
                {...props}
                page="categories"
                Component={CategoryList}
              />
            )}
          />
          <Route
            path="/categories"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="categories"
                    Component={CategoryList}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/checkout/applicable-value-offers"
            render={(props) => (
              (isAuthenticated() && isMobile)
                ? (
                  <Wrapper
                    {...props}
                    page="applicable-value-offers"
                    Component={ApplicableValueOffers}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/favourites"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="favourites"
                    Component={Favourites}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/search"
            render={(props) => {
              const queryParam = QueryString.parse(props.location.search);
              const { productId } = queryParam;
              if (productId) {
                return (
                  <Redirect
                    to={`/product-details?${QueryString.stringify(queryParam)}`}
                  />
                );
              }
              return (
                <Wrapper
                  {...props}
                  page="global-search"
                  Component={GlobalSearchV2}
                />
              );
            }}
          />
          <Route
            path="/popular-products"
            render={(props) => (
              <Wrapper
                {...props}
                page="popular-products"
                Component={PopularProducts}
              />
            )}
          />
          <Route
            path="/offers/combo-offers"
            render={(props) => (
              isMobile ? (
                <Wrapper
                  {...props}
                  page="offers-combo"
                  Component={BOGOOfferList}
                />
              ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/offers/shop-coupons"
            render={(props) => (
              <Wrapper
                {...props}
                page="shop-coupons"
                Component={StoreCouponList}
              />
            )}
          />
          <Route
            path="/offers/value-offers"
            render={(props) => (
              isMobile ? (
                <Wrapper
                  {...props}
                  page="offers-spend"
                  Component={SpendOfferList}
                />
              ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/offers/top-saving-offers"
            render={(props) => {
              const { history } = props;
              const query = QueryString.parse(history.location.search);
              return (
                isMobile ? (
                  <Wrapper
                    {...props}
                    page="offers-discount"
                    Component={query.sid
                      ? DiscountOfferList
                      : TopSavingOfferList}
                  />
                ) : <Redirect to="/" />
              );
            }}
          />
          <Route
            path="/offers/coupons"
            render={(props) => (
              <Wrapper
                {...props}
                page="offers-coupon"
                Component={CouponList}
              />
            )}
          />
          <Route
            path="/offers/rewards"
            render={(props) => (
              <Wrapper
                {...props}
                page="offers-reward"
                Component={RewardList}
              />
            )}
          />
          <Route
            path="/offers/reward-coupon"
            render={(props) => (
              <Wrapper
                {...props}
                page="reward-coupon"
                Component={RewardCoupon}
              />
            )}
          />
          <Route
            path="/offers"
            render={(props) => (
              <Wrapper
                {...props}
                page="offers"
                Component={Offers}
                isWhitePage
              />
            )}
          />
          <Route
            path="/order-past-products"
            render={(props) => {
              if (isAuthenticated()) {
                const queryParam = QueryString.parse(props.location.search);
                const { productId } = queryParam;
                if (isMobile && productId) {
                  return (
                    <Redirect
                      to={`product-details?${QueryString.stringify(queryParam)}`}
                    />
                  );
                }
                return (
                  <Wrapper
                    {...props}
                    page="order-past-products"
                    Component={LastOrderProducts}
                  />
                );
              }
              return <Redirect to="/" />;
            }}
          />
          {/* from global search */}
          <Route
            path="/store-wise-order-past-products"
            render={(props) => {
              if (isAuthenticated()) {
                return (
                  <Wrapper
                    {...props}
                    page="store-wise-order-past-products"
                    Component={StoreWiseLastOrderProducts}
                  />
                );
              }
              return <Redirect to="/" />;
            }}
          />
          <Route
            path="/orders/:orderId"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="order-details"
                    Component={OrderDetails}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/orders"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="orders"
                    Component={OrderList}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/order-feedback"
            render={(props) => (
              isAuthenticated() && isMobile
                ? (
                  <Wrapper
                    {...props}
                    page="order-feedback"
                    Component={SubmitFeedback}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/about"
            render={(props) => (
              <PartnerWrapper
                Component={PartnerAbout}
                headerTheme="PINK"
                page="about-us"
                {...props}
              />
            )}
          />
          <Route
            path="/partner/:lang/ceo-letter"
            render={(props) => (
              <PartnerWrapper
                Component={PartnerCEOLetters}
                headerTheme="PINK"
                page="ceo-letter"
                {...props}
              />
            )}
          />
          <Route
            path="/partner/:lang/thanks-for-your-interest"
            render={(props) => (
              <PartnerWrapper
                Component={PartnerThankYou}
                headerTheme="BLUE"
                page="thank-you"
                {...props}
              />
            )}
          />
          <Route
            path="/partner/:lang/m-paani-raises-5-5million-in-series-a-led-by-ac-ventures-henkel-candy-ventures-gojek-co-founders-and-others"
            render={(props) => (
              <PartnerWrapper
                Component={PartnerMpaaniFunding}
                headerTheme="BLUE"
                page="mpaani-funding"
                {...props}
              />
            )}
          />
          <Route
            path="/partner/:lang/LoveLocal-Raises-$18m-Pre-Series-B-in-the-largest-round-ever-raised-by-a-Solo-Woman-Tech-Founder-in-India"
            render={(props) => (
              <PartnerWrapper
                Component={PreSeriesBFunding}
                headerTheme="PINK"
                page="series-b-funding"
                {...props}
              />
            )}
          />
          <Route
            path="/LoveLocal-Raises-$18m-Pre-Series-B-in-the-largest-round-ever-raised-by-a-Solo-Woman-Tech-Founder-in-India"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <PreSeriesBFunding
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/lovelocal-launches-buildher-the-first-ever-women-engineers-tech-internship-program-in-the-indian-startup-ecosystem"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <BuildherMission
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/partner/:lang/akanksha-hazari-featured-in-vital-voices-100-women-using-their-power-to-empower-list-alongside-melinda-gates-malala-yousafzai-greta-thunberg-beyonce-serena-williams-and"
            render={(props) => (
              <PartnerWrapper
                Component={PartnerAKVitalVoice}
                headerTheme="PINK"
                page="ak-vital-voice"
                {...props}
              />
            )}
          />
          <Route
            path="/partner/:lang"
            render={(props) => {
              if (
                ['en', 'hi'].includes(props.match.params.lang)
              ) {
                return (
                  <PartnerWrapperNew
                    Component={PartnerHome}
                    headerTheme="BLUE"
                    page="home"
                    {...props}
                  />
                );
              }
              return (
                <Redirect
                  to="/partner/en"
                />
              );
            }}
          />
          <Route
            path="/partner"
            render={() => <Redirect to="/partner/en" />}
          />
          <Route
            path="/past-products"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="past-products"
                    Component={PastProducts}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/product-details"
            render={(props) => (
              <Wrapper
                {...props}
                page="product-details"
                Component={OnlineProductScreenNew}
              />
            )}
          />
          <Route
            path="/products/:productDetails"
            render={(props) => (
              <Wrapper
                {...props}
                page="product-details"
                Component={OnlineProductScreenNew}
              />
            )}
          />
          <Route
            path="/profile"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="profile"
                    Component={CustomerProfile}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/reorder"
            render={(props) => (
              isAuthenticated()
                ? (
                  <Wrapper
                    {...props}
                    page="reorder"
                    Component={Reorder}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            path="/member/terms"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <Terms
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/tgls-terms-conditions"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <TglsTerms
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/mobikwik-terms-conditions"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <MobikwikTerms
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/privacy-policy"
            exact
            render={(props) => (
              <ErrorBoundary
                language="en"
              >
                <Suspense
                  fallback={(
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  )}
                >
                  <PrivacyPolicy
                    {...props}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path="/shops/:storeId"
            render={(props) => {
              const queryParam = QueryString.parse(props.location.search);
              const { productId } = queryParam;
              if (isMobile && productId) {
                return (
                  <Redirect
                    to={`/product-details?${QueryString.stringify(queryParam)}`}
                  />
                );
              }
              return (
                <Wrapper
                  {...props}
                  page="shop-detail"
                  Component={StoreDetails}
                  isWhitePage
                />
              );
            }}
          />
          <Route
            path="/max-savings/:categoryId"
            exact
            render={(props) => {
              const { categoryId } = props.match.params;
              if (categoryId) {
                return (
                  <Wrapper
                    {...props}
                    page="max-savings"
                    Component={MaxSavingsProductList}
                  />
                );
              }
              return (
                <Wrapper
                  {...props}
                  page="offers"
                  Component={Offers}
                  isWhitePage
                />
              );
            }}
          />
          <Route
            path="/bogo-offers/:categoryName"
            exact
            render={(props) => {
              const { categoryName } = props.match.params;
              const categoryId = (categoryName && categoryName.split('-')[1]) || '';
              if (categoryId) {
                return (
                  <Wrapper
                    {...props}
                    page="bogo-offers"
                    Component={BuyOneGetOneOfferList}
                  />
                );
              }
              return (
                <Wrapper
                  {...props}
                  page="offers"
                  Component={Offers}
                  isWhitePage
                />
              );
            }}
          />
          <Route
            path="/brands/:brandName"
            exact
            render={(props) => {
              const { brandName } = props.match.params;
              const brandId = brandName && brandName.split('-')[1];
              if (brandId) {
                return (
                  <Wrapper
                    {...props}
                    page="brands-listing"
                    Component={BrandProductsListing}
                  />
                );
              }
              return (
                <Wrapper
                  {...props}
                  page="offers"
                  Component={Offers}
                  isWhitePage
                />
              );
            }}
          />
          <Route
            path="/super-savings-coupons"
            exact
            render={(props) => (
              <Wrapper
                {...props}
                page="super-savings-coupons"
                Component={SuperSavingsCouponList}
              />
            )}
          />
          <Route
            exact
            path="/:country/:city/:locality/c/:storeType"
            render={(props) => (
              <Wrapper
                {...props}
                page="local-stores"
                Component={LocalStores}
              />
            )}
          />
          <Route
            exact
            path="/:country/:city/:locality/"
            render={(props) => (
              <Wrapper
                {...props}
                page="local-stores"
                Component={LocalStores}
              />
            )}
          />
          <Route
            exact
            path="/:country/:city/c/:storeType"
            render={(props) => (
              <Wrapper
                {...props}
                page="local-stores"
                Component={LocalStores}
              />
            )}
          />
          <Route
            exact
            path="/india/all"
            render={(props) => (
              <Wrapper
                {...props}
                page="all-city-locality"
                Component={AllCityAndLocality}
              />
            )}
          />
          <Route
            exact
            path="/:country/:city"
            render={(props) => (
              <Wrapper
                {...props}
                page="seo-stores"
                Component={LocalStores}
              />
            )}
          />
          <Route
            exact
            path="/:country/:city/:locality/:shopType/:shopURL"
            render={(props) => (
              <Wrapper
                {...props}
                page="shop-detail"
                Component={StoreDetails}
                isWhitePage
              />
            )}
          />
          <Route
            exact
            path="/monthly-saman-list"
            render={(props) => (
              <Wrapper
                {...props}
                page="monthly-saman-list"
                Component={MonthlySamanList}
              />
            )}
          />
          <Route
            exact
            path="/trending"
            render={(props) => (
              <Wrapper
                {...props}
                page="trending"
                Component={Trending}
                isWhitePage
              />
            )}
          />
          <Route
            exact
            path="/india"
            render={() => <Redirect to="/shops" />}
          />
          <Route
            exact
            path="/blog"
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
