import * as buttonActions from '../events/buttonActions';

export const topNav = [
  {
    id: 1,
    displayText: 'SHOPS',
    link: '/shops',
    icon: 'logo',
    buttonAction: buttonActions.LL_SHOPS,
    isHrefApplicable: true,
  },
  {
    id: 2,
    displayText: 'OFFERS',
    link: '/offers',
    icon: 'offers',
    buttonAction: buttonActions.LL_OFFERS,
    isHrefApplicable: true,
  },
];

export const bottomNav = [
  {
    id: 1,
    displayText: 'HOME',
    link: '/',
    icon: 'home',
    buttonAction: buttonActions.HOME_TAB,
    isHrefApplicable: true,
  },
  {
    id: 2,
    displayText: 'SHOPS',
    link: '/shops',
    icon: 'logo',
    buttonAction: buttonActions.LL_SHOPS,
    isHrefApplicable: true,
  },
  {
    id: 3,
    displayText: 'OFFERS',
    link: '/offers',
    icon: 'offers',
    buttonAction: buttonActions.LL_OFFERS,
    isHrefApplicable: true,
  },
  {
    id: 4,
    displayText: 'ACCOUNT',
    link: '/account',
    icon: 'account',
    buttonAction: buttonActions.ACCOUNT_TAB,
    isHrefApplicable: false,
  },
];

export const languages = [
  {
    id: 1,
    key: 'en',
    displayTextHeading: 'English',
    displayTextSubHeading: 'English',
  },
  {
    id: 2,
    key: 'hi',
    displayTextHeading: 'हिंदी',
    displayTextSubHeading: 'Hindi',
  },
  {
    id: 3,
    key: 'mr',
    displayTextHeading: 'मराठी',
    displayTextSubHeading: 'Marathi',
  },
  {
    id: 4,
    key: 'gu',
    displayTextHeading: 'ગુજરતી',
    displayTextSubHeading: 'Gujarati',
  },
  {
    id: 5,
    key: 'kn',
    displayTextHeading: 'ಕನ್ನಡ',
    displayTextSubHeading: 'Kannada',
  },
  {
    id: 6,
    key: 'te',
    displayTextHeading: 'తెలుగు',
    displayTextSubHeading: 'Telugu',
  },
];

export const CDNURLMappings = {
  'home-delivery-product.s3.amazonaws.com': 'home-delivery-product',
  'apianalytics.mpaani.com': 'apianalytics',
  'lovelocal-store-front.s3.ap-south-1.amazonaws.com': 'lovelocal-store-front',
  'lovelocal-product-image.s3.ap-south-1.amazonaws.com': 'lovelocal-product-image',
  'storetypeimages.s3.ap-south-1.amazonaws.com': 'store-type-image',
  'product-categories-mum.s3.ap-south-1.amazonaws.com': 'product-categories-mum',
  'offer-banners.s3.ap-south-1.amazonaws.com': 'offer-banner-image',
};

export const dummyAddressPayload = {
  addressText: 'Chakala, Andheri East, Mumbai, Maharashtra, India',
  location: {
    lat: 19.1178548,
    lng: 72.8631304,
  },
  isCompleted: false,
  isDefault: true,
  flatNumber: '',
  buildingName: '',
  streetName: '',
  localityName: '',
  landmark: '',
  addressType: 'Mumbai',
  pinCode: '',
  isDummy: true,
};

export const dummyProfile = {
  DummyId: '',
  address: '',
  dob: '',
  email: '',
  first_name: '',
  gender: '',
  has_stores: false,
  isDummy: true,
  language: 'en',
  last_name: '',
  phone_number: '',
  points: 0,
  profile_completion_status: [],
  profile_image: '',
  receive_notification: true,
  referral_code: '',
  token: '',
  uid: '',
};
