import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Constants, Config } from '../../utilities';
import { Navbar, Svg } from '../../components/common';

const { String, Color } = Constants;
const { bottomNav } = Config;

function BottomMenu(props) {
  const {
    language, location, toggleLogin, history,
    unusedOfferCount,
  } = props;

  const navElement = (item) => (
    <span
      className="d-flex flex-column align-items-center fs-5"
    >
      <span
        className="d-flex align-items-center position-relative"
      >
        <Svg
          svg={item.icon}
          fill={(() => {
            let fillColor = 'none';
            if (item.id === 4) {
              if (location.pathname === item.link) {
                fillColor = Color.primary;
              } else { fillColor = Color.gray; }
            }
            return fillColor;
          })()}
          stroke={location.pathname === item.link ? Color.primary : Color.medium}
          width="1.5rem"
        />
        {
          item.id === 3
          && unusedOfferCount
          && unusedOfferCount.unusedSC + unusedOfferCount.unusedCoupon > 0
          && (
            <div
              className="red-dot-top bg-danger"
            />
          )
        }
      </span>
      <span>
        {String[item.displayText][language]}
      </span>
    </span>
  );
  const navState = (link) => (
    `${location.pathname === link ? 'text-primary' : 'text-medium'}`
  );

  return (
    <Navbar
      navConfig={bottomNav}
      navElement={navElement}
      navState={navState}
      classNames={{
        navbar: 'h-100 d-flex align-items-center justify-content-between',
        navLink: 'col text-decoration-none py-0',
      }}
      toggleLogin={toggleLogin}
      history={history}
    />
  );
}

const mapStateToProps = (state) => ({
  unusedOfferCount: state.main.unusedOfferCount,
});

BottomMenu.propTypes = {
  language: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  unusedOfferCount: PropTypes.shape({
    unusedSC: PropTypes.number,
    unusedCoupon: PropTypes.number,
  }),
  toggleLogin: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

BottomMenu.defaultProps = {
  unusedOfferCount: null,
};

export default connect(mapStateToProps, null)(BottomMenu);
