import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, FormControl, FormGroup, FormLabel, FormCheck, Button, InputGroup, Col, Spinner,
} from 'react-bootstrap';
import { Constants, validateForm, validateRegex } from '../../utilities';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import * as buttonActions from '../../events/buttonActions';
import { logButtonClick } from '../../events/Events';

const addressTypeLabels = [
  'Home',
  'Work',
  'Other',
];

class EditAddress extends Component {
  constructor(props) {
    super();
    const { address } = props;
    this.state = {
      addressText: address.addressText,
      flatNumber: address.flatNumber,
      buildingName: address.buildingName,
      streetName: address.streetName,
      localityName: address.localityName,
      pinCode: address.pinCode,
      landmark: address.landmark,
      addressType: address.addressType,
      addressTypeLabel: (address.addressType === 'Home' || address.addressType === 'Work')
        ? address.addressType : 'Other',
      errors: {},
    };
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    resetRequestStatus([ActionTypes.ADDRESS_EDIT_REQUEST]);
  }

  componentDidMount = () => {
    const a = document.getElementById('addressText');
    a.style.height = `${a.scrollHeight}px`;
    a.style.overflow = 'hidden';
    a.style.resize = 'none';
  }

  onSave = () => {
    const {
      address, addressEditRequest, onSuccess, screen,
      editAddressType, addAddress,
    } = this.props;
    const {
      addressType, buildingName, flatNumber,
      errors, localityName, streetName, pinCode, landmark,
    } = this.state;
    const newErrors = validateForm(errors, {
      addressType,
      buildingName,
      flatNumber,
      localityName,
      streetName,
      pinCode,
    });
    this.setState({ errors: newErrors }, () => {
      if (!Object.entries(newErrors).length) {
        const addressPayload = {
          ...address,
          addressType,
          buildingName,
          flatNumber,
          localityName,
          streetName,
          pinCode,
          landmark,
          isCompleted: true,
        };
        if (editAddressType === 'add') {
          addAddress(addressPayload, onSuccess);
        } else {
          addressEditRequest(
            addressPayload,
            onSuccess,
          );
        }
        logButtonClick(
          {
            BUTTON: buttonActions.SAVE_ADDRESS,
            SCREEN: screen,
          },
        );
      }
    });
  }

  handleChange = (event) => {
    if (event.target.id === 'pinCode') {
      if (!validateRegex(event.target.id, event.target.value)) {
        return;
      }
    }
    this.setState({ [event.target.id]: event.target.value });
  }

  handleAddressTypeLabelChange = (label) => {
    const { address } = this.props;
    let addressType = label;
    if (label === 'Other') {
      addressType = address.addressType !== 'Home' && address.addressType !== 'Work'
        ? address.addressType : '';
    }
    this.setState({ addressTypeLabel: label, addressType });
  }

  render() {
    const {
      requestsFailure, requestsProcessing, language,
    } = this.props;
    const {
      addressText, flatNumber, buildingName, streetName, localityName,
      pinCode, landmark, addressType, addressTypeLabel, errors,
    } = this.state;
    const loader = requestsProcessing[ActionTypes.ADDRESS_EDIT_REQUEST];
    const error = requestsFailure[ActionTypes.ADDRESS_EDIT_REQUEST];
    return (
      <Form>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.LOCATION[language]}
          </FormLabel>
          <FormControl
            as="textarea"
            type="text"
            id="addressText"
            defaultValue={addressText}
            className="pointer-event-none h-auto"
            readOnly
          />
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.FLAT_NUMBER[language]}
          </FormLabel>
          <FormControl
            type="text"
            id="flatNumber"
            value={flatNumber}
            onChange={this.handleChange}
            isInvalid={!!errors.flatNumber}
          />
          <FormControl.Feedback
            type="invalid"
          >
            {errors.flatNumber && errors.flatNumber[language]}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.BUILDING_NAME[language]}
          </FormLabel>
          <FormControl
            type="text"
            id="buildingName"
            value={buildingName}
            onChange={this.handleChange}
            isInvalid={!!errors.buildingName}
          />
          <FormControl.Feedback
            type="invalid"
          >
            {errors.buildingName && errors.buildingName[language]}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.STREET_NAME[language]}
          </FormLabel>
          <FormControl
            type="text"
            id="streetName"
            value={streetName}
            onChange={this.handleChange}
            isInvalid={!!errors.streetName}
          />
          <FormControl.Feedback
            type="invalid"
          >
            {errors.streetName && errors.streetName[language]}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.AREA_NAME[language]}
          </FormLabel>
          <FormControl
            type="text"
            id="localityName"
            value={localityName}
            onChange={this.handleChange}
            isInvalid={!!errors.localityName}
          />
          <FormControl.Feedback
            type="invalid"
          >
            {errors.localityName && errors.localityName[language]}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.PIN_CODE[language]}
          </FormLabel>
          <FormControl
            name="pinCode"
            type="text"
            id="pinCode"
            value={pinCode}
            onChange={this.handleChange}
            isInvalid={!!errors.pinCode}
          />
          <FormControl.Feedback
            type="invalid"
          >
            {errors.pinCode && errors.pinCode[language]}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup
          as={Col}
          className="mb-6"
        >
          <FormLabel>
            {Constants.String.LANDMARK[language]}
          </FormLabel>
          <InputGroup
            className="border rounded"
          >
            <FormControl
              type="text"
              id="landmark"
              value={landmark}
              aria-describedby="inputGroupAppend"
              className="border-0"
              onChange={this.handleChange}
            />
            <InputGroup.Append>
              <InputGroup.Text
                id="inputGroupAppend"
                className="bg-white border-0 fs-6"
              >
                {`(${Constants.String.OPTIONAL[language]})`}
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </FormGroup>
        <FormGroup
          as={Col}
        >
          <div
            className="d-flex flex-row align-items-center"
          >
            {addressTypeLabels.map((item) => (
              item === 'Other' && addressTypeLabel === 'Other'
                ? (
                  <div
                    key={item}
                  >
                    <input
                      type="text"
                      id="addressType"
                      placeholder="Enter Label"
                      className="custom-input shadow-none bg-transparent"
                      value={addressType}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                )
                : (
                  <div
                    key={item}
                    className="mr-5"
                  >
                    <FormCheck
                      custom
                      type="radio"
                      id={`custom-radio-${item}`}
                      label={item}
                      checked={item === addressTypeLabel}
                      onChange={() => this.handleAddressTypeLabelChange(item)}
                    />
                  </div>
                )
            ))}
          </div>
          {
            errors.addressType && (
              <FormControl.Feedback
                type="invalid"
              >
                {errors.addressType[language]}
              </FormControl.Feedback>
            )
          }
        </FormGroup>
        {
          error
            ? (
              <div
                className="mb-3 text-primary col"
              >
                {Constants.String.OOPS[language]}
              </div>
            )
            : ''
        }
        <div
          className="mb-3 col text-center"
        >
          {
            loader ? (
              <Spinner
                animation="border"
                variant="primary"
              />
            ) : (
              <Button
                variant="primary"
                className="py-2"
                onClick={() => {
                  this.onSave();
                }}
                block
              >
                {Constants.String.SAVE[language]}
              </Button>
            )
          }
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  requestsFailure: state.main.requestsFailure,
  requestsProcessing: state.main.requestsProcessing,
});

const mapDispatchToProps = (dispatch) => ({
  addAddress: (address, onSuccess) => {
    dispatch(Actions.addAddress(address, onSuccess));
  },
  addressEditRequest:
  (address, onSuccess) => {
    dispatch(Actions.addressEditRequest(address, onSuccess));
  },
  resetRequestStatus:
  (requests) => dispatch(Actions.resetRequestStatus(requests)),
});

EditAddress.propTypes = {
  language: PropTypes.string.isRequired,
  address: PropTypes.shape({
    addressText: PropTypes.string,
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    localityName: PropTypes.string,
    pinCode: PropTypes.string,
    landmark: PropTypes.string,
    addressType: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.shape({}),
  requestsFailure: PropTypes.shape({}).isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  addressEditRequest: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
  addAddress: PropTypes.func.isRequired,
  editAddressType: PropTypes.string,
};

EditAddress.defaultProps = {
  onSuccess: null,
  editAddressType: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
