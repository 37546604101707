import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import RoundedCartCounter from '../../components/derived/cart/RoundedCartCounter';
import { Constants } from '../../utilities';
import CustomImage from '../../components/common/CustomImage';
import { getCDNImageUrl } from '../../utilities/Utils';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

function CartCustomProduct(props) {
  const {
    product, store, isCart, isCheckout, reorder,
    language, outOfStock,
    updateCartOffers,
  } = props;

  let tag = {
    title: '',
    bgColor: '',
  };

  switch (product.tag) {
    case 'NEWLY ADDED':
      tag = {
        title: Constants.String.SUGGESTED_PRODUCT[language].toUpperCase(),
        bgColor: 'bg-green-light',
        textColor: 'text-green',
      };
      break;
    case 'QUANTITY_CHANGED':
      tag = {
        title: Constants.String.UPDATED_QUANTITY[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    case 'PRICE_CHANGED':
      tag = {
        title: Constants.String.UPDATED_PRICE[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    default: break;
  }

  return (
    <Col
      id="cart-product"
      xs={24}
      lg={reorder ? 12 : 24}
      className={`px-0 py-4 ${isCheckout ? 'fs-6' : ''}`}
    >
      <Row
        className={`mx-0 flex-nowrap ${reorder ? 'border p-3' : ''}`}
      >
        <Col
          xs={4}
          className="px-0"
        >
          <div
            className="cart-product-image"
          >
            <CustomImage
              imageUrl={getCDNImageUrl(product.imageUrl)}
              imageType="PRODUCT"
              imageStyle="mh-100 mw-100"
              imageAlt={product.name}
              imageTitle={product.name}
            />
          </div>
        </Col>
        <Col
          xs="auto"
          className="flex-grow-1 flex-shrink-1 fs-5 px-2"
        >
          {
            (tag.title || outOfStock) && (
              <Row
                className="mx-0 mb-1"
              >
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <span
                    className={`bg-primary py-1 fs-7 px-2 border-radius-4 font-weight-bold ${
                      outOfStock ? 'bg-danger-light text-danger' : `${tag.bgColor} ${tag.textColor}`
                    }`}
                  >
                    {outOfStock ? Constants.String.OUT_OF_STOCK[language].toUpperCase() : tag.title}
                  </span>
                </Col>
              </Row>
            )
          }
          <Row
            className="mx-0 pb-1 fs-5 text-truncate-1 word-break"
          >
            <b>{product.name}</b>
          </Row>
          <Row
            className="mx-0 mr-2 fs-5 text-truncate-1 text-medium word-break"
          >
            {product.unitString}
          </Row>
          <Row className="mx-0 fs-6 text-secondary">
            This product is subject to availability
          </Row>
        </Col>
        <Col
          xs={5}
          className="px-0 d-flex flex-column align-items-center"
        >
          {
            !outOfStock && (
              <Row
                xs="auto"
                className="mx-0 px-0"
              >
                <RoundedCartCounter
                  language={language}
                  productInfo={{
                    storeName: store.name,
                    productName: product.name,
                    sellingPrice: product.price,
                    // mrp: product.mrp,
                    imageUrl: product.imageUrl,
                    measurementDisplayText: product.unitString,
                  }}
                  isCustomProduct
                  storeCode={store.id}
                  productId={product.id}
                  quantity={product.quantity}
                  isCart={isCart}
                  isCheckout={isCheckout}
                  onSuccess={(productCount, isAdding) => {
                    if (updateCartOffers) {
                      updateCartOffers();
                    }
                    LogClevertapEvent.skusUpdatedToCart(
                      store.name,
                      product.name,
                      productCount,
                      // product.categories,
                    );
                    gaLogAddOrRemoveProductFromCart(
                      isAdding,
                      product.price,
                      {
                        item_id: product.id,
                        item_name: product.name,
                        price: product.price,
                        quantity: productCount,
                      },
                    );
                  }}
                />
              </Row>
            )
          }
          <Row
            className="mx-0 mt-2 px-0 text-center"
          >
            <span
              className="bg-yellow fs-8 px-2 py-1 border-radius-16 font-weight-black"
            >
              {'Custom Item'.toUpperCase()}
            </span>
          </Row>
        </Col>
        <Col xs={5} className="px-0" style={{ }} />
      </Row>
    </Col>
  );
}

CartCustomProduct.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    mrp: PropTypes.number,
    quantity: PropTypes.number,
    price: PropTypes.number,
    tag: PropTypes.string,
    unitString: PropTypes.string,
    freeSample: PropTypes.bool,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isCheckout: PropTypes.bool,
  isCart: PropTypes.bool,
  language: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool,
  reorder: PropTypes.bool,
  updateCartOffers: PropTypes.func,
};

CartCustomProduct.defaultProps = {
  isCheckout: false,
  isCart: false,
  outOfStock: false,
  reorder: false,
  updateCartOffers: null,
};

export default CartCustomProduct;
