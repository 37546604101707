import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Constants } from 'app/utilities';
import { LOVELOCAL } from 'app/utilities/Utils';

function FreeSampleModal(props) {
  const { freeProducts, onHide, language } = props;
  return (
    <Container
      fluid
      style={{
        width: '328px',
        position: 'relative',
      }}
    >
      <Row
        className="px-1 py-1"
        style={{
          background: 'url(\'/images/spend-offer-bg.png\') center',
          objectFit: 'contain',
          borderRadius: '16px',
        }}
      >
        <Col
          xs={24}
          className="py-0"
        >
          <Row className="mb-n4 mt-0">
            <Col
              className="d-flex align-items-center justify-content-end py-0"
            >
              <Button
                variant="link"
                className="fs-1 text-black px-0"
                onClick={onHide}
              >
                &times;
              </Button>
            </Col>
          </Row>
          <Row className="my-0 d-flex justify-content-center">
            <Col
              xs={16}
              className="px-0 py-0"
            >
              <span className="fs-1 font-weight-black line-height-24p free-text-center">
                {Constants.String.FREE_SAMPLE_STR[language]}
              </span>
            </Col>
          </Row>
          {
            freeProducts.length > 0 && freeProducts.map((item) => (
              <div
                key={item.libraryProductId}
              >
                <div className="dotted-border-bottom my-2 mb-1 px-1" />
                <Row className="px-1 py-2">
                  <Col
                    xs={9}
                  >
                    <div className="">
                      <img
                        className="mw-100 mh-100 bg-white border-radius-8"
                        alt="free"
                        src={item.image || LOVELOCAL.productPlaceholder}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={15}
                    className="d-flex align-items-center"
                  >
                    <Row className="d-flex flex-column">
                      <div className="font-weight-bold fs-4">
                        {item.name}
                      </div>
                      <div className="fs-6">
                        {item.measurementDisplayText}
                      </div>
                      <div className="fs-6">
                        <span className="font-weight-bold text-green">
                          {Constants.String.FREE[language].toUpperCase()}
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-strike-through">
                          &#8377;
                          {item.mrp}
                        </span>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))
          }
          <div className="dotted-border-bottom my-2 mb-1 px-1" />
          <Row className="d-flex justify-content-center my-4">
            <Button
              variant="primary"
              className="border-radius-8"
              onClick={onHide}
            >
              <span className="fs-6">
                {Constants.String.ORDER_NOW[language].toUpperCase()}
              </span>
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

FreeSampleModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  freeProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
};

export default FreeSampleModal;
