import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomScroller from 'app/components/common/CustomScroller';
import ProductThumb from 'app/components/common/ProductThumb';
import { SHOP_FRONT } from 'app/events/screens';
import TopCategorySectionTitle from './TopCategorySectionTitle';

function TopCategory({
  category, changeStoreState, storeId, history, isMobile,
  scrollMainContainerToTop, language,
}) {
  const cartItems = useSelector((state) => state.main.cartItems);
  const { productCategory, productsWithMeasurements } = category;
  return (
    <div>
      {
        (productsWithMeasurements.length > 0)
          ? (
            <>
              <TopCategorySectionTitle
                productCategory={productCategory}
                changeStoreState={changeStoreState}
                scrollMainContainerToTop={scrollMainContainerToTop}
                storeId={storeId}
                isMobile={isMobile}
                history={history}
                showSeeAll={productsWithMeasurements.length >= 6}
                language={language}
              />
              <CustomScroller
                noMargin
                content={(
                  <div className="r__flex r__flex-20 pb-2">
                    {productsWithMeasurements.map((product) => (
                      <ProductThumb
                        key={product[0].libraryProductId}
                        libraryProductId={product[0].libraryProductId}
                        retailProductId={product[0].retailerProductId}
                        name={product[0].name}
                        image={product[0].image}
                        mrp={product[0].mrp}
                        sellingPrice={product[0].sellingPrice}
                        measurementDisplayText={product[0].measurementDisplayText}
                        storeName={product[0].store.name}
                        storeId={product[0].store.id}
                        offerPercent={product[0].offerPercent}
                        history={history}
                        cartItems={cartItems}
                        variants={product.map((i) => ({
                          libraryProductId: i.libraryProductId,
                          retailProductId: i.retailerProductId,
                          name: i.name,
                          image: i.image,
                          mrp: i.mrp,
                          sellingPrice: i.sellingPrice,
                          measurementDisplayText: i.measurementDisplayText,
                          offerPercent: i.offerPercent,
                        }))}
                        page={SHOP_FRONT}
                        section={productCategory.name}
                      />
                    ))}
                  </div>
              )}
                contentPrefix={productCategory.name}
              />
            </>
          ) : null
      }
    </div>
  );
}

TopCategory.propTypes = {
  category: PropTypes.shape({
    productCategory: PropTypes.shape({
      name: PropTypes.string,
    }),
    productsWithMeasurements: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  storeId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  scrollMainContainerToTop: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default TopCategory;
