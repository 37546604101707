import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Svg } from 'app/components/common';
import CustomImage from 'app/components/common/CustomImage';
import { getCDNImageUrl } from 'app/utilities/Utils';
import { Constants } from 'app/utilities';
import CustomScroller from 'app/components/common/CustomScroller';
import ProductList from 'app/pages/global_search/ProductList';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

const RecentSearchSuggestion = (props) => {
  const {
    recentSearchSuggestions, onSelectSuggestion, onStoreSelectionSuggestion, onSelectBrand,
    language, cartItems, history, isMobile,
  } = props;
  return (
    <Row className="search-text-suggestions px-4 mx-0">
      <Col
        xs={24}
      >
        {
          recentSearchSuggestions && recentSearchSuggestions.previousSearches
          && recentSearchSuggestions.previousSearches.length > 0 && (
            <>
              <Row>
                <span className="font-weight-black fs-4 py-3">
                  {Constants.String.PREVIOUS_SEARCH[language]}
                </span>
              </Row>
              <Row className={`${isMobile ? '' : 'ml-n6'}`}>
                <Col
                  xs={24}
                  className="px-0 mb-3"
                >
                  <CustomScroller
                    content={(
                      <div className="d-flex">
                        {
                          recentSearchSuggestions.previousSearches.slice(0, 5).map((item) => (
                            <Col
                              xs="auto"
                              key={item.searchTerm}
                              className="mr-2 px-2 py-1 cursor-pointer border border-radius-25p fs-5 font-weight-bold overflow-hidden"
                              onClick={() => {
                                if (item.searchType === 'PRODUCT') {
                                  onSelectSuggestion(item.searchTerm);
                                } else if (item.searchType === 'STORE') {
                                  onStoreSelectionSuggestion(item.storeId, item.searchTerm);
                                }
                                LogClevertapEvent.recentProductSearch({
                                  Page: 'RecentSearch',
                                  Section: 'previous search',
                                });
                              }}
                            >
                              <Svg
                                svg="previousSearch"
                                height={24}
                                width={24}
                              />
                              &nbsp;
                              {item.searchTerm}
                            </Col>
                          ))
                        }
                      </div>
                    )}
                    contentPrefix="previousSearch1"
                  />
                </Col>
                {
                  recentSearchSuggestions.previousSearches.length > 5 && (
                    <Col
                      xs={24}
                      className="px-0 mb-3"
                    >
                      <CustomScroller
                        content={(
                          <div className="d-flex">
                            {
                              recentSearchSuggestions.previousSearches.slice(5, 10).map((item) => (
                                <Col
                                  xs="auto"
                                  key={item.searchTerm}
                                  className="mr-2 px-2 py-1 cursor-pointer border border-radius-25p fs-5 font-weight-bold overflow-hidden"
                                  onClick={() => {
                                    if (item.searchType === 'PRODUCT') {
                                      onSelectSuggestion(item.searchTerm);
                                    } else if (item.searchType === 'STORE') {
                                      onStoreSelectionSuggestion(item.storeId, item.searchTerm);
                                    }
                                    LogClevertapEvent.recentProductSearch({
                                      Page: 'RecentSearch',
                                      Section: 'previous search',
                                    });
                                  }}
                                >
                                  <Svg
                                    svg="previousSearch"
                                    height={24}
                                    width={24}
                                  />
                                  &nbsp;
                                  {item.searchTerm}
                                </Col>
                              ))
                            }
                          </div>
                        )}
                        contentPrefix="previousSearch2"
                      />
                    </Col>
                  )
                }
              </Row>
            </>
          )
        }

        {/*  */}
        {
          recentSearchSuggestions && recentSearchSuggestions.trendingSearches
          && recentSearchSuggestions.trendingSearches.length > 0 && (
            <>
              <Row>
                <span className="font-weight-black fs-4 py-3">
                  {Constants.String.TRENDING_SEARCH[language]}
                </span>
              </Row>
              <Row className={`${isMobile ? '' : 'ml-n6'}`}>
                <Col
                  xs={24}
                  className="px-0 mb-3"
                >
                  <CustomScroller
                    content={(
                      <div className="d-flex">
                        {
                          recentSearchSuggestions.trendingSearches.slice(0, 5).map((item) => (
                            <Col
                              xs="auto"
                              key={item.searchTerm}
                              className="mr-3 px-2 py-1 cursor-pointer border border-radius-25p fs-5 font-weight-bold overflow-hidden"
                              onClick={() => {
                                onSelectSuggestion(item.searchTerm);
                                LogClevertapEvent.recentProductSearch({
                                  Page: 'RecentSearch',
                                  Section: 'trending search',
                                });
                              }}
                            >
                              <Svg
                                svg="trendingSearch"
                                height={24}
                                width={24}
                              />
                              &nbsp;
                              {item.searchTerm}
                            </Col>
                          ))
                        }
                      </div>
                    )}
                    contentPrefix="trendingProduct1"
                  />
                </Col>
                {
                  recentSearchSuggestions.trendingSearches.length > 5 && (
                    <Col
                      xs={24}
                      className="px-0 mb-3"
                    >
                      <CustomScroller
                        content={(
                          <div className="d-flex">
                            {
                              recentSearchSuggestions.trendingSearches.slice(5, 10).map((item) => (
                                <Col
                                  xs="auto"
                                  key={item.searchTerm}
                                  className="mr-3 px-2 py-1 cursor-pointer border border-radius-25p fs-5 font-weight-bold overflow-hidden"
                                  onClick={() => {
                                    onSelectSuggestion(item.searchTerm);
                                    LogClevertapEvent.recentProductSearch({
                                      Page: 'RecentSearch',
                                      Section: 'trending search',
                                    });
                                  }}
                                >
                                  <Svg
                                    svg="trendingSearch"
                                    height={24}
                                    width={24}
                                  />
                                  &nbsp;
                                  {item.searchTerm}
                                </Col>
                              ))
                            }
                          </div>
                        )}
                        contentPrefix="trendingProduct2"
                      />
                    </Col>
                  )
                }
              </Row>
            </>
          )
        }

        {/*  */}
        {
          recentSearchSuggestions && recentSearchSuggestions.popularBrands
          && recentSearchSuggestions.popularBrands.length > 0 && (
            <>
              <Row>
                <span className="font-weight-black fs-4 py-3">
                  {Constants.String.POPULAR_BRANDS[language]}
                </span>
              </Row>
              <Row className={`${isMobile ? '' : 'ml-n6'}`}>
                <Col
                  xs={24}
                  className="px-0 mb-3"
                >
                  <CustomScroller
                    content={(
                      <div className="d-flex">
                        {
                          recentSearchSuggestions.popularBrands.slice(0, 5).map((item) => (
                            <Col
                              xs="auto"
                              key={item.id}
                              className="mr-3 px-0 cursor-pointer"
                              onClick={() => {
                                onSelectBrand(item.id, item.name);
                                LogClevertapEvent.popularBrandClick({
                                  Page: 'RecentSearch',
                                  'Banner name': item.name,
                                  Section: 'Popular Brands',
                                });
                              }}
                            >
                              <div className="text-center">
                                <div className="border border-radius-16 overflow-hidden bg-white">
                                  <div className="popular-brand-container">
                                    <CustomImage
                                      imageStyle="mw-100 mh-100"
                                      imageUrl={getCDNImageUrl(item.image)}
                                      imageType="PRODUCT"
                                      imageAlt={item.name}
                                      imageTitle={item.name}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-item-center justify-content-center w-96p my-1">
                                  <span className="font-weight-bold fs-5">
                                    {item.name}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          ))
                        }
                      </div>
                    )}
                    contentPrefix="popularBrands1"
                  />
                </Col>
                {
                  recentSearchSuggestions.popularBrands.length > 5 && (
                    <Col
                      xs={24}
                      className="px-0 mb-3"
                    >
                      <CustomScroller
                        content={(
                          <div className="d-flex">
                            {
                              recentSearchSuggestions.popularBrands.slice(5, 10).map((item) => (
                                <Col
                                  xs="auto"
                                  key={item.id}
                                  className="mr-3 px-0 cursor-pointer"
                                  onClick={() => {
                                    onSelectBrand(item.id, item.name);
                                    LogClevertapEvent.popularBrandClick({
                                      Page: 'RecentSearch',
                                      'Banner name': item.name,
                                      Section: 'Popular Brands',
                                    });
                                  }}
                                >
                                  <div className="text-center">
                                    <div className="border border-radius-16 overflow-hidden bg-white">
                                      <div className="popular-brand-container">
                                        <CustomImage
                                          imageStyle="mw-100 mh-100"
                                          imageUrl={getCDNImageUrl(item.image)}
                                          imageType="PRODUCT"
                                          imageAlt={item.name}
                                          imageTitle={item.name}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex align-item-center justify-content-center w-96p my-1">
                                      <span className="font-weight-bold fs-5">
                                        {item.name}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              ))
                            }
                          </div>
                        )}
                        contentPrefix="popularBrands2"
                      />
                    </Col>
                  )
                }
              </Row>
            </>
          )
        }

        {/*  */}
        {
          recentSearchSuggestions && recentSearchSuggestions.popularProducts
          && recentSearchSuggestions.popularProducts.length > 0 && (
            <>
              <Row>
                <span className="font-weight-black fs-4 py-3">
                  {Constants.String.MOST_SEARCHED_AND_BOUGHT[language]}
                </span>
              </Row>
              <Row className={`${isMobile ? '' : 'ml-n6'}`}>
                <Col
                  xs={24}
                  className="px-0 mb-3"
                >
                  <CustomScroller
                    content={(
                      <div className="d-flex">
                        {
                          recentSearchSuggestions.popularProducts.map((product) => (
                            <ProductList
                              {...props}
                              key={product[0].retailerProductId}
                              item={product}
                              language={language}
                              cartItems={cartItems}
                              history={history}
                              eventTrigger={() => {
                                LogClevertapEvent.mostSearchAndBought(
                                  'LL_Product_Viewed',
                                  {
                                    Page: 'RecentSearch',
                                    Section: 'trending search',
                                  },
                                );
                              }}
                            />
                          ))
                        }
                      </div>
                    )}
                    contentPrefix="mostSearchedProducts"
                  />
                </Col>
              </Row>
            </>
          )
        }
      </Col>
    </Row>
  );
};

RecentSearchSuggestion.propTypes = {
  recentSearchSuggestions: PropTypes.shape({
    previousSearches: PropTypes.arrayOf(PropTypes.shape({})),
    trendingSearches: PropTypes.arrayOf(PropTypes.shape({})),
    popularBrands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    popularProducts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  }).isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  onStoreSelectionSuggestion: PropTypes.func.isRequired,
  onSelectBrand: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  isMobile: PropTypes.bool.isRequired,
};

RecentSearchSuggestion.defaultProps = {
  cartItems: null,
};

export default RecentSearchSuggestion;
