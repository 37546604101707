import { singularLogout } from 'singular';
import * as ActionTypes from './ActionTypes';
import { Storage } from '../utilities';
import { isGuestUser } from '../utilities/Utils';
import {
  CART_ADD_COUPONS_REQUEST,
  CART_ADD_OFFER_REQUEST,
  CART_DELETE_COUPON_REQUEST,
  CART_DELETE_OFFER_REQUEST,
  CART_DELETE_REQUEST,
  CART_DELIVERY_UPDATE_REQUEST,
  CART_LOAD_SUCCESS,
  CART_LOAD_FAILURE,
  CART_LOAD_REQUEST,
  CART_PAYMENT_UPDATE_REQUEST,
  CART_PRODUCT_REQUEST,
  CART_DELETE_SUCCESS,
  CART_DELETE_FAILURE,
  CART_BULK_ADD_LOAD_REQUEST,
  CART_BULK_ADD_LOAD_SUCCESS,
  CART_BULK_ADD_LOAD_FAILURE,
  CART_DELIVERY_UPDATE_SUCCESS,
  CART_DELIVERY_UPDATE_FAILURE,
  CART_PAYMENT_UPDATE_SUCCESS,
  CART_PAYMENT_UPDATE_FAILURE,
  CART_ADD_COUPONS_SUCCESS,
  CART_ADD_COUPONS_FAILURE,
  CART_DELETE_COUPON_SUCCESS,
  CART_DELETE_COUPON_FAILURE,
  CART_ADD_OFFER_SUCCESS,
  CART_ADD_OFFER_FAILURE,
  CART_DELETE_OFFER_SUCCESS,
  CART_DELETE_OFFER_FAILURE,
  CART_PRODUCT_SUCCESS,
  CART_PRODUCT_FAILURE,
  HIGHLIGHT_CART_OFFER_REQUEST,
  HIGHLIGHT_CART_OFFER_SUCCESS,
  HIGHLIGHT_CART_OFFER_FAILURE,
  SET_CART_ITEMS,
  SET_GUEST_CUSTOM_CART_ITEMS,
} from './cart/actions';
import {
  STORE_OFFER_LOAD_FAILURE,
  STORE_OFFER_LOAD_REQUEST,
  STORE_OFFER_LOAD_SUCCESS,
  PAST_PURCHASE_ITEMS_LOAD_REQUEST,
  PAST_PURCHASE_ITEMS_LOAD_SUCCESS,
  PAST_PURCHASE_ITEMS_LOAD_FAILURE,
  MAX_SAVINGS_LOAD_REQUEST,
  MAX_SAVINGS_LOAD_SUCCESS,
  MAX_SAVINGS_LOAD_FAILURE,
  BUY_1_GET_1_FREE_LOAD_REQUEST,
  BUY_1_GET_1_FREE_LOAD_SUCCESS,
  BUY_1_GET_1_FREE_LOAD_FAILURE,
  GET_FREE_PRODUCT_LOAD_REQUEST,
  GET_FREE_PRODUCT_LOAD_SUCCESS,
  GET_FREE_PRODUCT_LOAD_FAILURE,
} from './offer/actions';
import {
  BRAND_SPACE_LOAD_REQUEST,
  BRAND_SPACE_LOAD_SUCCESS,
  BRAND_SPACE_LOAD_FAILURE,
} from './brand-space/actions';
import {
  SUPER_SAVER_COUPONS_LOAD_REQUEST,
  SUPER_SAVER_COUPONS_LOAD_SUCCESS,
  SUPER_SAVER_COUPONS_LOAD_FAILURE,
} from './coupon/actions';
import {
  BRAND_PRODUCTS_LOAD_REQUEST,
  BRAND_PRODUCTS_LOAD_SUCCESS,
  BRAND_PRODUCTS_LOAD_FAILURE,
} from './brand-products/actions';
import {
  HOME_NEW_LOAD_REQUEST,
  HOME_NEW_LOAD_SUCCESS,
  HOME_NEW_LOAD_FAILURE,
} from './home/actions';
import {
  SUPER_SAVINGS_LOAD_REQUEST,
  SUPER_SAVINGS_LOAD_SUCCESS,
  SUPER_SAVINGS_LOAD_FAILURE,
} from './super-savings/actions';
import {
  TRENDING_PRODUCTS_LOAD_REQUEST,
  TRENDING_PRODUCTS_LOAD_SUCCESS,
  TRENDING_PRODUCTS_LOAD_FAILURE,
} from './trending-products/actions';
import {
  REFERRALS_LOAD_REQUEST,
  REFERRALS_LOAD_SUCCESS,
  REFERRALS_LOAD_FAILURE,
} from './referrals/actions';
import { ACTIVE_ORDER_LOAD_REQUEST, ACTIVE_ORDER_LOAD_SUCCESS } from './orders/action';
import {
  ACK_LOAD_REQUEST,
  ACK_LOAD_SUCCESS,
  ACK_LOAD_FAILURE,
} from './acknowledgement/actions';

const defaultCoachmark = {
  applyOffers: {
    interval: 43200000,
    lastActive: 0,
  },
};

const expiryTime = Storage.getGuestUserSessionExpireTime();
if (!expiryTime || (new Date()).getTime() > expiryTime) {
  Storage.setGuestUserAddresses(null);
  Storage.setCartItems(null);
  Storage.setGuestUserSessionExpireTime((new Date()).getTime() + 86400000);
}

const initState = {
  requestsProcessing: {
    [ActionTypes.PREMIUM_STORES_REQUEST]: false,
    [ActionTypes.STORE_NEW_CATEGORY_REQUEST]: false,
    [ActionTypes.STORE_CATEGORIES_REQUEST]: false,
    [ActionTypes.PRODUCT_CATEGORY_REQUEST]: false,
    // [ActionTypes.ADD_BULK_ADDRESS_REQUEST]: false,
    [ActionTypes.ADD_ADDRESS_REQUEST]: false,
    [ActionTypes.ADDRESS_LOAD_REQUEST]: false,
    [ActionTypes.ADDRESS_CHANGE_REQUEST]: false,
    [ActionTypes.ADDRESS_DELETE_REQUEST]: false,
    [ActionTypes.BOGO_OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.CART_AVAILABLE_OFFERS_REQUEST]: false,
    [ActionTypes.COUPONS_LOAD_REQUEST]: false,
    [ActionTypes.STORE_AUXILIARY_REQUEST]: false,
    [ActionTypes.SPEND_OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.SPEND_OFFER_REQUEST]: false,
    [ActionTypes.STORES_LOAD_REQUEST]: false,
    [ActionTypes.ONLINE_STORES_LOAD_REQUEST]: false,
    [ActionTypes.SEO_METADATA_LOAD_REQUEST]: false,
    [ActionTypes.BEST_RATED_STORES_REQUEST]: false,
    [ActionTypes.STORE_LIKE_REQUEST]: false,
    [ActionTypes.STORE_RATING_REQUEST]: false,
    [ActionTypes.NEWLY_ADDED_STORES_REQUEST]: false,
    [ActionTypes.NOTIFICATIONS_LOAD_REQUEST]: false,
    [ActionTypes.OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.BRAND_BANNERS_REQUEST]: false,
    [ActionTypes.HOME_BANNERS_REQUEST]: false,
    [ActionTypes.HOME_TOP_BANNERS_REQUEST]: false,
    [ActionTypes.ONLINE_PRODUCTS_REQUEST]: false,
    [ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST]: false,
    [ActionTypes.POPULAR_PRODUCTS_REQUEST]: false,
    [ActionTypes.ORDER_DETAILS_REQUEST]: false,
    [ActionTypes.ORDER_PATCH_REQUEST]: false,
    [ActionTypes.ORDERS_CREATE_REQUEST]: false,
    [ActionTypes.ORDERS_LOAD_REQUEST]: false,
    [ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST]: false,
    [ActionTypes.PROFILE_LOAD_REQUEST]: false,
    [ActionTypes.PAST_PRODUCTS_REQUEST]: false,
    [ActionTypes.PROFILE_EDIT_REQUEST]: false,
    [ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST]: false,
    [ActionTypes.REWARD_LOAD_REQUEST]: false,
    [ActionTypes.UNLOCK_REWARD_REQUEST]: false,
    [ActionTypes.UNUSED_OFFER_COUNT_REQUEST]: false,
    [ActionTypes.STORES_SPECIFIC_COUPON_REQUEST]: false,
    [ActionTypes.VALUE_OFFER_REQUEST]: false,
    [ActionTypes.APPEX_OFFER_LOAD_REQUEST]: false,
    [ActionTypes.TOP_SAVING_OFFER_REQUEST]: false,
    [ActionTypes.TOP_PICKS_SHOPS_REQUEST]: false,
    [ActionTypes.CUSTOMER_STAT_REQUEST]: false,
    [ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST]: false,
    [ActionTypes.APPLY_VALUE_OFFER_REQUEST]: false,
    [ActionTypes.REFERRAL_CODE_GET_REQUEST]: false,

    // Cart Requests
    [CART_LOAD_REQUEST]: false,
    [CART_PRODUCT_REQUEST]: {},
    [CART_DELETE_REQUEST]: false,
    [CART_ADD_COUPONS_REQUEST]: false,
    [CART_DELETE_COUPON_REQUEST]: false,
    [CART_ADD_OFFER_REQUEST]: false,
    [CART_DELETE_OFFER_REQUEST]: false,
    [CART_DELIVERY_UPDATE_REQUEST]: false,
    [CART_PAYMENT_UPDATE_REQUEST]: false,
    [HIGHLIGHT_CART_OFFER_REQUEST]: false,

    // Store Offers
    [STORE_OFFER_LOAD_REQUEST]: false,
    [ActionTypes.HERO_BANNER_REQUEST]: false,
    // New Offers Section
    [PAST_PURCHASE_ITEMS_LOAD_REQUEST]: false,
    [MAX_SAVINGS_LOAD_REQUEST]: false,
    [BUY_1_GET_1_FREE_LOAD_REQUEST]: false,
    [GET_FREE_PRODUCT_LOAD_REQUEST]: false,
    // BRAND SPACES
    [BRAND_SPACE_LOAD_REQUEST]: false,
    // SUPER SAVER COUPONS
    [SUPER_SAVER_COUPONS_LOAD_REQUEST]: false,
    // BRAND SPACES
    [BRAND_SPACE_LOAD_REQUEST]: false,
    // BRAND PRODUCTS
    [BRAND_PRODUCTS_LOAD_REQUEST]: false,
    // HOME NEW
    [HOME_NEW_LOAD_REQUEST]: false,
    // SUPER SAVINGS
    [SUPER_SAVINGS_LOAD_REQUEST]: false,
    // TRENDING PRODUCTS
    [TRENDING_PRODUCTS_LOAD_REQUEST]: false,

    // Referrals
    [REFERRALS_LOAD_REQUEST]: false,
    // Acknowledgement
    [ACK_LOAD_REQUEST]: false,
  },
  requestsFailure: {
    [ActionTypes.PREMIUM_STORES_REQUEST]: false,
    [ActionTypes.STORE_NEW_CATEGORY_REQUEST]: false,
    [ActionTypes.STORE_CATEGORIES_REQUEST]: false,
    [ActionTypes.PRODUCT_CATEGORY_REQUEST]: false,
    // [ActionTypes.ADD_BULK_ADDRESS_REQUEST]: false,
    [ActionTypes.ADD_ADDRESS_REQUEST]: false,
    [ActionTypes.ADDRESS_LOAD_REQUEST]: false,
    [ActionTypes.ADDRESS_CHANGE_REQUEST]: false,
    [ActionTypes.ADDRESS_DELETE_REQUEST]: false,
    [ActionTypes.BOGO_OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.CART_AVAILABLE_OFFERS_REQUEST]: false,
    [ActionTypes.COUPONS_LOAD_REQUEST]: false,
    [ActionTypes.STORE_AUXILIARY_REQUEST]: false,
    [ActionTypes.SPEND_OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.SPEND_OFFER_REQUEST]: false,
    [ActionTypes.STORES_LOAD_REQUEST]: false,
    [ActionTypes.ONLINE_STORES_LOAD_REQUEST]: false,
    [ActionTypes.SEO_METADATA_LOAD_REQUEST]: false,
    [ActionTypes.BEST_RATED_STORES_REQUEST]: false,
    [ActionTypes.STORE_LIKE_REQUEST]: false,
    [ActionTypes.STORE_RATING_REQUEST]: false,
    [ActionTypes.NEWLY_ADDED_STORES_REQUEST]: false,
    [ActionTypes.NOTIFICATIONS_LOAD_REQUEST]: false,
    [ActionTypes.OFFERS_LOAD_REQUEST]: false,
    [ActionTypes.BRAND_BANNERS_REQUEST]: false,
    [ActionTypes.HOME_BANNERS_REQUEST]: false,
    [ActionTypes.HOME_TOP_BANNERS_REQUEST]: false,
    [ActionTypes.ONLINE_PRODUCTS_REQUEST]: false,
    [ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST]: false,
    [ActionTypes.POPULAR_PRODUCTS_REQUEST]: false,
    [ActionTypes.ORDER_DETAILS_REQUEST]: false,
    [ActionTypes.ORDER_PATCH_REQUEST]: false,
    [ActionTypes.ORDERS_CREATE_REQUEST]: false,
    [ActionTypes.ORDERS_LOAD_REQUEST]: false,
    [ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST]: false,
    [ActionTypes.PROFILE_LOAD_REQUEST]: false,
    [ActionTypes.PAST_PRODUCTS_REQUEST]: false,
    [ActionTypes.PROFILE_EDIT_REQUEST]: false,
    [ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST]: false,
    [ActionTypes.REWARD_LOAD_REQUEST]: false,
    [ActionTypes.UNLOCK_REWARD_REQUEST]: false,
    [ActionTypes.UNUSED_OFFER_COUNT_REQUEST]: false,
    [ActionTypes.STORES_SPECIFIC_COUPON_REQUEST]: false,
    [ActionTypes.VALUE_OFFER_REQUEST]: false,
    [ActionTypes.APPEX_OFFER_LOAD_REQUEST]: false,
    [ActionTypes.TOP_SAVING_OFFER_REQUEST]: false,
    [ActionTypes.TOP_PICKS_SHOPS_REQUEST]: false,
    [ActionTypes.CUSTOMER_STAT_REQUEST]: false,
    [ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST]: false,
    [ActionTypes.REFERRAL_CODE_GET_REQUEST]: false,

    // Cart Load requests
    [CART_LOAD_REQUEST]: false,
    [CART_PRODUCT_REQUEST]: {},
    [CART_DELETE_REQUEST]: false,
    [CART_ADD_COUPONS_REQUEST]: false,
    [CART_DELETE_COUPON_REQUEST]: false,
    [CART_ADD_OFFER_REQUEST]: false,
    [CART_DELETE_OFFER_REQUEST]: false,
    [CART_DELIVERY_UPDATE_REQUEST]: false,
    [CART_PAYMENT_UPDATE_REQUEST]: false,
    [HIGHLIGHT_CART_OFFER_REQUEST]: false,

    // Store Offers
    [STORE_OFFER_LOAD_REQUEST]: false,
    [ActionTypes.HERO_BANNER_REQUEST]: false,

    // New Offers
    [PAST_PURCHASE_ITEMS_LOAD_REQUEST]: false,
    [MAX_SAVINGS_LOAD_REQUEST]: false,
    [BUY_1_GET_1_FREE_LOAD_REQUEST]: false,
    [GET_FREE_PRODUCT_LOAD_REQUEST]: false,

    // Brand Spaces
    [BRAND_SPACE_LOAD_REQUEST]: false,
    [SUPER_SAVER_COUPONS_LOAD_REQUEST]: false,

    // Brand Spaces
    [BRAND_SPACE_LOAD_REQUEST]: false,
    // Brand Products
    [BRAND_PRODUCTS_LOAD_REQUEST]: false,
    // Home New
    [HOME_NEW_LOAD_REQUEST]: false,
    // TRENDING Products
    [TRENDING_PRODUCTS_LOAD_REQUEST]: false,
    // Referrals
    [REFERRALS_LOAD_REQUEST]: false,
    // Acknowledgement
    [ACK_LOAD_REQUEST]: false,
  },
  tryLogin: false,
  customer: Storage.getProfile(),
  customerAddresses: Storage.getGuestUserAddresses() || [],
  selectedAddress: Storage.getAddress(),
  storeCategories: null,
  categories: null,
  onlineProducts: null,
  categorySearchProducts: null,
  popularProducts: null,
  stores: null,
  onlineStores: null,
  seoMetadata: null,
  bestRatedStores: null,
  brandBanners: null,
  homeBanners: null,
  homeTopBanners: null,
  offerBanners: null,
  offers: null,
  coupons: null,
  cartId: Storage.getCartId(),
  orderSessionId: Storage.getOrderSessionId(),
  cartItems: Storage.getCartItems() || {},
  guestCustomCartItems: Storage.getCustomCartItems() || {},
  cartDetails: null,
  highlightCartOfferDetails: null,
  notifications: null,
  onGlobalSearch: false,
  openManageLocation: false,
  orders: null,
  orderDetails: null,
  pastProducts: null,
  previousOrderProducts: null,
  storeWisePrevOrderProducts: null,
  bogoOffers: null,
  cartAvailableOffers: null,
  spendOffers: null,
  storeDetails: null,
  rewards: null,
  scratchCard: null,
  storeCoupons: null,
  unusedOfferCount: null,
  language: Storage.getLanguage() || 'en',
  appExOffers: null,
  coachmark: Storage.getCoachmark() || defaultCoachmark,
  topSavingOffers: null,
  monthlySamanSection: null,
  topPicksShops: null,
  premiumStores: null,
  newlyAddedStores: null,
  customerStat: null,
  toggleGuestLocationPopup: false,
  isMandatoryGuestLocationPopup: false,
  showMultiStoreCheckoutDialog: false,
  multiStoreCheckoutErrorMsg: '',
  product: {},
  productInfo: {},
  referralCode: '',
  storeOffers: null,
  heroBanner: null,
  pastPurchaseItems: null,
  storeNewCategory: null,
  recentSearchedAddress: [],
  activeOrders: null,
};

const getCurrentStatus = (state, action, value) => ({
  ...state, [action]: value,
});

const getCurrentStatusOnReset = (requests, state) => {
  const newState = { ...state };
  requests.forEach((request) => {
    newState[request] = false;
  });
  return newState;
};

const getNewAddressList = (prevAddresses, address, action) => {
  let newAddresses = [];
  switch (action) {
    case 'add':
      newAddresses = prevAddresses.map((item) => (
        item.isDefault
          ? { ...item, isDefault: false }
          : item
      ));
      newAddresses = [address, ...newAddresses];
      break;

    case 'change':
      newAddresses = prevAddresses.map((item) => {
        if (item.locationId === address.locationId) {
          return address;
        }
        if (item.isDefault) {
          return { ...item, isDefault: false };
        }
        return item;
      });
      break;

    case 'delete':
      newAddresses = prevAddresses.filter(
        (item) => (item.locationId !== address.locationId),
      );
      break;

    case 'edit':
      newAddresses = prevAddresses.map((item) => (
        item.locationId === address.locationId
          ? address : item
      ));
      break;

    default:
      break;
  }
  return newAddresses;
};

const addressChangeHandler = (
  currentAddress = null,
  newAddress = null,
) => {
  if (
    (!currentAddress && newAddress)
    || (!newAddress && currentAddress)
    || (currentAddress
      && newAddress
      && currentAddress.locationId !== newAddress.locationId)
  ) {
    Storage.setCartItems(null);
    return ({
      categories: null,
      onlineProducts: null,
      stores: null,
      onlineStores: null,
      bestRatedStores: null,
      BrandBanners: null,
      homeBanners: null,
      homeTopBanners: null,
      offerBanners: null,
      offers: null,
      cartItems: null,
      cartDetails: null,
      highlightCartOfferDetails: null,
      pastProducts: null,
      previousOrderProducts: null,
      bogoOffers: null,
      cartAvailableOffers: null,
      spendOffers: null,
      storeDetails: null,
      unusedOfferCount: null,
      appexOffers: null,
      topSavingOffers: null,
      monthlySamanSection: null,
    });
  }
  return ({});
};

const extractCartItems = (cartDetails) => {
  const cartItems = {};
  (cartDetails.cartProducts || []).forEach((product) => {
    cartItems[product.retailerProductId] = {
      productId: product.retailerProductId,
      storeId: cartDetails.store.id,
      quantity: product.quantity,
    };
  });
  (cartDetails.cartCustomProducts || []).forEach((product) => {
    cartItems[product.id] = {
      productId: product.id,
      storeId: cartDetails.store.id,
      quantity: product.quantity,
    };
  });
  return (
    Object.keys(cartItems).length === 0
      ? null : cartItems
  );
};

const updateStoreLikeStatus = (list, storeId, status) => list.map(
  (item) => (item.code === storeId ? { ...item, isFavorite: status } : { ...item }),
);

const updateReward = (rewards, reward) => {
  if (reward.isBadLuckScratchCard) {
    return rewards && {
      ...rewards,
      data: rewards.data.filter(
        (item) => (item.id !== reward.id),
      ),
    };
  }
  return rewards && {
    ...rewards,
    data: rewards.data.map(
      (item) => (item.id === reward.id ? reward : item),
    ),
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_ADDRESS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADD_ADDRESS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADD_ADDRESS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ADD_ADDRESS_SUCCESS: {
      const { address } = action.payload;
      Storage.setAddress(address);
      if (isGuestUser()) {
        Storage.setGuestUserAddresses(getNewAddressList(
          state.customerAddresses,
          address,
          'add',
        ));
      }
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADD_ADDRESS_REQUEST,
          false,
        ),
        customerAddresses: getNewAddressList(
          state.customerAddresses,
          address,
          'add',
        ),
        selectedAddress: address,
        ...addressChangeHandler(
          state.selectedAddress,
          address,
        ),
      });
    }

    case ActionTypes.ADD_ADDRESS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADD_ADDRESS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADD_ADDRESS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ADDRESS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ADDRESS_LOAD_SUCCESS: {
      const { addresses } = action.payload;
      const selectedAddress = addresses
        .find((address) => address.isDefault);
      if (selectedAddress) {
        Storage.setAddress(selectedAddress);
      }
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_LOAD_REQUEST,
          false,
        ),
        customerAddresses: addresses,
        selectedAddress,
        ...addressChangeHandler(
          state.selectedAddress,
          selectedAddress,
        ),
      });
    }

    case ActionTypes.ADDRESS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.RECENT_ADDRESS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.RECENT_ADDRESS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.RECENT_ADDRESS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.RECENT_ADDRESS_SUCCESS: {
      const { addresses } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.RECENT_ADDRESS_REQUEST,
          false,
        ),
        recentSearchedAddress: addresses,
      });
    }

    case ActionTypes.RECENT_ADDRESS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.RECENT_ADDRESS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.RECENT_ADDRESS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ADDRESS_CHANGE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ADDRESS_CHANGE_SUCCESS: {
      const { address } = action.payload;
      Storage.setAddress(address);
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          false,
        ),
        customerAddresses: getNewAddressList(
          state.customerAddresses,
          address,
          'change',
        ),
        selectedAddress: address,
        ...addressChangeHandler(
          state.selectedAddress,
          address,
        ),
      });
    }

    case ActionTypes.ADDRESS_CHANGE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_CHANGE_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ADDRESS_DELETE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_DELETE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_DELETE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ADDRESS_DELETE_SUCCESS: {
      const { address } = action.payload;
      if (isGuestUser()) {
        Storage.setGuestUserAddresses(getNewAddressList(
          state.customerAddresses,
          address,
          'delete',
        ));
      }
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_DELETE_REQUEST,
          false,
        ),
        customerAddresses: getNewAddressList(
          state.customerAddresses,
          address,
          'delete',
        ),
      });
    }

    case ActionTypes.ADDRESS_DELETE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_DELETE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_DELETE_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ADDRESS_EDIT_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_EDIT_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_EDIT_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ADDRESS_EDIT_SUCCESS: {
      const { address } = action.payload;
      const { selectedAddress } = state;
      if (selectedAddress.locationId === address.locationId) {
        Storage.setAddress(address);
      }
      if (isGuestUser()) {
        Storage.setGuestUserAddresses(getNewAddressList(
          state.customerAddresses,
          address,
          'edit',
        ));
      }
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_EDIT_REQUEST,
          false,
        ),
        customerAddresses: getNewAddressList(
          state.customerAddresses,
          address,
          'edit',
        ),
        selectedAddress: selectedAddress.locationId === address.locationId
          ? address : selectedAddress,
      });
    }

    case ActionTypes.ADDRESS_EDIT_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ADDRESS_EDIT_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ADDRESS_EDIT_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.APPEX_OFFER_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.APPEX_OFFER_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.APPEX_OFFER_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.APPEX_OFFER_LOAD_SUCCESS: {
      const { appExOffers } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.APPEX_OFFER_LOAD_REQUEST,
          false,
        ),
        appExOffers,
      });
    }

    case ActionTypes.APPEX_OFFER_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.APPEX_OFFER_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.APPEX_OFFER_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.BOGO_OFFERS_LOAD_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
          false,
        ),
        bogoOffers: !offset && offset !== 0
          ? null : state.bogoOffers,
      });
    }

    case ActionTypes.BOGO_OFFERS_LOAD_SUCCESS: {
      const { bogoOffers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
          false,
        ),
        bogoOffers: offset === 0 || !state.bogoOffers
          ? bogoOffers
          : ({
            ...bogoOffers,
            results: state.bogoOffers.results
              .concat(bogoOffers.results),
          }),
      });
    }

    case ActionTypes.BOGO_OFFERS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.CART_AVAILABLE_OFFERS_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CART_AVAILABLE_OFFERS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CART_AVAILABLE_OFFERS_REQUEST,
          false,
        ),
        cartAvailableOffers: !offset && offset !== 0
          ? null : state.cartAvailableOffers,
      });
    }

    case ActionTypes.CART_AVAILABLE_OFFERS_SUCCESS: {
      const { cartAvailableOffers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CART_AVAILABLE_OFFERS_REQUEST,
          false,
        ),
        cartAvailableOffers: offset === 0 || !state.cartAvailableOffers
          ? cartAvailableOffers
          : ({
            ...cartAvailableOffers,
            results: state.cartAvailableOffers.results
              .concat(cartAvailableOffers.results),
          }),
      });
    }

    case ActionTypes.CART_AVAILABLE_OFFERS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CART_AVAILABLE_OFFERS_FAILURE,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CART_AVAILABLE_OFFERS_FAILURE,
          true,
        ),
      });
    }

    case ActionTypes.COUPONS_LOAD_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.COUPONS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.COUPONS_LOAD_REQUEST,
          false,
        ),
        coupons: !offset && offset !== 0
          ? null : state.coupons,
      });
    }

    case ActionTypes.COUPONS_LOAD_SUCCESS: {
      const { coupons, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.COUPONS_LOAD_REQUEST,
          false,
        ),
        coupons: offset === 0 || !state.coupons
          ? coupons
          : ({
            ...coupons,
            results: state.coupons.results
              .concat(coupons.results),
          }),
      });
    }

    case ActionTypes.COUPONS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.COUPONS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.COUPONS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.CUSTOMER_STAT_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CUSTOMER_STAT_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CUSTOMER_STAT_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.CUSTOMER_STAT_SUCCESS: {
      const { customerStat } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CUSTOMER_STAT_REQUEST,
          false,
        ),
        customerStat,
      });
    }

    case ActionTypes.CUSTOMER_STAT_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CUSTOMER_STAT_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CUSTOMER_STAT_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.DELIVERY_PREFERENCE_PATCH_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
          false,
        ),
        cartDetails: action.payload.cartDetails,
      });
    }

    case ActionTypes.DELIVERY_PREFERENCE_PATCH_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.HERO_BANNER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HERO_BANNER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HERO_BANNER_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.HERO_BANNER_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HERO_BANNER_REQUEST,
          false,
        ),
        heroBanner: action.payload.heroBanner,
      });
    }

    case ActionTypes.HERO_BANNER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HERO_BANNER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HERO_BANNER_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.BRAND_BANNERS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BRAND_BANNERS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BRAND_BANNERS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.BRAND_BANNERS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BRAND_BANNERS_REQUEST,
          false,
        ),
        brandBanners: action.payload.brandBanners,
      });
    }

    case ActionTypes.BRAND_BANNERS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BRAND_BANNERS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BRAND_BANNERS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.HOME_BANNERS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_BANNERS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HOME_BANNERS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.HOME_BANNERS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_BANNERS_REQUEST,
          false,
        ),
        homeBanners: action.payload.homeBanners,
      });
    }

    case ActionTypes.HOME_BANNERS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_BANNERS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HOME_BANNERS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.HOME_TOP_BANNERS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_TOP_BANNERS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HOME_TOP_BANNERS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.HOME_TOP_BANNERS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_TOP_BANNERS_REQUEST,
          false,
        ),
        homeTopBanners: action.payload.homeTopBanners,
      });
    }

    case ActionTypes.HOME_TOP_BANNERS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.HOME_TOP_BANNERS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.HOME_TOP_BANNERS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.LOGOUT: {
      singularLogout();
      Storage.removeAuthentication();
      Storage.setCoachmark(defaultCoachmark);
      return ({
        ...state,
        requestsProcessing: {
          [ActionTypes.PRODUCT_CATEGORY_REQUEST]: false,
          [ActionTypes.ADD_ADDRESS_REQUEST]: false,
          [ActionTypes.ADDRESS_LOAD_REQUEST]: false,
          [ActionTypes.ADDRESS_CHANGE_REQUEST]: false,
          [ActionTypes.ADDRESS_DELETE_REQUEST]: false,
          [ActionTypes.COUPONS_LOAD_REQUEST]: false,
          [ActionTypes.STORE_AUXILIARY_REQUEST]: false,
          [ActionTypes.STORES_LOAD_REQUEST]: false,
          [ActionTypes.ONLINE_STORES_LOAD_REQUEST]: false,
          [ActionTypes.BEST_RATED_STORES_REQUEST]: false,
          [ActionTypes.SPEND_OFFER_REQUEST]: false,
          [ActionTypes.STORE_LIKE_REQUEST]: false,
          [ActionTypes.NOTIFICATIONS_LOAD_REQUEST]: false,
          [ActionTypes.OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.HOME_BANNERS_REQUEST]: false,
          [ActionTypes.HOME_TOP_BANNERS_REQUEST]: false,
          [ActionTypes.ONLINE_PRODUCTS_REQUEST]: false,
          [ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST]: false,
          [ActionTypes.POPULAR_PRODUCTS_REQUEST]: false,
          [ActionTypes.ORDER_DETAILS_REQUEST]: false,
          [ActionTypes.ORDER_PATCH_REQUEST]: false,
          [ActionTypes.ORDERS_CREATE_REQUEST]: false,
          [ActionTypes.ORDERS_LOAD_REQUEST]: false,
          [ActionTypes.PROFILE_LOAD_REQUEST]: false,
          [ActionTypes.PAST_PRODUCTS_REQUEST]: false,
          [ActionTypes.PROFILE_EDIT_REQUEST]: false,
          [ActionTypes.REWARD_LOAD_REQUEST]: false,
          [ActionTypes.UNLOCK_REWARD_REQUEST]: false,
          [ActionTypes.STORES_SPECIFIC_COUPON_REQUEST]: false,
          [ActionTypes.UNUSED_OFFER_COUNT_REQUEST]: false,
          [ActionTypes.VALUE_OFFER_REQUEST]: false,
          [ActionTypes.APPEX_OFFER_LOAD_REQUEST]: false,
          [ActionTypes.TOP_SAVING_OFFER_REQUEST]: false,
          [ActionTypes.TOP_PICKS_SHOPS_REQUEST]: false,
          [ActionTypes.CUSTOMER_STAT_REQUEST]: false,
          [ActionTypes.NEWLY_ADDED_STORES_REQUEST]: false,
          [ActionTypes.SPEND_OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.BOGO_OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.CART_AVAILABLE_OFFERS_REQUEST]: false,
          // NEW CART REQ
          [CART_LOAD_REQUEST]: false,
          [CART_PRODUCT_REQUEST]: {},
          [CART_DELETE_REQUEST]: false,
          [CART_ADD_COUPONS_REQUEST]: false,
          [CART_DELETE_COUPON_REQUEST]: false,
          [CART_ADD_OFFER_REQUEST]: false,
          [CART_DELETE_OFFER_REQUEST]: false,
          [CART_DELIVERY_UPDATE_REQUEST]: false,
          [CART_PAYMENT_UPDATE_REQUEST]: false,
          [HIGHLIGHT_CART_OFFER_REQUEST]: false,

          // New Offers req
          [STORE_OFFER_LOAD_REQUEST]: false,

          // New Offers
          [PAST_PURCHASE_ITEMS_LOAD_REQUEST]: false,
          [MAX_SAVINGS_LOAD_REQUEST]: false,
          [BUY_1_GET_1_FREE_LOAD_REQUEST]: false,
          [GET_FREE_PRODUCT_LOAD_REQUEST]: false,

          // Brand Spaces
          [BRAND_SPACE_LOAD_REQUEST]: false,
          [SUPER_SAVER_COUPONS_LOAD_REQUEST]: false,
          // Brand Spaces
          [BRAND_SPACE_LOAD_REQUEST]: false,

          // Brand PRODUCTS
          [BRAND_PRODUCTS_LOAD_REQUEST]: false,

          // Home New
          [HOME_NEW_LOAD_REQUEST]: false,

          // Super Savings
          [SUPER_SAVINGS_LOAD_REQUEST]: false,

          // Trendign PRODUCTS
          [TRENDING_PRODUCTS_LOAD_REQUEST]: false,
          // Referrals
          [REFERRALS_LOAD_REQUEST]: false,
          // Acknowlwdgement
          [ACK_LOAD_REQUEST]: false,
        },
        requestsFailure: {
          [ActionTypes.PRODUCT_CATEGORY_REQUEST]: false,
          [ActionTypes.ADD_ADDRESS_REQUEST]: false,
          [ActionTypes.ADDRESS_LOAD_REQUEST]: false,
          [ActionTypes.ADDRESS_CHANGE_REQUEST]: false,
          [ActionTypes.ADDRESS_DELETE_REQUEST]: false,
          [ActionTypes.COUPONS_LOAD_REQUEST]: false,
          [ActionTypes.SPEND_OFFER_REQUEST]: false,
          [ActionTypes.STORE_AUXILIARY_REQUEST]: false,
          [ActionTypes.STORES_LOAD_REQUEST]: false,
          [ActionTypes.ONLINE_STORES_LOAD_REQUEST]: false,
          [ActionTypes.BEST_RATED_STORES_REQUEST]: false,
          [ActionTypes.STORE_LIKE_REQUEST]: false,
          [ActionTypes.NOTIFICATIONS_LOAD_REQUEST]: false,
          [ActionTypes.OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.HOME_BANNERS_REQUEST]: false,
          [ActionTypes.HOME_TOP_BANNERS_REQUEST]: false,
          [ActionTypes.ONLINE_PRODUCTS_REQUEST]: false,
          [ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST]: false,
          [ActionTypes.POPULAR_PRODUCTS_REQUEST]: false,
          [ActionTypes.ORDER_DETAILS_REQUEST]: false,
          [ActionTypes.ORDER_PATCH_REQUEST]: false,
          [ActionTypes.ORDERS_CREATE_REQUEST]: false,
          [ActionTypes.ORDERS_LOAD_REQUEST]: false,
          [ActionTypes.PROFILE_LOAD_REQUEST]: false,
          [ActionTypes.PAST_PRODUCTS_REQUEST]: false,
          [ActionTypes.PROFILE_EDIT_REQUEST]: false,
          [ActionTypes.REWARD_LOAD_REQUEST]: false,
          [ActionTypes.UNLOCK_REWARD_REQUEST]: false,
          [ActionTypes.STORES_SPECIFIC_COUPON_REQUEST]: false,
          [ActionTypes.UNUSED_OFFER_COUNT_REQUEST]: false,
          [ActionTypes.VALUE_OFFER_REQUEST]: false,
          [ActionTypes.APPEX_OFFER_LOAD_REQUEST]: false,
          [ActionTypes.TOP_SAVING_OFFER_REQUEST]: false,
          [ActionTypes.TOP_PICKS_SHOPS_REQUEST]: false,
          [ActionTypes.CUSTOMER_STAT_REQUEST]: false,
          [ActionTypes.NEWLY_ADDED_STORES_REQUEST]: false,
          [ActionTypes.SPEND_OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.BOGO_OFFERS_LOAD_REQUEST]: false,
          [ActionTypes.CART_AVAILABLE_OFFERS_REQUEST]: false,
          // NEW CART REQ
          [CART_LOAD_REQUEST]: false,
          [CART_PRODUCT_REQUEST]: {},
          [CART_DELETE_REQUEST]: false,
          [CART_ADD_COUPONS_REQUEST]: false,
          [CART_DELETE_COUPON_REQUEST]: false,
          [CART_ADD_OFFER_REQUEST]: false,
          [CART_DELETE_OFFER_REQUEST]: false,
          [CART_DELIVERY_UPDATE_REQUEST]: false,
          [CART_PAYMENT_UPDATE_REQUEST]: false,
          [HIGHLIGHT_CART_OFFER_REQUEST]: false,

          // New Offers Req
          [STORE_OFFER_LOAD_REQUEST]: false,

          // New Offers
          [PAST_PURCHASE_ITEMS_LOAD_REQUEST]: false,
          [MAX_SAVINGS_LOAD_REQUEST]: false,
          [BUY_1_GET_1_FREE_LOAD_REQUEST]: false,
          [GET_FREE_PRODUCT_LOAD_REQUEST]: false,

          // Brand Spaces
          [BRAND_SPACE_LOAD_REQUEST]: false,
          [SUPER_SAVER_COUPONS_LOAD_REQUEST]: false,
          // Brand Spaces
          [BRAND_SPACE_LOAD_REQUEST]: false,
          // Brand Products
          [BRAND_PRODUCTS_LOAD_REQUEST]: false,
          // Home New
          [HOME_NEW_LOAD_REQUEST]: false,
          // Super Savings
          [SUPER_SAVINGS_LOAD_REQUEST]: false,
          // TRENDING Products
          [TRENDING_PRODUCTS_LOAD_REQUEST]: false,
          // Referrals
          [REFERRALS_LOAD_REQUEST]: false,
          // Acknowledgement
          [ACK_LOAD_REQUEST]: false,
        },
        customer: null,
        customerAddresses: [],
        selectedAddress: null,
        categories: null,
        onlineProducts: null,
        stores: null,
        onlineStores: null,
        bestRatedStores: null,
        homeBanners: null,
        homeTopBanners: null,
        offerBanners: null,
        offers: null,
        coupons: null,
        cartDetails: null,
        highlightCartOfferDetails: null,
        cartId: null,
        notifications: null,
        orderSessionId: null,
        cartItems: null,
        guestCustomCartItems: null,
        orders: null,
        orderDetails: null,
        pastProducts: null,
        previousOrderProducts: null,
        bogoOffers: null,
        cartAvailableOffers: null,
        spendOffers: null,
        storeDetails: null,
        rewards: null,
        scratchCard: null,
        storeCoupons: null,
        unusedOfferCount: null,
        appExOffers: null,
        coachmark: defaultCoachmark,
        topSavingOffers: null,
        monthlySamanSection: null,
        topPicksShops: null,
        customerStat: null,
        storeOffers: null,
        pastPurchaseItems: null,
        recentSearchedAddress: [],
        activeOrders: null,
      });
    }

    case ActionTypes.CLEAR_SELECTED_ADDRESS: {
      Storage.setAddress(null);
      return ({
        ...state,
        selectedAddress: null,
      });
    }

    case ActionTypes.NOTIFICATIONS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.NOTIFICATIONS_LOAD_SUCCESS: {
      const { notifications, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
          false,
        ),
        notifications: offset === 0 || !state.notifications
          ? notifications
          : ({
            ...notifications,
            results: state.notifications.results
              .concat(notifications.results),
          }),
      });
    }

    case ActionTypes.NOTIFICATIONS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.PREMIUM_STORES_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREMIUM_STORES_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PREMIUM_STORES_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.PREMIUM_STORES_SUCCESS: {
      const { premiumStores } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREMIUM_STORES_REQUEST,
          false,
        ),
        premiumStores,
      });
    }

    case ActionTypes.PREMIUM_STORES_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREMIUM_STORES_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PREMIUM_STORES_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_NEW_CATEGORY_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_NEW_CATEGORY_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_NEW_CATEGORY_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORE_NEW_CATEGORY_SUCCESS: {
      const { storeNewCategory } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_NEW_CATEGORY_REQUEST,
          false,
        ),
        storeNewCategory,
      });
    }

    case ActionTypes.STORE_NEW_CATEGORY_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_NEW_CATEGORY_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_NEW_CATEGORY_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.NEWLY_ADDED_STORES_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NEWLY_ADDED_STORES_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.NEWLY_ADDED_STORES_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.NEWLY_ADDED_STORES_SUCCESS: {
      const { newlyAddedStores } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NEWLY_ADDED_STORES_REQUEST,
          false,
        ),
        newlyAddedStores,
      });
    }

    case ActionTypes.NEWLY_ADDED_STORES_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.NEWLY_ADDED_STORES_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.NEWLY_ADDED_STORES_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.OFFERS_LOAD_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.OFFERS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.OFFERS_LOAD_REQUEST,
          false,
        ),
        offers: !offset
          ? null : state.offers,
      });
    }

    case ActionTypes.OFFERS_LOAD_SUCCESS: {
      const { offers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.OFFERS_LOAD_REQUEST,
          false,
        ),
        offers: offset === 0 || !state.offers
          ? offers
          : ({
            ...offers,
            results: state.offers.results
              .concat(offers.results),
          }),
      });
    }

    case ActionTypes.OFFERS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.OFFERS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.OFFERS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ONLINE_PRODUCTS_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_PRODUCTS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ONLINE_PRODUCTS_REQUEST,
          false,
        ),
        onlineProducts: !offset
          ? null : state.onlineProducts,
      });
    }

    case ActionTypes.ONLINE_PRODUCTS_SUCCESS: {
      const {
        offset, onlineProducts,
      } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_PRODUCTS_REQUEST,
          false,
        ),
        onlineProducts: offset === 0 || !state.onlineProducts
          ? onlineProducts
          : ({
            ...onlineProducts,
            results: state.onlineProducts.results
              .concat(onlineProducts.results),
          }),
      });
    }

    case ActionTypes.ONLINE_PRODUCTS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_PRODUCTS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ONLINE_PRODUCTS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
          false,
        ),
        categorySearchProducts: !offset
          ? null : state.categorySearchProducts,
      });
    }

    case ActionTypes.CATEGORY_PRODUCT_SEARCH_SUCCESS: {
      const {
        offset, categorySearchProducts,
      } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
          false,
        ),
        categorySearchProducts: offset === 0 || !state.categorySearchProducts
          ? categorySearchProducts
          : ({
            ...categorySearchProducts,
            results: state.categorySearchProducts.results
              .concat(categorySearchProducts.results),
          }),
      });
    }

    case ActionTypes.CATEGORY_PRODUCT_SEARCH_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.POPULAR_PRODUCTS_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.POPULAR_PRODUCTS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.POPULAR_PRODUCTS_REQUEST,
          false,
        ),
        popularProducts: !offset
          ? null : state.popularProducts,
      });
    }

    case ActionTypes.POPULAR_PRODUCTS_SUCCESS: {
      const {
        offset, popularProducts,
      } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.POPULAR_PRODUCTS_REQUEST,
          false,
        ),
        popularProducts: offset === 0 || !state.popularProducts
          ? popularProducts
          : ({
            ...popularProducts,
            results: state.popularProducts.results
              .concat(popularProducts.results),
          }),
      });
    }

    case ActionTypes.POPULAR_PRODUCTS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.POPULAR_PRODUCTS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.POPULAR_PRODUCTS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ORDER_DETAILS_REQUEST: {
      const { orderId } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_DETAILS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDER_DETAILS_REQUEST,
          false,
        ),
        orderDetails: (
          state.orderDetails
            && state.orderDetails.orderId === orderId
            ? state.orderDetails
            : null
        ),
      });
    }

    case ActionTypes.ORDER_DETAILS_SUCCESS: {
      const { orderDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_DETAILS_REQUEST,
          false,
        ),
        orderDetails,
      });
    }

    case ActionTypes.ORDER_DETAILS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_DETAILS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDER_DETAILS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ORDER_PATCH_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_PATCH_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDER_PATCH_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ORDER_PATCH_SUCCESS: {
      const { orderDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_PATCH_REQUEST,
          false,
        ),
        orderDetails,
      });
    }

    case ActionTypes.ORDER_PATCH_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDER_PATCH_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDER_PATCH_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ORDERS_CREATE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_CREATE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDERS_CREATE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ORDERS_CREATE_SUCCESS: {
      //
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_CREATE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ORDERS_CREATE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_CREATE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDERS_CREATE_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ORDERS_LOAD_REQUEST: {
      const { nextUrl } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDERS_LOAD_REQUEST,
          false,
        ),
        orders: !nextUrl
          ? null : state.orders,
      });
    }

    case ActionTypes.ORDERS_LOAD_SUCCESS: {
      const {
        orders, nextUrl,
      } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_LOAD_REQUEST,
          false,
        ),
        orders: (!nextUrl || !state.orders)
          ? orders
          : ({
            ...orders,
            data: state.orders.data
              .concat(orders.data),
          }),
      });
    }

    case ActionTypes.ORDERS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ORDERS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ORDERS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.PAST_PRODUCTS_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PAST_PRODUCTS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PAST_PRODUCTS_REQUEST,
          false,
        ),
        pastProducts: !offset && offset !== 0
          ? null : state.pastProducts,
      });
    }

    case ActionTypes.PAST_PRODUCTS_SUCCESS: {
      const {
        pastProducts, offset,
      } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PAST_PRODUCTS_REQUEST,
          false,
        ),
        pastProducts: offset === 0 || !state.pastProducts
          ? pastProducts
          : ({
            ...pastProducts,
            results: state.pastProducts.results
              .concat(pastProducts.results),
          }),
      });
    }

    case ActionTypes.PAST_PRODUCTS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PAST_PRODUCTS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PAST_PRODUCTS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.PREVIOUS_ORDER_PRODUCTS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
          false,
        ),
        previousOrderProducts: action.payload.previousOrderProducts,
      });
    }

    case ActionTypes.PREVIOUS_ORDER_PRODUCTS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.PRODUCT_CATEGORY_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PRODUCT_CATEGORY_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PRODUCT_CATEGORY_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.PRODUCT_CATEGORY_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PRODUCT_CATEGORY_REQUEST,
          false,
        ),
        categories: action.payload.categories,
      });
    }

    case ActionTypes.PRODUCT_CATEGORY_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PRODUCT_CATEGORY_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PRODUCT_CATEGORY_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_CATEGORIES_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_CATEGORIES_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_CATEGORIES_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORE_CATEGORIES_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_CATEGORIES_REQUEST,
          false,
        ),
        storeCategories: action.payload.storeCategories,
      });
    }

    case ActionTypes.STORE_CATEGORIES_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_CATEGORIES_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_CATEGORIES_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.PROFILE_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PROFILE_LOAD_FAILURE,
          false,
        ),
      });
    }

    case ActionTypes.PROFILE_LOAD_SUCCESS: {
      Storage.setProfile(action.payload.profile);
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_LOAD_REQUEST,
          false,
        ),
        customer: action.payload.profile,
      });
    }

    case ActionTypes.PROFILE_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PROFILE_LOAD_FAILURE,
          true,
        ),
      });
    }

    case ActionTypes.PROFILE_EDIT_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_EDIT_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PROFILE_EDIT_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.PROFILE_EDIT_SUCCESS: {
      Storage.setProfile(action.payload.profile);
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_EDIT_REQUEST,
          false,
        ),
        customer: action.payload.profile,
      });
    }

    case ActionTypes.PROFILE_EDIT_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.PROFILE_EDIT_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.PROFILE_EDIT_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.RESET_REQUEST_STATUS: {
      const { requests, fields } = action.payload;
      const resetField = fields || {};
      return ({
        ...state,
        requestsProcessing: getCurrentStatusOnReset(
          requests,
          state.requestsProcessing,
        ),
        requestsFailure: getCurrentStatusOnReset(
          requests,
          state.requestsFailure,
        ),
        ...resetField,
      });
    }

    case ActionTypes.REFERRAL_CODE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REFERRAL_CODE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.REFERRAL_CODE_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_REQUEST,
          false,
        ),
        cartDetails,
      });
    }

    case ActionTypes.REFERRAL_CODE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REFERRAL_CODE_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.REWARD_LOAD_REQUEST: {
      const { nextUrl } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REWARD_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REWARD_LOAD_REQUEST,
          false,
        ),
        rewards: !nextUrl ? null : state.rewards,
      });
    }

    case ActionTypes.REWARD_LOAD_SUCCESS: {
      const { rewards, nextUrl } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REWARD_LOAD_REQUEST,
          false,
        ),
        rewards: !nextUrl || !state.rewards
          ? rewards
          : ({
            ...rewards,
            data: state.rewards.data
              .concat(rewards.data),
          }),
      });
    }

    case ActionTypes.REWARD_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REWARD_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REWARD_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_AUXILIARY_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_AUXILIARY_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_AUXILIARY_REQUEST,
          false,
        ),
        storeDetails: null,
      });
    }

    case ActionTypes.STORE_AUXILIARY_SUCCESS: {
      const { storeDetails, storeId } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_AUXILIARY_REQUEST,
          false,
        ),
        storeDetails,
        stores: state.stores && {
          ...state.stores,
          results: updateStoreLikeStatus(
            state.stores.onMpaani,
            storeId,
            storeDetails.is_favorite,
          ),
        },
      });
    }

    case ActionTypes.STORE_AUXILIARY_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_AUXILIARY_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_AUXILIARY_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.SPEND_OFFERS_LOAD_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
          false,
        ),
        spendOffers: !offset && offset !== 0
          ? null : state.spendOffers,
      });
    }

    case ActionTypes.SPEND_OFFERS_LOAD_SUCCESS: {
      const { spendOffers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
          false,
        ),
        spendOffers: offset === 0 || !state.spendOffers
          ? spendOffers
          : ({
            ...spendOffers,
            results: state.spendOffers.results
              .concat(spendOffers.results),
          }),
      });
    }

    case ActionTypes.SPEND_OFFERS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORES_SPECIFIC_COUPON_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
          false,
        ),
        storeCoupons: !offset && offset !== 0
          ? null : state.storeCoupons,
      });
    }

    case ActionTypes.STORES_SPECIFIC_COUPON_SUCCESS: {
      const { storeCoupons, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
          false,
        ),
        storeCoupons: offset === 0 || !state.storeCoupons
          ? storeCoupons
          : ({
            ...storeCoupons,
            results: state.storeCoupons.results
              .concat(storeCoupons.results),
          }),
      });
    }

    case ActionTypes.STORES_SPECIFIC_COUPON_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.SPEND_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SPEND_OFFER_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.SPEND_OFFER_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFER_REQUEST,
          false,
        ),
        cartDetails,
      });
    }

    case ActionTypes.SPEND_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SPEND_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SPEND_OFFER_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORES_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORES_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORES_LOAD_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_LOAD_REQUEST,
          false,
        ),
        stores: action.payload.stores,
      });
    }

    case ActionTypes.STORES_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORES_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORES_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.ONLINE_STORES_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_STORES_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ONLINE_STORES_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.ONLINE_STORES_LOAD_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_STORES_LOAD_REQUEST,
          false,
        ),
        onlineStores: action.payload.onlineStores,
      });
    }

    case ActionTypes.ONLINE_STORES_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.ONLINE_STORES_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.ONLINE_STORES_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.SEO_METADATA_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SEO_METADATA_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SEO_METADATA_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.SEO_METADATA_LOAD_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SEO_METADATA_LOAD_REQUEST,
          false,
        ),
        seoMetadata: action.payload.seoMetadata,
      });
    }

    case ActionTypes.SEO_METADATA_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.SEO_METADATA_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.SEO_METADATA_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.BEST_RATED_STORES_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BEST_RATED_STORES_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BEST_RATED_STORES_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.BEST_RATED_STORES_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BEST_RATED_STORES_REQUEST,
          false,
        ),
        bestRatedStores: action.payload.bestRatedStores,
      });
    }

    case ActionTypes.BEST_RATED_STORES_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.BEST_RATED_STORES_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.BEST_RATED_STORES_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.TOP_PICKS_SHOPS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_PICKS_SHOPS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.TOP_PICKS_SHOPS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.TOP_PICKS_SHOPS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_PICKS_SHOPS_REQUEST,
          false,
        ),
        topPicksShops: action.payload.topPicksShops,
      });
    }

    case ActionTypes.TOP_PICKS_SHOPS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_PICKS_SHOPS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.TOP_PICKS_SHOPS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_LIKE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_LIKE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_LIKE_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORE_LIKE_SUCCESS: {
      const { storeId, status } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_LIKE_REQUEST,
          false,
        ),
        stores: state.stores && {
          ...state.stores,
          onMpaani: updateStoreLikeStatus(
            state.stores.onMpaani,
            storeId,
            status,
          ),
        },
        storeDetails: state.storeDetails
          && {
            ...state.storeDetails,
            is_favorite: status,
          },
      });
    }

    case ActionTypes.STORE_LIKE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_LIKE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_LIKE_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_RATING_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_RATING_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_RATING_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORE_RATING_SUCCESS: {
      const { myReview } = action.payload;
      const { storeDetails } = state;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_RATING_REQUEST,
          false,
        ),
        storeDetails: {
          ...state.storeDetails,
          rating: {
            ...storeDetails,
            my_review: myReview,
            reviews: [myReview, ...storeDetails.reviews],
          },
        },
      });
    }

    case ActionTypes.STORE_RATING_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_RATING_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_RATING_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_SUCCESS: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
          false,
        ),
        storeWisePrevOrderProducts: action.payload.storeWisePrevOrderProducts,
      });
    }

    case ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.TOGGLE_LOGIN: {
      return ({
        ...state,
        tryLogin: !state.tryLogin,
      });
    }

    case ActionTypes.TOGGLE_GUEST_LOCATION: {
      const {
        toggleGuestLocationPopup,
        isMandatoryGuestLocationPopup,
      } = action.payload;
      return ({
        ...state,
        toggleGuestLocationPopup,
        isMandatoryGuestLocationPopup,
      });
    }

    case ActionTypes.TOGGLE_MULTI_STORE_CHECKOUT: {
      const {
        productInfo = {}, productId = '', product = {}, multiStoreCheckoutErrorMsg = '',
      } = action.payload;
      return ({
        ...state,
        showMultiStoreCheckoutDialog: !state.showMultiStoreCheckoutDialog,
        productInfo,
        productId,
        product,
        multiStoreCheckoutErrorMsg,
      });
    }

    case ActionTypes.TOGGLE_GLOBAL_SEARCH: {
      return ({
        ...state,
        onGlobalSearch: !state.onGlobalSearch,
      });
    }

    case ActionTypes.TOGGLE_MANAGE_LOCATION: {
      return ({
        ...state,
        openManageLocation: !state.openManageLocation,
      });
    }

    case ActionTypes.TOGGLE_SCRATCH_CARD: {
      const { scratchCard } = action.payload;
      return ({
        ...state,
        scratchCard: scratchCard || null,
      });
    }

    case ActionTypes.CART_NULL_REQUEST: {
      return ({
        ...state,
        cartDetails: null,
        cartItems: null,
      });
    }

    case ActionTypes.TOP_SAVING_OFFER_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_SAVING_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.TOP_SAVING_OFFER_REQUEST,
          false,
        ),
        topSavingOffers: !offset && offset !== 0
          ? null : state.topSavingOffers,
      });
    }

    case ActionTypes.TOP_SAVING_OFFER_SUCCESS: {
      const { offers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_SAVING_OFFER_REQUEST,
          false,
        ),
        topSavingOffers: offset === 0 || !state.topSavingOffers
          ? offers
          : ({
            ...offers,
            results: state.topSavingOffers.results
              .concat(offers.results),
          }),
      });
    }

    case ActionTypes.TOP_SAVING_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.TOP_SAVING_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.TOP_SAVING_OFFER_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST: {
      const { offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
          false,
        ),
        monthlySamanSection: !offset && offset !== 0
          ? null : state.monthlySamanSection,
      });
    }

    case ActionTypes.MONTHLY_SAMAN_SECTION_SUCCESS: {
      const { offers, offset } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
          false,
        ),
        monthlySamanSection: offset === 0 || !state.monthlySamanSection
          ? offers
          : ({
            ...offers,
            results: state.monthlySamanSection.results
              .concat(offers.results),
          }),
      });
    }

    case ActionTypes.MONTHLY_SAMAN_SECTION_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.CHANGE_LANGUAGE: {
      const { language } = action.payload;
      Storage.setLanguage(language);
      return ({
        ...state,
        language,
      });
    }

    case ActionTypes.UNLOCK_REWARD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNLOCK_REWARD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.UNLOCK_REWARD_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.UNLOCK_REWARD_SUCCESS: {
      const { reward } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNLOCK_REWARD_REQUEST,
          false,
        ),
        rewards: updateReward(state.rewards, reward.data),
        scratchCard: {
          ...state.scratchCard,
          ...reward.data,
        },
      });
    }

    case ActionTypes.UNLOCK_REWARD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNLOCK_REWARD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.UNLOCK_REWARD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.UNUSED_OFFER_COUNT_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.UNUSED_OFFER_COUNT_SUCCESS: {
      const { unusedOfferCount } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
          false,
        ),
        unusedOfferCount,
      });
    }

    case ActionTypes.UNUSED_OFFER_COUNT_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.UPDATE_ADDRESS: {
      const { address } = action.payload;
      Storage.setAddress(address);
      return ({
        ...state,
        selectedAddress: address,
        ...addressChangeHandler(
          state.selectedAddress,
          address,
        ),
      });
    }

    case ActionTypes.UPDATE_CART_ITEMS: {
      const { cartItems } = action.payload;
      Storage.setCartItems(cartItems);
      return ({
        ...state,
        cartItems,
      });
    }

    case ActionTypes.UPDATE_COACHMARK: {
      const { coachmark } = action.payload;
      Storage.setCoachmark(coachmark);
      return ({
        ...state,
        coachmark,
      });
    }

    case ActionTypes.UPDATE_CUSTOMER: {
      const { customer } = action.payload;
      Storage.setProfile(customer);
      return ({
        ...state,
        customer,
      });
    }

    case ActionTypes.VALUE_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.VALUE_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.VALUE_OFFER_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.VALUE_OFFER_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.VALUE_OFFER_REQUEST,
          false,
        ),
        cartDetails,
      });
    }

    case ActionTypes.VALUE_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.VALUE_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.VALUE_OFFER_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.APPLY_VALUE_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.APPLY_VALUE_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.APPLY_VALUE_OFFER_FAILURE,
          false,
        ),
      });
    }

    case ActionTypes.APPLY_VALUE_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.APPLY_VALUE_OFFER_FAILURE,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.APPLY_VALUE_OFFER_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.REFERRAL_CODE_GET_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_GET_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REFERRAL_CODE_GET_REQUEST,
          false,
        ),
      });
    }

    case ActionTypes.REFERRAL_CODE_GET_SUCCESS: {
      const { referralCode } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_GET_REQUEST,
          false,
        ),
        referralCode,
      });
    }

    case ActionTypes.REFERRAL_CODE_GET_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ActionTypes.REFERRAL_CODE_GET_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ActionTypes.REFERRAL_CODE_GET_REQUEST,
          true,
        ),
      });
    }

    // Cart Load request
    case CART_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_LOAD_REQUEST,
          false,
        ),
      });
    }

    case CART_LOAD_SUCCESS: {
      const { cartDetails } = action.payload;
      Storage.setCartId(cartDetails.cartId);
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_LOAD_REQUEST,
          false,
        ),
        cartDetails,
        cartId: cartDetails.cartId,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_LOAD_REQUEST,
          true,
        ),
      });
    }

    case CART_DELETE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_REQUEST,
          false,
        ),
      });
    }

    case CART_DELETE_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_DELETE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_REQUEST,
          true,
        ),
      });
    }

    // Cart Product Actions
    case CART_PRODUCT_REQUEST: {
      const {
        productId, storeId,
      } = action.payload.data.product;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PRODUCT_REQUEST,
          {
            ...state.requestsProcessing[CART_PRODUCT_REQUEST],
            [productId]: storeId,
          },
        ),
      });
    }

    case CART_PRODUCT_SUCCESS: {
      const {
        product, cartDetails, forceAdd,
      } = action.payload;
      const {
        [product.productId]: productRequest,
        ...newProcessingRequests
      } = state.requestsProcessing[CART_PRODUCT_REQUEST];
      let cartItems = {};
      if (forceAdd) {
        cartItems = {
          [product.productId]: product,
        };
      } else {
        cartItems = {
          ...state.cartItems,
          [product.productId]: product,
        };
        if (product.quantity === 0) {
          delete cartItems[product.productId];
        }
      }
      if (isGuestUser()) {
        if (product.quantity === 0) {
          delete cartItems[product.productId];
        }
        Storage.setCartItems(cartItems);
      }
      return ({
        ...state,
        cartItems,
        cartDetails,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PRODUCT_REQUEST,
          newProcessingRequests,
        ),
      });
    }

    case CART_PRODUCT_FAILURE: {
      const {
        productId, multiStoreCheckoutErrorMsg, product, productInfo,
      } = action.payload;
      const {
        [productId]: productRequest,
        ...newProcessingRequests
      } = state.requestsProcessing[CART_PRODUCT_REQUEST];
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PRODUCT_REQUEST,
          newProcessingRequests,
        ),
        multiStoreCheckoutErrorMsg,
        product,
        productInfo,
      });
    }

    case CART_BULK_ADD_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_BULK_ADD_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_BULK_ADD_LOAD_REQUEST,
          false,
        ),
      });
    }

    case CART_BULK_ADD_LOAD_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_BULK_ADD_LOAD_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_BULK_ADD_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_BULK_ADD_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_BULK_ADD_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Cart Delivery update request
    case CART_DELIVERY_UPDATE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELIVERY_UPDATE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELIVERY_UPDATE_REQUEST,
          false,
        ),
      });
    }

    case CART_DELIVERY_UPDATE_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELIVERY_UPDATE_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_DELIVERY_UPDATE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELIVERY_UPDATE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELIVERY_UPDATE_REQUEST,
          true,
        ),
      });
    }

    // Cart Payment Update request
    case CART_PAYMENT_UPDATE_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PAYMENT_UPDATE_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_PAYMENT_UPDATE_REQUEST,
          false,
        ),
      });
    }

    case CART_PAYMENT_UPDATE_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PAYMENT_UPDATE_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_PAYMENT_UPDATE_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_PAYMENT_UPDATE_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_PAYMENT_UPDATE_REQUEST,
          true,
        ),
      });
    }

    // Cart Coupon Add request
    case CART_ADD_COUPONS_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_COUPONS_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_ADD_COUPONS_REQUEST,
          false,
        ),
      });
    }

    case CART_ADD_COUPONS_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_COUPONS_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_ADD_COUPONS_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_COUPONS_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_ADD_COUPONS_REQUEST,
          true,
        ),
      });
    }

    // Cart Coupon delete request
    case CART_DELETE_COUPON_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_COUPON_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_COUPON_REQUEST,
          false,
        ),
      });
    }

    case CART_DELETE_COUPON_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_COUPON_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_DELETE_COUPON_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_COUPON_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_COUPON_REQUEST,
          true,
        ),
      });
    }

    // Cart Coupon Add request
    case CART_ADD_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_ADD_OFFER_REQUEST,
          false,
        ),
      });
    }

    case CART_ADD_OFFER_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_OFFER_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_ADD_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_ADD_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_ADD_OFFER_REQUEST,
          true,
        ),
      });
    }

    // Cart Offer delete request
    case CART_DELETE_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_OFFER_REQUEST,
          false,
        ),
      });
    }

    case CART_DELETE_OFFER_SUCCESS: {
      const { cartDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_OFFER_REQUEST,
          false,
        ),
        cartDetails,
        cartItems: extractCartItems(cartDetails),
      });
    }

    case CART_DELETE_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          CART_DELETE_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          CART_DELETE_OFFER_REQUEST,
          true,
        ),
      });
    }

    case SET_CART_ITEMS: {
      const { cartItems } = action.payload;
      Storage.setCartItems(cartItems);
      return ({
        ...state,
        cartItems,
      });
    }

    case SET_GUEST_CUSTOM_CART_ITEMS: {
      const { guestCustomCartItems } = action.payload;
      Storage.setCustomCartItems(guestCustomCartItems);
      return ({
        ...state,
        guestCustomCartItems,
      });
    }

    case HIGHLIGHT_CART_OFFER_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HIGHLIGHT_CART_OFFER_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          HIGHLIGHT_CART_OFFER_REQUEST,
          false,
        ),
      });
    }

    case HIGHLIGHT_CART_OFFER_SUCCESS: {
      const { highlightCartOfferDetails } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HIGHLIGHT_CART_OFFER_REQUEST,
          false,
        ),
        highlightCartOfferDetails,
      });
    }

    case HIGHLIGHT_CART_OFFER_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HIGHLIGHT_CART_OFFER_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          HIGHLIGHT_CART_OFFER_REQUEST,
          true,
        ),
      });
    }

    // Store Offers Load request
    case STORE_OFFER_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          STORE_OFFER_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          STORE_OFFER_LOAD_REQUEST,
          false,
        ),
      });
    }

    case STORE_OFFER_LOAD_SUCCESS: {
      const { storeOffers } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          STORE_OFFER_LOAD_REQUEST,
          false,
        ),
        storeOffers,
      });
    }

    case STORE_OFFER_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          STORE_OFFER_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          STORE_OFFER_LOAD_REQUEST,
          true,
        ),
      });
    }

    case PAST_PURCHASE_ITEMS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          PAST_PURCHASE_ITEMS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          PAST_PURCHASE_ITEMS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case PAST_PURCHASE_ITEMS_LOAD_SUCCESS: {
      const { pastPurchaseItems } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          PAST_PURCHASE_ITEMS_LOAD_REQUEST,
          false,
        ),
        pastPurchaseItems,
      });
    }

    case PAST_PURCHASE_ITEMS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          PAST_PURCHASE_ITEMS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          PAST_PURCHASE_ITEMS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Max Savings Offers
    case MAX_SAVINGS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          MAX_SAVINGS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          MAX_SAVINGS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case MAX_SAVINGS_LOAD_SUCCESS: {
      const { maxSavingsOffers } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          MAX_SAVINGS_LOAD_REQUEST,
          false,
        ),
        maxSavingsOffers,
      });
    }

    case MAX_SAVINGS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          MAX_SAVINGS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          MAX_SAVINGS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Buy 1 GET 1 Offers
    case BUY_1_GET_1_FREE_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BUY_1_GET_1_FREE_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BUY_1_GET_1_FREE_LOAD_REQUEST,
          false,
        ),
      });
    }

    case BUY_1_GET_1_FREE_LOAD_SUCCESS: {
      const { bogoOffers } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BUY_1_GET_1_FREE_LOAD_REQUEST,
          false,
        ),
        bogoOffers,
      });
    }

    case BUY_1_GET_1_FREE_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BUY_1_GET_1_FREE_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BUY_1_GET_1_FREE_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Brand Spaces
    case BRAND_SPACE_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_SPACE_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BRAND_SPACE_LOAD_REQUEST,
          false,
        ),
      });
    }

    case BRAND_SPACE_LOAD_SUCCESS: {
      const { brandSpaces } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_SPACE_LOAD_REQUEST,
          false,
        ),
        brandSpaces,
      });
    }

    case BRAND_SPACE_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_SPACE_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BRAND_SPACE_LOAD_REQUEST,
          true,
        ),
      });
    }

    // GET Free Products
    case GET_FREE_PRODUCT_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          GET_FREE_PRODUCT_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          GET_FREE_PRODUCT_LOAD_REQUEST,
          false,
        ),
      });
    }

    case GET_FREE_PRODUCT_LOAD_SUCCESS: {
      const { comboOffers } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          GET_FREE_PRODUCT_LOAD_REQUEST,
          false,
        ),
        comboOffers,
      });
    }

    case GET_FREE_PRODUCT_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          GET_FREE_PRODUCT_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          GET_FREE_PRODUCT_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Brand Products
    case BRAND_PRODUCTS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_PRODUCTS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BRAND_PRODUCTS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case BRAND_PRODUCTS_LOAD_SUCCESS: {
      const { brandProducts } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_PRODUCTS_LOAD_REQUEST,
          false,
        ),
        brandProducts,
      });
    }

    case BRAND_PRODUCTS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          BRAND_PRODUCTS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          BRAND_PRODUCTS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Home New
    case HOME_NEW_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HOME_NEW_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          HOME_NEW_LOAD_REQUEST,
          false,
        ),
      });
    }

    case HOME_NEW_LOAD_SUCCESS: {
      const { homeNew } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HOME_NEW_LOAD_REQUEST,
          false,
        ),
        homeNew,
      });
    }

    case HOME_NEW_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          HOME_NEW_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          HOME_NEW_LOAD_REQUEST,
          true,
        ),
      });
    }

    // SUPER SAVER COUPONS
    case SUPER_SAVER_COUPONS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVER_COUPONS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          SUPER_SAVER_COUPONS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case SUPER_SAVER_COUPONS_LOAD_SUCCESS: {
      const { superSaverCoupons } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVER_COUPONS_LOAD_REQUEST,
          false,
        ),
        superSaverCoupons,
      });
    }

    case SUPER_SAVER_COUPONS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVER_COUPONS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          SUPER_SAVER_COUPONS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Super Savings
    case SUPER_SAVINGS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVINGS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          SUPER_SAVINGS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case SUPER_SAVINGS_LOAD_SUCCESS: {
      const { superSavings } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVINGS_LOAD_REQUEST,
          false,
        ),
        superSavings,
      });
    }

    case SUPER_SAVINGS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          SUPER_SAVINGS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          SUPER_SAVINGS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Trending Products
    case TRENDING_PRODUCTS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          TRENDING_PRODUCTS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          TRENDING_PRODUCTS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case TRENDING_PRODUCTS_LOAD_SUCCESS: {
      const { trendingProducts } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          TRENDING_PRODUCTS_LOAD_REQUEST,
          false,
        ),
        trendingProducts,
      });
    }

    case TRENDING_PRODUCTS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          TRENDING_PRODUCTS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          TRENDING_PRODUCTS_LOAD_REQUEST,
          true,
        ),
      });
    }

    // Referrals Load request
    case REFERRALS_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          REFERRALS_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          REFERRALS_LOAD_REQUEST,
          false,
        ),
      });
    }

    case REFERRALS_LOAD_SUCCESS: {
      const { referrals } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          REFERRALS_LOAD_REQUEST,
          false,
        ),
        referrals,
      });
    }

    case REFERRALS_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          REFERRALS_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          REFERRALS_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ACTIVE_ORDER_LOAD_SUCCESS: {
      const { activeOrders } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ACTIVE_ORDER_LOAD_REQUEST,
          false,
        ),
        activeOrders,
      });
    }

    // Acknowledgement Load request
    case ACK_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ACK_LOAD_REQUEST,
          true,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ACK_LOAD_REQUEST,
          false,
        ),
      });
    }

    case ACK_LOAD_SUCCESS: {
      const { ackBanners } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ACK_LOAD_REQUEST,
          false,
        ),
        ackBanners,
      });
    }

    case ACK_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          ACK_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          ACK_LOAD_REQUEST,
          true,
        ),
      });
    }

    case ActionTypes.UPDATE_STATE: {
      return ({
        ...state,
        ...action.payload.state,
      });
    }

    default:
      return state;
  }
};
