/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { getCodeFromURL, isAuthenticated } from 'app/utilities/Utils';
import * as BrandSpaceActionTypes from 'app/store/brand-space/actions';
import * as AckBannersActionTypes from 'app/store/acknowledgement/actions';
import { storeAuxiliary } from 'api/api';
import SectionTitle from 'app/components/common/SectionTitle';
import CustomScroller from 'app/components/common/CustomScroller';
import QuickCard from 'app/components/common/QuickCard';
import BogoOfffer from 'app/components/derived/offer/BOGOOffer';
import { bogoOffersLoadRequest, offersLoadRequest, toggleLogin } from 'app/store/Actions';
import {
  getStoreCategories, previousOrderProducts,
  storeCategoryProducts, storeSearch, storeSuperSave,
} from 'api/shop-front';
import ProductThumb from 'app/components/common/ProductThumb';
import { Constants } from 'app/utilities';
import { CustomModal, List, Svg } from 'app/components/common';
import { SHOP_FRONT, STORE_DETAILS } from 'app/events/screens';
import { buttonEventClick } from 'clevertap/LogEvent';
import Events from 'clevertap/Event';
import FooterMobile from 'app/layout/footer/FooterMobile';
import Footer from 'app/layout/footer/Footer';
import SpendOfferCard from 'app/components/derived/offer/SpendOfferCard';
import CouponCard from 'app/components/derived/offer/CouponCard';
import SpendOfferSummary from 'app/layout/store_details/SpendOfferSummary';
import FeaturedBanner from 'app/components/common/homepage/FeaturedBanner';
import Shimmer from 'app/shimmer/Shimmer';
import ReverseTimer from 'app/components/common/ReverseTimer';
import ScratchCards from 'app/components/common/ScratchCards';
import ReferFriendInfo from 'app/layout/refer-friends/ReferFriendInfo';
import FreeSampleModal from 'app/layout/store_details/FreeSampleModal';
import ShopOffline from 'app/components/common/ShopOffline';
import valueUnlockingStripEL from 'app/event-listeners/CommonEventListener';
import TopCategory from './layout/TopCategory';
import StoreRetailerStory from './layout/StoreRetailerStory';
import StoreBasicDetails from './layout/StoreBasicDetails';
import * as screens from '../../events/screens';
import StoreFooterSEOData from './layout/StoreFooterSEOData';
import { logCTEvent } from '../../events/Events';
import StoreCategory from './layout/StoreCategory';
import CategoryFilters from './layout/CategoryFilters';
import RepeatProductsSeeAll from './layout/see-all/RepeatProductsSeeAll';
import CategoryProductsSeeAll from './layout/see-all/CategoryProductsSeeAll';
import ComboOffersSeeAll from './layout/see-all/ComboOffersSeeAll';
import TopSavingSeeAll from './layout/see-all/TopSavingSeeAll';
import StoreSearchResults from './layout/see-all/StoreSearchResults';
import * as ctEvents from '../../events/Events';
import PageMetaData from './layout/PageMetaData';
import BottomBanners from './layout/BottomBanners';
import '../../styles/home/homeNew.scss';

const { CancelToken } = Axios;

class StoreDetails extends React.Component {
  constructor(props) {
    super(props);
    const queryParam = QueryString.parse(props.location.search);
    const { b2bStorePreview } = queryParam;
    let { storeId } = props.match.params;
    if (!storeId) {
      const { shopURL } = props.match.params;
      storeId = getCodeFromURL(shopURL);
    }
    const { selectedAddress } = props;
    const location = selectedAddress ? `${selectedAddress.location.lat},${selectedAddress.location.lng}` : '19.1207983,72.8782323';
    this.state = {
      b2bStorePreview,
      storeId,
      storeCatProducts: null,
      previouslyOrderedProducts: null,
      storeDetails: null,
      location,
      storeState: 'ALL',
      searchText: '',
      storeCategories: null,
      selectedCategory: null,
      superSavings: null,
      spendOffer: null,
      isModalOpen: false,
      freeSampleProducts: [],
      searchResults: null,
      firstLoad: false,
      shopNotServicablePopup: false,
      isShopOffline: false,
    };
    this.mainContainerRef = React.createRef();
    this.storySectionRef = React.createRef();
    this.activeTimeout = null;
    this.source = CancelToken.source();
  }

  static getDerivedStateFromProps = (props) => {
    let { storeId } = props.match.params;
    if (!storeId) {
      const { shopURL } = props.match.params;
      storeId = getCodeFromURL(shopURL);
    }
  }

  componentDidMount = () => {
    valueUnlockingStripEL(document.getElementById('shop-front-main-content'));
    this.handleLoad();
    this.offerLoad();
  }

  componentDidUpdate = (prevProps) => {
    const { brandSpaces } = this.props;
    const { firstLoad } = this.state;
    if (prevProps.brandSpaces !== brandSpaces || !firstLoad) {
      this.setState({ firstLoad: true });
      if (brandSpaces && brandSpaces?.featured && brandSpaces?.featured.length > 0) {
        (brandSpaces?.featured).map((banner) => {
          this.CaptureBrandBannerImpression(banner, 'Featured');
          return 0;
        });
      }
      if (brandSpaces && brandSpaces?.focusToday && brandSpaces?.focusToday.length > 0) {
        (brandSpaces?.focusToday).map((banner) => {
          this.CaptureBrandBannerImpression(banner, 'In_Focus');
          return 0;
        });
      }
    }
  }

  componentWillUnmount = () => {
    this.source.cancel();
    // removing event listener
    valueUnlockingStripEL(document.getElementById('shop-front-main-content'), true);
  }

  handleCaptureBrandBannerClicked = (banner, section) => {
    const { isMobile } = this.props;
    ctEvents.logBrandBannerClicked(
      {
        Page: SHOP_FRONT,
        banner_name: banner.name,
        section,
        id: banner.bannerId,
        image: (isMobile) ? banner.image : banner.webimage,
      },
    );
  }

  CaptureBrandBannerImpression = (banner, section) => {
    const { isMobile } = this.props;
    ctEvents.logBrandBannerImpression(
      {
        Page: SHOP_FRONT,
        banner_name: banner.name,
        section,
        id: banner.bannerId,
        image: (isMobile) ? banner.image : banner.webimage,
      },
    );
  }

  offerLoad = () => {
    const { location } = this.props;
    if (
      location && location.state && location.state.item
    ) {
      this.setState({
        spendOffer: location.state.item,
      });
    }
  }

  // category Meta will come in case of desktop view and category selected
  // in case of left hand category filters it will come different and
  // for must have like it will come diff
  changeStoreState = (newState, categoryMeta = null) => {
    this.scrollToTop();
    this.setState({
      storeState: newState,
      selectedCategory: categoryMeta,
      searchResults: null,
      searchText: '',
    });
  }

  moveToSection = (currentFocusRef) => {
    this.mainContainerRef.current.scrollTo({
      behavior: 'smooth', top: currentFocusRef.current.offsetTop,
    });
  }

  handleLoad = () => {
    const {
      brandSpaces, bogoOffersRequest, offersRequest,
      ackBanners,
    } = this.props;
    const {
      storeId, storeCatProducts,
      previouslyOrderedProducts, storeCategories,
      superSavings,
    } = this.state;
    this.loadStoreDetails();
    bogoOffersRequest(null, null, storeId);
    offersRequest(storeId, null);
    if (!brandSpaces) {
      this.loadBrandSpaces();
    }
    if (!storeCatProducts) {
      this.loadStoreCatProducts();
    }
    if (!previouslyOrderedProducts) {
      this.loadPreviouslyOrderedProducts();
    }
    if (!storeCategories) {
      this.loadStoreCategories();
    }
    if (!superSavings) {
      this.loadSuperSaveOffers();
    }
    if (!ackBanners) {
      this.loadAckBanners();
    }
  }

  loadSuperSaveOffers = () => {
    const { storeId } = this.state;
    const { selectedAddress } = this.props;
    storeSuperSave(
      storeId,
      this.source.token,
      {
        latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
        longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
      },
    ).then((res) => {
      if (res.data.data.superSavings) {
        this.setState({
          superSavings: res.data.data.superSavings,
        });
      }
    }).catch(() => { });
  }

  loadStoreCategories = () => {
    const { storeId } = this.state;
    getStoreCategories(
      storeId,
      this.source.token,
    ).then((res) => {
      this.setState({
        storeCategories: res.data,
      });
    }).catch(() => { });
  }

  loadStoreDetails = () => {
    const { storeDetails, storeId, location } = this.state;
    if (!storeDetails) {
      storeAuxiliary(
        'get',
        this.source.token,
        location,
        storeId,
      ).then((res) => {
        logCTEvent(
          Events.Store_Landing_Page,
          {
            'Store Name': res.data.store_name,
            'EP Code': res.data.uid,
          },
        );
        this.setState({
          shopNotServicablePopup: !res.data.isServicable || !res.data.is_delivering,
          isShopOffline: !res.data.is_delivering,
          storeDetails: res.data,
        });
      }).catch(() => { });
    }
  }

  loadPreviouslyOrderedProducts = () => {
    const { storeId } = this.state;
    const { selectedAddress } = this.props;
    if (isAuthenticated()) {
      previousOrderProducts(
        storeId,
        this.source.token,
        {
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        this.setState({
          previouslyOrderedProducts: res.data,
        });
      }).catch(() => {});
    }
  }

  loadStoreCatProducts = () => {
    const {
      storeId,
    } = this.state;
    storeCategoryProducts(
      storeId,
      this.source.token,
    ).then((res) => {
      this.setState({
        storeCatProducts: res.data,
      });
    }).catch(() => { });
  };

  loadAckBanners = () => {
    const { ackBannersReq } = this.props;
    ackBannersReq();
  };

  getStoreOtherData = () => {
    this.getStoreStickers();
  }

  scrollToTop = () => {
    this.mainContainerRef.current.scrollTo({
      behavior: 'smooth', top: 0,
    });
  }

  handleProductSearch = (event) => {
    const { value } = event.target;
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = null;
    }
    if (value) {
      this.setState({
        searchText: value,
      }, () => {
        this.activeTimeout = setTimeout(
          () => this.loadSearchProduct(true),
          300,
        );
      });
    } else {
      this.setState({
        searchText: value,
      }, () => this.loadSearchProduct(true));
    }
  }

  loadSearchProduct = () => {
    const {
      storeId, searchText,
      storeDetails,
    } = this.state;
    storeSearch(
      storeId,
      this.source.token,
      {
        searchText,
      },
    ).then((res) => {
      const { data } = res.data;
      logCTEvent(
        Events.ShopSearched_Product,
        {
          Search_Result_Found: searchText,
          'EP Code': storeDetails.uid,
          Page: SHOP_FRONT,
        },
      );
      this.setState({
        searchResults: data[0],
      });
    }).catch(() => { });
  }

  onBannerClick = (brand) => {
    const { isMobile, history } = this.props;
    ctEvents.logBrandBannerClicked(
      {
        Page: SHOP_FRONT,
        banner_name: brand.name,
        section: 'In Focus',
        id: brand.brandId,
        image: (isMobile) ? brand.image : brand.webimage,
      },
    );
    history.push(`/brands/${brand.name.toLowerCase()}-${brand.brandId}`);
  };

  loadBrandSpaces() {
    const { brandSpacesReq } = this.props;
    brandSpacesReq(null, null, '', {});
  }

  render() {
    const {
      brandSpaces, isMobile, history, bogoOffers, language,
      cartItems, offers: productTopSavingOffers,
      selectedAddress, toggleSideOverlay, toggleUserLogin,
      match, ackBanners,
    } = this.props;
    const {
      storeCatProducts, previouslyOrderedProducts, storeId,
      storeDetails, b2bStorePreview, storeState,
      storeCategories, selectedCategory, superSavings,
      spendOffer, searchText, searchResults, shopNotServicablePopup,
      isShopOffline, freeSampleProducts, isModalOpen,
    } = this.state;

    return (
      <Container
        fluid
        id="shop-front-main-content"
        className="overflow-y-scroll h-100 px-0"
        ref={this.mainContainerRef}
      >
        {
          !!spendOffer && (
            <CustomModal
              show={!!spendOffer}
              body={(
                <SpendOfferSummary
                  offer={spendOffer}
                  onHide={() => { this.setState({ spendOffer: null }); }}
                />
              )}
              size="md"
              onHide={() => {
                this.setState({
                  spendOffer: null,
                });
              }}
              backdrop
            />
          )
        }
        {
          isModalOpen && (
            <CustomModal
              show={isModalOpen}
              body={(
                <ReferFriendInfo
                  {...this.props}
                  onHide={() => { this.setState({ isModalOpen: false }); }}
                />
              )}
              size="md"
              onHide={() => {
                this.setState({
                  isModalOpen: false,
                });
              }}
              backdrop
            />
          )
        }
        {
          freeSampleProducts && freeSampleProducts.length > 0 && (
            <CustomModal
              show
              body={(
                <FreeSampleModal
                  freeProducts={freeSampleProducts}
                  onHide={() => this.setState({ freeSampleProducts: [] })}
                  language={language}
                />
              )}
              size="md"
              onHide={() => {
                this.setState({
                  freeSampleProducts: [],
                });
              }}
              backdrop
            />
          )
        }
        {
          (shopNotServicablePopup) && (
            <CustomModal
              show
              closeButton={shopNotServicablePopup}
              backdrop={shopNotServicablePopup || isShopOffline}
              onHide={() => this.setState({ shopNotServicablePopup: false, isShopOffline: false })}
              body={(
                <ShopOffline
                  language={language}
                  history={history}
                  isMobile={isMobile}
                  reason={isShopOffline ? '' : 'DELAYED_DELIVERY'}
                  page="Shop_front"
                  onHide={() => this.setState({ shopNotServicablePopup: false })}
                />
              )}
            />
          )
        }
        <PageMetaData />
        {
          storeDetails ? (
            <StoreBasicDetails
              storeDetails={storeDetails}
              b2bStorePreview={b2bStorePreview}
              selectedAddress={selectedAddress}
              storeState={storeState}
              changeStoreState={this.changeStoreState}
              handleProductSearch={this.handleProductSearch}
              moveToStorySection={() => this.moveToSection(this.storySectionRef)}
              toggleSideOverlay={toggleSideOverlay}
              isMobile={isMobile}
              language={language}
              searchText={searchText}
            />
          ) : (
            <Col
              xs={24}
            >
              <Shimmer
                shimmerType="SHOP_DETAILS"
              />
            </Col>
          )
        }
        {
          storeDetails ? (
            <Container>
              <Row>
                <Col xs={5} className="d-none d-md-block">
                  <CategoryFilters
                    selectedCategory={selectedCategory}
                    categories={storeCategories}
                    changeStoreState={this.changeStoreState}
                    language={language}
                  />
                </Col>
                {
                  storeState === 'ALL' ? (
                    <Col xs={24} md={17} className="r__flex-30 d-flex flex-column h-100 p-4 p-md-6 pr-md-4">
                      {
                        (superSavings && superSavings.length > 0)
                          ? (
                            <>
                              <section>
                                <CustomScroller
                                  noMargin
                                  content={(
                                    <div className="r__flex r__flex-10">
                                      {
                                        superSavings.map((card) => ((card.type === 'FREE_SAMPLE')
                                          ? (
                                            <div
                                              key={card.type}
                                              className="r__refer_card free_sample_bg"
                                              onClick={() => {
                                                buttonEventClick(
                                                  Events.LL_Banner_Clicked,
                                                  {
                                                    Page: 'Shop_front',
                                                    'Banner name': 'Free Sample',
                                                    Section: 'Middle Banner',
                                                  },
                                                );
                                                this.setState({
                                                  freeSampleProducts: card.freeSampleProducts,
                                                });
                                              }}
                                            >
                                              <img
                                                src={card.image}
                                                alt={card.name}
                                              />
                                              <span className="timer_wrapper fs-5 font-weight-bold d-flex align-items-center justify-content-center">
                                                <Svg
                                                  svg="clock"
                                                  width="24px"
                                                  height="24px"
                                                  fill={Constants.Color.black}
                                                  stroke={Constants.Color.black}
                                                  stroke1={Constants.Color.yellow}
                                                />
                                                <ReverseTimer
                                                  targetDate={card.validTill}
                                                />
                                              </span>
                                            </div>
                                          ) : null))
                                      }
                                      {
                                        superSavings.map((card) => ((card.type === 'REFER_AND_EARN')
                                          ? (
                                            <div
                                              key={card.type}
                                              className="r__refer_card free_sample_bg"
                                              onClick={() => {
                                                buttonEventClick(
                                                  Events.LL_Banner_Clicked,
                                                  {
                                                    Page: 'Shop_front',
                                                    'Banner name': 'Refer',
                                                    Section: 'Middle Banner',
                                                  },
                                                );
                                                this.setState({
                                                  isModalOpen: true,
                                                });
                                              }}
                                            >
                                              <img src={card.image} alt={card.name} />
                                            </div>
                                          ) : null))
                                      }
                                      {
                                        superSavings.map((card) => ((card.type === 'SCRATCH_CARD')
                                          ? (
                                            <ScratchCards
                                              {...this.props}
                                              key={card.type}
                                              item={card}
                                              page={screens.STORE_DETAILS}
                                            />
                                          ) : null))
                                      }
                                    </div>
                                  )}
                                  contentPrefix="storeSuperSavingsForYou"
                                />
                              </section>
                              <section>
                                <CustomScroller
                                  noMargin
                                  content={(
                                    <div className="r__flex r__flex-10">
                                      {
                                    superSavings.map((card) => ((card.type === 'SPEND_OFFER' || card.type === 'VALUE_OFFER')
                                      ? (
                                        <SpendOfferCard
                                          {...this.props}
                                          key={card.type}
                                          item={{ ...card, type: `${card.type}S` }}
                                          isStoreView
                                          cardHeight="100px"
                                          cardWidth="316px"
                                          page={SHOP_FRONT}
                                          onOfferClick={() => {
                                            logCTEvent(
                                              Events.Offer_Clicked,
                                              {
                                                'Offer Id': card.id,
                                                'Offer Name': card.name,
                                                'Offer Type': card.type,
                                                Search_Result_Found: searchText,
                                                'EP Code': storeDetails.uid,
                                                'Store Name': storeDetails.store_name,
                                                Page: SHOP_FRONT,
                                              },
                                            );
                                            this.setState({
                                              spendOffer: card,
                                            });
                                          }}
                                        />
                                      ) : null))
                                  }
                                      {
                                    superSavings.map((card) => ((card.type === 'COUPONS')
                                      ? (
                                        <CouponCard
                                          {...this.props}
                                          key={card.id}
                                          item={card}
                                          isStoreView
                                          page={SHOP_FRONT}
                                        />
                                      ) : null))
                                  }
                                    </div>
                              )}
                                  contentPrefix="storeSuperSavingsForYou"
                                />
                              </section>
                            </>
                          ) : null
                      }
                      {
                        (previouslyOrderedProducts && previouslyOrderedProducts.count > 0)
                          ? (
                            <section>
                              <SectionTitle
                                titleCopy={Constants.String.WANT_TO_REPEAT[language]}
                                hasBtn
                              >
                                {
                                previouslyOrderedProducts.count >= 6 ? (
                                  <button
                                    type="button"
                                    className="r__title_link-btn"
                                    onClick={() => {
                                      logCTEvent(
                                        Events.See_All_Clicked,
                                        {
                                          'See all type': 'Repeat Order Product',
                                          'Shop Category Type': storeDetails.store_type,
                                          'EP Code': storeDetails.uid,
                                          'Store Name': storeDetails.store_name,
                                          Page: SHOP_FRONT,
                                        },
                                      );
                                      if (isMobile) {
                                        history.push({
                                          pathname: `/shops/repeat-products/${storeId}`,
                                          state: {
                                            sname: storeDetails.store_name,
                                          },
                                        });
                                      } else {
                                        this.scrollToTop();
                                        this.setState({
                                          storeState: 'REPEAT_PRODUCTS_SEE_ALL',
                                        });
                                      }
                                    }}
                                  >
                                    {Constants.String.SEE_ALL[language]}
                                    <Svg
                                      svg="arrowRightNew"
                                      stroke={Constants.Color.primary}
                                      width="18px"
                                    />
                                  </button>
                                ) : null
                              }
                              </SectionTitle>
                              <CustomScroller
                                noMargin
                                content={(
                                  <div className="r__flex r__flex-20 pb-2">
                                    {previouslyOrderedProducts.data.slice(0, 5).map((product) => (
                                      <ProductThumb
                                        key={product.libraryProductId}
                                        {...this.props}
                                        libraryProductId={product.libraryProductId}
                                        retailProductId={product.id}
                                        name={product.name}
                                        image={product.image}
                                        mrp={product.mrp}
                                        sellingPrice={product.sellingPrice}
                                        measurementDisplayText={product.measurementDisplayText}
                                        storeName={storeDetails.store_name}
                                        storeId={storeId}
                                        offerPercent={product.offerPercent}
                                        page={SHOP_FRONT}
                                        section="Want To Repeat"
                                      />
                                    ))}
                                  </div>
                              )}
                                contentPrefix="storeRepeatOrderProducts"
                              />
                            </section>
                          ) : null
                      }
                      {
                        (productTopSavingOffers && productTopSavingOffers.count > 0)
                          ? (
                            <section>
                              <SectionTitle
                                titleCopy={Constants.String.TOP_SAVING_PRODUCTS[language]}
                                hasBtn
                              >
                                {
                                productTopSavingOffers && productTopSavingOffers.count >= 6 ? (
                                  <button
                                    type="button"
                                    className="r__title_link-btn"
                                    onClick={() => {
                                      logCTEvent(
                                        Events.See_All_Clicked,
                                        {
                                          'See all type': 'Top Saving Product',
                                          'Shop Category Type': storeDetails.store_type,
                                          'EP Code': storeDetails.uid,
                                          'Store Name': storeDetails.store_name,
                                          Page: SHOP_FRONT,
                                        },
                                      );
                                      if (isMobile) {
                                        history.push({
                                          pathname: `/shops/top-saving-products/${storeId}`,
                                          state: {
                                            sname: storeDetails.store_name,
                                          },
                                        });
                                      } else {
                                        this.scrollToTop();
                                        this.setState({
                                          storeState: 'TOP_SAVING_PRODUCTS_SEE_ALL',
                                        });
                                      }
                                    }}
                                  >
                                    {Constants.String.SEE_ALL[language]}
                                    <Svg
                                      svg="arrowRightNew"
                                      stroke={Constants.Color.primary}
                                      width="18px"
                                    />
                                  </button>
                                ) : null
                              }
                              </SectionTitle>
                              <CustomScroller
                                noMargin
                                content={(
                                  <div className="r__flex r__flex-20 pb-2">
                                    {productTopSavingOffers.results.map((product) => {
                                      const { primaryProduct } = product;
                                      return (
                                        <ProductThumb
                                          key={primaryProduct.id}
                                          {...this.props}
                                          libraryProductId={primaryProduct.id}
                                          retailProductId={primaryProduct.retailerProductId}
                                          name={primaryProduct.displayName}
                                          image={primaryProduct.image}
                                          mrp={primaryProduct.mrp}
                                          sellingPrice={primaryProduct.sellingPrice}
                                          measurementDisplayText={primaryProduct.unitString}
                                          storeName={storeDetails.store_name}
                                          storeId={storeId}
                                          displayTag={primaryProduct.displayTag}
                                          page={SHOP_FRONT}
                                          section="Top Saving Products"
                                        />
                                      );
                                    })}
                                  </div>
                              )}
                                contentPrefix="topSavingProducts"
                              />
                            </section>
                          ) : null
                      }
                      {
                        bogoOffers && bogoOffers.count > 0
                          ? (
                            <section>
                              <SectionTitle
                                titleCopy={Constants.String.EXCITING_COMBO_OFFERS[language]}
                                hasBtn
                              >
                                {
                                  bogoOffers && bogoOffers.count >= 6 ? (
                                    <button
                                      type="button"
                                      className="r__title_link-btn"
                                      onClick={() => {
                                        logCTEvent(
                                          Events.See_All_Exciting_Combo_Offer,
                                          {
                                            'See all type': 'Exciting Combo Offer',
                                            'Shop Category Type': storeDetails.store_type,
                                            'EP Code': storeDetails.uid,
                                            'Store Name': storeDetails.store_name,
                                            Page: SHOP_FRONT,
                                          },
                                        );
                                        if (isMobile) {
                                          history.push({
                                            pathname: `/shops/combo-offers/${storeId}`,
                                            state: {
                                              sname: storeDetails.store_name,
                                            },
                                          });
                                        } else {
                                          this.scrollToTop();
                                          this.setState({
                                            storeState: 'COMBO_OFFER_SEE_ALL',
                                          });
                                        }
                                      }}
                                    >
                                      {Constants.String.SEE_ALL[language]}
                                      <Svg
                                        svg="arrowRightNew"
                                        stroke={Constants.Color.primary}
                                        width="18px"
                                      />
                                    </button>
                                  ) : null
                                }
                              </SectionTitle>
                              <CustomScroller
                                contentPrefix="combo-offers"
                                noMargin
                                content={(
                                  <div
                                    className="d-flex gap-20 pb-2"
                                  >
                                    {bogoOffers.results.map((item) => (
                                      <BogoOfffer
                                        key={item.id}
                                        item={item}
                                        language={language}
                                        cartItems={cartItems}
                                        history={history}
                                        isMobile={isMobile}
                                        page={SHOP_FRONT}
                                        section="Exciting Combo Offer"
                                      />
                                    ))}
                                  </div>
                                )}
                              />
                            </section>
                          ) : null
                      }
                      {
                        !!storeCatProducts && storeCatProducts.count > 0
                          ? (
                            <>
                              {
                                storeCatProducts.data.map((category) => (
                                  <TopCategory
                                    {...this.props}
                                    category={category}
                                    language={language}
                                    storeName={storeDetails.store_name}
                                    storeId={storeId}
                                    history={history}
                                    isMobile={isMobile}
                                    changeStoreState={this.changeStoreState}
                                    scrollMainContainerToTop={this.scrollToTop}
                                  />
                                ))
                              }
                            </>
                          ) : null
                      }
                      {
                        (ackBanners?.length > 0)
                          ? (
                            <section
                              className="r__container_rest"
                            >
                              <BottomBanners
                                ackBanners={ackBanners}
                                page={SHOP_FRONT}
                                language={language}
                                isMobile={isMobile}
                                toggleSideOverlay={toggleSideOverlay}
                              />
                            </section>
                          ) : null
                      }
                      {
                        (brandSpaces?.featured.length > 0)
                          ? (
                            <section className="">
                              <SectionTitle
                                titleCopy={Constants.String.FEATURED[language]}
                                hasLink={false}
                              />
                              <FeaturedBanner
                                banners={brandSpaces?.featured}
                                captureBrandBannerClicked={this.handleCaptureBrandBannerClicked}
                                {...this.props}
                              />
                            </section>
                          ) : null
                      }
                      {
                        (brandSpaces?.focusToday.length > 0)
                          ? (
                            <section className="">
                              <SectionTitle
                                titleCopy={Constants.String.IN_FOCUS_TODAY[language]}
                                hasLink={false}
                              />
                              <CustomScroller
                                noMargin
                                content={(
                                  <div className="r__flex r__flex-28">
                                    {brandSpaces?.focusToday?.map((brand) => (
                                      <button
                                        key={brand.brandId}
                                        type="button"
                                        className="r__button_clean"
                                        onClick={() => this.onBannerClick(brand)}
                                      >
                                        <QuickCard
                                          name={brand.name}
                                          image={(isMobile) ? brand.image : brand.webimage}
                                          cardStyle={
                                            {
                                              width: isMobile ? '140px' : '350px',
                                              height: isMobile ? '180px' : '222px',
                                              overflow: 'hidden',
                                              borderRadius: '16px',
                                            }
                                          }
                                        />
                                      </button>
                                    ))}
                                  </div>
                                )}
                                contentPrefix="inFocusToday"
                              />
                            </section>
                          ) : null
                      }
                      {
                        (
                          isMobile
                          && storeCategories
                          && !!storeCategories.count
                        ) && (
                          <Container
                            fluid
                            className="px-0"
                          >
                            <Row
                              className="py-1 bg-light"
                            />
                            <Row
                              className="mx-0 py-7 align-items-center"
                            >
                              <Col
                                xs={24}
                                className="px-0 fs-4 font-weight-black"
                              >
                                {Constants.String.SHOP_BY_CATEGORY[language]}
                              </Col>
                              <Col
                                xs={24}
                                className="px-0"
                              >
                                <List
                                  idField="categoryId"
                                  match={match}
                                  Component={StoreCategory}
                                  list={storeCategories.data}
                                  history={history}
                                  storeId={storeId}
                                  onCategoryClick={(cat) => {
                                    history.push({
                                      pathname: `/shops/category-products/${storeId}`,
                                      state: {
                                        sname: storeDetails.store_name,
                                        productCategory: {
                                          code: 'category',
                                          ...cat,
                                        },
                                      },
                                    });
                                  }}
                                  toggleLogin={toggleUserLogin}
                                  isMobile={isMobile}
                                  screen={STORE_DETAILS}
                                />
                              </Col>
                            </Row>
                          </Container>
                        )
                      }
                      {
                        storeDetails && (
                          <div
                            ref={this.storySectionRef}
                          >
                            <StoreRetailerStory
                              {...this.props}
                              storeDetails={storeDetails}
                              isMobile={isMobile}
                              language={language}
                              storeId={storeId}
                            />
                          </div>
                        )
                      }
                    </Col>
                  ) : storeState === 'REPEAT_PRODUCTS_SEE_ALL' ? (
                    <Col xs={24} md={19} className="r__flex-30 d-flex flex-column h-100 p-4 p-md-6">
                      <RepeatProductsSeeAll
                        {...this.props}
                        storeId={storeId}
                        storeName={storeDetails.store_name}
                        changeStoreState={this.changeStoreState}
                      />
                    </Col>
                  ) : storeState === 'CATEGORY_SEE_ALL' ? (
                    <Col xs={24} md={19} className="r__flex-30 d-flex flex-column h-100 px-0">
                      <CategoryProductsSeeAll
                        // key is required to avoid creating instances of same component
                        key={selectedCategory ? selectedCategory.id : ''}
                        {...this.props}
                        storeId={storeId}
                        selectedCategory={selectedCategory}
                        storeName={storeDetails.store_name}
                        changeStoreState={this.changeStoreState}
                      />
                    </Col>
                  ) : storeState === 'COMBO_OFFER_SEE_ALL' ? (
                    <Col xs={24} md={19} className="r__flex-30 d-flex flex-column h-100 p-4 p-md-6">
                      <ComboOffersSeeAll
                        {...this.props}
                        storeId={storeId}
                        changeStoreState={this.changeStoreState}
                      />
                    </Col>
                  ) : storeState === 'TOP_SAVING_PRODUCTS_SEE_ALL' ? (
                    <Col xs={24} md={19} className="r__flex-30 d-flex flex-column h-100 p-4 p-md-6">
                      <TopSavingSeeAll
                        {...this.props}
                        storeId={storeId}
                        storeName={storeDetails.store_name}
                        changeStoreState={this.changeStoreState}
                      />
                    </Col>
                  ) : storeState === 'STORE_SEARCH_PRODUCT' ? (
                    <Col xs={24} md={19} className="r__flex-30 d-flex flex-column h-100 p-4 p-md-6">
                      <StoreSearchResults
                        {...this.props}
                        key="search-results"
                        changeStoreState={this.changeStoreState}
                        searchText={searchText}
                        storeName={storeDetails.store_name}
                        storeId={storeId}
                        searchResults={searchResults}
                      />
                    </Col>
                  ) : null
                }
              </Row>
            </Container>
          ) : null
        }
        {
          storeDetails && (
            <div className="bg-light py-4 py-md-8">
              <StoreFooterSEOData
                storeDetails={storeDetails}
                language={language}
                b2bStorePreview={b2bStorePreview}
                categories={storeCategories}
              />
            </div>
          )
        }
        {
          !b2bStorePreview && (
            <div
              className="d-md-none bg-black"
            >
              <FooterMobile
                {...this.props}
                language={language}
              />
              <div className="p-6" />
            </div>
          )
        }
        <div
          className="d-none d-md-block bg-black"
        >
          <Footer
            {...this.props}
            language={language}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  processing: state.main.requestsProcessing,
  failure: state.main.requestsFailure,
  brandSpaces: state.main.brandSpaces,
  ackBanners: state.main.ackBanners,
  bogoOffers: state.main.bogoOffers,
  cartItems: state.main.cartItems,
  selectedAddress: state.main.selectedAddress,
  cartId: state.main.cartId,
  offers: state.main.offers,
});

const mapDispatchToProps = (dispatch) => ({
  brandSpacesReq: (cancelToken, onSuccess, onFailure) => {
    dispatch(
      BrandSpaceActionTypes.brandSpaceLoadActions.loadRequest(
        onSuccess, onFailure, cancelToken, { requestFrom: 'WEB', page: 'HOME' },
      ),
    );
  },
  bogoOffersRequest: (onSuccess, cancelToken, storeId) => {
    dispatch(bogoOffersLoadRequest(0, 6, onSuccess, cancelToken, storeId));
  },
  offersRequest: (storeId, cancelToken) => {
    dispatch(offersLoadRequest(0, 6, null, cancelToken, storeId));
  },
  toggleUserLogin: () => dispatch(toggleLogin()),
  ackBannersReq: () => {
    dispatch(AckBannersActionTypes.ackLoadActions.loadRequest('', {
      section: 'REFER',
    }));
  },
});

StoreDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeId: PropTypes.string,
      shopURL: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      item: PropTypes.shape({
        name: PropTypes.string,
        valid_until: PropTypes.string,
      }),
    }),
  }),
  selectedAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  bogoOffers: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
  offers: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
  cartId: PropTypes.string,
  cartItems: PropTypes.shape({}),
  ackBanners: PropTypes.arrayOf(PropTypes.shape({})),
  brandSpaces: PropTypes.shape({
    focusToday: PropTypes.arrayOf(PropTypes.shape({})),
    featured: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  brandSpacesReq: PropTypes.func.isRequired,
  bogoOffersRequest: PropTypes.func.isRequired,
  offersRequest: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  toggleUserLogin: PropTypes.func.isRequired,
  categoryProductSearchLoadRequest: PropTypes.func.isRequired,
  ackBannersReq: PropTypes.func.isRequired,
};

StoreDetails.defaultProps = {
  location: null,
  bogoOffers: null,
  offers: null,
  cartItems: null,
  cartId: null,
  ackBanners: null,
  selectedAddress: null,
  brandSpaces: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);
