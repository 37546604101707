import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import {
  cartAddOfferRequest, cartDeleteOfferRequest,
} from 'app/store/cart/actions';
import { useDispatch } from 'react-redux';
import { ActionAlert } from 'app/components/common';
import Cart from 'app/pages/cart/Cart';
import { logButtonClick } from 'app/events/Events';
import { Constants, Utils } from '../../../utilities';
import { errorImageFallback, getCDNImageUrl } from '../../../utilities/Utils';
import * as buttonActions from '../../../events/buttonActions';
import * as ctEvents from '../../../events/Events';

const SpendOfferCard = ({
  item,
  language,
  history,
  isStoreView,
  isOrderDetailView,
  isCheckoutView,
  page,
  isOfferPage,
  isMobile,
  spendOfferInCart,
  cardHeight,
  cardWidth,
  onOfferClick,
  toggleSideOverlay,
}) => {
  const dispatch = useDispatch();
  const [alertReason, setAlertReason] = useState(null);
  const [spendOfferError, setSpendOfferError] = useState(null);

  const offerDisabled = isCheckoutView && !item.isApplicable;
  const offerAlreadyApplied = spendOfferInCart && spendOfferInCart.id === item.offerId;

  const style = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '330px',
      padding: '0',
      border: 'none',
      borderRadius: '16px',
      background: 'linear-gradient(to right, #ffffff 70%, #f1faf0 100%)',
    },
    left: {
      display: 'flex',
      alignItems: 'center',
      height: cardHeight || '126px',
      width: '98px',
      padding: '12px 8px',
      borderRadius: '16px 0 0 16px',
      border: '1px solid #005317',
      borderRight: 'none',
    },
    image_wrapper: {
      width: '77px',
      height: '77px',
      padding: '10px',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: (isOfferPage) ? '' : '16px',
      border: (isOfferPage) ? '' : '1px solid var(--black-6, #C8C8C8)',
    },
    image: {
      width: '100%',
    },
    barrier: {
      paddingRight: '12px',
      position: 'relative',
      overflow: 'hidden',
      height: cardHeight || '126px',
      width: '12px',
    },
    circleTop: {
      position: 'absolute',
      top: 0,
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: '1px solid #005317',
    },
    circleBottom: {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: '1px solid #005317',
    },
    right: {
      display: 'flex',
      alignItems: 'center',
      height: cardHeight || '126px',
      width: cardWidth,
      paddingRight: '4px',
      borderRadius: '0 16px 16px 0',
      border: '1px solid #005317',
      borderLeft: 'none',
    },
    copy: {
      width: offerDisabled || cardWidth ? '210px' : '140px',
      // paddingRight: '8px',
      marginLeft: '-6px',
    },
    copyStore: {
      whiteSpace: 'break-spaces',
      display: '-webkit-box',
      webkitLineClamp: '2',
      webkitBoxOrient: 'vertical',
      width: offerDisabled ? '210px' : '140px',
      overflow: 'hidden',
      textAlign: 'left',
      fontSize: '11px',
      textTransform: 'uppercase',
      color: '#505050',
    },
    button: {
      width: '70px',
      fontSize: '14px',
      fontWeight: '900',
      color: '#B8238E',
    },
  };

  const shopPath = `/shops/${item.store.id || item.store.code}`;

  const handleSpendOfferRequest = (offer, apply) => {
    logButtonClick(
      {
        BUTTON: item.offerType !== 'SPECIAL_SPEND_OFFER'
          ? buttonActions[
            item.appliedStatus
              ? 'REMOVE_VALUE_OFFER'
              : 'APPLY_VALUE_OFFER'
          ]
          : buttonActions[
            item.appliedStatus
              ? 'REMOVE_SPEND_OFFER'
              : 'APPLY_SPEND_OFFER'
          ],
        SPEND_OFFER: item,
        SCREEN: page,
      },
    );
    const offerType = offer.offerType === 'SPECIAL_SPEND_OFFER' ? 'SPEND_OFFER' : 'VALUE_OFFER';
    const onSuccess = {
      key: 'async',
      callback: () => {
        setAlertReason(apply
          ? 'SPEND_OFFER_APPLIED'
          : 'SPEND_OFFER_REMOVED');
      },
    };
    const onFailure = {
      key: 'async',
      callback: (data) => {
        setAlertReason('SPEND_OFFER_FAILURE');
        setSpendOfferError((data && data.errorMsg) || Constants.String.OOPS[language]);
      },
    };
    if (apply) {
      dispatch(
        cartAddOfferRequest.addRequest(
          onSuccess,
          onFailure,
          {
            offerId: offer.offerId,
            offerType,
          },
          {},
        ),
      );
    } else {
      dispatch(
        cartDeleteOfferRequest.deleteRequest(
          onSuccess,
          onFailure,
          {
            offerId: offer.offerId,
            offerType,
          },
          {},
        ),
      );
    }
  };

  let title = null;
  let message = '';
  let actionOnConfirm = null;
  let actionOnCancel = null;
  let confirmText = '';
  let cancelText = '';
  if (alertReason) {
    switch (alertReason) {
      case 'REMOVE_SPEND_OFFER':
        title = Constants.String.ARE_YOU_SURE[language];
        message = Constants.String.REMOVE_SPEND_OFFER_ALERT[language];
        cancelText = Constants.String.GO_BACK[language];
        confirmText = Constants.String.YES_REMOVE[language];
        actionOnCancel = () => {
          setAlertReason({
            alertReason: '',
            spendOffer: null,
          });
        };
        actionOnConfirm = () => {
          handleSpendOfferRequest(item, false);
          setAlertReason({
            alertReason: '',
            spendOffer: null,
          });
        };
        break;
      case 'REPLACE_SPEND_OFFER':
        title = Constants.String.REPLACE_OFFER[language];
        message = Constants.String.REPLACE_OFFER_DESC[language];
        cancelText = Constants.String.DISMISS[language];
        confirmText = Constants.String.OKAY[language];
        actionOnConfirm = () => {
          handleSpendOfferRequest(item, true);
          setAlertReason('');
        };
        actionOnCancel = () => {
          setAlertReason('');
        };
        break;
      case 'SPEND_OFFER_APPLIED':
        title = Constants.String.OFFER_APPLIED[language];
        message = Constants.String.OFFER_APPLIED_DESC[language];
        confirmText = Constants.String.YAY[language];
        actionOnConfirm = () => {
          setAlertReason('');
          if (isCheckoutView) {
            if (!isMobile) {
              toggleSideOverlay(Cart);
              return;
            }
            history.push('/cart');
          }
        };
        break;
      case 'SPEND_OFFER_REMOVED':
        title = Constants.String.OFFER_REMOVED[language];
        message = Constants.String.OFFER_REMOVED_DESC[language];
        confirmText = Constants.String.OKAY[language];
        actionOnConfirm = () => {
          setAlertReason('');
        };
        break;
      case 'SPEND_OFFER_FAILURE':
        message = spendOfferError;
        cancelText = Constants.String.OKAY[language];
        actionOnCancel = () => {
          setAlertReason('');
        };
        break;
      default:
        break;
    }
  }

  return (
    <button
      type="button"
      style={style.wrapper}
      onClick={() => {
        if (!isCheckoutView && !isStoreView) {
          ctEvents.launchShopFront(
            {
              PAGE: page,
              STORE_NAME: item.store.name || item.store.displayName,
              SECTION: 'Super Saving',
            },
          );
          history.push({
            pathname: shopPath,
            state: { item },
          });
        }
        if (onOfferClick) {
          onOfferClick();
        }
      }}
    >
      <ActionAlert
        show={!!alertReason}
        title={title}
        message={message}
        actionOnCancel={actionOnCancel}
        actionOnConfirm={actionOnConfirm}
        cancelText={cancelText}
        confirmText={confirmText}
        isMobile={isMobile}
      />
      <div style={style.left}>
        {
          (item.type === 'SPEND_OFFERS' || item.offerType === 'SPECIAL_SPEND_OFFER')
            && (
            <div style={style.image_wrapper}>
              <img
                style={style.image}
                src={getCDNImageUrl(item.image) || Utils.LOVELOCAL.productPlaceholder}
                alt={item.image ? item.name : ''}
                title={item.image ? item.name : ''}
                onError={(event) => {
                  errorImageFallback(event, Utils.LOVELOCAL.productPlaceholder);
                }}
              />
              <div className={`${isOfferPage ? 'r__offer_card-label--offerspage' : 'r__offer_card-label'}`}>
                {(isOfferPage) ? 'Free!' : 'Free'}
              </div>
            </div>
            )
        }
        {
          (item.type === 'VALUE_OFFERS')
            && (
              <div className={`r__offer_card-image ${offerDisabled ? 'disabled' : ''}`}>
                {item.offerAmount}
              </div>
            )
        }
        {
          (item.offerType === 'SPECIAL_VALUE_OFFER')
            && (
              <div className={`r__offer_card-image ${offerDisabled ? 'disabled' : ''}`}>
                &#x20B9;
                {item.discountAmount}
                {' '}
                off
              </div>
            )
        }
      </div>
      <div style={style.barrier}>
        <div style={style.circleTop} />
        <div style={style.circleBottom} />
      </div>
      <div style={style.right}>
        <div style={style.copy}>
          <div className={`r__offer_card-title word-break ${offerDisabled ? 'disabled' : ''} ${isStoreView ? 'shop_front' : ''}`}>
            {' '}
            {item.name}
          </div>
          <div style={style.copyStore}>
            {
              isCheckoutView
                ? (
                  <>
                    {!item.isApplicable ? (
                      <div
                        className="font-weight-bold text-primary"
                      >
                        {item.errorMessage}
                      </div>
                    ) : (
                      <div className="fs-7">
                        OFFER AUTO APPLIES
                      </div>
                    )}
                  </>
                )
                : !isOrderDetailView
                && !isStoreView && (
                  <div>{item.store.name}</div>
                )
            }
          </div>
        </div>
        <div>
          {
            isCheckoutView
            && item.isApplicable
            && (
              <Button
                className={`border-radius-16 p-0 ${offerAlreadyApplied ? 'text-black' : ''}`}
                variant={item.appliedStatus ? 'outline-secondary disabled' : 'link'}
                style={style.button}
                disabled={offerAlreadyApplied}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSpendOfferRequest(item, !item.appliedStatus);
                }}
              >
                {
                  Constants.String[
                    offerAlreadyApplied ? 'APPLIED' : 'APPLY'
                  ][language].toUpperCase()
                }
              </Button>
            )
          }
          {
            !isCheckoutView && !isOrderDetailView && !isStoreView && (
              <div
                style={style.button}
              >
                {Constants.String.SHOP[language].toUpperCase()}
              </div>
            )
          }
        </div>
      </div>
    </button>
  );
};

SpendOfferCard.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    spendAmount: PropTypes.number,
    type: PropTypes.string,
    offerAmount: PropTypes.string,
    discountAmount: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.number,
      name: PropTypes.string,
      displayName: PropTypes.string,
      locality: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      storeType: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
    validUntil: PropTypes.string,
    valid_until: PropTypes.string,
    appliedStatus: PropTypes.bool,
    offerType: PropTypes.string,
    errorString: PropTypes.string,
    isApplicable: PropTypes.bool,
    errorMessage: PropTypes.string,
    offerId: PropTypes.number,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  isStoreView: PropTypes.bool,
  isCheckoutView: PropTypes.bool,
  isOrderDetailView: PropTypes.bool,
  page: PropTypes.string,
  isOfferPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  spendOfferInCart: PropTypes.shape({
    id: PropTypes.number,
  }),
  cardHeight: PropTypes.string,
  cardWidth: PropTypes.string,
  onOfferClick: PropTypes.func,
};

SpendOfferCard.defaultProps = {
  isStoreView: false,
  isCheckoutView: false,
  isOrderDetailView: false,
  page: '',
  isOfferPage: false,
  isMobile: false,
  spendOfferInCart: null,
  cardHeight: '',
  cardWidth: '',
  onOfferClick: null,
};

export default SpendOfferCard;
