import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components/common';
import { Button } from 'react-bootstrap';
import { logApplyCouponCheckoutClick } from 'clevertap/LogEvent';
import { Constants, Utils } from '../../../utilities';
import Toast from '../../../HOC/Toast';
import * as ctEvents from '../../../events/Events';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-in', options);
  return (formattedDate);
}

const CouponCard = ({
  item, showToast, isApplyCoupon, applyCoupon, page,
  isStoreView,
}) => (
  <button
    type="button"
    className="r__coupon_card"
    onClick={() => {
      ctEvents.couponCodeCopied(
        {
          PAGE: page,
          Coupon_id: item.id,
          Coupon_Code: item.couponCode,
          Section: 'Super Savings',
        },
      );
      Utils.copyToClipboard(item ? item.couponCode : '');
      showToast(`Copied to clipboard: ${item.couponCode}`);
    }}
  >
    <div className={`r__coupon_card-left ${isStoreView ? 'isStoreView' : ''}`}>
      <span className="r__coupon_card-offer">
        &#8377;
        {`${item.maxDiscount} OFF`}
      </span>
    </div>
    <div className={`r__coupon_card-barrier ${isStoreView ? 'isStoreView' : ''}`}>
      <div className="r__coupon_card-circleTop" />
      <div className="r__coupon_card-circleBottom" />
    </div>
    <div className={`r__coupon_card-right ${isStoreView ? 'isStoreView' : ''}`}>
      <div className="r__coupon_card-copy">
        <div className="r__coupon_card-title">
          {item.description}
        </div>
        <div className="r__coupon_card-sub">
          <span className="r__coupon_card-code">{item.couponCode}</span>
          {' '}
          |
          {' '}
          <span className="r__coupon_card-validity">
            Expires on
            {' '}
            {formatDate(item.validTill)}
          </span>
        </div>
        {
          !isStoreView && (
            <div className="r__coupon_card-logo">
              <Svg
                svg="logo"
                fill={Constants.Color.primary}
                stroke={Constants.Color.white}
                strokeWidth="0px"
                width="18px"
              />
              <span className="r__coupon_card-logo-text">LOVELOCAL</span>
            </div>
          )
        }
      </div>
      <div className={`r__coupon_card-btn ${applyCoupon && isApplyCoupon ? 'align-self-center' : ''}`}>
        {
          isApplyCoupon && applyCoupon ? (
            <Button
              variant="link"
              onClick={(e) => {
                e.stopPropagation();
                applyCoupon(item.couponCode);
                logApplyCouponCheckoutClick();
              }}
              className="px-0 fs-5"
            >
              APPLY
            </Button>
          ) : 'COPY'
        }
      </div>
    </div>
  </button>
);

CouponCard.propTypes = {
  item: PropTypes.shape({
    validTill: PropTypes.string,
    id: PropTypes.number,
    canApply: PropTypes.bool,
    maxDiscount: PropTypes.number,
    campaignName: PropTypes.string,
    type: PropTypes.string,
    couponCode: PropTypes.string,
    displayText: PropTypes.string,
    description: PropTypes.string,
    discountType: PropTypes.string,
  }).isRequired,
  showToast: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isApplyCoupon: PropTypes.bool,
  isStoreView: PropTypes.bool,
  applyCoupon: PropTypes.func,
  page: PropTypes.string.isRequired,
};

CouponCard.defaultProps = {
  isApplyCoupon: false,
  applyCoupon: null,
  isStoreView: false,
};

export default Toast(CouponCard);
