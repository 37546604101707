import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Spinner, FormCheck, Row,
} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getGeoAddress } from '../../../api/api';
import { Constants } from '../../utilities';
import { isGuestUser } from '../../utilities/Utils';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import Svg from './Svg';
import * as buttonActions from '../../events/buttonActions';
import { logButtonClick } from '../../events/Events';
import '../../styles/account/location_search.scss';
import Shimmer from '../../shimmer/Shimmer';
import { fbLogCustomEvent } from '../../../facebook/Facebook';

const addressPayload = {
  addressText: '',
  location: null,
  isCompleted: true,
  isDefault: true,
  flatNumber: '',
  buildingName: '',
  streetName: '',
  localityName: '',
  landmark: '',
  pinCode: '',
  addressType: 'Home',
};

const addressTypeLabels = [
  'Home',
  'Work',
  'Other',
];

class LocationSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      address: '',
      addressText: '',
      addressTypeLabel: 'Home',
      location: null,
      error: null,
      loader: true,
    };
    this.searchPlacesRef = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    resetRequestStatus([ActionTypes.ADD_ADDRESS_REQUEST]);
  }

  init = () => this.googleMapApi()
    .then(() => {
      if (document.getElementById('google-apis')) {
        this.setState({
          loader: false,
        }, () => {
          this.searchPlacesRef.current.focus();
        });
      }
    })
    .catch(() => {
      this.setState({
        loader: false,
        error: Constants.String.OOPS,
      });
    })

  googleMapApi = () => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q&libraries=places&country=IN';
    script.async = true;
    script.id = 'google-apis';

    script.onload = () => {
      resolve('https://maps.googleapis.com/maps/api/js?key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q&libraries=places&country=IN');
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(script);
  })

  handleChange = (address) => {
    this.setState({ address });
  }

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          addressText: results[0].formatted_address,
        });
        return getLatLng(results[0]);
      })
      .then((location) => {
        this.setState({ location });
      })
      .catch(() => {
        this.setState({
          error: Constants.String.OOPS,
        });
      });
  }

  handleAddressTypeLabel = (label) => {
    this.setState({ addressTypeLabel: label });
  }

  locateMe = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      this.setState({
        location: {
          lat: latitude,
          lng: longitude,
        },
      });
      getGeoAddress(`${latitude}, ${longitude}`)
        .then((res) => {
          const address = res.data.results[0].formatted_address;
          this.setState({
            address,
            addressText: address,
          });
        })
        .catch(() => {
          this.setState({
            error: Constants.String.OOPS,
          });
        });
    }, () => {
      this.setState({
        error: Constants.String.LOCATE_ME_ERROR,
      });
    });
  }

  addNewAddress = () => {
    const { addressText, location, addressTypeLabel } = this.state;
    const { addAddress, onSuccess, onSubmitAddAddress } = this.props;
    let addressType = addressTypeLabel;
    if (addressTypeLabel === 'Other') {
      addressType = document.getElementById('input-address-type').value;
    }
    const addAddressPayload = {
      ...addressPayload,
      addressText,
      location,
      addressType,
      isDummy: false,
      locationId: `Guest-${(new Date()).getTime()}`,
    };
    if (onSubmitAddAddress) {
      onSubmitAddAddress(addAddressPayload);
    } else {
      addAddress(addAddressPayload, onSuccess);
    }
  }

  render() {
    const {
      address, addressText, addressTypeLabel,
      location, error, loader,
    } = this.state;
    const {
      isMobile, language, requestsProcessing,
      requestsFailure, screen, checkoutAddressAddStatus,
      checkoutAddAddressError,
    } = this.props;
    const processing = requestsProcessing[ActionTypes.ADD_ADDRESS_REQUEST] || checkoutAddressAddStatus === 'loading';
    const failure = requestsFailure[ActionTypes.ADD_ADDRESS_REQUEST] || checkoutAddressAddStatus === 'error';

    return (
      <div
        className="location-search"
      >
        {
          loader ? (
            <Row
              className="justify-content-center"
            >
              <Shimmer
                shimmerType="FALLBACK"
              />
            </Row>
          ) : (
            <PlacesAutocomplete
              value={address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              debounce={500}
              shouldFetchSuggestions={address.length > 3}
            >
              {({
                getInputProps, suggestions, getSuggestionItemProps, loading,
              }) => (
                <div
                  className="position-relative"
                >
                  <div
                    className={`form-control form-control-lg d-flex ${error
                      ? 'border-danger' : ''} align-items-center`}
                  >
                    <span
                      className="d-flex align-items-center"
                    >
                      <Svg
                        svg="search"
                        fill={Constants.Color.medium}
                        width="1rem"
                      />
                    </span>
                    <input
                      {...getInputProps({
                        ref: this.searchPlacesRef,
                        placeholder: Constants.String.SEARCH_PLACES[language],
                        className: 'pl-2 location-search-input w-100 fs-4 border-0 shadow-none',
                      })}
                    />
                    <Button
                      variant="link"
                      className={address.length === 0 ? 'd-none' : 'text-secondary px-1 fs-2'}
                      onClick={() => {
                        this.setState({
                          address: '',
                          addressText: '',
                          location: null,
                          addressTypeLabel: null,
                        });
                      }}
                    >
                      &times;
                    </Button>
                  </div>
                  {
                    error ? (
                      <div
                        className="fs-6 text-danger pt-1"
                      >
                        {error[language]}
                      </div>
                    ) : ''
                  }
                  <div
                    className="autocomplete-dropdown-container position-absolute border bg-white"
                  >
                    {
                      loading && (
                        <div className="loading">Loading...</div>
                      )
                    }
                    {
                      suggestions.map((suggestion) => (
                        <div
                          {
                            ...getSuggestionItemProps(
                              suggestion,
                              {
                                className: `suggestion-item ${suggestion.active ? ' active' : ''}`,
                              },
                            )
                          }
                        >
                          <span>
                            {suggestion.description}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                  <div
                    className="mb-3"
                  >
                    <Button
                      id="locate-me"
                      variant="link"
                      className="d-flex align-items-center px-0 pb-0"
                      onClick={() => {
                        this.locateMe();
                        logButtonClick(
                          {
                            BUTTON: buttonActions.LOCATE_ME,
                            SCREEN: screen,
                          },
                        );
                      }}
                    >
                      <span
                        className="d-flex align-items-center"
                      >
                        <Svg
                          svg="locate-me"
                          fill={Constants.Color.primary}
                          width="1rem"
                        />
                      </span>
                  &nbsp;&nbsp;
                      {Constants.String.LOCATE_ME[language]}
                    </Button>
                  </div>
                  <div
                    className={
                  !location || addressText === ''
                    ? 'd-none' : 'mb-3'
                }
                  >
                    <b>{Constants.String.YOUR_SELECTED_ADDRESS[language]}</b>
                    <div
                      className="mb-3"
                    >
                      {addressText}
                    </div>
                    <div
                      className="d-flex flex-row mb-3"
                    >
                      {
                    addressTypeLabels.map((item, index) => (
                      addressTypeLabel === 'Other' && item === 'Other'
                        ? (
                          <input
                            key={item}
                            type="text"
                            maxLength={20}
                            id="input-address-type"
                            placeholder="Enter label"
                            className="ml-3 p-0 border-0 text-primary shadow-none"
                            autoComplete="off"
                          />
                        ) : (
                          <FormCheck
                            key={item}
                            custom
                            type="radio"
                            id={item}
                            label={item}
                            checked={addressTypeLabel === item}
                            className={index ? 'ml-3' : ''}
                            style={{ zIndex: 0 }}
                            onChange={() => this.handleAddressTypeLabel(item)}
                          />
                        )
                    ))
                  }
                    </div>
                    {
                  failure && (
                    <div
                      className="text-danger fs-5 mb-2 text-center"
                    >
                      {checkoutAddAddressError || Constants.String.OOPS[language]}
                    </div>
                  )
                }
                    <div
                      className="text-center"
                    >
                      {
                    processing ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      <Button
                        id="add-address-submit"
                        block={isMobile}
                        className="fs-2 py-2 px-6 w-100"
                        onClick={() => {
                          this.addNewAddress();
                          fbLogCustomEvent('LocationSearchNextButtonClick');
                          logButtonClick(
                            {
                              BUTTON: buttonActions.SUBMIT_NEW_ADDRESS,
                              SCREEN: screen,
                            },
                          );
                        }}
                        disabled={processing}
                      >
                        {
                          isGuestUser()
                            ? Constants.String.NEXT[language].toUpperCase()
                            : Constants.String.NEXT[language]
                        }
                      </Button>
                    )
                  }
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.main.customer,
  requestsProcessing: state.main.requestsProcessing,
  requestsFailure: state.main.requestsFailure,
});

const mapDispatchToProps = (dispatch) => ({
  addAddress: (address, onSuccess) => {
    dispatch(Actions.addAddress(address, onSuccess));
  },
  updateSelectedAddress: (address) => {
    dispatch(Actions.updateSelectedAddress(address));
  },
  resetRequestStatus:
  (requests) => dispatch(Actions.resetRequestStatus(requests)),
});

LocationSearch.propTypes = {
  customer: PropTypes.shape({
    isDummy: PropTypes.bool,
  }),
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  addAddress: PropTypes.func.isRequired,
  updateSelectedAddress: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  requestsFailure: PropTypes.shape({}).isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  onSuccess: PropTypes.shape({}).isRequired,
  screen: PropTypes.string.isRequired,
  checkoutAddAddressError: PropTypes.string,
  checkoutAddressAddStatus: PropTypes.string,
  onSubmitAddAddress: PropTypes.func,
};

LocationSearch.defaultProps = {
  customer: null,
  checkoutAddAddressError: null,
  checkoutAddressAddStatus: '',
  onSubmitAddAddress: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearch);
