import React, { Component } from 'react';
import {
  Row, Col, Form, Container, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Constants, Config, Storage } from '../../utilities';
import * as Actions from '../../store/Actions';
import { synctohomedelivery } from '../../../api/api';
import { isAuthenticated, isGuestUser } from '../../utilities/Utils';
import { logButtonClick } from '../../events/Events';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import { fbLogCustomEvent } from '../../../facebook/Facebook';

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: props.language,
    };
  }

  handleOnChange = () => {
    const {
      history, changeLanguage, callback, isMobile,
    } = this.props;
    const { selectedLanguage } = this.state;
    let currentPath = '/';
    if (
      history.location.state
      && history.location.state.currentPathName
    ) {
      currentPath = history.location.state.currentPathName;
    }
    changeLanguage(selectedLanguage);
    if (callback) {
      callback();
    }
    if (isMobile) {
      history.push('/');
    }
    if (isAuthenticated()) {
      this.sync();
    }
  }

  sync = (count = 0) => {
    const { selectedLanguage } = this.state;
    synctohomedelivery(
      'PATCH',
      Storage.getUUID(),
      {
        language_code: selectedLanguage,
      },
    ).then(() => {})
      .catch(() => {
        if (count + 1 > 3) {
          return;
        }
        this.sync(count + 1);
      });
  }

  render() {
    const { isMobile, language } = this.props;
    const { selectedLanguage } = this.state;

    return (
      <Container
        className="h-100 d-flex flex-column px-0 bg-white container"
      >
        <Row
          className="mx-0 bg-white"
        >
          {
            Config.languages.map((item) => (
              <Col
                xs={24}
                className="cursor-pointer"
                key={item.key}
                onClick={() => {
                  this.setState({
                    selectedLanguage: item.key,
                  });
                }}
              >
                <Row
                  className="p-4 border-top d-flex flex-direction-row"
                >
                  <Col
                    xs="auto"
                    className="px-0"
                  >
                    <div
                      className="fs-4 font-weight-bold text-primary"
                    >
                      {item.displayTextHeading}
                    </div>
                    <div
                      className="text-secondary fs-6"
                    >
                      {item.displayTextSubHeading}
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="ml-auto px-0 d-flex align-items-center justify-content-center"
                  >
                    <Form.Check
                      custom
                      label=""
                      type="radio"
                      name="language"
                      id={item.id}
                      key={item.key}
                      checked={selectedLanguage === item.key}
                    />
                  </Col>
                </Row>
              </Col>
            ))
          }
        </Row>
        <Row
          className={`mx-0 mt-auto px-4 py-2 mt-auto ${isMobile ? '' : 'pb-4'}`}
        >
          <Button
            size="lg"
            variant="primary"
            className="font-weight-black w-100"
            onClick={() => {
              fbLogCustomEvent('LanguageSelectionButton');
              if (language !== selectedLanguage) {
                logButtonClick({
                  BUTTON: buttonActions.CHANGE_LANGUAGE,
                  SCREEN: screens.ACCOUNT,
                  CHNAGED_FROM: language,
                  CHANGED_TO: selectedLanguage,
                });
              }
              if (isGuestUser()) {
                fbLogCustomEvent('StartShopping');
              }
              this.handleOnChange();
            }}
          >
            {
              isGuestUser()
                ? Constants.String.START_SHOPPING[language].toUpperCase()
                : (
                  <>
                    {(isMobile && !Storage.getLanguage())
                      ? Constants.String.CONTINUE[language].toUpperCase()
                      : Constants.String.UPDATE[language].toUpperCase()}
                  </>
                )
            }
          </Button>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.main.language,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (language) => {
    dispatch(Actions.changeLanguage(language));
  },
});

Language.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      state: PropTypes.shape({
        currentPathName: PropTypes.string,
      }),
    }),
    push: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

Language.defaultProps = {
  callback: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
