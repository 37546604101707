import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import { logButtonClick } from '../../events/Events';
import { getSEOFriendlyURL } from '../../utilities/Utils';

const ShopByCategory = (props) => {
  const { categories } = props;
  const [showCategory, setShowCategory] = useState(true);

  const handleButtonClick = () => {
    setShowCategory(false);
    setTimeout(() => {
      setShowCategory(true);
    }, 50);
  };

  return (
    <div
      className={`${showCategory ? 'shop-by-category' : 'd-none'}`}
    >
      {
        categories && categories.map((category) => (
          <div
            key={category.categoryId}
            className="nav-category"
          >
            <Link
              to={`/pc/${
                getSEOFriendlyURL(category.categoryId)
              }`}
            >
              <Button
                variant="link"
                className="p-3 text-left d-flex align-items-center mt-0 text-black"
                block
                onClick={() => {
                  handleButtonClick();
                  logButtonClick(
                    {
                      BUTTON: buttonActions.CATEGORY,
                      SCREEN: screens.CATEGORY_LANDING,
                      CATEGORY_ID: category.categoryId,
                    },
                  );
                  LogClevertapEvent.categoryFilterChanged(
                    false,
                    category.displayName,
                    category.categoryId,
                  );
                }}
              >
                {/* <img
                  className="w-40p border mr-4"
                  src={getCDNImageUrl(category.image) || LOVELOCAL.categoryPlaceholder}
                  alt=""
                /> */}
                <span
                  className="fs-6 category-text text-left"
                >
                  {category.displayName}
                </span>
              </Button>
            </Link>
            <div
              className="nav-sub-category shadow"
            >
              {
                category.children.map((subCategory) => (
                  <Link
                    key={subCategory.categoryId}
                    to={`/pc/${
                      getSEOFriendlyURL(category.categoryId)
                    }/${
                      getSEOFriendlyURL(subCategory.categoryId)
                    }`}
                  >
                    <Button
                      key={subCategory.categoryId}
                      variant="link"
                      className="p-4 text-black d-flex align-items-center bg-white"
                      block
                      onClick={() => {
                        handleButtonClick();
                        LogClevertapEvent.categoryFilterChanged(
                          true,
                          subCategory.displayName,
                          subCategory.categoryId,
                        );
                        logButtonClick(
                          {
                            BUTTON: buttonActions.SUB_CATEGORY,
                            SCREEN: screens.CATEGORY_LANDING,
                            CATEGORY_ID: category.categoryId,
                            SUB_CATEGORY_ID: subCategory.categoryId,
                          },
                        );
                      }}
                    >
                      <span
                        className="fs-6 sub-category-text text-left"
                      >
                        {subCategory.displayName}
                      </span>
                    </Button>
                  </Link>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

ShopByCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

ShopByCategory.defaultProps = {
  categories: null,
};

export default ShopByCategory;
