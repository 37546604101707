import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components/common';
import { Constants } from 'app/utilities';
import { launchShopFront } from 'app/events/Events';
import { getCDNImageUrl, getSeoShopUrl } from 'app/utilities/Utils';
import CustomImage from 'app/components/common/CustomImage';
import SocialShareOverlay from 'app/layout/social-share/SocialShareOverlay';

const StoreCard = ({
  item, language, screen, toggleSideOverlay, isOnSeoPage, isMobile,
}) => {
  const rating = parseFloat(item.rating);

  const badges = [];
  if (item.isPremium) badges.push('premium');
  if (item.isGreatOffers) badges.push('greatOffers');
  if (item.isTrending) badges.push('trending');
  if (item.isWideVariety) badges.push('wideVariety');
  if (item.isBestRated) badges.push('bestRated');
  if (item.isNewlyOpened) badges.push('newlyOpened');

  const renderBadge = (badge) => {
    switch (badge) {
      case 'premium':
        return (
          <Svg
            svg="premium"
            height="20"
            width="74"
          />
        );
      case 'greatOffers':
        return (
          <Svg
            svg="greatOffers"
            width="81"
            height="20"
          />
        );
      case 'trending':
        return (
          <Svg
            svg="trending"
            width="71"
            height="20"
          />
        );
      case 'wideVariety':
        return (
          <Svg
            svg="wideVariety"
            width="86"
            height="21"
          />
        );
      case 'bestRated':
        return (
          <Svg
            svg="bestRated"
            width="80"
            height="20"
          />
        );
      case 'newlyOpened':
        return (
          <Svg
            svg="storeNew"
            width="43"
            height="20"
          />
        );

      default:
        return null;
    }
  };

  const { shopUrl } = getSeoShopUrl(
    item.country || '',
    item.city || '',
    item.locality || '',
    item.storeType?.name || item.storeType?.displayName || '',
    item.name || item.displayName || '',
    item.storeId || item.code || '',
  );

  return (
    <div className="r__store_wrapper">
      <div className="r__store_container">
        <div className="r__store_main">
          <a
            href={shopUrl}
            onClick={() => {
              launchShopFront(
                {
                  SCREEN: screen,
                  STORE_NAME: item.name || item.displayName,
                },
              );
            }}
            className="r__store_card"
          >
            <div className="r__store_image">
              <CustomImage
                imageUrl={getCDNImageUrl(item.logo || item.shopImagePlaceholder)}
                imageAlt={item.name || item.displayName}
                imageTitle={item.name || item.displayName}
                imageType="STORE"
              />
            </div>
            <div className="r__store_copy">
              <h4 className="r__store_title">
                {item.name || item.displayName}
              </h4>
              <div className="r__store_type">
                {item.storeType.name || (item.storeType.displayName).toLowerCase()}
              </div>
              <div className="r__store_badges">
                {
                  badges.slice(0, 2).map((badge) => (
                    <div
                      key={badge}
                      className="d-flex"
                    >
                      {
                        renderBadge(badge)
                      }
                    </div>
                  ))
                }
              </div>
              <div className="r__store_params1">
                {rating !== 0 ? (
                  <>
                    <div className="r__store_rating">
                      <Svg
                        svg="newStar"
                        height="16"
                        width="16"
                        fill=""
                      />
                      <span>{rating}</span>
                    </div>
                    <span className="r__store_params-dot" />
                  </>
                ) : null}
                {
                  (item.productTransferMode?.homeDelivery || item.onHomeDelivery)
                    ? (
                      <div className="r__store_hours">
                        <Svg
                          svg="vehical"
                          height="16"
                          width="16"
                        />
                        {
                          (item.averageDeliveryTime)
                            ? (
                              <span>
                                {item.averageDeliveryTime / 60}
                                {' '}
                                Hours
                              </span>
                            )
                            : (
                              <>
                                { (!isOnSeoPage) ? (
                                  <span>
                                    {item.averageDeliveryTimeString}
                                  </span>
                                ) : null}
                              </>
                            )
                        }
                        {
                          (item.expectedDeliveryTime)
                            ? (
                              <span>
                                {item.expectedDeliveryTime}
                                {' '}
                                Delivery
                              </span>
                            )
                            : null
                        }
                      </div>
                    )
                    : Constants.String.SHOP_PICKUP_ONLY[language]
                }
              </div>
              <div className="r__store_params2">
                {(item.calculatedDistance) ? (
                  <span className="r__store_distance">
                    {(item.calculatedDistance / 1000).toFixed(2)}
                    {' '}
                    Km
                  </span>
                ) : null}
                {(item.distance) ? (
                  <span className="r__store_distance">{item.distance}</span>
                ) : null}
                {((item.calculatedDistance && item.locality) || (item.distance && item.locality))
                  ? (<span className="r__store_params-dot" />) : null}
                {item.locality ? (
                  <span className="r__store_locality">{item.locality}</span>
                ) : null}
              </div>
            </div>
          </a>
          <button
            type="button"
            className="r__store_share-btn"
            onClick={() => {
              toggleSideOverlay(() => (
                <SocialShareOverlay
                  language={language}
                  isMobile={isMobile}
                  storeDetails={item}
                />
              ), true, true);
            }}
          >
            <Svg
              svg="shareButton"
            />
          </button>
        </div>
        <div className="r__store_offer">
          <Svg
            svg="graphicOffer"
            width="16px"
            circleFill=""
            pathFill={Constants.Color.black}
          />
          <div className="r__store_offer-text">
            {(item.offerText || item.offerSummary?.specialOffer?.text || item.offerSummary?.discountOffer?.text) ? item.offerText || item.offerSummary?.specialOffer?.text || item.offerSummary?.discountOffer?.text : 'Exciting Offers Inside'}
          </div>
        </div>
      </div>
    </div>
  );
};

StoreCard.propTypes = {
  item: PropTypes.shape({
    storeId: PropTypes.number,
    name: PropTypes.string,
    logo: PropTypes.string,
    storeType: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
      displayName: PropTypes.string,
    }),
    locality: PropTypes.string,
    calculatedDistance: PropTypes.number,
    calculatedDistanceMeasurement: PropTypes.string,
    averageDeliveryTimeString: PropTypes.string,
    averageDeliveryTime: PropTypes.number,
    isDelivering: PropTypes.bool,
    deliveryTimeMeasurement: PropTypes.string,
    rating: PropTypes.number,
    isGreatOffers: PropTypes.bool,
    isWideVariety: PropTypes.bool,
    isPremium: PropTypes.bool,
    isNewlyOpened: PropTypes.bool,
    isBestRated: PropTypes.bool,
    isVerified: PropTypes.bool,
    isTrending: PropTypes.bool,
    offerText: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    productTransferMode: PropTypes.shape({
      storePickup: PropTypes.bool,
      homeDelivery: PropTypes.bool,
    }),
    code: PropTypes.string,
    displayName: PropTypes.string,
    distance: PropTypes.string,
    expectedDeliveryTime: PropTypes.string,
    isFavorite: PropTypes.bool,
    nextOnlineTime: PropTypes.string,
    offerSummary: PropTypes.shape({
      discountOffer: PropTypes.shape({
        text: PropTypes.string,
      }),
      specialOffer: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
    pickupOnlyAllowed: PropTypes.bool,
    shopImagePlaceholder: PropTypes.string,
    phoneNumber: PropTypes.number,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    avgRating: PropTypes.string,
    ratingCount: PropTypes.number,
    address: PropTypes.string,
    pincode: PropTypes.string,
    onHomeDelivery: PropTypes.bool,
  }).isRequired,
  language: PropTypes.string.isRequired,
  screen: PropTypes.string.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  isOnSeoPage: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

StoreCard.defaultProps = {
  isOnSeoPage: false,
};

export default StoreCard;
